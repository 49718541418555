import React from 'react';

import './feedback.scss';

class Feedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }
    
    okFeedback = () => {
        console.log("satisfied/neutral");
    }


    render () {
        const { negativeFeedback } = this.props;
        return (
            <div className="menu">
                <ul>
                    <li onClick={this.okFeedback}><i className="material-icons">sentiment_satisfied_alt</i></li>
                    <li onClick={this.okFeedback}><i className="material-icons">sentiment_satisfied</i></li>
                    <li onClick={negativeFeedback}><i className="material-icons">sentiment_very_dissatisfied</i></li>
                </ul>
            </div>
        )
    }; 
}


export default Feedback