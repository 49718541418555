import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Toggle from 'react-toggle'
import firebase from '../auth/auth';
import school from '../../resources/school-solid.svg'

import Spinner from '../spinner/spinner'
import './manageClasses.scss'
import ManageClassesPopup from './manageClassesPopup';
import ChangeNamePopup from './changeNamePopup';
import WarningPopup from './warningPopup';
import SignUpPopup from './signUpPopup'
import { func } from 'prop-types';


class ManageClasses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {
        this.getStudents();
    }


    getStudents() {
        const { props } = this;

        if (!props.profile.school) return;

        let subjectString = ""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }

        // Subject specific class is assigned to user during sign up
        // Subject specific class is assigned to subject-specific studentProgress during sign up
    
        firebase.firestore().collection(`studentProgress${subjectString}`).where('school', '==', props.profile.school).orderBy('lastName')
            .get()
            .then(querySnapshot => {
                const students = querySnapshot.docs.map(doc => doc.data());
                

                students.map((student)=>{
                    if (!student[`${subjectString}class`]) {

                        firebase.firestore().collection(`studentProgress${subjectString}`).where('user', '==', student.user)
                        .get()
                        .then(querySnapshot => {
                            const studentProgress = querySnapshot.docs[0].id
                            firebase.firestore().collection(`studentProgress${subjectString}`).doc(studentProgress).update({[`${subjectString}class`]: "Unassigned"})
                            return student
                        })
                    } 
                })

                this.setState({
                    students,
                    //CONFIG: sets active subject as subject for session
                    subject: this.props.profile.subject              
                });

                return students;
            }).then(() => this.getClasses())
    }

    getClasses() {        
        const { props } = this;
        const { students } = this.state;

        if (!props.profile.school) return;

        let subjectString = ""
        if (this.state.subject!=="Economics") {
            subjectString = this.state.subject
        }
        
        this.setState({transferring: false})

        firebase.firestore().collection('schools').where('school', '==', props.profile.school)
            .get()
            .then(querySnapshot => {
                
                const school = querySnapshot.docs.map(doc => doc.data());

                const id = querySnapshot.docs[0].id
                this.setState({id : id})
                
                let classes = {}
                //If science and no classes for any science
                if ((subjectString.includes("gcseBiologySingleS")
                || subjectString.includes("gcseChemistry")
                || subjectString=="Physics")
                && !school[0]['gcseBiologySingleScienceAQAclasses']
                && !school[0]['gcseChemistrySingleScienceAQAclasses']
                && !school[0]['gcsePhysicsSingleScienceAQAclasses']
                ) {
                    console.log("No classes set up")
                    classes["Unassigned"]= students
                    this.setState({
                        classes,
                        activeClass: "Unassigned",
                        noClasses: true,
                    });
                }
                //If science subject, no classes, but classes for another science
                if ((subjectString.includes("gcseBiology")
                || subjectString.includes("gcseChemistry")
                || subjectString=="Physics")
                && 
                (school[0]['gcseBiologySingleScienceAQAclasses']
                || school[0]['gcseChemistrySingleScienceAQAclasses']
                || school[0]['gcsePhysicsSingleScienceAQAclasses'])
                ) {
                    console.log("Importing classes from other science")
                    this.setState({
                        classes: school[0]['gcseBiologySingleScienceAQAclasses'] || school[0]['gcseChemistrySingleScienceAQAclasses'] || school[0]['gcsePhysicsSingleScienceAQAclasses'],
                        activeClass: "Unassigned",
                    }, this.save);
                }
                //If not a science subject and no classes
                else if (!(subjectString.includes("gcseBiology")
                    && !subjectString.includes("gcseChemistry")
                    && !subjectString=="Physics")
                    && !school[0][subjectString+'classes']) {
                        console.log("No classes set up")
                        classes["Unassigned"]= students
                        this.setState({
                            classes,
                            activeClass: "Unassigned",
                            noClasses: true,
                        });
                } else {
                    this.setState({
                        classes: school[0][subjectString+'classes'],
                        activeClass: Object.keys(school[0][subjectString+'classes'])[0],
                        noClasses: false,
                    })
                }

                let newClassObj = {};

                //Reference the studentProgress records and create classlist. This runs every time component is loaded
                //Must retrieve empty classes from the school classlist otherwise these will disappear
                    this.state.students.map(student => {
                    //Creates class props in newClassObj then adds 
                    if (!newClassObj[(student[subjectString+'class'])]) {
                        newClassObj[(student[subjectString+'class'])]=[]
                    }
                    newClassObj[(student[subjectString+'class'])].push(student)
                    })
                    if(!newClassObj["Unassigned"]) {
                        newClassObj["Unassigned"]=[]
                    }

                    //Adds empty classes to newClassObj
                    school[0][subjectString+'classes'] && Object.keys(school[0][subjectString+'classes']).map(nameClass =>{
                        if (nameClass!=="Unassigned" && school[0][subjectString+'classes'][nameClass].length==0) {
                            console.log(nameClass + "is emmpty")
                            newClassObj[nameClass]=[]
                        }
                    })

                    //Adds classes to school profile
                    //Only run this if importing other science subjects as save is handled elsewhere
                    if (!((subjectString.includes("gcseBiology")
                    || subjectString.includes("gcseChemistry")
                    || subjectString=="Physics")
                    && 
                    (school[0]['gcseBiologySingleScienceAQAclasses']
                    || school[0]['gcseChemistrySingleScienceAQAclasses']
                    || school[0]['gcsePhysicsSingleScienceAQAclasses'])
                    )) { 
                        firebase.firestore()
                        .runTransaction(t => {
                            return t.get(firebase.firestore().collection('schools')
                                .doc(id))
                                .then(doc => {
                                    return t.update(firebase.firestore().collection('schools').doc(id), {[subjectString+'classes']: newClassObj})
                                })
                        })

                        this.setState({
                            classes: newClassObj,
                            activeClass: Object.keys(newClassObj)[0],
                            noClasses: false
                        })
                    }

                    //If there are classes, then load these. If not, then noClasses had already been set to true.
                    if (Object.keys(newClassObj).length > 1) {
                        this.setState({
                            classes: newClassObj,
                            activeClass: Object.keys(newClassObj)[0],
                            noClasses: false
                        })
                    }

                    

                // }

                //If classes don't exist & not sciences
                // if (
                //     !(subjectString.includes("gcseBiology")
                //     && !subjectString.includes("gcseChemistry")
                //     && !subjectString=="Physics")
                //     &&
                //     !school[0][subjectString+'classes']
                // ) {
                //     this.state.students.map(student => {
                //         //Creates class props in newClassObj then adds 
                //         if (!newClassObj[(student[subjectString+'class'])]) {
                //             newClassObj[(student[subjectString+'class'])]=[]
                //         }
                //         newClassObj[(student[subjectString+'class'])].push(student)
                //         })
                //         console.log(newClassObj)
    
                //         //Adds classes to classObj 
                //         firebase.firestore()
                //         .runTransaction(t => {
                //             return t.get(firebase.firestore().collection('schools')
                //                 .doc(id))
                //                 .then(doc => {
                //                     return t.update(firebase.firestore().collection('schools').doc(id), {[subjectString+'classes']: newClassObj})
                //                 }).then(() => {
                //                     this.setState({
                //                         classes: newClassObj,
                //                         activeClass: Object.keys(newClassObj)[0],
                //                         noClasses: false
                //                     }, this.getClasses)
                //                 })
                //         })
    
                // }


                //REMOVED 6th May: moved to system where school class list is referenced against studentProgress each time getClasses is called. New school class list
                // is written to school profile each time getClasses is called.

                // //Finds all students that are not within a class list in the schools table, assign them to the Unassigned class.
                //     //Makes an array of all students currently found in class lists
                //     let assignedIDstudents = []
                //     school[0][subjectString+'classes'] && Object.keys(school[0][subjectString+'classes']).map(classID => {
                //         school[0][subjectString+'classes'][classID].map(student => {
                //             assignedIDstudents.push(student.user)
                //         })
                //     })
                    
                //     //Finds students who are not part of a class list
                //     //Very important- this should only run when a teacher has already created classes otherwise unassignedIDstudents will be empty
                //     let unassignedstudents = []
                //     assignedIDstudents.length >0 && school[0][subjectString+'classes'] && this.state.students.map(student => {
                //         if (student[subjectString+'class']==="Unassigned") {
                //             if (assignedIDstudents.indexOf(student.user)<0){
                //                 unassignedstudents.push(student)
                //             }
                //         }
                //     })

                //     console.log('unassigned', unassignedstudents)


                //     //Only have to assign missing students if array is not empty
                //     if (unassignedstudents.length >0 ) {
                //         console.log('reassigning missing students')
                //         this.setState({transferring: true})
                //         let classesCopy = JSON.parse(JSON.stringify(school[0][subjectString+'classes']))
                //         unassignedstudents.map(student => {
                //             classesCopy["Unassigned"].push(student)
                //         })

                //         // Assign missing students to unassigned class
                //         firebase.firestore().runTransaction(t => {
                //             return t.get(firebase.firestore().collection('schools')
                //                 .doc(id))
                //                 .then(doc => {
                //                     return t.update(firebase.firestore().collection('schools').doc(id), {[subjectString+'classes']: classesCopy})
                //                 }).then(() => this.getClasses())
                //         })
                //     }



                return school;
            })
    }

    getDropdown() {

        const { noClasses, classes, activeClass } = this.state

        if (noClasses) {
            return (
                <select className="selectSortManageClasses" onChange={this.toggleClass}>
                    <option>Unassigned</option>
                </select>
            )
        }

        if (!noClasses) {            
            return (
                <select className="selectSortManageClasses" onChange={this.toggleClass} value={activeClass}>
                    {Object.entries(classes).map((e) => {
                        return (
                        <option key={e[0]}>{e[0]}</option>
                        )
                        })
                    }
                </select>
            )
        }
        
    }

    getStudentDropdown (obj) {

        const { classes, activeClass } = this.state


        return (
            <select className="studentSelectSort" onChange={(e) => this.moveStudent(e, obj)} value={activeClass}>
                {Object.entries(classes).map((e) => {
                    return (
                    <option key={e[0]}>{e[0]}</option>
                    )
                    })
                }
            </select>
        )
    }

    async moveStudent (event, obj) {
        const nameClass = event.target.value
        const {activeClass} = this.state

        let classesCopy = JSON.parse(JSON.stringify(this.state.classes));
        // Object.assign
        /*{
            name: 'John',
            student: true,
            list: [1,2,3]
        }*/

        //locates the new class
        var newClass = classesCopy[nameClass]

        //pushes the student object into the new class
        newClass.push(obj)

        //adds the revised active class array to the active class property
        classesCopy[nameClass] = newClass
        
        //removes the student object from the old class
        var oldClass = classesCopy[activeClass]
        
        let revisedOldClass = oldClass.filter(el => el.user !== obj.user)
        
        classesCopy[activeClass] = revisedOldClass
/*
        this.setState({
            classes: classesCopy
        })
        this.save(obj, nameClass)

        const resolvedData = new Promise((resolve, reject) => {
            this.setState({
                classes: classesCopy
            })

            resolve('Message!')
        })
        .then(()=> this.save(obj, nameClass) )*/

        console.log('Waiting 1 second...')
        const resolvedData = await this.updateClasses(classesCopy);
        
        
        this.save(obj, nameClass);
    }

    updateClasses(classes) {
        return new Promise((resolve, reject) => {
            this.setState({
                classes
            })

            setTimeout(() => {
                resolve('Message!');
            }, 1000)
        })
    }

    getClassContainer () {
        const {noClasses, classes, activeClass} = this.state

        if (noClasses) {
            return (
                <div className="classContainer1">
                    
                    
                    <div className="eightyFlex">
                        <p className="flexCentre">You haven't added any classes yet</p>
                    </div>
                    
                </div>
            )
        } else if (classes) {

            
            
            return (
                <div className="classContainer1">
                    
                    <div className="eightyFlex">
                        {
                            Object.entries(classes).map((e) => {
                                return (
                                    <div className="classNameContainerManageClasses" key={e[0]} onClick={(()=>{this.changeClass(e[0])})}>
                                        <p key={e[0]} className="classNameManageClasses">{e[0]}</p>
                                        {e[0]!="Unassigned" ?
                                            <div className="flexRowClasses">
                                                <div className="flexRowButtons">
                                                    <p className="signupTextManageClasses" onClick={(()=>this.toggleSignUpPopup(e[0]))}>Sign-up students to this class</p>
                                                </div>
                                                <div className="flexRowButtons">
                                                    <i className="material-icons flexAuto" onClick={(()=>this.changeName(e[0]))} key={e[0]}>edit</i>
                                                </div>
                                                <div className="flexRowButtons">
                                                    <i className="material-icons red-text flexAuto" onClick={(() => this.toggleWarning(e[0]))} key={e[0]}>  
                                                        close
                                                    </i>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
    }

    toggleSignUpPopup = (classID) => {
        //Makes new signup key each time button is pressed?
        let randomKey= Math.random().toString(36).substr(2, 11)
        
        //Only retrieve relevant key when Popup is being opened, do not retrieve when Popup is being closed
        if(classID) {
            firebase.firestore().collection("signUpKeys")
            .where('school', '==', this.props.profile.school)
            .where('subject', '==', this.props.profile.subject)
            .where("classID", '==', classID)
            .get()
            .then(doc => {
                if (!doc.empty) {
                    console.log(doc.docs[0].id)
                    randomKey=doc.docs[0].id
                } else {
                    firebase.firestore().collection("signUpKeys")
                    .doc(randomKey)
                    .set({
                        school: this.props.profile.school,
                        subject: this.props.profile.subject,
                        classID: classID
                    })
                }
                return
            }).then(()=>{
                this.setState({
                    signUpKey: randomKey,
                    signUpPopup: this.state.signUpPopup ? false : true
                })
            })
        } else {
            this.setState({
                signUpPopup: this.state.signUpPopup ? false : true
            })
        }

        
    }

    getStudentContainer () {

        const {classes, activeClass} = this.state

        return (
            <ul>
            {classes[activeClass].map((obj,index) => {
                return(
                    <div className="studentContainerManageClasses" key={`student-container-${obj.user}`}>
                        <div className="flex">
                            <i className="material-icons studentIcon">account_circle</i>
                        </div>
                        <p className="studentManageClasses" key={`name${index}`}>{obj.firstName} {obj.lastName}</p>
                        {this.getStudentDropdown(obj)}
                    </div>
                )
            }) }
            </ul>

        )

    }

    addClass = () => {
        this.setState ({
            popUp: true
        })
    }

    toggleWarning = (e) => {

        this.setState ({
            warning: true,
            classSelector : e
        })
        

    }

    closeWarning = () => {
        this.setState ({
            warning: false
        })
    }

    removeClass = (e) => {
        let subjectString = ""
        if (this.state.subject!=="Economics") {
            subjectString = this.state.subject
        }

        let classesCopy = JSON.parse(JSON.stringify(this.state.classes));
        
        var key=e

        classesCopy[key].map((student)=>{
            firebase.firestore().collection('studentProgress'+subjectString).where('user', '==', student.user)
            .get()
            .then(querySnapshot => {
                const studentProgress = querySnapshot.docs[0].id

                firebase.firestore().collection('studentProgress'+subjectString).doc(studentProgress).update({[subjectString+'class']: "Unassigned"})
                firebase.firestore().collection('users').doc(studentProgress).update({[subjectString+'class']: "Unassigned"})


                //For science students only
                let subjectArray= ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                if (subjectArray.includes(subjectString)) {
                    subjectArray.map(subj => {
                        if (subj!==subjectString) {
                            firebase.firestore().collection('studentProgress'+subj).doc(studentProgress).update({[subj+'class']: "Unassigned"})
                            firebase.firestore().collection('users').doc(studentProgress).update({[subj+'class']: "Unassigned"})
                        }
                    })
                }
            })
        })


        const newUnassinged= classesCopy[key].concat(classesCopy["Unassigned"])
        //Handles case where active class is the one being removed
        if (this.state.activeClass=e) {
            this.setState({activeClass:"Unassigned"})
        }
        delete classesCopy[key]

        classesCopy["Unassigned"]=newUnassinged
        
        return new Promise((resolve, reject) => {
        this.setState({
            classes:classesCopy
        }, resolve())
        }).then(()=> this.save() )

    }

    toggleEditor = () => {
        const { popUp } = this.state
        if (popUp) {
            this.setState (
                {popUp: false}
                )
        }
    }

    handleNewClass = (name) => {
        let classesCopy = Object.assign({}, this.state.classes);
        classesCopy[name]=[]

        return new Promise((resolve, reject) => {
        this.setState({
            classes:classesCopy,
            noClasses: false
        },resolve())
        }).then(()=> this.save() )
    }

    changeName = (e) => {
        const {changeName} = this.state
        if (changeName) {
            this.setState ({
                changeName: false
            })
        } else {
            this.setState ({
                changeName: true,
                nameSelector: e
            })
        }
    }

    renameClass = ( old, rename ) =>  {
        let subjectString = ""
        if (this.state.subject!=="Economics") {
            subjectString = this.state.subject
        }

        let classesCopy = Object.assign({}, this.state.classes);
        classesCopy[rename]=classesCopy[old]

        classesCopy[old].map((student)=>{
            firebase.firestore().collection('studentProgress'+subjectString).where('user', '==', student.user)
            .get()
            .then(querySnapshot => {
                const studentProgress = querySnapshot.docs[0].id

                firebase.firestore().collection('studentProgress'+subjectString).doc(studentProgress).update({[subjectString+'class']: rename})
                firebase.firestore().collection('users').doc(studentProgress).update({[subjectString+'class']: rename})

                //For science students only
                let subjectArray= ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                if (subjectArray.includes(subjectString)) {
                    subjectArray.map(subj => {
                        if (subj!==subjectString) {
                            firebase.firestore().collection('studentProgress'+subj).doc(studentProgress).update({[subj+'class']: rename})
                            firebase.firestore().collection('users').doc(studentProgress).update({[subj+'class']: rename})
                        }
                    })
                }

            }).then(() => delete classesCopy[old])
            .then(()=> {
                return new Promise((resolve, reject) => {
                    
                    this.setState({
                        classes:classesCopy,
                        activeClass: rename
                    }, resolve())
                    }).then(()=> this.save() )
            })
        })

    }

    toggleClass = (event) => {
        this.setState ({
            activeClass: event.target.value
        })
    }

    changeClass = (selectClass) => {

        this.setState ({
            activeClass: selectClass
        })
    }

    save(obj, nameClass) {
        let subjectString = ""
        if (this.state.subject!=="Economics") {
            subjectString = this.state.subject
        }
        const { classes, id } = this.state


        //When classes as opposed to individuals are altered
        firebase.firestore().collection('schools')
        .doc(id)
        .update({[subjectString+'classes']: classes})

            //For science only: change classes for all subjects
            let subjectArray= ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
            if (subjectArray.includes(subjectString)) {
                subjectArray.map(subj => {
                    if (subj!==subjectString) {
                        firebase.firestore().collection('schools').doc(id).update({[subj+'classes']: classes})
                    }
                })
            }

        //When individuals as opposed to classes are altered
        if (obj) {
            firebase.firestore().collection('studentProgress'+subjectString).where('user', '==', obj.user)
            .get()
            .then(querySnapshot => {
                const studentProgress = querySnapshot.docs[0].id

                firebase.firestore().collection('studentProgress'+subjectString).doc(studentProgress).update({[subjectString+'class']: nameClass})
                firebase.firestore().collection('users').doc(studentProgress).update({[subjectString+'class']: nameClass})

                //Science only: change studentProgress, profile and class list of other sciences
                let subjectArray= ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                if (subjectArray.includes(subjectString)) {
                    subjectArray.map(subj => {
                        if (subj!==subjectString) {
                            firebase.firestore().collection('studentProgress'+subj).doc(studentProgress).update({[subj+'class']: nameClass})
                            firebase.firestore().collection('users').doc(studentProgress).update({[subj+'class']: nameClass})
                            firebase.firestore().collection('schools').doc(id).update({[subj+'classes']: classes})
                        }
                    })
                }
            })
        }

    }


    render() {
        const { auth, profile } = this.props;

        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />
        if (profile.school==="individual" && auth.isLoaded) return <Redirect to='/' />

        const { classes, activeClass, popUp, warning, classSelector, changeName, nameSelector, transferring, signUpPopup } = this.state;

        
        return (
            <div className="mobileContainerManageClasses">

                {popUp 
                ? <ManageClassesPopup toggleEditor={this.toggleEditor} handleNewClass={this.handleNewClass}/>
                : null}

                {warning
                ? <WarningPopup closeWarning={this.closeWarning} removeClass={this.removeClass} classSelector={classSelector}/>
                : null}

                {changeName
                ? <ChangeNamePopup changeName={this.changeName} nameSelector={nameSelector} renameClass={this.renameClass}/>
                : null}

                {signUpPopup
                ? <SignUpPopup 
                    toggleSignUpPopup={this.toggleSignUpPopup}
                    classID={this.state.activeClass}
                    school={this.props.profile.school}
                    signUpKey={this.state.signUpKey}
                    />
                : null
                }

                <div className="dashboardContainer">

                    {!classes || transferring
                        ? <Spinner />
                        :
                        <div className="container">

                            <div className="activeClassContainer">
                                <div className="flexColumnTenTop">
                                    <div className="flexRowManageClasses">
                                        <p className="myClasses">{profile.school}'s classes</p>
                                        <div className="addClass" onClick={this.addClass}>
                                            <p className="addClass">ADD CLASS</p>
                                            <div className="flexAuto">
                                                <i className="material-icons">add</i>
                                            </div>                 
                                        </div>
                                        <i className="material-icons addManageClasses" onClick={this.addClass}>add</i>
                                    </div>
                                {this.getClassContainer()}
                                </div>

                            </div>

                            { classes ? 
                            
                            <div className="activeClassContainer">
                                <div className="flexRowViewClass">
                                    <p className="viewClass">VIEW CLASS</p>
                                    {this.getDropdown()}
                                </div>

                                {this.getStudentContainer()}

                            
                            </div>
                            :
                            null }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

export default connect(mapStateToProps)(ManageClasses)
