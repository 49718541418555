import React from 'react';
import { connect } from 'react-redux'

class SimilarSchools extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    
    render() {

        const {similarSchools, schoolSelected } = this.props


        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp">
                            <h5 className="needHelp">Are you sure?</h5>
                            <p className="needHelp">Select the correct school from the list below</p>

                            <div>
                                <div className="schoolMenuSchoolContainer" onClick={() => this.props.selectSchoolFromPopUp(schoolSelected)}>
                                    <p className="schoolMenuSchoolText">{schoolSelected}</p>
                                </div>
                                {similarSchools.map (school => {
                                    return(
                                        <div className="schoolMenuSchoolContainer"  onClick={() => this.props.selectSchoolFromPopUp(school)}>
                                            <p className="schoolMenuSchoolText">{school}</p>
                                        </div>
                                    )
                                })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default SimilarSchools;