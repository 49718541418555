import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Route, Redirect, Link } from 'react-router-dom'
import Spinner from '../spinner/spinner'
import CategoryMenu from './categoryMenu'
import McqGraph from '../mcq/mcqGraph'
import Collapsible from 'react-collapsible';
import { getQuizzesTrial } from '../../helpers/getData';
import GlobalWarning from './globalWarning'

import './restrictions.scss';


class Restrictions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            category: "",
            categoryArray: [],
            numberOfQuestions: 25,
            categoryNumber: 0,
            activeClass: ""
        }



    }

    componentDidMount() {

        let subject
        this.props.profile.subject == "Economics"
            ? subject = ""
            : subject = this.props.profile.subject


        //Retrieves themes
        let themeArray = []
        firebase.firestore().collection("quizzes" + subject)
            .where("school", "==", "GradeIt")
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.map(doc => {
                    if (!themeArray.includes(doc.data()["quizCategory"])) {
                        themeArray.push(doc.data()["quizCategory"])
                    }
                })
                let themeArraySorted = themeArray.sort()
                console.log(themeArraySorted)

                //Retrieves classes
                firebase.firestore().collection('schools').where('school', '==', this.props.profile.school)
                .get()
                .then(querySnapshot => {
                    const school = querySnapshot.docs.map(doc => doc.data());
                    
                    let classArray = []
                    school[0][`${subject}classes`] && Object.keys(school[0][`${subject}classes`]).map(classID => {
                        let existingRestrictions = "(No existing restrictions)"
                        if (school[0][`${subject}restrictions`] && school[0][`${subject}restrictions`][classID] && Object.keys(school[0][`${subject}restrictions`][classID]).length>0) {
                            existingRestrictions = true
                        }
                        classArray.push([classID, existingRestrictions])
                    })
                    classArray.sort()

                    let globalRestrictions = false
                    if (school[0][`${subject}restrictions`]
                    && school[0][`${subject}restrictions`]["Global"]
                    && school[0][`${subject}restrictions`]["Global"]["active"]) {
                        globalRestrictions = true
                    }

                    if (!school[0][`${subject}classes`]) { 
                        this.setState({
                            restrictions: school[0][`${subject}restrictions`] || {},
                            globalRestrictions: globalRestrictions,
                            classArray: classArray,
                            activeClass: "All students",
                            noClasses: true,
                            themeArraySorted,
                            loaded: true
                        });
                    } else {
                        this.setState({
                            restrictions: school[0][`${subject}restrictions`] || {},
                            globalRestrictions: globalRestrictions, 
                            classArray: classArray,
                            activeClass: "All students",
                            noClasses: false,
                            themeArraySorted,
                            loaded: true
                        })
                    }
                }).then(() => this.getQuizzesTrialProps())
            })
    }

    getQuizzesTrialProps (props) {

        props = props || this.props;

        if (!props.profile.school) return;

        getQuizzesTrial(props.profile.school, this.props.profile.subject || this.state.subject)
            .then((quizzes) => {
                
                //Stops quizzes from changing if user toggles subject on another tab
                if (!this.state.quizzes) {
                    this.setState({
                        quizzes,
                    });
                }
            })
    }

    getRestrictions = () => {
        const { restrictionsArray, classArray, activeClass, restrictions, globalRestrictions } = this.state

        return (
            <div className="flexColumn getRestrictions">
            <p className="classesRestrictions">Existing restrictions for <span style={{"margin-left": "5px", color: "purple"}}> {activeClass[0]}</span></p>
            {activeClass[0]!=="Global"
            ?
                restrictions[activeClass[0]]
                ? this.getRestrictionsContainer()
                : this.getQuizzesContainer()
            :    
                globalRestrictions 
                ?   restrictions[activeClass[0]]
                    ? this.getRestrictionsContainer()
                    : this.getQuizzesContainer()
                :   
                <div className="restrictionsNoClassesContainer global">
                    <p className="restrictionsAddClasses global">Global restrictions not active</p>
                </div>
            }
            </div>
        )
    }

    getQuizzesContainer = () => {
        const {quizzes} = this.state
        console.log(quizzes)
        return (
            <div className="restrictionsCollapsibleContainer">
                {Object.entries(quizzes).map(([category, quizzesList], index) => (
                        <div className="quizLandingFormContainer" key={index+category}>
                            
                            <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">{category}</p>
                                </div>
                            }>
                                <div className="categoryContainers restrictions">
                                    {quizzesList.map(({ quizName, testMode, unlocked, flashcard, id, upvotes, userID, visibility }, index) => {
                                        return(
                                            <div className='themeContainer restrictions' key={index+quizName}>
                                                <p className="restrictionsQuizName">{quizName}</p>
                                                {visibility==false
                                                ?
                                                <i className="material-icons restrictionsQuizVisibility" 
                                                onClick={()=>this.toggleVisibility(category,quizName,true)}>
                                                    visibility_off
                                                </i>
                                                :
                                                <i className="material-icons restrictionsQuizVisibility" 
                                                onClick={()=>this.toggleVisibility(category,quizName,false)}>
                                                    visibility
                                                </i>
                                                }

                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            </Collapsible>
                        </div>
                    ))}
            </div>
        )
    }

    getRestrictionsContainer = () => {
        const {restrictions, activeClass} = this.state

        return (
            
            <div className="restrictionsCollapsibleContainer">
                {restrictions && restrictions[activeClass[0]]
                ?
                    Object.entries(restrictions[activeClass[0]]).sort().map(([category, quizzesList], index) => (
                        category!=="active"
                        ?
                            <div className="quizLandingFormContainer" key={index+category}>
                            
                                <Collapsible trigger={
                                    <div className="quizLandingThemeName">
                                        <p className="quizLandingThemeNameTextQuizDash">{category}</p>
                                    </div>
                                }>
                                <div className="categoryContainers restrictions">
                                    {quizzesList.length>0 && quizzesList.map(({ quizName, testMode, unlocked, flashcard, id, upvotes, userID, visibility }, index) => {
                                        return(
                                            <div className='themeContainer restrictions' key={index+quizName}>
                                                <p className="restrictionsQuizName">{quizName}</p>
                                                {visibility==false
                                                ?
                                                <i className="material-icons restrictionsQuizVisibility" 
                                                onClick={()=>this.toggleVisibility(category,quizName,true)}>
                                                    visibility_off
                                                </i>
                                                :
                                                <i className="material-icons restrictionsQuizVisibility" 
                                                onClick={()=>this.toggleVisibility(category,quizName,false)}>
                                                    visibility
                                                </i>
                                                }

                                            </div>
                                        )
                                    })
                                    }

                                </div>
                                </Collapsible>
                            </div>
                        :
                        null 
                    ))
                : null
                }
                
            </div>
        )
                
    }

    toggleVisibility = (category, quizName, visibility) => {

        let newRestrictions = {}
        
        if (Object.keys(this.state.restrictions).length ==0)  {
            //If no restrictions exist at all
            // let newQuizzes = Object.assign({}, this.state.quizzes);
            const newQuizzes = JSON.parse(JSON.stringify(this.state.quizzes));

            newQuizzes[category].map((quiz, index) => {
                if (quiz.quizName===quizName) {
                    newQuizzes[category][index]["visibility"]= visibility
                    const {activeClass} = this.state
                    newRestrictions[activeClass[0]]=newQuizzes
                    this.setState({
                        restrictions: {[this.state.activeClass[0]]:newQuizzes}
                    }, this.writeToDatabase(newRestrictions))
                }
            })
        } else {
            //If restrictions don't exist already for active class
            if (!this.state.restrictions[this.state.activeClass[0]]) {
                const newQuizzes = JSON.parse(JSON.stringify(this.state.quizzes));
                newQuizzes[category].map((quiz, index) => {
                    if (quiz.quizName===quizName) {
                        newQuizzes[category][index]["visibility"]= visibility
                        let newRestrictions = JSON.parse(JSON.stringify(this.state.restrictions))
                        const {activeClass} = this.state
                        newRestrictions[activeClass[0]]=newQuizzes

                        this.setState({
                            restrictions: newRestrictions
                        }, this.writeToDatabase(newRestrictions))

                    }
                })

            } else {
                //If restrictions exist already for active class
                //To copy an object from the state
                let newQuizzes = JSON.parse(JSON.stringify(this.state.restrictions[this.state.activeClass[0]]));

                newQuizzes[category].map((quiz, index) => {
                    if (quiz.quizName===quizName) {
                        newQuizzes[category][index]["visibility"]= visibility
                        let newRestrictions = JSON.parse(JSON.stringify(this.state.restrictions))
                        const {activeClass} = this.state
                        newRestrictions[activeClass[0]]=newQuizzes

                        this.setState({
                            restrictions: newRestrictions
                        }, this.writeToDatabase(newRestrictions))
                    }
                })
            }
        }

    }

    writeToDatabase = (newRestrictions) => {
        let subject
        this.props.profile.subject == "Economics"
            ? subject = ""
            : subject = this.props.profile.subject

        firebase.firestore().collection("schools")
        .where("school", "==", this.props.profile.school)
        .get()
        .then(querySnapshot => {
            querySnapshot.docs.map(school => {
                if (!school.data().unapproved)
                console.log(school.data())
                firebase.firestore().collection("schools")
                .doc(school.id)
                .update({
                    [`${subject}restrictions`]: newRestrictions
                })
                
                // TO ADD "NO EXISTING RESTRICTIONS"
                // .then(()=> {
                //     let newClassArray = []
                //     this.state.classArray.map(classID => {
                //         if (Object.keys(newRestrictions).includes(classID[0])) {

                //         }
                //     })

                // })
            })
        })

    }
    
    toggleGlobalWarning = () => {
        this.setState({
            globalWarning: this.state.globalWarning ? false : true
        })
    }

    applyGlobalRestrictions = (modifier) => {
        console.log(modifier)
        let newRestrictions = {}
        //If global restrictions already apply

        const Promise1 = new Promise((resolve,reject) => {
            if(this.state.restrictions["Global"]) {
                console.log("yoyo")
                let newQuizzes = JSON.parse(JSON.stringify(this.state.restrictions["Global"]));
                newQuizzes["active"]=this.state.globalRestrictions ? false : true
                let newRestrictions = JSON.parse(JSON.stringify(this.state.restrictions))
                newRestrictions["Global"]=newQuizzes
                resolve(newRestrictions)
            } else {
                console.log("yono")
                const newQuizzes = JSON.parse(JSON.stringify(this.state.quizzes));
                let newRestrictions = JSON.parse(JSON.stringify(this.state.restrictions))
                newRestrictions["Global"] = newQuizzes
                newRestrictions["Global"]["active"]=true
                console.log(newRestrictions)
                resolve(newRestrictions)
            }
        })

        Promise1.then((newRestrictions)=> {
            console.log(newRestrictions)

            this.setState({
                activeClass: ["Global"],
                globalWarning: false,
                globalRestrictions: this.state.globalRestrictions ? false : true,
                restrictions: newRestrictions
            }, this.writeToDatabase(newRestrictions))

        })
    }


    render() {

        const { restrictionsArray, classArray, activeClass, restrictions, globalWarning, globalRestrictions, loaded } = this.state
        let subjectName

        if (this.props.profile.subject && (
            this.props.profile.subject.includes("gcseBiology")
            || this.props.profile.subject.includes("gcseChemistry")
            || this.props.profile.subject=="Physics"
        )) {
            if (this.props.profile.subject==="gcseBiologySingleScienceAQA") {
                subjectName="Biology"
            } else if (this.props.profile.subject==="gcseChemistrySingleScienceAQA") {
                subjectName="Chemistry"
            } else if (this.props.profile.subject==="Physics"){
                subjectName="Physics"
            } else {
                subjectName= this.props.profile.subject
            }
        }

        return (
            <div>
                {loaded
                ? null
                : <Spinner />
                }
                {globalWarning
                ? 
                    <GlobalWarning
                        toggleGlobalWarning={this.toggleGlobalWarning}
                        applyGlobalRestrictions={this.applyGlobalRestrictions}
                        globalRestrictions={globalRestrictions} 
                    />
                : 
                    null
                }
                <div className="container">           
                    <div className="flexRow">
                        <div className="restrictionsFiftyLeft">
                            {activeClass && activeClass!=="All students"
                            ?
                            this.getRestrictions()
                            :
                            <div className="restrictionsContainerInitial">
                                <p className="restrictionsInitialText">Please select a class from the list on the right or create a class</p>
                            </div>
                            }
                            
                        </div>
                        <div className="restrictionsFiftyRight">
                            <Link to={'/manageclasses'} className="noClasses restrictions manage">
                                    <p className='assignButtonText restrictions'>Manage classes</p>
                                    <i className="material-icons assignButton">manage_accounts</i>
                            </Link>
                            <p className="classesRestrictions">Classes</p>
                            <p className="addRestrictionsText">Click on a class to add/view restrictions</p>
                            {loaded ?
                                <div className="restrictionsScrollableClassContainer">
                                    {globalRestrictions 
                                    ?
                                        activeClass=="Global"
                                        ?
                                        <div className={`restrictionsClassContainer global ${globalRestrictions}`} onClick={() => this.toggleGlobalWarning()}>
                                            <p className="restrictionsClassText">Global restrictions active- click to deactivate <span className="material-icons globalRestrictions">public</span></p>
                                        </div>
                                        :
                                        <div className={`restrictionsClassContainer global ${globalRestrictions}`} onClick={() => this.setState({activeClass: ["Global"]})}>
                                            <p className="restrictionsClassText">Global restrictions active- click to view <span className="material-icons globalRestrictions">public</span></p>
                                        </div>
                                    :
                                        <div className={`restrictionsClassContainer global ${globalRestrictions}`} onClick={() => this.toggleGlobalWarning()}>
                                            <p className="restrictionsClassText">Global restrictions not active- click to activate <span className="material-icons globalRestrictions">public</span></p>
                                        </div>
                                    }
                                    {classArray && classArray.length > 0 
                                        ?   
                                        classArray.map(classID => {
                                            return(
                                                <div key={classID} className="restrictionsClassContainer" onClick={()=> this.setState({activeClass: classID})}>
                                                    <p className="restrictionsClassText">{classID[0]}</p>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className="flexColumn restrictionsNoClasses">
                                            <div className="restrictionsNoClassesContainer">
                                                <p className="restrictionsAddClasses">You have not created any classes</p>
                                                <Link to={'/manageclasses'} className="assignButton noClasses restrictions">
                                                    <p className='assignButtonText'>Manage classes</p>
                                                    <i className="material-icons assignButton">manage_accounts</i>
                                                </Link>
                                            </div>                                
                                        </div>
                                    }
                                </div>
                                :
                                null
                            }

                        </div>

                    </div>

                        
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Restrictions)