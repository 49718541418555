import React from 'react';
import {connect} from 'react-redux'
import './econMaths.scss';
import MobileNavbar from '../layout/mobileNavbar'
import Collapsible from 'react-collapsible';
import { Link } from 'react-router-dom'
import mobileNavbar from '../layout/mobileNavbar';



class EconMaths extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount(){
        // document.addEventListener('DOMContentLoaded', function() {
        //     var elems = document.querySelectorAll('.collapsible');
        //     var instances = M.Collapsible.init(elems, options);
        //   });
    
    }

    link (location, target) {
        const newTab = target === '_blank';
        if (newTab) {
            window.open(location);
        } else {
            window.location = location;
        }
    }

    render() {

        return (
            <div>
                <MobileNavbar />
                <div className="container">
                    <div>
                        <div className="flexRow">
                        <h3 className="econMathsTitle">Economics for Mathematicians</h3>
                        <Link to={this.props.auth.uid? '/quizzes': '/trial'} className="resourceButton">
                            <p className="resourceButtonTitle">View A-level resources ></p>
                        </Link>
                        </div>
                        <p>This video series is designed for A-level/IB economics students who are also studying Maths. Great for Oxbridge interview preparation, or for those thinking of studying economics at degree level.</p>
                        <p className="econMathsBold">Be sure to attempt each worksheet before you watch the video!</p>
                    </div>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Video 1- solving for the market equilibrium</p>
                                    <a
                                    className="worksheetButton" 
                                    href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FSolving%20for%20the%20market%20equilibrium.pdf?alt=media&token=ea0138d9-76da-4e04-b7bb-b80ea847f95e"
                                    onClick={() => this.link('https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FSolving%20for%20the%20market%20equilibrium.pdf?alt=media&token=ea0138d9-76da-4e04-b7bb-b80ea847f95e', '_blank')}
                                    target="_blank">
                                        <i className="material-icons worksheetButton">sticky_note_2</i>
                                        <p>Worksheet</p>
                                    </a>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/8FZowtllsPs"  
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Video 2- differentiating to find marginal revenue</p>
                                    <a
                                    className="worksheetButton" 
                                    href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FMarginal%20revenue%20vs%20average%20revenue.pdf?alt=media&token=84608bc5-292d-45d1-8171-57d317040310"
                                    onClick={() => this.link('https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FMarginal%20revenue%20vs%20average%20revenue.pdf?alt=media&token=84608bc5-292d-45d1-8171-57d317040310', '_blank')} 
                                    target="_blank">
                                        <i className="material-icons worksheetButton">sticky_note_2</i>
                                        <p>Worksheet</p>
                                    </a>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/HP9lrkKGnZM" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Video 3- investigating average fixed cost</p>
                                    <a
                                    className="worksheetButton" 
                                    href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FAverage%20fixed%20cost.pdf?alt=media&token=7c00028a-03f4-404b-a8a2-70f37169b13d"
                                    onClick={() => this.link("https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FAverage%20fixed%20cost.pdf?alt=media&token=7c00028a-03f4-404b-a8a2-70f37169b13d", '_blank')}  
                                    target="_blank">
                                        <i className="material-icons worksheetButton">sticky_note_2</i>
                                        <p>Worksheet</p>
                                    </a>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/qaLAB62En_A" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Video 4- productive efficiency</p>
                                    <a
                                    className="worksheetButton" 
                                    href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FProductive%20Efficiency.pdf?alt=media&token=3a7e037b-0ef8-45dd-a535-5402bd6c1bdc"
                                    onClick={() => this.link("https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FProductive%20Efficiency.pdf?alt=media&token=3a7e037b-0ef8-45dd-a535-5402bd6c1bdc", '_blank')} 
                                    target="_blank">
                                        <i className="material-icons worksheetButton">sticky_note_2</i>
                                        <p>Worksheet</p>
                                    </a>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/1UFxjngHoHA" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Video 5- oligopoly kinked demand curve</p>
                                    <a
                                    className="worksheetButton" 
                                    href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FKinked%20Demand%20Curve.pdf?alt=media&token=c3bc0a56-19f3-4cd0-a8c9-464a1bcdcbd3"
                                    onClick={() => this.link("https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FKinked%20Demand%20Curve.pdf?alt=media&token=c3bc0a56-19f3-4cd0-a8c9-464a1bcdcbd3", '_blank')} 
                                    target="_blank">
                                        <i className="material-icons worksheetButton">sticky_note_2</i>
                                        <p>Worksheet</p>
                                    </a>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/1sKz1IOn6tk"  
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                    

                </div>
            </div>

        );     
    }
};

const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth

    }
}

export default connect(mapStateToProps)(EconMaths)