import React from 'react';
import './quizDropdown.css'

class QuizDropdown extends React.Component {

    render() {

        const quizzes = this.props.quizzes.map(({ quizName }, index) => {

            return (
            <div className="themeContainer">
                    <li key={index} className='themeListContainer'>
                        <a href={'/quizzes/' + quizName} className="quizName">
                            {quizName}
                        </a></li>
            </div>)
        });

        return (
            <ul className="bigContainer">
                {quizzes}
                {/* <li onClick={this.checkAnswer} className={classNames[3]} data-id="4"><span>D</span> <p>{answers[3]}</p></li> */}
            </ul>
        );
    }
}

export default QuizDropdown