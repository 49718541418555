import React from 'react';
import firebase from '../auth/auth';
import {connect} from 'react-redux';
import { createScore } from '../../store/actions/quizscoreActions'

import './feedback.scss';


class NegativeFeedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            feedback: '',
            stylingOne: '',
            stylingTwo: '',
            stylingThree: '',
            submittable: false
        };
    }
    
    handleChange = (e) => {
        let text = e.target.value;
        this.setState({
            feedback: text
        })
    }

    handleLink = () => {
        const textarea = document.querySelector('.feedbackTextAreaContainer');
        textarea.classList.add('active');
        this.setState({
            brokenLink: true,
            incorrectAnswer: false,
            incorrectExplanation: false,
            stylingOne: "active",
            stylingThree: '',
            stylingTwo: ''
        })
    }

    handleAnswer = () => {
        const textarea = document.querySelector('.feedbackTextAreaContainer');
        textarea.classList.add('active');
        this.setState({
            incorrectAnswer: true,
            incorrectExplanation: false,
            brokenLink: false, 
            stylingTwo: "active",
            stylingOne: '',
            stylingThree: ''
        })
    }

    handleExplanation = () => {
        const textarea = document.querySelector('.feedbackTextAreaContainer');
        textarea.classList.add('active');
        this.setState({
            incorrectExplanation: true,
            brokenLink: false,
            incorrectAnswer: false,
            stylingThree: "active",
            stylingTwo: '',
            stylingOne: ''
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { feedback, brokenLink, incorrectAnswer, incorrectExplanation } = this.state;
        const { quiz, nr, negativeFeedback } = this.props;
        // const checkSubmit = document.querySelector('.feedbackSubmit');
        let issue = '';
        if(brokenLink){
            issue = "brokenLink"
        } else if(incorrectAnswer){
            issue = "incorrectAnswer"
        } else if(incorrectExplanation){
            issue = "incorrectExplanation"
        }
        const date = new Date().toLocaleString();
        firebase.firestore().collection('feedback')
        .add({
            quiz, 
            questionNumber: nr, 
            issue,
            feedback, 
            date,
            email: this.props.profile.email,
            firstName: this.props.profile.firstName,
            lastName: this.props.profile.lastName
        }).then(() => {
            console.log("feedback logged");
        }).catch((err) => {
            console.log(err.message);
        })
        negativeFeedback();
        // checkSubmit.classList.remove('active');
    }

    

    render() {
        const { stylingOne, stylingTwo, stylingThree, brokenLink, incorrectAnswer, incorrectExplanation, feedback } = this.state;
        const { negativeFeedback } = this.props;
        const checkSubmit = document.querySelector('.feedbackSubmit');
        if (feedback || brokenLink || incorrectAnswer || incorrectExplanation){
            checkSubmit.classList.add('active');
            this.submittable = true;
        }
         return (
             <div className="popup-container">
                 <div className="container">
                     <div className="CCC maxWidth">
                         <div className="popupFeedback">
                             <div className="btn-floating closePopup" onClick={negativeFeedback}><i className="material-icons">close</i></div>
                             <h5 className="needHelp">What's wrong?</h5>
                             <div className={`fancy-btn feedback ${stylingOne}`} onClick={this.handleLink}>
                                 <i className="material-icons feedback">sentiment_very_dissatisfied</i>
                                 <p className="needHelp">Broken link</p>
                             </div>
                             <div className={`fancy-btn feedback ${stylingTwo}`} onClick={this.handleAnswer}>
                                 <i className="material-icons feedback">sentiment_very_dissatisfied</i>
                                 <p className="needHelp">Answer incorrect</p>
                             </div>
                             <div className={`fancy-btn feedback ${stylingThree}`} onClick={this.handleExplanation}>
                                 <i className="material-icons feedback">sentiment_very_dissatisfied</i>
                                 <p className="needHelp">Explanation incorrect</p>
                             </div>
                             <div className="feedbackTextAreaContainer">
                                <label>Give us more details (optional):</label>
                                <textarea id="feedback" onChange={this.handleChange} className="feedbacktextArea" placeholder="Optional feedback..."/>
                            </div>
                            <div className="fancy-btn feedbackSubmit">
                                {this.submittable  
                                ? <div className="activeButton" onClick={this.handleSubmit}><p className="needHelp"> SUBMIT </p></div>
                                : <div className="inactiveButton"><p className="needHelp"> SUBMIT </p></div>
                                }
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         );
     }
 }

 const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, school, firstName, lastName) => dispatch(createScore(score, total, quizName, school, firstName, lastName))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(NegativeFeedback)