import React from 'react'
import firebase from '../auth/auth'
import { ExportToCsv } from 'export-to-csv';


class ExportUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            export: 'student'
        }
    }

    componentDidMount() {
        this.callData('student');
    }

    callData (data1="student", data2="Economics") {

        firebase.firestore().collection("users")
            .where('student', "==", data1)
            .where('subject', '==', data2)
            .get()
            .then(querySnapshot => {
                const queryData = querySnapshot.docs.map(doc => doc.data());
                this.setState({queryData : queryData})

                

                // let csvContent = "data:text/csv;charset=utf-8," 
                
                // queryData.map(obj => {
                //     if (obj.email) {
                //         console.log(obj.firstName, obj.lastName, obj.email)
                //     }
                // })
                
                })
    }

    exportCSV = () => {
        const {queryData} = this.state

        


        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: true,
            title: 'My Awesome CSV',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        }

        const csvExporter = new ExportToCsv(options)

        csvExporter.generateCsv(queryData)
    }

    onSortChange = (event) => {
        this.setState({ export: event.target.value })
        this.callData(event.target.value, this.state.subject)
    }

    onSubjectChange = (event) => {
        this.setState({ subject: event.target.value })
        this.callData(this.state.export, event.target.value)
    }
    
    render() {
       
        
        return (
            <div className="container">
                <label className="label.select-label">Student/teacher</label>
                <select className="selectSort" onChange={this.onSortChange}>
                    <option>student</option>
                    <option>teacher</option>
                </select>
                <label className="label.select-label">Subject:</label>
                <select className="selectSort" onChange={this.onSubjectChange}>
                    <option>Economics</option>
                    <option>Physics</option>
                    <option>gcseBiologySingleScienceAQA</option>
                </select>
                <button onClick={this.exportCSV} className="btn-floating">Download</button>
            </div>

        );
    }
}

export default ExportUsers