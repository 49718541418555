import React, { useState } from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Calendar from 'react-calendar'
import Spinner from '../spinner/spinner'
import Toggle from 'react-toggle'
import 'react-calendar/dist/Calendar.css'

class DateContainer extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {

        };
        
    }


    render() {
            //Today's date must be expressed at 00:00
            var d = new Date ()
            var dizzle = new Date(d.setHours(0,0,0,0))
        

        
        return (    <div className="popup-container mobileCalendar">
                        <div className="closeContainer mobileCalendar">
                            <i className='material-icons mobileCalendar' onClick={this.props.toggleDateContainer}>close</i>
                        </div>
                        <div className="twoCalendarContainer newAssignment">
                            <div className={`calendarContainer newAssignmentShow`}>
                                <div className="calendarContainerSingle newAssignmentShow">
                                        <Calendar
                                            onChange={this.props.onChange}
                                            value={this.props.value}
                                            // minDate={dizzle}
                                        />
                                </div>

                            </div>
                        </div>
                    </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(DateContainer) 