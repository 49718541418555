import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import './studentAssignment.scss';
import Spinner from '../spinner/spinner'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'


class StudentAssignments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeframe: "Active assignments",
        }

    }

    componentDidMount() {
        this.getSchool()

        var d = new Date ()
        var dizzle = new Date(d.setHours(0,0,0,0))
        this.setState({value:dizzle})
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getSchool(props)
    }

    getSchool = (value="") => {
        const {timeframe} = this.state

        let subjectString =""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }

        // if (value==="") {
        //     var d = new Date ()
        //     var dizzle = new Date(d.setHours(0,0,0,0))
        // } else {
        //     dizzle=value
        // }

        var d = new Date ()
        var dizzle = new Date(d.setHours(0,0,0,0))

        firebase.firestore().collection('schools')
        .where('school', '==', this.props.profile.school)
        .get()
        .then(querySnapshot => {
            let school= querySnapshot.docs[0].data()
            console.log(school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]])

            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            let viewAssignmentsArray = []

            if (timeframe==="Past assignments") {
                Object.keys(school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]]).map(date => {
                    const dateObj= new Date(Date.parse(date))
                    if (dateObj<dizzle) {
                        viewAssignmentsArray.push({[date]: school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]][date], date:[date]})
                    }
                })
                viewAssignmentsArray.sort(function(a,b) {
                    return new Date(b.date) - new Date(a.date)
                })
            } else if (timeframe==="Active assignments") {
                Object.keys(school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]]).map(date => {
                    const dateObj= new Date(Date.parse(date))
                    if (dateObj>=dizzle) {
                        viewAssignmentsArray.push({[date]: school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]][date], date:[date]})
                    }
                })
                viewAssignmentsArray.sort(function(a,b) {
                    return new Date(a.date) - new Date(b.date)
                })
                console.log(Object.keys(viewAssignmentsArray[0])[0])
                //If active assignments is chosen, default date to first active assignment
                this.setState({
                    value: new Date (Object.keys(viewAssignmentsArray[0])[0]),
                    school
                }, () => this.selectAssignment(new Date (Object.keys(viewAssignmentsArray[0])[0])) )
            } else if (timeframe==="All assignments") {
                Object.keys(school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]]).map(date => {
                    const dateObj= new Date(Date.parse(date))
                    viewAssignmentsArray.push({[date]: school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]][date], date:[date]})
                })
                viewAssignmentsArray.sort(function(a,b) {
                    return new Date(a.date) - new Date(b.date)
                })
            }

            this.setState({
                school,
                viewAssignmentsArray
            }
            // , () => {if ((!this.state.value) || (timeframe ==="Active assignments") ) {
            //     this.onChangeStart(new Date(dizzle))
            // }}
            )

        })
    }


    setLoading = () => {
        this.setState({loading: true})
    }

    createMarkup(text) {
        return {__html: text};
    }

    // onChangeStart = (value, event) => {
    //     this.getSchool(value)
    //     this.setState({value})
    // }

    getViewAssignmentsContainer = () => {
        const {viewAssignmentsArray} = this.state
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

        return( 
            viewAssignmentsArray && viewAssignmentsArray.length > 0 ? 
                viewAssignmentsArray.map(objy => {

                    //Handles empty assignment objects
                        if (Object.keys(objy[Object.keys(objy)[0]]).length>0) {  
                            return (
                                <div className="assignmentDateContainer" onClick={()=>this.selectAssignment(new Date(Object.keys(objy)[0]))}>
                                    <p className="assignmentDateContainerText">{new Date(Object.keys(objy)[0]).toLocaleDateString(undefined, options)}</p>
                                </div>
                            )
                        }
                })
                : null
        )

    }

    toggleTimeframe = (event) => {

        this.setState({
            timeframe: event.target.value,
        }, this.getSchool)
    }

    selectAssignment = (date) => {

        const {school} = this.state

        let subjectString =""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }
        
        this.setState({
            value: date,
            assignments: school[`${subjectString}Assignments`][this.props.profile[`${subjectString}class`]][date], 
        })
    }


    render() {
        //Today's date must be expressed at 00:00
        var d = new Date ()
        var dizzle = new Date(d.setHours(0,0,0,0))
        const {loading, assignments, value, timeframe} = this.state

        return (
            <div className="mobileContainer assign">
                {loading
                ?   <Spinner />
                :   null
                }
                <div className="studentAssignmentTwoFlex">
                    {/* <div className="studentAssignmentCalendarContainer">
                        <div className="calendarContainerSingle newAssignment">
                            <div className="setDateContainer">
                                <p className="setDate studentAssignment">Select date</p>
                            </div>
                            <Calendar
                                onChange={this.onChangeStart}
                                value={value}
                                minDate={dizzle}
                                defaultValue={dizzle}
                            />
                        </div>
                    </div> */}
                    <div className="viewAssignmentsContainer">
                        <div className="newAssignmentSelectContainer timeframe">
                            <p className='chooseClassText newAssignment'>View:</p>
                            <select className="newAssignment" onChange={this.toggleTimeframe} value={timeframe}>
                                    <option>Active assignments</option>
                                    <option>Past assignments</option>
                                    <option>All assignments</option>
                            </select>
                        </div>
                        <div className="scrollableViewAssignmentsContainer">
                            {this.getViewAssignmentsContainer()}
                        </div>
                    </div>
                    {assignments ?
                        <div className="studentAssignmentsContainer">
                            <div>
                                <p className="studentAssignmentDate">{(value || dizzle).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            </div>
                            {assignments.message ? 
                                <div className='studentAssignmentMessageContainer'>
                                    <p className="studentAssignmentTeacherMessage">Teacher message:</p>
                                    <div className='teacherMessageText'>
                                        <p dangerouslySetInnerHTML={this.createMarkup(assignments.message)}></p>
                                    </div>
                                </div>
                                : 
                                null
                            }
                            {Object.keys(assignments).map((assignment, index) => {
                                if (assignment !=='message') {
                                    return (
                                        <div className="studentAssignmentsTaskContainer">
                                            <p className="computerTaskNumber">Task {index+1}</p>
                                            <a href={
                                                assignments[assignment]['activity']==='quiz' ?
                                                    assignments[assignment]['category']==="My quizzes" ?
                                                        `quizzesschool/${assignments[assignment]['quiz']}`
                                                        :`quizzes/${assignments[assignment]['quiz']}`
                                                    : `flashcardformbeta/${assignments[assignment]['flashcard']}`
                                            }
                                            className="flashcardPreviewText columnAssign"
                                            >
                                                {assignments[assignment]['activity']}- {assignments[assignment]['quiz']}
                                            </a>
                                        </div>
                                    )
                                }
                            })

                            }
                            
                        </div>
                    :
                        <div className="studentAssignmentsContainer">
                            <div>
                                <p className="studentAssignmentDate">{(value || dizzle).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                            </div>
                            <p>No assignments</p>
                        </div>
                    }
                    </div>
                </div>    
        );
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps)(StudentAssignments)
