import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import firebase from '../auth/auth'
import StudentView from './studentView'
import Spinner from '../spinner/spinner'
import { getQuizzesTrial, getStudentArray } from '../../helpers/getData';
import Toggle from 'react-toggle'



class StudentData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: 'students',
            activeClass: "All students",
            flags: false,
            flagPopup: false,
            editor: false,
            students: [],
        }
    }

    componentDidMount() {
        this.getQuizzesTrialProps()
        this.getStudents();
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
        // props.auth && this.getAttempts(props)
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        getQuizzesTrial(props.profile.school, props.profile.subject)
        .then((quizzes) => getStudentArray(this.props.auth.uid, quizzes))
        .then(({ quizzes, studentArray }) => {
            this.setState({
                quizzes
            });
        });
    }




    //This runs when auth updates
    //***THIS ISN'T RUNNING AT ALL NOW*/
    /*componentWillReceiveProps(props) {
        this.getStudents();
    }*/

    getStudents() {
        const { props } = this;

        if (!props.profile.school) return;

        let dynamicQuery = firebase.firestore().collection('studentProgress')
                            .where('school', '==', props.profile.school)
                            .where('user', '==', this.props.userId)

        firebase.firestore().collection('users')
            .doc(this.props.userId)
            .get()
            .then(querySnapshot => {
                const students = [querySnapshot.data()];

                this.setState({
                    students
                });

                return students;
            })
            .then((students) => {
                // component calls quizlist
                // which quiz they want

                
                dynamicQuery
                    .get()
                    .then(querySnapshot => {
                        const { quizzes } = this.state;
                        const studentProgress = querySnapshot.docs.map(doc => doc.data());

                        if (studentProgress.length===0) {
                            this.props.toggleContainerWidth(true)
                            console.log(true)
                        } else {
                            this.props.toggleContainerWidth(false)
                            console.log(false)
                        }

                        students.forEach(student => {
                            student.name = `${student.firstName} ${student.lastName}`;
                        });

                        studentProgress.forEach(student => {

                            const currentStudent = students.find(({ firstName, lastName, school }) => {
                                return student.firstName === firstName &&
                                    student.lastName === lastName &&
                                    student.school === school;
                            });

                            const completedQuizzes = student.studentProgress;
                            this.setState({numberCompleted:Object.values(completedQuizzes).length})

                            // currentStudent.studentProgress = student.studentProgress;
                            currentStudent.studentProgress = JSON.parse(JSON.stringify(quizzes));

                            let userScore = 0;

                            // puts student data into the relevant quiz objects within the relevant theme object
                            for (let quizName in completedQuizzes) {
                                let quizScore = completedQuizzes[quizName];

                                quizScore = typeof quizScore === 'number'
                                    ? quizScore
                                    : 0 // Object.values(progress)[0];

                                for (let category in currentStudent.studentProgress) {
                                    const quizzesList = currentStudent.studentProgress[category];

                                    if (typeof quizzesList !== 'object') continue;

                                    const quiz = quizzesList.find(
                                        (quiz) => quiz.quizName === quizName
                                    );

                                    if (quiz) {
                                        quiz.quizScore = quizScore;
                                    }
                                }

                                currentStudent.studentProgress[quizName] = quizScore;
                                userScore += quizScore;
                            }

                            currentStudent.totalAttempts = 0;

                            //calculates the number of quizzes attempted
                            Object.values(currentStudent.studentProgress).forEach(quizList => {
                                if (typeof quizList !== 'object') return;

                                quizList.forEach(({ quizScore = 0 }) => {
                                    if (quizScore) {
                                        currentStudent.totalAttempts++;
                                    }
                                });
                            });

                            //calculates the total number of quizzes                            
                            currentStudent.totalQuizzes = Object.values(quizzes)
                                .reduce((total, quizzes) => total + quizzes.length, 0);

                            //adds average accuracy and themeAccuracy to the studentArray
                            currentStudent.averageAccuracy = Math.round(
                                userScore / currentStudent.totalAttempts
                            );

                            currentStudent.completion = Math.round(
                                currentStudent.totalAttempts / currentStudent.totalQuizzes * 100
                            );
                        })

                        this.setState({ students });
                    })
            });
    }

    render() {
        const { auth, profile, loaded} = this.props;

        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />

        const { students, numberCompleted} = this.state;

        return (
            
            <div>
                {loaded===true && students[0] && students[0].studentProgress ? 
                <div style={{height: "100%"}}>
                        <div className="flexRowStudentDataContainer">
                            <div className="classContainerStudentDataContainer">
                                <div style={{height: "100%"}}>
                                    <StudentView
                                        students={students}
                                        profile={this.props.profile}
                                        numberCompleted={numberCompleted}
                                    />  
                                </div>
                            </div>           
                        </div>
                </div>
                : null
                }
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

export default connect(mapStateToProps)(StudentData)
