import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'



import '../diagnostic/diagnostic.scss';


class Analytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }



    }

  


    render() {

        const {finished} = this.props


        return(
            <div className="popup-container">
                <div className="container">
                    <div className="popupSubjectMenu">
                        <div className="subjectMenuCloseContainer">
                            {finished 
                            ? null
                            : <i className="material-icons closeCategoryMenu" onClick={this.props.toggleAnalytics}>close</i>
                            }
                        </div>
                        <div>
                            <p className="diagnosticsTitle">Diagnostics</p>
                        </div>
                        <div className="analyticsScrollableContainer">
                            <div className="analyticsTextContainer">
                                <div className="categoryMenuflexColumn">
                                        {this.props.mcqData && this.props.mcqData.map((question, index) => {
                                                return (
                                                <div className="analyticsQuestionContainer" key={question.questionText}>
                                                    <div className="flexRow">
                                                        <div className="analyticsQuestionNumberContainer">
                                                            <p className="analyticsQuestionNumberText">Question {index+1}</p>
                                                        </div>
                                                        <div className="analyticsMiddleQuestionContainer">
                                                            <p className="analyticsQuestionDetails">{question.topic}</p>
                                                            <p className="analyticsQuestionQuizName">{question.quizName}</p>
                                                            <p className="analyticsQuestionText" dangerouslySetInnerHTML={{__html: question.questionText}}></p>
                                                        </div>
                                                        <div className="analyticsAnsweredContainer">
                                                                {!question.answeredRight 
                                                                ? <p className="notAnswered"> Not answered </p>
                                                                : 
                                                                    question.answeredRight=="right" 
                                                                    ?    <i className="material-icons analyticsRight"> done </i>
                                                                    :    <i className="material-icons analyticsWrong"> close </i>
                                                                }
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })
                                        }
                                </div>
                            </div>
                        </div>
                        {finished 
                        ?
                            <div>
                                <Link to='/quizzes' className="fancy-btn analytics" >Back to quizzes</Link>
                            </div>
                        : null
                        }
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)