import React, { useState } from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Calendar from 'react-calendar'
import Spinner from '../spinner/spinner'
import Toggle from 'react-toggle'
import 'react-calendar/dist/Calendar.css'

class NewTask extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activity: '',
            finishDateActive: false
        };
        
    }
    
    componentDidMount = () => {
        this.getQuizzes()
    }
    


    getDropdown() {

        const { noClasses, classes } = this.props
        const { activeClass } = this.state

        if (noClasses) {
            return (
                <div>
                    <p>You must set up at least one class before assigning work</p>
                </div>
            )
        }

        if (classes && !noClasses) {            
            return (
                <div className="newAssignmentSelectContainer">
                    <p className='chooseClassText newAssignment'>Class:</p>
                    <select className="newAssignment" onChange={this.toggleClass} value={activeClass}>
                            <option>Select from list</option>
                        {Object.entries(classes).map((e) => {
                            return (
                            <option key={e[0]}>{e[0]}</option>
                            )
                            })
                        }
                    </select>
                </div>
            )
        }
    }

    handleChange = (e) => {
        let { name, value } = e.target

        if (name==="activity" && value=="quiz") {
            this.setState({
                flashcard: "",
                category: "",
            })
        } else if (name==="activity" && value=="flashcards") {
            this.setState({
                quiz: "",
                category: "",
            })
        }

        if (name==="category") {
            this.setState({
                quiz: "",
                flashcard: "",
            })
        }

        //Flashcards must have quizName + flashcard properties added to the state in order
        //to render clean message for user
        if (name==="flashcard") {
            this.setState({
                [name]: this.props.quizzes[this.state.category][value]['flashcard'],
                //Quiz used to house the clean name of the flashcards- makes it easier to
                // render message
                quiz: this.props.quizzes[this.state.category][value]['quizName'],
                randomString: Math.random().toString(36).slice(2)
            }, this.checkSubmittable)
        } else if (name==="quiz") {
            this.setState({
                [name]: this.props.quizzes[this.state.category][value]['quiz'],
                //Quiz used to house the clean name of the flashcards- makes it easier to
                // render message
                quiz: this.props.quizzes[this.state.category][value]['quizName'],
                randomString: Math.random().toString(36).slice(2)
            }, this.checkSubmittable)
        } else {
                this.setState({
                    [name]: value,
                    randomString: Math.random().toString(36).slice(2)
                }, this.checkSubmittable)

        }
    }

    checkSubmittable = () => {
        
        let submittable = false
        if (this.props.startDate
            &&
            (this.state.activity==="flashcards" && this.state.flashcard) || (this.state.activity==="quiz" && this.state.quiz)) {
            submittable = true
        } 

        this.setState({
            submittable: submittable,
            userID: this.props.auth.uid,
            subject: this.props.subject,
            lastName: this.props.profile.lastName,
            randomString: Math.random().toString(36).slice(2)
        })
    }

    onChangeStart = (value, event) => {
        // let startD = value.toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        this.setState ({
            startDate: value
        }, this.checkSubmittable)

        if (this.props.assignments[this.state.activeClass][value] && Object.keys(this.props.assignments[this.state.activeClass][value]).length>0) {
            let assignmentArray = []
            Object.keys(this.props.assignments[this.state.activeClass][value]).map(assignment => {
                assignmentArray.push(this.props.assignments[this.state.activeClass][value][assignment])
             })
            this.setState({
                activeAssignmentObject: this.props.assignments[this.state.activeClass][value],
                assignmentArray
            })
        } else {
            this.setState({
                activeAssignmentObject: false
            })
        }
    }

    onChangeFinish = (value, event) => {
        // let endD = value.toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        this.setState ({
            endDate: value
        }, this.checkSubmittable)
    }

    changeViewStart = () => {
        if (this.state.startDateActive) {
            this.setState ({
                startDate: ""
            })
        }

        this.setState({
            startDateActive: this.state.startDateActive ? false : true 
        })
    }

    changeViewFinish = () => {
        if (this.state.finishDateActive) {
            this.setState ({
                endDate: ""
            })
        }
        
        this.setState({
            finishDateActive: this.state.finishDateActive ? false : true
        })
    }

    getQuizzes = () => {
        const {quizzes} = this.props

        let categories =[]
        quizzes && Object.keys(quizzes).map(category =>{
            categories.push(category)
        })
        this.setState ({
            categories
        })
    }


    assignWork = () => {
        this.props.setLoading()
        let subjectString =""
        if (this.props.subject!=="Economics") {
            subjectString = this.props.subject
        }

        firebase.firestore().collection('schools')
            .where('school', '==', this.props.profile.school)
            .get()
            .then((querySnapshot => {

                const {assignments, startDate, activeClass, assignmentsObject} = this.props
                
                let assignmentsCopy = {}

                //No existing assignments
                if (Object.keys(assignmentsObject).length === 0) {
                    console.log('No existing assignments')
                    assignmentsCopy[activeClass]={}
                    assignmentsCopy[activeClass][startDate]={}
                    assignmentsCopy[activeClass][startDate][1]= {...this.state, randomString:Math.random().toString(36).slice(2)}
                } else if (Object.keys(assignmentsObject).length > 0) {
                    //Assignments already assigned to classes
                    assignmentsCopy = this.props.assignmentsObject
                    //No assignments assigned to active class
                    if (!(assignmentsObject[activeClass]) || Object.keys(assignmentsObject[activeClass]).length===0) {
                        console.log('No assignments assigned to active class')
                        assignmentsCopy[activeClass]={}
                        assignmentsCopy[activeClass][startDate]={}
                        assignmentsCopy[activeClass][startDate][1]= {...this.state, randomString:Math.random().toString(36).slice(2)}
                    //Assignments already assigned to active class
                    } else {
                        console.log(assignmentsObject[activeClass][startDate])
                        //No assignments assigned to active class on active date
                        if (!assignmentsObject[activeClass][startDate]) {
                            console.log('No assignments assigned to active class on active date')
                            assignmentsCopy[activeClass][startDate]={}
                            assignmentsCopy[activeClass][startDate][1]= {...this.state, randomString:Math.random().toString(36).slice(2)}
                        //Assignments already assigned to active class on active date
                        } else {
                            console.log('Assignments assigned to active class on active date')
                            let assignmentNumber = Object.keys(assignmentsObject[activeClass][startDate]).length+1
                            assignmentsCopy[activeClass][startDate][assignmentNumber] = {...this.state, randomString:Math.random().toString(36).slice(2)}
                        }
                    }
                }

                firebase.firestore().collection('schools')
                .doc(querySnapshot.docs[0].id)
                .update({
                    [subjectString+"Assignments"]: assignmentsCopy
                }).then(()=>{
                    setTimeout (() => {
                        this.setState({message: `Assigned "${this.state.quiz}" ${this.state.activity} to ${this.props.activeClass}`})
                    }, 1000)
                }).then(()=>{
                    setTimeout (() => {
                        this.props.getClasses()
                    }, 1000)
                })
            }))

    }

    handleReturn = () => {
        this.props.getClasses()
        this.props.parseViewAssignmentsList()
        this.props.toggleNewTask()
    }

    render() {

        const { quizzes, startDate, activeClass} = this.props
        const { categories, value, finishDateActive, message } = this.state

        const laptop= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Flaptop.png?alt=media&token=3b65f2c5-9902-42e1-8065-18f40a3de4f5"


        
        return (    <div className="popup-container">
                        <div className="container newTask">
                            <div className="newTask100Container">
                                <div className="closeContainer">
                                    {!message 
                                    ?
                                    <i className='material-icons' onClick={this.props.toggleNewTask}>close</i>
                                    : null
                                    }
                                </div>
                                {!message 
                                ?
                                <div>
                                    <p className="newTaskTitle">Assign task for {activeClass} due on {startDate.toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                </div>
                                : null
                                }       

                                {!message ?
                                <div>
                                    <div className="chooseClassContainer newAssignment">
                                        {this.getDropdown()}
                                    </div>
                                    <div className="chooseClassContainer newAssignment">
                                        <p className='chooseClassText newAssignment newTask'>Choose an activity:</p>
                                        <select className="newTask" onChange={this.handleChange} name="activity">
                                            <option value="">Choose activity</option>
                                            <option value="quiz">Quiz</option>
                                            <option value="flashcards">Flashcards</option>
                                        </select>
                                    </div>
                                </div>
                                : null
                                }

                                {this.state.activity && !message
                                ?
                                <div className="chooseClassContainer newAssignment">
                                    <p className='chooseClassText newAssignment newTask'>Choose a category:</p>
                                    <select className="newTask" value={this.state.category} onChange={this.handleChange} name="category">
                                            <option value="">Click here to choose from options</option>
                                            {categories && categories.map(category => {
                                                if (!(this.state.activity=="flashcards" && category=="My quizzes")) return <option key={category}>{category}</option>}
                                            )}
                                    </select>
                                </div>
                                : null}
                                {this.state.category && !message ?
                                    this.state.activity==="quiz"
                                    ? 
                                    <div className="chooseClassContainer newAssignment">
                                        <p className='chooseClassText newAssignment newTask'>Choose a quiz:</p>
                                        <select className="newTask" onChange={this.handleChange} name="quiz">
                                                <option value="">Click here to choose from options</option>
                                                {quizzes && quizzes[this.state.category].map((quiz, index) => {
                                                    return <option value={index} key={quiz.quizName}>{quiz.quizName}</option>}
                                                )}
                                        </select>
                                    </div>
                                    :
                                    <div className="chooseClassContainer newAssignment">
                                        <p className='chooseClassText newAssignment newTask'>Choose flashcards:</p>
                                        <select className="newTask" onChange={this.handleChange} name="flashcard">
                                                <option value="">Click here to choose from options</option>
                                                {quizzes && quizzes[this.state.category].map((quiz, index) => {
                                                    return <option value={index}>{quiz.quizName}</option>
                                                }
                                                )}
                                        </select>
                                    </div>
                                : null
                                }
                                {/* REINSTATE DEADLINE HERE */}
                                {/* {this.state.quiz && !message
                                ?
                                <div className="twoCalendarContainer newAssignment">
                                    <div className={`calendarContainer newAssignment true`}>
                                        <div className="calendarContainerSingle newAssignment">
                                            <div className="setDateContainer">
                                                <p className="finishDate newAssignment">Set deadline (optional)</p>
                                                <Toggle
                                                defaultChecked={this.state.tofuIsReady}
                                                icons={false}
                                                onChange={this.changeViewFinish} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                                } */}
                                
                                <div>
                                    {this.state.submittable && !message
                                    ?
                                    <div className="flexColumnEndCalendar">
                                        {finishDateActive ?
                                            <div className='endCalendar'> 
                                                <Calendar
                                                    onChange={this.onChangeFinish}
                                                    value={value}
                                                    minDate={new Date()}
                                                />
                                            </div>
                                            :
                                            null 
                                        }
                                        <div className={`assignButton newAssignment popup`} onClick={this.assignWork}>
                                            <p className="assignButtonText">Assign</p>
                                            <i className='material-icons assignButton'>thumb_up_off_alt</i>
                                        </div>
                                    </div>
                                    : 
                                        !message ?
                                        <div className={`assignButton newAssignment greyed popup`}>
                                            <p className="assignButtonText">Assign</p>
                                            <i className='material-icons assignButton'>thumb_up_off_alt</i>
                                        </div>
                                        : null
                                    }
                                    {message 
                                    ?   
                                        <div className="newTaskVerifiedContainer">
                                            <div className="newTaskVerified">
                                                <i className="material-icons newTaskTick">done</i>
                                                <p className="newTaskVerified">{message}</p>
                                            </div>
                                            <div className={`returnButton newAssignment`} onClick={this.handleReturn}>
                                                <p className="assignButtonText">Back to preview</p>
                                                <i className='material-icons assignButton'>exit_to_app</i>
                                            </div>
                                        </div>
                                    :   
                                    null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(NewTask) 