import React from 'react';
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link } from 'react-router-dom'


class ImageWarning extends React.Component {
    constructor(props) {
        super(props);
        
    }
    
    render() {
       const {toggleImageWarning}=this.props
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp">
                            <div className="btn-floating closePopup" onClick={toggleImageWarning}><i className="material-icons">close</i></div>
                            <h5 className="needHelp">File size is too large</h5>
                                <p className="needHelp">Please select a smaller image file</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageWarning