import React, { Component } from 'react'
import MobileNavBar from '../layout/mobileNavbar'
import { connect } from 'react-redux'
import {signIn} from '../../store/actions/authActions'
import { Redirect, Link } from 'react-router-dom'
import './signin.css'

export class SignIn extends Component {
    state = {
        email: "",
        password: "",
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.signIn(this.state)
    }

    render() {
        const { auth, authError } = this.props;
        if (auth.isLoaded && auth.uid) return <Redirect to='/' />

        return (
            <div>
                <MobileNavBar />
                <div className="container">
                    <form onSubmit={this.handleSubmit} className="white">
                        <div className="signInHeader">
                            <h5 className="grey-text text-darken-3">Sign In</h5>
                            <Link to='/signup' className="newUser" >New user </Link>
                        </div>
                        <div className="input-field">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <button className="btn pink lighten-1 z-depth-0 waves-effect waves-light">Login</button>
                            <Link to='/password' className="black-text" >Forgot password </Link>
                            <div className="red-text center">
                                { authError ? <p>{authError}</p> : null }
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
