import React from 'react';
import './flagStudentList.css'

class FlagStudentList extends React.Component {
    constructor(props) {
        super(props);
        
        this.state= {
            
        }
    }
    
    render() {
       
        
        return (

            <div className="popup-container">      
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupFlagStudentList">
                            <div className="btn-floating closePopupFlagStudentList" onClick={this.props.toggleFlagStudentList}><i className="material-icons">close</i></div>
                            <h6 className="popupFlagStudentListTitle">These guys have asked for your help!</h6>
                            {this.props.student.map ((studentObject)=> {
                                return(
                                    <div className="flagStudentListNameContainer">
                                        <p className="flagStudentListText">{studentObject.firstName} {studentObject.lastName}</p>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default FlagStudentList
