import React from 'react'
import './accuracy.scss';
import NewAccuracyCircle from './newAccuracyCircle';
import firebase from '../auth/auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class NewAccuracy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quizzes: []
        }
    }


    render() {
        const { auth } = this.props;

        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />


        const { quizAccuracy } = this.props


        const circleContainerStyle = {
            width: '75px',
            height: '75px',
            display: 'inline-block',
        };


        return (
            <div className="dashboardContainerRightElement">
                <h1 className="dashboardElementTextAccuracy">🎯 Accuracy</h1>

                {
                    quizAccuracy && Object.entries(quizAccuracy).map(category => {
                        if (category[0]!== "My quizzes") {
                                return (
                                    <div className="themeBox" key={category[0]}>
                                    <div className="themeBoxText">
                                        <p className="dashboardElementText">{category[0]}</p>
                                    </div>

                                    <div className="dashboardElementCircle">
                                        <div style={circleContainerStyle}>
                                            <NewAccuracyCircle
                                                average={category[1]['average']}
                                            />
                                        </div>
                                    </div>
                
                                </div>
                                )
                        }
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth

    }
}

export default connect(mapStateToProps)(NewAccuracy);