import React, { Component } from 'react'
import './home.scss'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Footer from '../layout/footer'
import firebase from '../auth/auth'

import HomeSlider from './homeSlider'
import MobileNavBar from '../layout/mobileNavbar'

import books1 from '../../resources/Books.png'
import graph from '../../resources/gradit_upgraph.png'
import adaptive from '../../resources/gradit_laptopbook.png'
import phone from '../../resources/gradit_phone.png'



class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }

        this.bannerLoaded = this.bannerLoaded.bind(this);
    }

    bannerLoaded = () => {
        this.setState({ loading: false })
    }


    render() {
        const { auth } = this.props;
        const { loading } = this.state

        if (auth.uid) return <Redirect to='/dashboard' />

        return (
            <div>
                <MobileNavBar />
                <div className={`homeContainer${loading}`}>
                    <div className="bannerBackground">
                        <div className="container section home">
                            <div className="row home">
                                <div className="flexRow">
                                    <div className="flexColumn home">
                                        <h2 className="headerBold banner">LEARN FASTER</h2>
                                        <img className="responsive-img books" src={books1} alt="books" />
                                        <h4 className="grey-text banner">Active recall for optimal studying</h4>
                                        <div className="tryQuiz">
                                            <Link to='/trial' className="trialButtonText">Try a quiz</Link>
                                            <Link to='/trial' className="btn-floating pulse"><i className="material-icons">play_arrow</i></Link>
                                        </div>
                                        {/* <div className="flexRow trustpilot">
                                            <img className="responsive-img trustpilot" src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Ftp.png?alt=media&token=95cf796d-52c4-450a-8553-418c21207d59" alt="adaptive"/>
                                            <div className="flexRow">
                                                <p className="trustpilotText"><span className="trustpilotBold">Excellent</span>4.6 out of 5</p>
                                                <img className="responsive-img trustpilotstar" src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Ftpstar.png?alt=media&token=23436254-5a7b-44cf-9c4f-e3368b471019" alt="adaptive"/>
                                                <p className="trustpilotText"><span className="trustpilotBold">Trustpilot</span></p>
                                            </div>
                                            
                                        </div> */}
                                    </div>
                                    <div className="col s0 m6 l6">
                                        <img className="responsive-img hide-on-small-only" src={books1} alt="books" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bannerBackgroundReverse">
                        <div className="container section">
                            <div className="row">
                                <div className="col s12 m12 l12">
                                    <h5 className="grey-text text-darken-2 center hundredschools">Used by 100+ schools</h5>
                                    <HomeSlider />
                                </div>
                                <div className="col s12 m12 l12">
                                    <h4 className="grey-text text-darken-3 offset-l1 howitworks">How GradeIt works</h4>
                                </div>
                                <div className="col s12 m4 l4">
                                    <div className="card medium">
                                        <div className="card-image">
                                            <img onLoad={this.bannerLoaded} className="responsive-img" src={adaptive} alt="adaptive" />
                                        </div>
                                        <div className="card-content">
                                            <span className="card-title">Adaptive learning</span>
                                            <p className="grey-text text-darken-1">Identify and target students' weaknesses using our analytics tools.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 m4 l4">
                                    <div className="card medium">
                                        <div className="card-image">
                                            <img src={graph} alt="graph" />
                                        </div>
                                        <div className="card-content">
                                            <span className="card-title">Classroom data</span>
                                            <p className="grey-text text-darken-1">Track the progress of your entire class throughout the syllabus.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 m4 l4">
                                    <div className="card medium">
                                        <div className="card-image">
                                            <img src={phone} alt="books" />
                                        </div>
                                        <div className="card-content">
                                            <span className="card-title">Mobile-friendly</span>
                                            <p className="grey-text text-darken-1">Our accessible platform encourages students to learn in bitesize chunks.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps)(Home)


