import React, { useState } from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import CKEditor from 'ckeditor4-react';

class NewMessage extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activity: '',
            noticeboardMessage: "Type your message here..."
        };
        
    }

    componentDidMount () {
        if (this.props.noticeboardMessage) {
            this.setState({
                editing: true
            })
        }
        this.setState({
            noticeboardMessage: this.props.noticeboardMessage
        })
    }

    onEditorChange = ( evt ) => {
        const clean = evt.editor.getData()

        this.setState({
            noticeboardMessage: clean
        });

    }

    messageComplete = () => {
        this.props.getClasses()
        this.props.parseViewAssignmentsList()
        this.props.toggleMessage()
    }


    assignWork = () => {
        this.props.setLoading()
        let subjectString =""
        if (this.props.subject!=="Economics") {
            subjectString = this.props.subject
        }

        firebase.firestore().collection('schools')
            .where('school', '==', this.props.profile.school)
            .get()
            .then((querySnapshot => {

                const {assignments, startDate, activeClass, assignmentsObject} = this.props
                
                let assignmentsCopy = {}

                //No existing assignments
                if (Object.keys(assignmentsObject).length === 0) {
                    console.log('No existing assignments')
                    assignmentsCopy[activeClass]={}
                    assignmentsCopy[activeClass][startDate]={}
                    assignmentsCopy[activeClass][startDate]['message']={}
                    assignmentsCopy[activeClass][startDate]['message']= this.state.noticeboardMessage
                } else if (Object.keys(assignmentsObject).length > 0) {
                    //Assignments already assigned to classes
                    assignmentsCopy = this.props.assignmentsObject
                    //No assignments assigned to active class
                    if (!(assignmentsObject[activeClass]) || Object.keys(assignmentsObject[activeClass]).length===0) {
                        console.log('No assignments assigned to active class')
                        assignmentsCopy[activeClass]={}
                        assignmentsCopy[activeClass][startDate]={}
                        assignmentsCopy[activeClass][startDate]['message']= {}
                        assignmentsCopy[activeClass][startDate]['message']= this.state.noticeboardMessage
                    //Assignments already assigned to active class
                    } else {
                        console.log(assignmentsObject[activeClass][startDate])
                        //No assignments assigned to active class on active date
                        if (!assignmentsObject[activeClass][startDate]) {
                            console.log('No assignments assigned to active class on active date')
                            assignmentsCopy[activeClass][startDate]={}
                            assignmentsCopy[activeClass][startDate]['message']= {}
                            assignmentsCopy[activeClass][startDate]['message']= this.state.noticeboardMessage
                        //Assignments already assigned to active class on active date
                        } else {
                            console.log('Assignments assigned to active class on active date')
                            assignmentsCopy[activeClass][startDate]['message'] = this.state.noticeboardMessage
                        }
                    }
                }

                firebase.firestore().collection('schools')
                .doc(querySnapshot.docs[0].id)
                .update({
                    [subjectString+"Assignments"]: assignmentsCopy
                }).then(()=>{
                    setTimeout (() => {
                        this.setState({message: `Assigned "${this.state.quiz}" ${this.state.activity} to ${this.props.activeClass}`})
                    }, 1000)
                }).then(()=>{
                    setTimeout (() => {
                        this.messageComplete()
                    }, 1000)
                })
            }))

    }


    render() {

        const { quizzes, startDate, activeClass} = this.props
        const { exposition, message, noticeboardMessage, editing } = this.state

        
        return (    <div className="popup-container">
                        <div className="container newTask">
                            <div className="newTask100Container">
                                <div className="closeContainer">
                                    <i className='material-icons' onClick={this.props.toggleMessage}>close</i>
                                </div>

                                <div>
                                    <p className="newTaskTitle">{editing ? 'Edit' : "Add"} noticeboard message for {activeClass} on {startDate.toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                </div>

                                <div className="editMCQFormContainer newMessage">
                                    <CKEditor
                                        data={this.props.noticeboardMessage}
                                        type="inline"
                                        onChange={this.onEditorChange}
                                        config={{
                                            toolbar: [['Bold']],
                                        }}
                                        
                                    />                                
                                </div>
 
                                    <div>
                                    {!editing 
                                    ?
                                        noticeboardMessage 
                                        ?
                                            <div className={`assignButton confirmMessage`} onClick={this.assignWork}>
                                                <p className="assignButtonText">Add message</p>
                                                <i className='material-icons assignButton'>thumb_up_off_alt</i>
                                            </div>

                                        : 

                                            <div className={`assignButton confirmMessage greyed`}>
                                                <p className="assignButtonText">Add message</p>
                                                <i className='material-icons assignButton'>thumb_up_off_alt</i>
                                            </div>
                                    
                                    :
                                    
                                        <div className={`assignButton confirmMessage`} onClick={this.assignWork}>
                                            <p className="assignButtonText">Edit message</p>
                                            <i className='material-icons assignButton'>thumb_up_off_alt</i>
                                        </div>
                                    }
                                    </div>
                            
                                </div>
                            </div>
                        </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(NewMessage) 