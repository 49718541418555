import React, { Component } from 'react'
import { connect } from 'react-redux'
import {signIn} from '../../store/actions/authActions'
import { signOut } from '../../store/actions/authActions'
import './notVerified.scss'

export class NotVerified extends Component {
    constructor(props) {
        super(props);
        this.state={
            emailSent: false
        }
    }



    render() {
        const { emailSent } = this.state;

        return (
            <div>
                <div className="container">
                    <h4 className="grey-text text-darken-3">Awaiting teacher verification 🔒</h4> 
                    <h5 className="grey-text"><br></br>Your colleagues have been asked to verify that you are a teacher at this school... you might want to give them a gentle nudge.</h5> 
                    
                    <div className="signOutButton" onClick={this.props.signOut}>
                        <p className="signOutButtonText">Signout</p>
                        <i className="material-icons signOutButton">logout</i>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        signIn: (creds) => dispatch(signIn(creds)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotVerified)
