import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import "./pdfViewer.scss"
import firebase from '../auth/auth'

function PDFViewer(props) {
  const [Url, setUrl] = useState('')
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfScale, setPdfScale] = React.useState(1.0)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);

  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function handleZoominIcon() {
    setPdfScale(prev => {
        if (prev > 2.1) {
            return 2.2;
        } else {
            return prev + 0.1;
        }
    });
}

function handleZoomoutIcon() {
    setPdfScale(prev => {
        console.log(prev);
        if (prev <= 0.5) {
            return 0.5;
        } else {
            return prev - 0.1;
        }
    });
}


    return (
      <div>
        <div className="pdfViewerToolsContainer">
          <div className="pdfViewerToolsInnerContainer">
            <button
              type="button"
              // disabled={pageNumber <= 1}
              onClick={handleZoomoutIcon}
            >
              -
            </button>
            <p className="pdfViewerButtonDivider">
              {Math.round(pdfScale*100)}%
            </p>
            <button
              type="button"
              // disabled={pageNumber >= numPages}
              onClick={handleZoominIcon}
            >
              +
            </button>
            <p className="pdfViewerToolsDivider">
              |
            </p>

            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              {"<"}
            </button>
            <p className="pdfViewerButtonDivider">
              {pageNumber || (numPages ? 1 : '--')}/{numPages || '--'}
            </p>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              {">"}
            </button>
            {/* <button onClick={saveFile}>
              <i className="material-icons">download</i>
            </button> */}
            <a href={props.url} download target="_blank">
              <i className="material-icons">download</i>
            </a>
          </div>
            
        </div>

        {props.url ?
          <div className="pdfViewerDocumentContainer">
            <Document
              file= {props.url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page 
                pageNumber={pageNumber}
                scale={pdfScale} 
              />
            </Document>
          </div>
          : null
        }
      </div>
    );
}

export default PDFViewer