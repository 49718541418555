import React from 'react';
import { connect } from 'react-redux'
import { getQuizzesTrial, getStudentArray } from '../../helpers/getData';
import { Redirect, Link } from 'react-router-dom'

import './createQuizPopup.scss'

class CreateQuizPopup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
           
        };
        
    }

    componentDidMount() {
        this.getQuizzesTrialProps()
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        let quizArray= []

        getQuizzesTrial(props.profile.school, props.profile.subject)
            .then((quizzes) => {
                Object.entries(quizzes).map(category => {
                    category[1].map(quiz => {
                        quizArray.push({
                            quiz:quiz.quizName,
                            school: quiz.school,
                            userID: quiz.userID
                        })
                    })
                })
                this.setState({quizArray: quizArray})
            })
    }

    handleChange = (e) => {
        const {quizArray} = this.state
        let { name, value } = e.target

        let school

        quizArray.forEach((quizName) => {
            if (quizName.quiz===value) {
                school = quizName.school
                
            }
        })

        this.setState({
            [name]: value,
            school: school
        })
    }

    
    
    render() {
        const {quizArray, school, quizSelected} = this.state
        const { toggleEditor } = this.props

        //If quiz is a GradeIt quiz, urlBase= newmcqform... this forces non GradeIt users to rename quiz
        let urlBase
        if (school==='GradeIt' && !this.props.profile.contentCreator) {
            urlBase="newmcqform"
        } else {
            urlBase='newmcqformschool'
        }

        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="createQuizPopup">
                            <div className="btn-floating closePopup" onClick={toggleEditor}><i className="material-icons">close</i></div>
                            <div className="flexColumnWarning">
                                <Link to={`/newmcqform/New quiz`} className="createQuizButton">
                                    <p className="removeButton">CREATE NEW QUIZ</p>
                                </Link>
                                <div className="marginTenTop">
                                    <p>or</p>
                                </div>

                                <div className="marginTenTop">
                                    <p>Modify an existing quiz (you will still have access to the original quiz)</p>
                                </div>
                                {quizArray ? 
                                <select className="quizDropdown" onChange={this.handleChange} name="quizSelected">
                                    <option value="">Click to choose from quizzes</option>
                                    <option value="">---</option>
                                    {quizArray.map(quiz => {
                                        //Content creators can only modify their own quizzes
                                        if (this.props.profile.contentCreator) {
                                            if (quiz.userID===this.props.auth.uid) { 
                                                return (<option key={quiz.quiz}>{quiz.quiz}</option>)
                                            }
                                        //Non content-creators can modify any quiz
                                        } else if (!this.props.profile.contentCreator) {
                                            return (<option key={quiz.quiz}>{quiz.quiz}</option>)
                                        }
                                    })
                                    }
                                </select>
                                : null
                                }
                                {quizSelected
                                ? <Link to={`/${urlBase}/${quizSelected}`} className="createQuizButton">
                                    <p className="removeButton">MODIFY</p>
                                </Link>
                                : null 
                                }
                                

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(CreateQuizPopup);
