import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NavBar from './components/layout/navbar'
import BottomNavBar from './components/layout/bottomNavbar'
// import Footer from './components/layout/footer'
import NewDashboard from './components/dashboard/newDashboard'

import SignIn from './components/auth/signin'
import SignUp from './components/auth/signup'
import QuizLanding from './components/quizzes/quizzes'
import QuizLandingTrial from './components/quizzesTrial/quizzes'
import MCQForm from './components/mcqForm/mcqForm'
import newMCQForm from './components/newMCQForm/newMCQForm'
import McqGraph from './components/mcq/mcqGraph'
import McqGraphTrial from './components/mcqTrial/mcqGraph'
import Home from './components/home/home'
import TeacherDash from './components/teacherDash/teacherDash'
import FatFinger from './components/mcqFatFinger/fatFinger'
import Verify from './components/auth/verify'
import Password from './components/auth/password'
import FlashcardForm from './components/flashcardForm/flashcardForm'
import FlashcardFormBeta from './components/flashcardForm/flashcardFormBeta'
import Negative from './components/tutoring/negativeNumbers'
import ExportUsers from './components/admin/exportUsers'
import ManageClasses from './components/manageClasses/manageClasses'
import TeacherData from './components/teacherDash/teacherData'
import EconMaths from './components/misc/econMaths'
import ReactGA, { ga } from 'react-ga'
import MobileTeacherFeed from './components/teacherFeed/mobileTeacherFeed';
import NotVerified from './components/auth/notVerified'
import ManualSignUp from './components/auth/manualAdd'
import Assign from './components/assign/assign'
import StudentAssignments from './components/assign/studentAssignments'
import Help from './components/help/help'
import AdminDash from './components/admin/adminDash';
import SignupError from './components/auth/signupError';
import McqMaths from './components/mcq/mcqMaths';
import PastPapers from './components/pastPapers/pastPaper';
import ResourceUpload from './components/pastPapers/resourceUpload';
import PaperDump from './components/pastPapers/paperDump';
import Diagnostic from './components/diagnostic/diagnostic'
import Restrictions from './components/diagnostic/restrictions';
import BestTutor from './components/bestTutor/bestTutor'
// import 'autotrack';

const App = ({ auth, profile }) => {


  ReactGA.initialize('UA-163676299-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  ReactGA.ga('require', 'urlChangeTracker');
  ReactGA.ga('send', 'pageview');

  //Manages signup error
  if (auth.isLoaded && auth.uid && profile.isLoaded && profile.isEmpty) {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <BottomNavBar />
          <SignupError />

          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    )

  }

  // user signed in but not verified
  if (auth.isLoaded && auth.uid && !auth.emailVerified && profile.isLoaded && !profile.isEmpty) {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <BottomNavBar />
          <Switch>
            <Route exact path='/verify' component={Verify} />
            <Route path='/signin' component={SignIn} />
            <Route path='/signup' component={SignUp} />
            <Route path='/trialmcq/:id' component={McqGraphTrial} />
            <Route exact path='/trial' component={QuizLandingTrial} />
            <Route exact path='/home' component={Home} />
            <Route path='/flashcardformbeta/:id' component={FlashcardFormBeta} />
            <Route path='/resources/:id' component={PastPapers} />
            <Route path='/papers' component={PaperDump} />
            <Redirect to='/verify' />
          </Switch>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>


    )
    // user is signed in and verified 
  } else if (auth.uid && auth.emailVerified && profile.student && auth.isLoaded) {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <BottomNavBar />
          <Switch>
            {profile.teacherNotVerified ?
              <Route path='/' component={NotVerified} />
              : null
            }

            {/* JUAN ROUTE TEMPLATE */}
            {/* {profile.student==="student" && (
              <>
                <Route exact path='/verify' component={NewDashboard}/>  
                <Route exact path='/' component={NewDashboard}/>
                <Route exact path='/assign' component={StudentAssignments} />
              </>
            )} */}

            {/* If user hits refresh on verification page once verified */}
            {profile.student === "student"
              ? <Route exact path='/verify' component={NewDashboard} />
              : <Route exact path='/verify' component={TeacherDash} />
            }

            {/* Default dashboard */}
            {profile.student === "student"
              ? <Route exact path='/' component={NewDashboard} />
              : <Route exact path='/' component={TeacherDash} />
            }

            {/* Manage classes only available to teachers */}
            {profile.student === "student"
              ? null
              : <Route exact path='/manageclasses' component={ManageClasses} />
            }

            {/* Manage classes only available to teachers */}
            {profile.student === "student"
              ? null
              : <Route exact path='/teacherdata' component={TeacherData} />
            }

            {/* Mobile Teacher Feed only available to teachers */}
            {profile.student === "student"
              ? null
              : <Route exact path='/mobilefeed' component={MobileTeacherFeed} />
            }

            {/* Assign work only available to teachers */}
            {profile.student === "student"
              ? <Route exact path='/assign' component={StudentAssignments} />
              : <Route exact path='/assign' component={Assign} />
            }

            <Route path='/quizzes/:id' component={McqGraph} />
            <Route exact path='/quizzes' component={QuizLanding} />
            <Route exact path='/home' component={Home} />
            <Route path='/signin' component={SignIn} />
            <Route path='/help' component={Help} />
            <Route path='/signup' component={SignUp} />
            <Route path='/fatFinger/:id' component={FatFinger} />
            <Route path='/flashcardform/:id' component={FlashcardForm} />
            <Route path='/flashcardformbeta/:id' component={FlashcardFormBeta} />
            <Route path='/quizzesschool/:id' component={McqGraph} />
            <Route path='/negative' component={Negative} />
            <Route path='/econmaths' component={EconMaths} />
            <Route path='/resources/:id' component={PastPapers} />
            <Route path='/papers' component={PaperDump} />
            <Route path='/diagnostic' component={Diagnostic} />

            <Route exact path='/bookmarks' component={McqGraph} />
            <Route path='/bookmarks/:id' component={McqGraph} />

            {profile.student === "student"
              ?
              null
              :
              <Route path='/flagged' component={McqGraph} />
            }

            {profile.student === "restrictions"
              ?
              null
              :
              <Route path='/restrictions' component={Restrictions} />
            }

            {/* Must secure these!! */}
            {(profile.student === "teacher" || profile.admin)
              ?
              <Route path='/mcqform' component={MCQForm} />
              :
              null}

            {(profile.student === "teacher" || profile.admin)
              ?
              <Route path={'/newmcqformschool/:id'} component={newMCQForm} />
              :
              null}

            {(profile.student === "teacher" || profile.admin)
              ?
              <Route path={'/newmcqform/:id'} component={newMCQForm} />
              :
              null}





            {auth.uid === "6HgdaSjsLmUstYac0zPlCCNpiln1" ? <Route path='/export' component={ExportUsers} /> : null}
            {auth.uid === "6HgdaSjsLmUstYac0zPlCCNpiln1" ? <Route path='/admindash' component={AdminDash} /> : null}
            {auth.uid === "6HgdaSjsLmUstYac0zPlCCNpiln1" ? <Route path='/manual' component={ManualSignUp} /> : null}
            {auth.uid === "6HgdaSjsLmUstYac0zPlCCNpiln1" ? <Route path='/maths/:id' component={McqMaths} /> : null}
            {profile.upload ? <Route path='/resourceupload' component={ResourceUpload} /> : null}


          </Switch>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    );

    // user is not signed up 
  } else if (!auth.uid && auth.isLoaded) {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <Switch>
            <Route exact path='/'>
              <Redirect to='/home' />
            </Route>
            <Route path='/trialmcq/:id' component={McqGraphTrial} />
            <Route path='/biology/:id' component={McqGraphTrial} />
            <Route path='/economics/:id' component={McqGraphTrial} />
            <Route path='/chemistry/:id' component={McqGraphTrial} />
            <Route path='/Physics/:id' component={McqGraphTrial} />
            <Route path='/flashcardformbeta/:id' component={FlashcardFormBeta} />
            <Route path='/economicsflashcards/:id' component={FlashcardFormBeta} />
            <Route path='/biologyflashcards/:id' component={FlashcardFormBeta} />
            <Route path='/Physicsflashcards/:id' component={FlashcardFormBeta} />
            <Route path='/chemistryflashcards/:id' component={FlashcardFormBeta} />
            <Route exact path='/trial' component={QuizLandingTrial} />
            <Route exact path='/quizzes' component={QuizLandingTrial} />
            <Route exact path='/home' component={Home} />
            <Route path='/signin' component={SignIn} />
            <Route path='/signup' component={SignUp} />
            <Route path='/password' component={Password} />
            <Route path='/negative' component={Negative} />
            <Route path='/econmaths' component={EconMaths} />
            <Route exact path='/verify' component={SignUp} />
            <Route exact path='/teacherdata' component={SignIn} />
            <Route path='/resources/:id' component={PastPapers} />
            <Route path='/papers' component={PaperDump} />
            <Route path='/tutor' component={BestTutor} />
          </Switch>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <BottomNavBar />
        </div>
      </BrowserRouter>
    )
  }
}



const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(App);