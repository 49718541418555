import React, { Component } from 'react'
import Collapsible from 'react-collapsible';
import Spinner from '../spinner/spinner';
import './studentview.scss'
import firebase from '../auth/auth'

class StudentView extends Component {
    state = {}

    constructor(props) {
        super(props);
        this.state = {
            processing: true,
        }
        this.mapStudent = this.mapStudent.bind(this);
    }

    calculateCompletion(attemps, total) {
        return Math.round(attemps / total * 100) || 0;
    }

    calculateAccuracy(quizList) {
        let attemps = 0;
        let score = 0;

        quizList.forEach(({ quizScore = 0 }) => {
            score += quizScore;

            if (quizScore !== 0) {
                attemps++;
            }
        });

        attemps = attemps || 1;

        const accuracy = Math.round(score / attemps);
        return accuracy;
    }

    // Each student object in the studentArray object was structured name: data.
    mapStudent(student, index) {
        //We need this because the data object is an empty array when the props are 
        //not populated. This converts the empty array into an empty object for 
        //consistency, though this is not actually used.
        const categories = Object.entries(student.studentProgress || [])
            .filter(([category, quizList]) => typeof quizList === 'object');

        const { totalAttempts, totalQuizzes } = student;
        const completionPercentage = this.calculateCompletion(totalAttempts, totalQuizzes);
        const completeList = categories.reduce((completeList, [, quizList]) => [...completeList, ...quizList], [])
        const averageAccuracy = this.calculateAccuracy(completeList);

        let completionColor
        if (completionPercentage >= 66) {
            completionColor = "green"
        } else if (completionPercentage > 25 && completionPercentage < 66) {
            completionColor = "#cc5200"
        } else {
            completionColor = "red"
        }

        let accuracyColor
        if (averageAccuracy >= 66) {
            accuracyColor = "green"
        } else if (averageAccuracy > 25 && averageAccuracy < 66) {
            accuracyColor = "#cc5200"
        } else {
            accuracyColor = "red"
        }

        let studentTrigger =
            <div className="studentName">
                <p className="studentNameText">{student.name}</p>
                <p className="completion" style={{ color: completionColor }}>Completion {completionPercentage}%</p>
                <p className="accuracy" style={{ color: accuracyColor }}>Accuracy {averageAccuracy}%</p>
            </div>
        
        
        if (student.studentProgress) {
            return (
                
                <div key={index} className="studentViewLeftFlexContainer">
                    <div className="studentViewFlexContainer">
                        <div className="studentNameContainerStudentViewTop">
                            <i className="material-icons studentNameContainerStudentView">account_circle</i>
                            <p className="studentNameTextStudentView">{student.name}</p>
                        </div>
                        <div className="flexRow">
                            <div className="studentNameContainerStudentView">
                                <p className="studentViewPerformanceLabel">COMPLETION</p>
                                <p className="studentViewPerformanceMeasure">{completionPercentage}%</p>
                            </div>
                            <div className="studentNameContainerStudentView">
                                <p className="studentViewPerformanceLabel">ACCURACY</p>
                                <p className="studentViewPerformanceMeasure">{averageAccuracy}%</p>
                            </div>
                            {/* <div className="studentNameContainerStudentView">
                                <p>Completed quizzes: </p><p>{this.props.numberCompleted || 0}</p>
                            </div> */}
                            <div className="studentNameContainerStudentView">
                                <p className="studentViewPerformanceLabel">AVG. RESPONSE</p>
                                <p className="studentViewPerformanceMeasure">n/a</p>
                            </div>
                        </div>
                    </div>
                    {/* <Collapsible trigger={studentTrigger} className="student"> */}

                    <div className="collapsibleContainerStudentView">
                        <div className="studentNameContainerStudentViewTop">
                            <i className="material-icons studentNameContainerStudentView">analytics</i>
                            <p className="studentNameTextStudentView">Student data</p>
                        </div>

                        {/* The data object is structured {themename:attributes} */}
                        {/* {Object.entries(student).map(([themename, attributes], index) => {*/}
                        {
                        categories.map(([category, quizList], index) => {
                            
                    const completionPercentage = this.calculateCompletion(
                        quizList.filter(({ quizScore }) => quizScore && quizScore > 0).length,
                        quizList.length
                    );
                
                    const accuracy = this.calculateAccuracy(quizList);
                
                    let themeCompletionColor = 'red'
                    if (completionPercentage >= 66) {
                        themeCompletionColor = "green"
                    } else if (completionPercentage > 25 && completionPercentage < 66) {
                        themeCompletionColor = "#cc5200"
                    }
                
                    let themeAccuracyColor = 'red';
                    if (accuracy >= 66) {
                        themeAccuracyColor = "green"
                    } else if (accuracy > 25 && accuracy < 66) {
                        themeAccuracyColor = "#cc5200"
                    }
                
                    let themeTrigger =
                        <div className="themeNameStudentView">
                            <p className="themeNameTextStudentView">{category}</p>
                            {/* <p className="completion">Completion {completionPercentage}%</p> */}
                            <p className="themeAccuracyStudentView" style={{ color: themeAccuracyColor }}>Accuracy {accuracy} %</p>
                            <p className="themeCompletionStudentView" style={{ color: themeCompletionColor }}>Completion {completionPercentage} %</p>
                        </div>
                    return (
                        <div key={index}>
                            <Collapsible trigger={themeTrigger}>
                                <ul className="quizAttemptsContainer">
                                    {/* The attributes object is structured {quizName: } */}
                                    {(quizList || []).map(({ quizName, quizScore = 0 }, index) => {
                
                                        let quizAccuracyColor
                                        if (quizScore >= 66) {
                                            quizAccuracyColor = "green"
                                        } else if (quizScore > 25 && quizScore < 66) {
                                            quizAccuracyColor = "#cc5200"
                                        } else {
                                            quizAccuracyColor = "red"
                                        }
                                        return (
                                            <div className="questionAttempts" key={`questionAttempts${index}`}>
                                                <li className="classViewQuestion" key={`classViewQuestion${index}`}>{quizName}</li>
                                                <li className="classViewAttempt" style={{ color: quizAccuracyColor }} key={`classViewAttempt${index}`}>{quizScore}%</li>
                                            </div>
                                        )
                                    })}
                                </ul>
                            </Collapsible >
                        </div>
                    )
                        })}
                    </div>
                    {/* </ Collapsible> */}
                </div>
            )
        }
    }
    
    innerSlider() {
    }

    onSortChange = (event) => {
        this.setState({ sort: event.target.value })
    }

    sendEmail = () => {
        const {profile} = this.props
        firebase.firestore().collection('onboarding')
        .add({
            createdAt: new Date(),
            email: profile.email,
            firstName: profile.firstName,
        }).then(()=>this.setState({emailSent: true}))

    }

    getData() {
        if (!this.state.sort) {
            return this.props.students || [];
        }

        const sort = {};

        if (this.state.sort.match(/Accuracy/)) {
            sort.attr = 'averageAccuracy';
        } else {
            sort.attr = 'completion';
        }

        if (this.state.sort.match(/Asc/)) {
            sort.order = 1;
        } else {
            sort.order = -1;
        }

        return this.props.students.sort((studentA, studentB) => {
            return (studentA[sort.attr] || 0) > (studentB[sort.attr] || 0) ? sort.order : -sort.order;
        });
    }

    render() {
        const {emailSent, processing} = this.state
        const { students } = this.props;
        const studentsList = students && this.getData().map(this.mapStudent);

        return (
            <div style={{height: "100%"}}>
                {processing && !studentsList  ?
                    <Spinner/>
                    :null
                }

                <div className="studentDataContainerStudentView">
                {/* The code below waits for the studentArray props to be populated.
                It will iterate through each student in the studentArray object,
                passing down each student object into the mapStudent function. */}
                {studentsList}

                </div>                
   
            </div>
        )
    }
}

export default StudentView