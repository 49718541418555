import React, { Component } from 'react'
import { Line } from 'rc-progress';



class NewProgressLine extends Component {
    constructor() {
        super();
        this.state = {
          zeroPercent: 0,
        };
        this.increaseProgress = this.increaseProgress.bind(this);
    }

    componentDidMount() {
        this.increaseProgress();
    }
    
    increaseProgress() {
        
        const { zeroPercent } = this.state;
        const newPercent = zeroPercent + 1;
        if (newPercent >= this.props.percent+1) {
          clearTimeout(this.tm);
          return;
        }
            this.setState({ zeroPercent: newPercent });
            this.tm = setTimeout(this.increaseProgress, 10);
    }


    render () {
        let color
        if (this.state.zeroPercent<=25) {
            color="#a6a5c0"
        } else if (this.state.zeroPercent>25 && this.state.zeroPercent<=55) {
            color="#a6a5c0"
        } else if (this.state.zeroPercent>56 && this.state.zeroPercent<=80) {
            color="#7978a1"
        } else {
            color="#525174"
        }

        return (
            <div>
                <div className="dashboardElementLine">
                    {this.props.percent ? <Line percent={this.props.percent===0 ? '0' :`${this.state.zeroPercent}`} strokeWidth="4" trailWidth='4' strokeColor={color} />:
                    <Line percent='0' strokeWidth="4" trailWidth='4' strokeColor="#D9D9D9" />} 
                            
                </div>
                <div className="dashboardElement">
                    <p className="progressLabel">{this.props.themeAttempts}/{this.props.themeTotal} attempted</p>
                </div>
            </div>
        )
    }
}



export default NewProgressLine