import React from 'react';
import { connect } from 'react-redux'
import firebase from '../auth/auth'
import { Redirect } from 'react-router-dom'
import Spinner from '../spinner/spinner'
import './report.scss'

class Report extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isSubmittable: false,
        };
        
    }
    
    handleTypo = () => {
        this.setState({
            isSubmittable: true,
            typo: true,
            incorrectAnswer: false,
            incorrectExplanation: false,
            incorrectOther: false,
            stylingOne: "active",
            stylingThree: '',
            stylingTwo: '',
            stylingFour: ""
        })
    }

    handleAnswer = () => {
        this.setState({
            isSubmittable: true,
            typo: false,
            incorrectAnswer: true,
            incorrectExplanation: false,
            incorrectOther: false,
            stylingTwo: "active",
            stylingOne: '',
            stylingThree: '',
            stylingFour: ""
        })
    }

    handleExplanation = () => {
        this.setState({
            isSubmittable: true,
            typo: false,
            incorrectAnswer: false,
            incorrectExplanation: true,
            incorrectOther: false,
            stylingThree: "active",
            stylingTwo: '',
            stylingOne: '',
            stylingFour: ""
        })
    }

    handleOther = () => {
        this.setState({
            isSubmittable: true,
            typo: false,
            incorrectAnswer: false,
            incorrectExplanation: false,
            incorrectOther: true,
            stylingThree: "",
            stylingTwo: '',
            stylingOne: '',
            stylingFour: "active"
        })
    }

    handleChange = (e) => {
        let text = e.target.value;
        this.setState({
            feedback: text
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { feedback, typo, incorrectAnswer, incorrectExplanation, other } = this.state;
        const { quiz, question } = this.props;
        // const checkSubmit = document.querySelector('.feedbackSubmit');
        let issue = '';
        if(typo){
            issue = "typo"
        } else if(incorrectAnswer){
            issue = "incorrectAnswer"
        } else if(incorrectExplanation){
            issue = "incorrectExplanation"
        } else if (other) {
            issue = "other"
        }
        const date = new Date().toLocaleString();
        firebase.firestore().collection('feedback')
        .add({
            quiz: quiz, 
            question: question, 
            issue,
            feedback: feedback ? feedback : false, 
            date,
            email: this.props.profile.email,
            firstName: this.props.profile.firstName,
            lastName: this.props.profile.lastName,
            subject: this.props.profile.subject
        }).then(() => {
            console.log("feedback logged");
        }).catch((err) => {
            console.log(err.message);
        })

        this.setState({
            submitted: true
        })
    }

    
    render() {

        const {stylingOne, stylingTwo, stylingThree, stylingFour, isSubmittable, submitted} = this.state
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popupReport">
                            <div className="returnToQuizzesContainer">
                                <div className="closeContainer">
                                    <i className="material-icons" onClick={this.props.toggleReport}>clear</i>
                                </div>
                            </div>
                            
                            {!submitted ? 
                            <div>
                                <div className="marginTenTop">
                                    <label className='select-label' htmlFor="School">What's wrong?</label>
                                </div>                   
                                <div className={`feedbackBtn ${stylingOne}`} onClick={this.handleTypo}>
                                    <div className="reportButtonContainer">
                                        <i className="material-icons feedback">sentiment_very_dissatisfied</i>
                                        <p className="reportText">Typo</p>
                                    </div>
                                </div>
                                <div className={`feedbackBtn ${stylingTwo}`} onClick={this.handleAnswer}>
                                    <div className="reportButtonContainer">
                                        <i className="material-icons feedback">sentiment_very_dissatisfied</i>
                                        <p className="reportText">Answer incorrect</p>
                                    </div>
                                </div>
                                <div className={`feedbackBtn ${stylingThree}`} onClick={this.handleExplanation}>
                                    <div className="reportButtonContainer">
                                        <i className="material-icons feedback">sentiment_very_dissatisfied</i>
                                        <p className="reportText">Explanation incorrect</p>
                                    </div>
                                </div>
                                <div className={`feedbackBtn ${stylingFour}`} onClick={this.handleOther}>
                                    <div className="reportButtonContainer">
                                        <i className="material-icons feedback">sentiment_very_dissatisfied</i>
                                        <p className="reportText">Other</p>
                                    </div>
                                </div>
                                <div className="feedbackTextAreaContainer">
                                    <label>Give us more details (optional):</label>
                                    <textarea id="feedback" onChange={this.handleChange} className="feedbacktextArea" placeholder="Optional feedback..."/>
                                </div>
                                {isSubmittable ?
                                <div className="fancy-btn feedbackSubmit">
                                    <div className="activeButton" onClick={this.handleSubmit}><p className="needHelp"> SUBMIT </p></div>
                                </div>
                                : null
                                }
                            </div>
                            :
                            <div>
                                <p className='feedbackThanksText'>Thanks for the feedback! Our team will take a look.</p>
                                <p className='feedbackThanksEmoji'>👍</p>                           
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(Report) 