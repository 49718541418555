import React from 'react';
import {connect} from 'react-redux'
import MobileNavbar from '../layout/mobileNavbar'
import Collapsible from 'react-collapsible';
import firebase from '../auth/auth'
import './help.scss'

class Help extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schools: []
        }

    }

    componentDidMount() {
        this.getSchools();
    }

    getSchools() {
        firebase.firestore().collection('schools').orderBy('school')
            .get()
            .then(querySnapshot => {
                let schools = [] 
                
                querySnapshot.docs.map(doc => {
                    if (doc.data().unapproved) {
                        return;
                    } else {
                    schools.push(doc.data().school)
                    }
                })
                

                this.setState({ schools })
            })
    }

    link (location, target) {
        const newTab = target === '_blank';
        if (newTab) {
            window.open(location);
        } else {
            window.location = location;
        }
    }

    render() {

        const {schools} = this.state

        return (
            <div>
                <MobileNavbar />
                <div className="container">
                    <div>
                        <div className="flexRow">
                        <h3 className="econMathsTitle">Help and support</h3>
                        </div>
                        <p className="econMathsBold">The team is contactable via email at team@gradeit.io</p>
                    </div>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Onboarding students in 60 seconds</p>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div>
                            <p className="econMathsBold">1) Check to see if your school has been added to the system.</p>
                            <div className="input-field">
                                    <select className="schoolDropdown" onChange={this.handleChange} name="school">
                                        <option value="">Click here to view available schools</option>
                                        {schools && schools.map(school => {if (school!=="individual" && school!== "GradeIt") return <option key={school}>{school}</option>}
                                        )}
                                    </select>

                            </div>
                            <p>If not, please email your school name to team@gradeit.io</p>

                            <hr className="comfyMargin"></hr>

                            <p className="econMathsBold">2) Tell your students to sign up at https://gradeit.io/signup</p>
                            <p>• Make sure that they select your school name from the dropdown menu</p>
                            <p>• If the verification email does not arrive in 60 seconds, students may need to check their spam folder</p>

                            <hr className="comfyMargin"></hr>

                            <p className="econMathsBold">3) Optional: you can send students a link to this video guiding them through the signup process</p>
                            <a className='link comfyMargin'>https://www.youtube.com/watch?v=hcPe-ImbGVU</a>
                            
                        </div>
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/hcPe-ImbGVU"  
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Setting up classes and assignments</p>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div className="helpBulletContainer">   
                            <p className="helpBullet">• The video below shows you how to set up classes and assign work to students</p>
                            <p className="helpBullet">• Each quiz is designed to relate specifically to the associated flashcards</p>
                            <p className="helpBullet">• A lot of teachers will just guide students to the relevant flashcards/quizzes without using the assignments feature</p>
                            <p className="helpBullet">• <b>Encourage students to use the platform independently!</b> Our research has found that most students will complete several quizzes in a single sitting- this is perfect for revision!</p>
                        </div>
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/fbCpGFMZ4EQ" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                    <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">Creating and modifying quizzes</p>
                                </div>
                    }>
                    <div className="lessonContainer">
                        <div className="videoContainer">
                            <iframe 
                                className="responsive-iframe"
                                src="https://www.youtube.com/embed/C_CRjJiRkHg" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>      
                    </Collapsible>
                </div>
            </div>

        );     
    }
};

const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth

    }
}

export default connect(mapStateToProps)(Help)