import React from 'react';
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link } from 'react-router-dom'

class Popup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            time: 'start',
            title: 'Welcome to Quizz',
            text: 'This is a quiz application built using ReactJS. <br /><br /> Currently it\'s loaded with CSS questions from W3Scools, but you can easily load any type of questions into it. <br /><br /> It will dynamically load the question->answers pair and upload them into the components.' ,
            // buttonText: 'Start the quiz',
            buttonText: 'Restart' 
        };
        
        this.popupHandleTryAgain = this.popupHandleTryAgain.bind(this);
        this.popupHandleTryAnother = this.popupHandleTryAnother.bind(this);
    }
    
    popupHandleTryAgain() {
        // let { time } = this.state;
        
        // if(time === 'start'){
        //     this.setState({
        //         time: 'end',
        //         title: 'Congratulations!',
        //         buttonText: 'Restart'
        //     });
            
        //     this.props.startQuiz();
        // } else {
                        
            window.location.reload();// restart the application
        
    }

    popupHandleTryAnother() {
        // let { time } = this.state;
        
        // if(time === 'start'){
        //     this.setState({
        //         time: 'end',
        //         title: 'Congratulations!',
        //         buttonText: 'Restart'
        //     });
            
        //     this.props.startQuiz();
        // } else {
            this.props.history.push('/')
        
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            text: 'You got: <strong>' + this.props.score + '</strong> out of <strong>' +this.props.total +'</strong> questions right.'
        })
    }
    
    createMarkup(text) {
        return {__html: text};
    }
    
    
    render() {
       
        let { text } = this.state;
        
        let { style } = this.props;
        
        return (
            <div className="popup-container" style={style}>
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popup">
                            <h1>Quiz Completed</h1>
                            <p dangerouslySetInnerHTML={this.createMarkup(text)} />
                            <button className="fancy-btn" onClick={this.popupHandleTryAgain}>Try again</button>
                            <Link to='/trial' className="fancy-btn" >Try another quiz</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, firstName, lastName) => dispatch(createScore(score, total, quizName, firstName, lastName))
    }
}

export default connect(null, mapDispatchToProps)(Popup)