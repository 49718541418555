import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'



import './diagnostic.scss';


class CategoryMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }



    }

  


    render() {


        return(
            <div className="popup-container">
                <div className="container subjectMenu">
                    <div className="popupSubjectMenu">
                        <div className="subjectMenuCloseContainer">
                            <i className="material-icons closeCategoryMenu" onClick={this.props.toggleCategoryMenu}>close</i>
                        </div>
                        <div>
                            <div className="subjectMenuChangeSubjectTextContainer">
                                <div className="categoryMenuflexColumn">
                                        <div className="categoryMenuCategoryContainer" onClick={()=>this.props.addTopic("All topics")}>
                                            <p className="categoryMenuCategoryName">All topics</p>
                                            <p className="categoryMenuCategoryName right">+</p>
                                        </div>
                                        {this.props.themeArraySorted && this.props.themeArraySorted.map(category => {
                                                return (
                                                <div className="categoryMenuCategoryContainer" key={category} onClick={()=>this.props.addTopic(category)}>
                                                    <p className="categoryMenuCategoryName">{category}</p>
                                                    <p className="categoryMenuCategoryName right">+</p>
                                                </div>
                                                )
                                            })
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenu)