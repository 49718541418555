import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import Spinner from '../spinner/spinner'

class RemoveWarning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberDeleted: 0,
            processing: false
        }
        
    }

    removeQuiz = () => {
        let subjectPath
        if (this.props.profile.subject==='Economics') {
            subjectPath=``
        } else {
            subjectPath=this.props.profile.subject
        }
        
        const {removalIndex} = this.props
        this.setState({processing:true})

        firebase.firestore().collection('quizzes'+subjectPath)
            .doc(removalIndex)
            .delete()
            .then(() => console.log("Deleted quiz" + removalIndex))
            .catch(err => console.log(err))
        
        //Deleting a quiz will delete the questions for the relevant quiz
        //and the relevant school
        firebase.firestore().collection('mcqDatabase'+subjectPath)
            .where('school', '==', this.props.profile.school)
            .where('quizName', '==', this.props.removalQuiz)
            .get()
            .then(snapshot => {
                const result = snapshot.docs.map(doc => doc.id);
                this.setState({targetNumber: result.length})
                
                result.map(questionID => {
                    firebase.firestore().collection('mcqDatabase'+subjectPath)
                    .doc(questionID)
                    .delete()
                    .then(() => {
                        console.log("Admin deleted:" + questionID)
                        this.setState({
                            numberDeleted: this.state.numberDeleted + 1
                        })
                        this.checkComplete()
                    }).catch(err => console.log(err))
                })
                // toggleRemoveWarning()
                // refreshPage()
            })
    }

    checkComplete = () => {
        const {numberDeleted, targetNumber} = this.state
        const {toggleRemoveWarning, refreshPage} = this.props
        if (numberDeleted===targetNumber) {
            console.log('finished')
            toggleRemoveWarning()
            refreshPage()
        } else {
            console.log('incomplete')
        }
    }
    
    render() {
       const {toggleRemoveWarning}=this.props
       const {processing}=this.state
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp">
                            <div className="btn-floating closePopup" onClick={toggleRemoveWarning}><i className="material-icons">close</i></div>
                            <h5 className="needHelp">Are you sure?</h5>
                                <div className="fancy-btn needHelp" onClick={this.removeQuiz}>
                                    <i className="material-icons needHelp">delete</i>
                                    <p className="needHelp">Yes, delete</p>
                                </div>
                                <p className="needHelp">This cannot be reversed</p>
                                <div className="green-text center">
                                    {processing ? <Spinner /> : null}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(RemoveWarning);