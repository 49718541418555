import React from 'react';
import { Redirect, Link } from 'react-router-dom'
import './manageClassesPopup.scss'

class ManageClassesPopup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
           
        };
        
    }

    

    
    handleChange = (e) => {
        
        let { id, name, value, type, checked } = e.target
        this.setState ( 
            {edited : true}
        )

        this.setState ({editorText:e.target.value})

        

        
    }
    
    submitNewClass = () => {
        const {handleNewClass, toggleEditor} = this.props
        handleNewClass(this.state.editorText)
        toggleEditor()
    }
    
    
    render() {
        const { toggleEditor, auth } = this.props
        const { edited } = this.state
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popupManageClasses">
                        <div className="closePopupManageClasses" onClick={toggleEditor}>
                            <i className="material-icons">close</i>
                        </div>

                            <div className="marginTenTop">
                                <label className='select-label' htmlFor="School">Type a name for this class: </label>
                            </div>
                            <div className="ninetyPadded">
                                <input type="text" name="school" onChange={this.handleChange} />
                                <div>
                                    {edited 
                                    ? <i className="material-icons green-text manageClasses" onClick={this.submitNewClass}>thumb_up_alt</i>
                                    : <i className="material-icons grey-text">thumb_up_alt</i>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default ManageClassesPopup