import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { signUp } from '../../store/actions/authActions'
import MobileNavBar from '../layout/mobileNavbar'
import firebase from '../auth/auth'
import Spinner from '../spinner/spinner'
import './signup.scss'
import SimilarSchools from './similarSchools'

export class SignUp extends Component {
    state = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        school: "",
        student: "",
        subject: "",
        legal: false,
        loading: true,
        formComplete: false,
        schools: []
    }

    componentDidMount() {
        this.getSchools();

        //Deals with form-based signups
        if (this.props.location.pathname.length>8) {
            firebase.firestore().collection('signUpKeys')
            .doc(this.props.location.pathname.replace('/signup/',''))
            .get()
            .then(querySnapshot => {
                const formData= querySnapshot.data()

                this.setState({
                    school: formData.school,
                    classID: formData.classID,
                    subject: formData.subject,
                    student: "student",
                    formBased: true,
                    loading: false
                })
            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    getSchools() {
        firebase.firestore().collection('schools').orderBy('school')
            .get()
            .then(querySnapshot => {
                let schools = [] 
                
                querySnapshot.docs.map(doc => {
                    if (doc.data().unapproved) {
                        return;
                    } else {
                    schools.push({[doc.data().school]: {school: doc.data().school, similarTo: doc.data().similarTo }})
                    }
                })
                

                this.setState({ schools })
            })
    }

    handleChange = (e) => {
        let { id, name, value, type, checked } = e.target

        if (this.handleExceptions(name, value)) {
            return;
        }

        if (type === 'checkbox') {
            value = checked;
        }

        this.setState({
            [name || id]: value
        }, () => name=="school" ? this.checkSimilar(): null)

    }

    checkSimilar = () =>{
        console.log("checking")
        this.state.school && this.state.schools.map(school => {
            if (school[Object.keys(school)[0]]["school"] == this.state.school) {
                if (school[Object.keys(school)[0]]["similarTo"]) {
                    this.setState({
                        similarSchoolsFound: true,
                        similarSchools: school[Object.keys(school)[0]]["similarTo"]
                    })
                }
                
            }
        })
        
    }

    handleExceptions(input, value) {
        let exception = false;

        if (input === 'school' && value === 'other') {
            this.setState({
                newSchool: true
            });

            exception = true;
        }

        return exception;
    }

    handleNameInput = (e) => {
        String.prototype.capitalize = function () {
            return this.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
        };

        var input = e.target.value.capitalize()

        this.setState({
            [e.target.id]: input
        })

    }

    handleSubmit = (e) => {
        e.preventDefault()

        const { email, password, firstName, lastName,
        school, subject, student, legal } = this.state

        if (!firstName) {
            this.setState({ firstNameMissing: true })
        } else {
            this.setState({ firstNameMissing: false })
        }
        if (!lastName) {
            this.setState({ lastNameMissing: true })
        } else {
            this.setState({ lastNameMissing: false })
        }
        if (!school) {
            this.setState({ schoolMissing: true })
        } else {
            this.setState({ schoolMissing: false })
        }
        if (!subject) {
            this.setState({ subjectMissing: true })
        } else {
            this.setState({ subjectMissing: false })
        }
        if (!student) {
            this.setState({ studentMissing: true })
        } else {
            this.setState({ studentMissing: false })
        }
        if (!legal) {
            this.setState({ legalMissing: true })
        } else {
            this.setState({ legalMissing: false })
        } 

        //If new school, put school name into Title Case and removes erroneous spaces at the end
        if (email && password && firstName && lastName
            && school && this.state.newSchool && subject && student && legal && school!== "GradeIt") {
                console.log("New school being added...parsing")
                console.log(school[school.length-1])
                
                let strSplit = school.split(" ")
                let newWord=[]
                strSplit.map((word, index) => {
                    if(word.length>3) {
                        //If word has more than three characters, capitalise
                        newWord.push(word.charAt(0).toUpperCase() + strSplit[index].slice(1))
                    } else {
                        //Otherwise don't bother
                        newWord.push(word)
                    }
                })

                let capitalisedSchoolName = newWord.join(' ')
                
                //Remove erroneous space from the end of school names
                if (capitalisedSchoolName[capitalisedSchoolName.length-1]==" ") {
                    console.log("space at end of name")
                    this.setState({
                        school: capitalisedSchoolName.substring(0,capitalisedSchoolName.length-1)
                    }, this.processSignUp)
                }
                //Otherwise don't bother 
                else {
                    this.setState({
                        school: capitalisedSchoolName
                    }, this.processSignUp)
                }
        } 
        //If not a new school
        else if (email && password && firstName && lastName
            && school && !this.state.newSchool && subject && student && legal && school!== "GradeIt") {
            console.log("User is signing up to existing school... no parsing required");
            this.processSignUp();
        }

    }

    processSignUp = () =>{
        const { email, password, firstName, lastName,
            school, subject, student, legal } = this.state

        this.props.signUp(this.state)
        this.setState({isProcessing:true})

        if (email && password && firstName && lastName
            && school && subject && student && legal && school &&
            this.state.newSchool===true && this.state.school && school!== "GradeIt") {

                if (this.state.schools.indexOf(school)=== -1) {
                    firebase.firestore().collection('schools').add({
                        school:school,
                        createdAt: new Date(),
                        unapproved: true
                    }).then(()=>{
                        console.log("School added")
                    }).catch(function(error) {
                        console.log(error)
                    });
                } else {
                    console.log('School already exists')
                }
        }
    }

    // //makes this asynchrous so createStudentProgress and signUp can BOTH be completed ahead of re-direct
    // nowSignUp () {
    //     this.props.signUp(this.state)
    // }

    selectSchoolFromPopUp = (school) => {
        this.setState ({
            school: school,
            similarSchoolsFound: false,
        })

    }

    render() {
        const { auth, authError } = this.props;
        const { firstNameMissing, lastNameMissing, schoolMissing, subjectMissing,
            studentMissing, isProcessing, schools, newSchool, legalMissing, formBased, loading, similarSchoolsFound, similarSchools } = this.state

        if (auth.uid) return <Redirect to='/' />

        return (
            <div>
                <MobileNavBar />
                {
                  similarSchoolsFound 
                    ? 
                    <SimilarSchools
                        schoolSelected= {this.state.school}
                        selectSchoolFromPopUp= {this.selectSchoolFromPopUp}
                        similarSchools= {similarSchools}
                    />
                    : null  
                }
                { !loading ?
                    <div className="container">
                        <form onSubmit={this.handleSubmit} className="white">
                            <div className="signUpHeader">
                                <h5 className="grey-text text-darken-3">Sign Up{formBased? `- ${this.state.school}`:null}</h5>
                                {formBased 
                                    ? null
                                    : <Link to='/signin' className="alreadyUser" >I am already a user </Link>
                                }
                            </div>
                            {formBased 
                                ? <div className="signUpHeader">
                                    <h6 className="grey-text text-darken-3">Class: {this.state.classID}</h6>
                                </div>
                                    : null
                            }
                            <div className="input-field">
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" onChange={this.handleChange} />
                            </div>
                            <div className="input-field">
                                <label htmlFor="password">Password</label>
                                <input type="password" id="password" onChange={this.handleChange} />
                            </div>
                            <div className="input-field">
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" id="firstName" onChange={this.handleNameInput} />
                            </div>
                            <div className="input-field">
                                <label htmlFor="lastName">Last Name</label>
                                <input type="text" id="lastName" onChange={this.handleNameInput} />
                            </div>
                            {!formBased
                            ?
                                <div className="input-field">
                                    <label className={newSchool ? 'select-label active' : 'select-label'} htmlFor="School">School</label>
                                    {newSchool
                                        ? (
                                            <>
                                            <input type="text" name="school" onChange={this.handleChange} placeholder="Type school name to add new school here"/>
                                            <span className="get-schools-list" onClick={() => this.setState({ newSchool: false, school: "" })}>
                                                Or choose school from dropdown
                                            </span>
                                            </>
                                        )
                                        : (
                                            <select
                                                className="schoolDropdown"
                                                onChange={this.handleChange}
                                                name="school"
                                                value={this.state.school}>
                                                <option value="">Click here to choose from options</option>
                                                <option value="other">Add new school</option>
                                                <option value="individual">Individual user</option>
                                                <option value="">---</option>
                                                {schools.map(school => {if (school!=="individual" && school!== "GradeIt") return <option key={Object.keys(school)[0]}>{Object.keys(school)[0]}</option>}
                                                )}
                                            </select>
                                        )
                                    }
                                </div>
                            : null
                            }
                            {!formBased
                            ?
                                <div className="input-field">
                                    <label className='select-label' htmlFor="School">Subject</label>      
                                            <select className="schoolDropdown" onChange={this.handleChange} name="subject">
                                                <option value="">Click here to choose from options</option>
                                                <option value="Economics">A-level Economics</option>
                                                <option value="gcseBiologySingleScienceAQA">GCSE Science (Biology, Physics, Chemistry)</option>
                                            </select>
                                </div>
                            : null
                            }
                            {!formBased
                            ?
                            <>
                            <div className="radio">
                                <label className="studentTeacherQuestion" htmlFor="student"> I am a: </label>
                                <br />
                                <input className="radioButton" type="radio" id="student" name="student" value="student" onChange={this.handleChange} />
                                <label className="studentTeacherRadio">
                                    Student
                                </label>
                            </div>
                            <div className="radio">
                            <input className="radioButton" type="radio" id="student" name="student" value="teacher" onChange={this.handleChange} />
                                <label className="studentTeacherRadio">
                                    Teacher
                                </label>
                            </div>
                            </>
                            : null
                            }
                            <div className="input-field mt-1">
                                    <input type="checkbox" id="legal" name="legal" onChange={this.handleChange}/>
                                    <label htmlFor="legal" className="termsLabel">
                                        I agree to the <a href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/legal%2FGradeIt%20Terms%20and%20Conditions.docx?alt=media&token=dfe1d534-9c69-433f-be77-b0418bf8e86e" download>Terms and Conditions</a> and <a href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/legal%2FGradeIt%20Privacy%20Policy.docx?alt=media&token=97be433c-9e42-422a-af64-54727f1f34cc" download>Privacy Policy</a>.                        
                                    </label>
                            </div>
                            <div className="input-field mt-1 bottom">
                                    <input type="checkbox" id="consent" name="consent" onChange={this.handleChange}/>
                                    <label htmlFor="consent" className="termsLabel">
                                        Yes, send me GradeIt emails.                      
                                    </label>
                            </div>
                            <div className="input-field">
                                <button className="btn pink lighten-1 z-depth-0 waves-effect waves-light">Submit</button>
                            </div>
                            <div className="red-text center">
                                {authError ? <p>{authError}</p> : null}
                                {firstNameMissing ? <p> Please enter your first name</p> : null}
                                {lastNameMissing ? <p> Please enter your last name</p> : null}
                                {schoolMissing ? <p> Please select or add your school</p> : null}
                                {subjectMissing ? <p> Please select your subject</p> : null}
                                {studentMissing ? <p> Please select whether you are a student or a teacher</p> : null}
                                {legalMissing ? <p> You need to agree to our Terms and Conditions and Privacy Policy</p> : null}
                            </div>
                            <div className="green-text center">
                                {isProcessing ? <p>Processing...please wait 💪</p> : null}
                            </div>

                        </form>
                    </div>
                : <Spinner />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
