import React from 'react';
import './theme.scss'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Collapsible from 'react-collapsible';

class Theme extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClick = (url) => {
        window.location.href=url
    }

    deleteClickHandler = (id, quizName) => {
        this.props.toggleRemoveWarning()
        this.props.retrieveIndex(id)
        this.props.retrieveQuizName(quizName)
    }

    render() {
        const { highScores, quizzes, category} = this.props;

        const quizzesHTML = quizzes.map(({ quizName, testMode, unlocked, flashcard, id, upvotes, userID }, index) => {
            const classNames = ['innerThemeContainer1'];
            const highScore = highScores ? highScores[quizName] : '';
            let hrefBase;
            
            if (testMode) {
                hrefBase = '/fatfinger/';
            } else {
                if (category === 'My quizzes') {
                    hrefBase = '/quizzesschool/'
                } else {
                    hrefBase = '/quizzes/'
                }
            }
            
            // *** To apply locks, uncomment this
            // if (unlocked !== true) {
            //     classNames.push('locked');
            // } 

            return (
                
                <Collapsible trigger={
                <div className={classNames.join(' ')} key={index+quizName}>
                    
                    <li key={index} className='quizLandingQuizName'>
                        <p className="quizNameCollapsible text">{quizName}</p>
                        {/* <div className='upvotesInfo'>
                            <p className='upvotesNumber'>{upvotes}</p>
                            <i className="material-icons">arrow_upward</i>
                        </div> */}
                    
                        {/* Teachers can delete quizzes from the My Quizzes section but... */}
                        {(category==='My quizzes' && this.props.profile.student !=="student" &&
                        // If they are a content creator, they must have created the quiz 
                            ((this.props.profile.contentCreator && userID===this.props.auth.uid) || !this.props.profile.contentCreator ))
                            ? 
                            <div className="themeButtonContainer">
                                <Link className='material-icons quizLandingRemoveIcon' to={`/newmcqformschool/${quizName}`} >create</Link>
                                <i className='material-icons quizLandingRemoveIcon' onClick={()=> this.deleteClickHandler(id, quizName)}>delete</i>
                            </div>
                            : null}
                    </li>
                        {/* <a href={hrefBase + quizName} className="quizName">
                            {quizName}
                        </a></li>
                        {highScore ? <p className="innerHighScore">{Math.round(highScore)} % </p> : <p className="innerHighScore"> -- % </p>} */}
                </div>
                }>  
                    <div className="quizLandingQuizNameContainer" onClick={()=>this.onClick(hrefBase + (testMode ? this.props.profile.lastName[0] + "z" + this.props.profile.firstName[0] + Buffer.from(quizName).toString('base64') : quizName))} key={index}>
                        <li key={index} className='themeListContainer'>
                            {/* <div className="flexRow">
                                <div className="btn-floating">
                                    <i className="material-icons">
                                        help
                                    </i>
                                </div>
                            </div> */}
                            <a href={hrefBase + (testMode ? this.props.profile.lastName[0] + "z" + this.props.profile.firstName[0] + Buffer.from(quizName).toString('base64') : quizName) } className="quizLandingQuizName">
                                Quiz
                            </a>
                        </li>
                        {highScore ? <p className="quizLandingInnerHighScore">{Math.round(highScore)}% </p> : <p className="quizLandingInnerHighScore"> -- % </p>}
                    </div>
                        {flashcard ?
                        <div className="quizLandingQuizNameContainer" onClick={()=>this.onClick(`/flashcardformbeta/${flashcard}`)}>
                            <div>
                                <a href={`/flashcardformbeta/${flashcard}`} className="quizLandingQuizName">Flashcards</a>
                            </div>
                            <div className="quizLandingflashcardIconFlexRow">
                                <i className="material-icons">
                                    style
                                </i>
                            </div>
                        </div>
                        :   null
                        }
                    
                
                </Collapsible>
            )
        });

        return (
            <ul className="bigContainer">
                {quizzesHTML}
                {/* <li onClick={this.checkAnswer} className={classNames[3]} data-id="4"><span>D</span> <p>{answers[3]}</p></li> */}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Theme);
