import React, { Component } from 'react'
import './footer.css'
import Twitter from '../../resources/Twitter_Social_Icon_Rounded_Square_Color.png'
export class Footer extends Component {

  render() {

    return (
      <footer className="footer">
        {/* <div class="container">
            <div class="row">
              <div class="col l6 s12">
                <h5 class="white-text">Footer Content</h5>
                <p class="grey-text text-lighten-4">You can use rows and columns here to organize your footer content.</p>
              </div>
              <div class="col l4 offset-l2 s12">
                <h5 class="white-text">Links</h5>
                <ul>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 1</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 2</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 3</a></li>
                  <li><a class="grey-text text-lighten-3" href="#!">Link 4</a></li>
                </ul>
              </div>
            </div>
          </div> */}
        <div className="footerContainer">
          <p className="grey-text left" >© 2020 GradeIt || <a href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/legal%2FGradeIt%20Privacy%20Policy.docx?alt=media&token=97be433c-9e42-422a-af64-54727f1f34cc">Privacy </a> || <a href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/legal%2FGradeIt%20Terms%20and%20Conditions.docx?alt=media&token=dfe1d534-9c69-433f-be77-b0418bf8e86e">Terms </a> || <a href="/papers">Papers</a></p>
          <div className="footerContainerRight">
            <a className="grey-textFooter" href="https://gradeit.io/tutor" >|</a>
            <p className="grey-textFooter" >Contact us ||</p>
            <i className="material-icons blue-text">email</i>
            <p className="grey-textFooter" >team@gradeit.io</p>
            <p className="grey-textFooter" >||</p>
            <a href="https://twitter.com/GradeItEcon" target="_blank">@GradeItEcon<img src={Twitter} className="twitter" alt="twitter"></img></a>
          </div>
        </div>
      </footer>
    )
  }
}


export default Footer
