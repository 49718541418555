import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import './settings.scss'
import { signOut } from '../../store/actions/authActions'

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    componentDidMount () {
        this.setState({
            weeklyEmails: this.props.profile.weeklyEmails,
            flagEmails: this.props.profile.flagEmails
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.id]: this.state[event.target.id]? false : true
        })

        firebase.firestore().collection('users')
        .doc(this.props.auth.uid)
        .update({
            [event.target.id]: this.state[event.target.id]? false : true
        })
    }

    render() {
        const {weeklyEmails, flagEmails} = this.state
        const {toggleSettings} = this.props

        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupSettings">
                            <div className="btn-floating closePopupSettings" onClick={toggleSettings}><i className="material-icons">close</i></div>
                            <p className="settingsTitle">SETTINGS</p>
                            {this.props.auth.emailVerified && this.props.profile.student==="teacher" 
                                ?
                                <div>
                                    <div className="input-field mt-1">
                                        <input checked={flagEmails} type="checkbox" id="flagEmails" onChange={this.handleChange}/>
                                        <label htmlFor="legal" className="termsLabel">
                                            Receive email notifications when a student flags questions                        
                                        </label>
                                    </div>
                                    <div className="input-field mt-1">
                                        <input checked={weeklyEmails} type="checkbox" id="weeklyEmails" onChange={this.handleChange}/>
                                        <label htmlFor="legal" className="weeklysummaryLabel">
                                            Receive weekly summary statistics      
                                        </label>
                                    </div>
                                </div>
                            :
                            null
                            }
                            <div className='settingsLogoutButton' onClick={this.props.signOut}>
                                <i className='material-icons logout'>exit_to_app</i>
                                <p className='logoutText'>Logout</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

