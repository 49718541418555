import React from 'react'
import './negativeNumber.css'

class Negative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sign1: ""
        }
    
    }


    componentDidMount () {
        this.newCalculation()
    }

    newCalculation = () => {
        const signs = [ "(+)", "( - )" ]
        const x = [ "+", "-"]

        let sign1 = signs[Math.round(Math.random())]
        let sign2 = "x"
        let sign3 = signs[Math.round(Math.random())]
        let number1= Math.round(Math.random()*12)
        let number2= Math.round(Math.random()*12)

        this.setState({
            sign1,
            sign2,
            sign3,
            number1,
            number2
        })

        if (sign1==="(+)") {
            let key1=`negativeTextSymbolGreen`
            this.setState({key1})
        } else {
            let key1=`negativeTextSymbolRed`
            this.setState({key1})
        }

        if (sign3==="(+)") {
            let key3=`negativeTextSymbolGreen`
            this.setState({key3})
        } else {
            let key3=`negativeTextSymbolRed`
            this.setState({key3})
        }
    }


    render() {
        const {sign1, sign2, sign3, number1, number2} = this.state

        return (
            <div className="negativeContainerTop">
                <div className="negativeContainer">
                    <div className="negativeCardTemplate">
                        <p className={this.state.key1}>{sign1}</p>
                    </div>
                    <div className="negativeCardTemplate">
                        <p className="negativeText">{number1}</p>
                    </div>
                    <div className="negativeCardTemplate">
                        <p className="negativeTextSymbol">{sign2}</p>
                    </div>
                    <div className="negativeCardTemplate">
                        <p className={this.state.key3}>{sign3}</p>
                    </div>
                    <div className="negativeCardTemplate">
                        <p className="negativeText">{number2}</p>
                    </div>
                </div>
                <button className="negativeButton waves-effect" onClick={this.newCalculation}>
                    <p>New calculation</p>
                </button>  
            </div>
        )
    }
}

export default Negative