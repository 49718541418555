import React, { Component } from 'react'
import './teacherDash.scss';

import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import firebase from '../auth/auth'
import Spinner from '../spinner/spinner'
import Classview from './classview'
import Quizview from './quizview'
import CustomiseFeed from '../teacherFeed/customiseFeed'
import { getQuizzes, getQuizzesTrial, getStudentArray } from '../../helpers/getData';
import Toggle from 'react-toggle'



class TeacherData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: 'students',
            activeClass: "All students",
            flags: false,
            flagPopup: false,
            editor: false,
            customView: false,
            activeClass: ""
        }
    }

    componentDidMount() {
        this.getQuizzesTrialProps()
        this.getStudents("All students");
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
        // props.auth && this.getAttempts(props)
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        getQuizzesTrial(props.profile.school, props.profile.subject)
        .then((quizzes) => getStudentArray(this.props.auth.uid, quizzes))
        .then(({ quizzes, studentArray }) => {
            this.setState({
                quizzes
            });
        });
    }

    

    toggleFlagPopup = () => {
        this.setState({flagPopup: this.state.flagPopup ? false : true })
    }

    toggleEditor = () => {
        this.setState({editor: this.state.editor ? false : true })
    }


    getStudents(classSelector) {
        const { props } = this;

        if (!props.profile.school) return;

        if(this.props.profile.school==="individual") {
            this.setState({
                loaded: true,
                individualUser: true
            })
            return
        }

        let subjectString = ""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }

        let dynamicQuery
        if (classSelector === "All students") {
            dynamicQuery = firebase.firestore().collection(`studentProgress${subjectString}`).where('school', '==', props.profile.school).orderBy('lastName')

        } else {
            dynamicQuery = firebase.firestore().collection(`studentProgress${subjectString}`).where('school', '==', props.profile.school).where(`${subjectString}class`,'==', classSelector).orderBy('lastName')
        }
        

        firebase.firestore().collection('users')
            .where('school', '==', props.profile.school)
            .where('student', "==", "student")
            //LINE BELOW SHOULD INVOLVE AN ARRAY WITH AVAILABLE SUBJECTS
            .where('subject', "in", props.profile.availableSubjects)
            .orderBy('lastName')
            .get()
            .then(querySnapshot => {
                
                const students = querySnapshot.docs.map(doc => doc.data());
                console.log(students)

                this.setState({
                    students
                });

                return students;
            })
            .then((students) => {
                // component calls quizlist
                // which quiz they want

                let schoolRecord
                firebase.firestore().collection('schools').where('school', '==', this.props.profile.school)
                        .get()
                        .then(querySnapshot => {
                            schoolRecord = querySnapshot.docs.map(doc => doc.data());
                            let schoolID
                            if (querySnapshot.docs[0].id) {
                                schoolID = querySnapshot.docs[0].id
                            }
                        })
                        .then(()=> {
                            dynamicQuery
                    .get()
                    .then(querySnapshot => {
                        const { quizzes } = this.state;
                        const studentProgress = querySnapshot.docs.map(doc => doc.data());
                        

                        students.forEach(student => {
                            student.name = `${student.firstName} ${student.lastName}`;
                        });
                        
                        //1) Forms class list from studentProgress records
                        //2) Parses studentProgress from studentProgress records
                        let newClassObj = {};
                        studentProgress.forEach(student => {

                            if (!newClassObj[(student[subjectString+'class'])]) {
                                newClassObj[(student[subjectString+'class'])]=[]
                            }
                            newClassObj[(student[subjectString+'class'])].push(student)

                            const currentStudent = students.find(({ firstName, lastName, school }) => {
                                return student.firstName === firstName &&
                                    student.lastName === lastName &&
                                    student.school === school;
                            });

                            if (!currentStudent) {
                                console.error('Student not found!', { student })
                                return;
                            }

                            const completedQuizzes = student.studentProgress;

                            // currentStudent.studentProgress = student.studentProgress;
                            currentStudent.studentProgress = JSON.parse(JSON.stringify(quizzes));

                            let userScore = 0;

                            // puts student data into the relevant quiz objects within the relevant theme object
                            for (let quizName in completedQuizzes) {
                                let quizScore = completedQuizzes[quizName];

                                quizScore = typeof quizScore === 'number'
                                    ? quizScore
                                    : 0 // Object.values(progress)[0];

                                for (let category in currentStudent.studentProgress) {
                                    const quizzesList = currentStudent.studentProgress[category];

                                    if (typeof quizzesList !== 'object') continue;

                                    const quiz = quizzesList.find(
                                        (quiz) => quiz.quizName === quizName
                                    );

                                    if (quiz) {
                                        quiz.quizScore = quizScore;
                                    }
                                }

                                currentStudent.studentProgress[quizName] = quizScore;
                                userScore += quizScore;
                            }

                            currentStudent.totalAttempts = 0;

                            //calculates the number of quizzes attempted
                            Object.values(currentStudent.studentProgress).forEach(quizList => {
                                if (typeof quizList !== 'object') return;

                                quizList.forEach(({ quizScore = 0 }) => {
                                    if (quizScore) {
                                        currentStudent.totalAttempts++;
                                    }
                                });
                            });

                            //calculates the total number of quizzes                            
                            currentStudent.totalQuizzes = Object.values(quizzes)
                                .reduce((total, quizzes) => total + quizzes.length, 0);

                            //adds average accuracy and themeAccuracy to the studentArray
                            currentStudent.averageAccuracy = Math.round(
                                userScore / currentStudent.totalAttempts
                            );

                            currentStudent.completion = Math.round(
                                currentStudent.totalAttempts / currentStudent.totalQuizzes * 100
                            );
                        })

                        //Adds unassigned class if it doesn't already exit
                        if(!newClassObj["Unassigned"]) {
                            newClassObj["Unassigned"]=[]
                        }

                        //Adds empty classes to newClassObj
                        schoolRecord[0][`${subjectString}classes`] && Object.keys(schoolRecord[0][`${subjectString}classes`]).map(nameClass =>{
                            console.log(nameClass)
                            if (schoolRecord[0][`${subjectString}classes`][nameClass].length==0) {
                                console.log(nameClass + "is emmpty")
                                newClassObj[nameClass]=[]
                            }
                        })
                    

                        this.setState(
                            { 
                            students,
                            loaded: true,
                            //Classes should only load once
                            classes: this.state.classes ? this.state.classes : newClassObj
                            });
                            
                    })

                        })
                
            });
    }

    changeView = () => {
        this.setState({
            currentView: this.state.currentView === 'students' ? 'quizzes' : 'students'
        });
    }

    getDropdown() {

        const { noClasses, classes, activeClass } = this.state

        if (noClasses) {
            return (
                <select className="selectSortManageClasses" onChange={this.toggleClass}>
                    <option>All students</option>
                </select>
            )
        }

        if (!noClasses) {            
            return (
                <select className="selectSortManageClasses" onChange={this.toggleClass} value={activeClass}>
                        <option>All students</option>
                    {Object.entries(classes).map((e) => {
                        return (
                        <option key={e[0]}>{e[0]}</option>
                        )
                        })
                    }
                </select>
            )
        }
    }

    toggleClass = (event) => {
        this.setState ({
            activeClass: event.target.value,
            loaded: false
        })
        this.getStudents(event.target.value)
    }

    toggleClassMobile = (c) => {
        this.setState ({
            activeClass: c || "All students",
            loaded: false
        })
        this.getStudents(c || "All students")
    }

    toggleCustomView = () => {
        this.setState({
            customView: this.state.customView ? false : true
        })
    }

    toggleSpinner = () => {
        this.setState({loaded: false})
    }


    render() {
        const { auth, profile } = this.props;

        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />
        if (profile.school==="individual" && auth.isLoaded) return <Redirect to='/' />

        const { currentView, students, quizzes, checked, classes, flagPopup, editor, loaded } = this.state;

        return (
            <div className="containerTeacherDataContainer">
                {loaded
                ? null
                : <Spinner />
                }

                {this.state.customView
                ? <CustomiseFeed 
                toggleCustomView={this.toggleCustomView}
                classData={classes} 
                toggleClass={this.toggleClassMobile}
                activeClass={this.state.activeClass} />
                : null}

                <div className="flexRow TeacherDataContainer">
                    <div className="classContainerTeacherDataContainer">
                        <div className="flexColumnTeacherDashDataContainer">
                            <div className="teacherDashFlexRowTeacherDataContainer">
                                <div className="flexRowMyDataContainer">
                                    <p className="teacherDashMyData"> MY DATA </p>
                                    <i className="material-icons myData">analytics</i>
                                </div>
                                <i className='material-icons feedListTeacherData' onClick={this.toggleCustomView}>list</i>
                            </div>
                            <div className="flexRowTeacherData">
                                 <div className="blueContainerTeacherDashSelect" >
                                    <p className="classSelectLabelTeacherDash">CLASS</p>
                                    {classes ? 
                                    this.getDropdown()
                                    :
                                    null}
                                </div>
                                <Link to='/manageclasses' className="blueContainerTeacherDash">
                                        <p className="manageClassesTeacherDash">MANAGE CLASSES</p>
                                        <div className="flexSettingsTeacherDash">
                                            <i className="material-icons">settings</i>
                                            
                                        </div>
                                </Link>
                            </div>
                            <div className="flexRow">
                                <div className="toggleContainer">
                                    <p className="toggleViewLeft">View by student</p>
                                        <Toggle
                                            defaultChecked={this.state.tofuIsReady}
                                            icons={false}
                                            onChange={this.changeView} />
                                    <p className="toggleViewRight">View by quiz</p>
                                </div>
                            </div>                               
                            
                        </div>

                        <div>
                    

                            {currentView === "students"
                                ? <Classview
                                    students={students}
                                    profile={this.props.profile}
                                    individualUser={this.state.individualUser}
                                />
                                : <Quizview
                                    students={students}
                                    quizzes={quizzes}
                                    individualUser={this.state.individualUser}
                                    subject={this.props.profile.subject}
                                />
                            }
                        </div>
                    </div>           
                </div>
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

export default connect(mapStateToProps)(TeacherData)
