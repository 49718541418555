import React from 'react';
import './changeNamePopupMCQForm.scss'
import { getQuizzesTrial } from '../../helpers/getData';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Spinner from '../spinner/spinner'

class ChangeNamePopupMCQForm extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isSubmittable: true,
            numberChecked: 0
        };
        
    }

    componentDidMount() {
        this.setState({
            subject: this.props.profile.subject
        }, this.getQuizzesTrialProps)
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        

        getQuizzesTrial(props.profile.school, this.state.subject)
            .then((quizzes) => {
                
                let quizArray = []

                Object.entries(quizzes).map(category => {
                    category[1].map(quiz => {
                        quizArray.push(quiz.quizName)
                    })
                })
                
                this.setState({
                    quizArray,
                    numberOfQuizzes: quizArray.length,
                })
            })
            
    }

    
    
    submitNameChange = () => {
        const {quizArray, title} = this.state
        
        this.setState({isSubmittable: true})
        let quizOk=true
        quizArray.map((quizName) => {
            
            if ((title===quizName) || (title==="New quiz")) {
                console.log('Please choose a NEW name')
                quizOk= false
                this.setState({namingError: "Name matches an existing quiz, please choose another name"})
            }
            if (title.indexOf("/") > -1) {
                console.log('string contains a slash')
                quizOk= false
                this.setState({namingError: "Names cannot contain a forward slash, please choose another name"})
            }
            this.setState({numberChecked: ++this.state.numberChecked })
            this.checkFinished(quizOk, title)
        })
        
                
    }

    checkFinished = (quizOk, title) => {
        const {numberChecked, numberOfQuizzes, isSubmittable} = this.state
        console.log('checking', quizOk)
        if (numberChecked===numberOfQuizzes && quizOk) {
            this.setState({
                numberChecked: 0,
                isSubmittable: true,
                processing: true,
            })
            this.props.renameQuiz()
            console.log('check complete, quiz submittable')
        } else if (numberChecked===numberOfQuizzes && !quizOk) {
            this.setState({
                numberChecked: 0,
                isSubmittable: false})
            console.log('check complete, quiz not submittable')
        }
    }
    
    handleEditText = (e) => {
        this.props.changeTitle(e)

        let { value } = e.target

        this.setState({
            title: value
        })
        
    }

    home = () => {
        this.setState ({home: true})
    }
    
    render() {
        const { changeName } = this.props
        const { isSubmittable, home, processing, namingError } = this.state

        if (home) {
            return <Redirect to='/quizzes'/>
        }
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popupRenameQuizNewMCQForm">
                            <div className="returnToQuizzesContainer">
                                <p className="returnToQuizzes">Return to quizzes</p>
                                <div className="btn-floating closePopup" onClick={this.home}>
                                    <i className="material-icons">exit_to_app</i>
                                </div>
                            </div>
                            <div className="marginTenTop">
                                <label className='select-label' htmlFor="School">Please choose a NEW name for this quiz: </label>
                            </div>
                            <div className="ninetyPadded">
                                <input type="text" name="title" onChange={this.handleEditText} />
                                <div>
                                    <i className="material-icons green-text manageClasses" onClick={this.submitNameChange}>thumb_up_alt</i>
                                </div>
                            </div>
                            <div className="red-text center">
                                {!isSubmittable
                                ? <p>{namingError}</p>
                                : null
                                }
                            </div>
                            <div className="green-text center">
                                    {processing ? <Spinner/> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(ChangeNamePopupMCQForm) 