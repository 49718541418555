import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import ColumnBeta from './columnBeta'
import { DragDropContext } from 'react-beautiful-dnd'
import Toggle from 'react-toggle'
import { storage } from '../auth/auth';
import CKEditor from 'ckeditor4-react';
// import DOMPurify from 'dompurify'

import './flashcardFormBeta.scss';

class FlashcardFormBeta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotated: false,
            mcqData: [],
            cards: [],
            editMode: false,
            slide: "",
            sidebar: "",
            quizSubject: "",

        }
        this.rotate = this.rotate.bind(this);
        this.nextCard = this.nextCard.bind(this);
        this.previousCard = this.previousCard.bind(this);
        this.flashcardSelect = this.flashcardSelect.bind(this);
        this.addQuestion = this.addQuestion.bind(this)
        this.removeQuestion = this.removeQuestion.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)
        this.previousCardNav = this.previousCardNav.bind(this)
        this.onEditorChange = this.onEditorChange.bind( this );
    }

    componentDidMount() {
        this.pushData();
        // this.logPerformance()
    }


    componentWillReceiveProps(props) {
        // props.profile.xp && this.logPerformance(props)
    }

    // logPerformance = (props) =>{
    //     props = props || this.props
        
    //     let subjectString = ""
    //     if (this.props.profile.subject!=="Economics") {
    //         subjectString = this.props.profile.subject
    //     }

    //     let currentXp = this.props.profile[`${subjectString}xp`]
        
    //     let xpRequired

    //     if (currentXp < 50){
    //         xpRequired = 50-currentXp                       
    //     } else if (currentXp <= 1000){
    //         xpRequired = 1000-currentXp                       
    //     } else if (currentXp > 1000 && currentXp <= 2800){
    //         xpRequired = 2800-currentXp                      
    //     } else if (currentXp > 2800 && currentXp <= 5400){
    //         xpRequired = 5400-currentXp                       
    //     } else if (currentXp > 5400 && currentXp <= 8800){
    //         xpRequired = 8800-currentXp                       
    //     } else if (currentXp > 8800 && currentXp <= 13000){
    //         xpRequired = 13000-currentXp                       
    //     } else if (currentXp > 13000){
    //         xpRequired = "n/a"                       
    //     }   

    //     Removed datalogging
    //     console.log(xpRequired)
    //     const {school} = this.props.profile
    //     if (xpRequired && this.props.profile.student=="student" && this.props.profile.school!=="INSEEC M1") {
    //         firebase.firestore().collection('dataLogging')
    //         .add({
    //             school: school[1]+ school[school.length-2] + school[2],
    //             studentID: this.props.auth.uid,
    //             eventID: Date.now() + this.props.auth.uid, 
    //             type: "flashcard",
    //             topic: this.props.match.params.id,
    //             timePresented: Date.now(),
    //             timeAnswered: Date.now(),
    //             timeTaken: "n/a",
    //             streak: "n/a",
    //             questionID: this.props.match.params.id,
    //             correct: "n/a",
    //             question: "n/a",
    //             answerClicked: "n/a",
    //             answer: "n/a",
    //             xpRequired: parseInt(xpRequired),
    //             xp: parseInt(this.props.profile.xp),
    //             versionID: this.props.profile.versionID | 0,
    //             subject: this.props.profile.subject
    //         })
    //     }
    // }


    pushData(id) {
        let { subject } = this.props.profile

        if (this.props.match.path === "/economicsflashcards/:id") {
            subject= "Economics"
            this.setState({quizSubject:'economics'})
        } else if (this.props.match.path === "/biologyflashcards/:id") {
            subject = "gcseBiologySingleScienceAQA"
            this.setState({quizSubject:'biology'})
        } else if (this.props.match.path === "/Physicsflashcards/:id") {
            subject = "Physics"
            this.setState({quizSubject:'Physics'})
        } else if (this.props.match.path === "/chemistryflashcards/:id") {
            subject = "gcseChemistrySingleScienceAQA"
            this.setState({quizSubject:'chemistry'})
        }

        let flashcards

        if (subject==='Economics'|| !subject) {
            flashcards= 'flashcards'
        } else {
            flashcards= `flashcards${subject}` 
        }


        firebase.firestore().collection(flashcards)
            .doc(id || this.props.match.params.id)
            .get()
            .then(querySnapshot => {
                const mcqData = querySnapshot.data();
                const cards = []
                mcqData.cards.map((card) => {
                    cards.push(card)
                })

                this.setState({
                    mcqData,
                    text: mcqData.cards[0].flashcardQuestion,
                    answer: mcqData.cards[0].flashcardAnswer,
                    series: mcqData.flashcardSeries,
                    url: mcqData.cards[0].url,
                    urlBack: mcqData.cards[0].urlBack,
                    graphCard: mcqData.cards[0].graphCard,
                    graphCardBack: mcqData.cards[0].graphCardBack,
                    type: mcqData.cards[0].type,
                    total: mcqData.cards.length,
                    nr: 0,
                    theme: mcqData.theme,
                    Edexcel: mcqData.cards[0].Edexcel,
                    OCR: mcqData.cards[0].OCR,
                    AQA: mcqData.cards[0].AQA,
                    value: mcqData.cards[0].type,
                    pictureFront: mcqData.cards[0].pictureFront,
                    pictureBack: mcqData.cards[0].pictureBack,  
                    cards,
                    newUrl: null,
                    quiz: mcqData.quiz
                })
            })
    }

    previousCardNav() {
        const { nr, cards } = this.state
        let nr1 = nr - 1
        this.setState({
            nr: nr1,
            text: cards[nr - 1].flashcardQuestion,
            answer: cards[nr - 1].flashcardAnswer,
            url: cards[nr - 1].url,
            urlBack: cards[nr - 1].urlBack,
            graphCard: cards[nr - 1].graphCard,
            graphCardBack: cards[nr - 1].graphCardBack,
            type: cards[nr-1].type,
            theme: cards[nr-1].theme,
            rotated: false,
            Edexcel: cards[nr-1].Edexcel,
            OCR: cards[nr-1].OCR,
            AQA: cards[nr-1].AQA,
            value:cards[nr-1].type,
            pictureFront: cards[nr-1].pictureFront,
            pictureBack: cards[nr-1].pictureBack,   
        })
        this.slideLeft()
        if (this.state.editMode) {
            this.save() 
        }
    }

    nextCard() {
        const { nr, cards } = this.state
        let nr1 = nr + 1
        this.setState({
            nr: nr1,
            text: cards[nr + 1].flashcardQuestion,
            answer: cards[nr + 1].flashcardAnswer,
            url: cards[nr + 1].url,
            urlBack: cards[nr + 1].urlBack,
            graphCard: cards[nr + 1].graphCard,
            graphCardBack: cards[nr + 1].graphCardBack,
            type: cards[nr+1].type,
            theme: cards[nr+1].theme,
            rotated: false,
            Edexcel: cards[nr+1].Edexcel,
            OCR: cards[nr+1].OCR,
            AQA: cards[nr+1].AQA,
            value: cards[nr+1].type,
            pictureFront: cards[nr+1].pictureFront,
            pictureBack: cards[nr+1].pictureBack,
        })
        this.slideRight()
        if (this.state.editMode) {
            this.save() 
        }
    }

    slideRight() {
        this.setState(
            {slide:"right"}
        )
        setTimeout(() => {
            this.setState({slide:"hiddenLeft"})
                setTimeout(()=> {
                    this.setState({slide:"rightReturn"})
                    setTimeout(()=> {
                        this.setState({slide:"finished"})
                    }, 200)
                }, 300)
            }, 100)
    }

    slideLeft() {
        this.setState(
            {slide:"left"}
        )
        setTimeout(() => {
            this.setState({slide:"hiddenRight"})
                setTimeout(()=> {
                    this.setState({slide:"leftReturn"})
                    setTimeout(()=> {
                        this.setState({slide:"finished"})
                    }, 200)
                }, 300)
            }, 100)
    }

    previousCard(cards) {
        const { nr } = this.state;

        const nnr = nr === 0
            ? 0
            : nr - 1;

        const card = cards[nnr];

        return {
            nr: nnr,
            text: card.flashcardQuestion,
            answer: card.flashcardAnswer,
            url: card.url,
            urlBack: card.urlBack,
            graphCard: card.graphCard,
            graphCardBack: card.graphCardBack,
            type: card.type,
            theme: card.theme,
            rotated: false,
            Edexcel: card.Edexcel,
            OCR: card.OCR,
            AQA: card.AQA,
            value: card.type,
            pictureFront: card.pictureFront,
            pictureBack: card.pictureBack,     
        };
    }

    flashcardSelect(index) {
        const { cards } = this.state
        this.setState({
            nr: index,
            text: cards[index].flashcardQuestion,
            answer: cards[index].flashcardAnswer,
            url: cards[index].url,
            urlBack: cards[index].urlBack,
            graphCard: cards[index].graphCard,
            graphCardBack: cards[index].graphCardBack,
            type: cards[index].type,
            theme: cards[index].theme,
            rotated: false,
            Edexcel: cards[index].Edexcel,
            OCR: cards[index].OCR,
            AQA: cards[index].AQA,
            value: cards[index].type,
            pictureFront: cards[index].pictureFront,
            pictureBack: cards[index].pictureBack,     
        })
        if (this.state.editMode) {
            this.save() 
        }
    }

    addQuestion() {
        const { cards } = this.state
        const newCards = Array.from(this.state.cards)

        newCards.push({
            flashcardAnswer: "Answer goes here...",
            flashcardQuestion: `Question ${this.state.total + 1}`,
            flashcardSeries: this.state.flashcardSeries,
            graphCard: false,
            graphCardBack: false,
            type: "definition",
            theme: cards[0].theme,
            url: "",
            urlBack: "",
            Edexcel: true,
            OCR: true,
            AQA: true,
            value: "definition",
            pictureFront: "no picture",
            pictureBack: "no picture",   
        })
        const newState = {
            ...this.state,
            cards: newCards,
            total: newCards.length
        }
        this.setState(newState)
        this.save()
    }

    removeQuestion(index, event) {
        event.stopPropagation();

        if (this.state.total===1) {
            const { cards } = this.state
            const newCards = []
            newCards.push({
                flashcardAnswer: "Answer goes here...",
                flashcardQuestion: `Question 1`,
                flashcardSeries: this.state.flashcardSeries,
                graphCard: false,
                graphCardBack: false,
                type: "definition",
                theme: cards[0].theme,
                url: "",
                urlBack: "",
                Edexcel: true,
                OCR: true,
                AQA: true,
                value: "definition",
                pictureFront: "no picture",
                pictureBack: "no picture",   
             })
             const newState = {
                ...this.state,
                cards: newCards,
                total: newCards.length
            }
            this.setState(newState);
        }

        else {

            const newCards = Array.from(this.state.cards)

            newCards.splice(index, 1)

            const previous = this.previousCard(newCards);

            const newState = {
                cards: newCards,
                total: newCards.length,
                ...previous
            }
            this.setState(newState);
        }
        this.save()

    }

    rotate() {
        const { rotated, editMode } = this.state

        if (rotated) {
            this.setState ({slide:""})
        }

        if (!editMode) {
            rotated ? this.setState({ rotated: false }) : this.setState({ rotated: true })
        }
    }

    editRotate = () => {
        const { rotated } = this.state
        rotated ? this.setState({ rotated: false }) : this.setState({ rotated: true })
    }

    onDragEnd = result => {
        const { destination, source} = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const newFlashcardIds = Array.from(this.state.cards)
        newFlashcardIds.splice(source.index, 1);
        newFlashcardIds.splice(destination.index, 0, this.state.cards[source.index])

        const newState = {
            ...this.state,
            nr: destination.index,
            cards: newFlashcardIds,
            text: newFlashcardIds[destination.index].flashcardQuestion,
            answer: newFlashcardIds[destination.index].flashcardAnswer,
            url: newFlashcardIds[destination.index].url,
            urlBack: newFlashcardIds[destination.index].urlBack,
            graphCard: newFlashcardIds[destination.index].graphCard,
            graphCardBack: newFlashcardIds[destination.index].graphCardBack,
            type: newFlashcardIds[destination.index].type,
            theme: newFlashcardIds[destination.index].theme,
            rotated: false,
            Edexcel: newFlashcardIds[destination.index].Edexcel,
            OCR: newFlashcardIds[destination.index].OCR,
            AQA: newFlashcardIds[destination.index].AQA,
            value: newFlashcardIds[destination.index].type,
            pictureFront: newFlashcardIds[destination.index].pictureFront,
            pictureBack: newFlashcardIds[destination.index].pictureBack,
        }

        this.setState(newState)
        if (this.state.editMode) {
            this.save() 
        }

    }

    handleChange(event) {
        const newFlashcards = Array.from(this.state.cards)
        newFlashcards.forEach((card) => {
            card.flashcardSeries = event.target.value
        }
        )
        this.setState({ series: event.target.value })
    }

    handleTextChange(event) {
        const { nr } = this.state

        let newCards = this.state.cards
        newCards[nr][event.target.id] = event.target.value

        if (event.target.id === "flashcardQuestion") {
            this.setState({
                text: event.target.value,
                cards: newCards
            })
        } else if (event.target.id === "flashcardAnswer") {
            this.setState({
                answer: event.target.value,
                cards: newCards
            })
        } else {
            this.setState({
                answer: event.target.value,
                cards: newCards
            })
        }
    }


    changeView = () => {
        this.setState({
            editMode: this.state.editMode === true ? false : true,
        });
        this.save()
    }

    togglePicture = () => {
        const { nr, graphCard } = this.state
        let newCards = this.state.cards

        graphCard
            ? newCards[nr].graphCard = false
            : newCards[nr].graphCard = true

        this.setState({
            graphCard: this.state.graphCard === true ? false : true,
            cards: newCards
        });
    }

    togglePictureBack = () => {
        const { nr, graphCardBack } = this.state
        let newCards = this.state.cards

        graphCardBack ? newCards[nr].graphCardBack = false : newCards[nr].graphCardBack = true

        this.setState({
            graphCardBack: this.state.graphCardBack === true ? false : true,
            cards: newCards
        });
    }

    handleImage = e => {

        const { nr, rotated } = this.state
        let newCards = this.state.cards
        let key= (Math.random().toFixed(5)).substring(2,7)

        if (e.target.files[0]) {
            const image = e.target.files[0];
            const uploadTask = storage.ref(`flashCardImages/${image.name}${key}`).put(image);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                    // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {
                    // error function ....
                    console.log(error);
                },
                () => {
                    // complete function ....
                    storage.ref('flashCardImages').child(`${image.name}${key}`).getDownloadURL().then(url => {

                        if (rotated) {
                            newCards[nr].urlBack = url
                            this.setState({
                                urlBack: url,
                                cards: newCards
                            })
                        } else {
                            newCards[nr].url = url
                            this.setState({
                                url: url,
                                cards: newCards
                            })
                        }
                        this.save()
                    })
                }
            );
        }
    }

    getGraphEditContainer() {
        const { url, urlBack, rotated, pictureBack, pictureFront } = this.state

        let image
        let classNamePlaceholder
        let classNameImageContainer
        let classNameInput
        let placeholderText


        if (rotated) {
            urlBack
                ? image = urlBack
                : image = null

        } else {
            url
                ? image = url
                : image = null
        }


        if ((rotated && pictureBack==="picture") || (!rotated && pictureFront==="picture")) {
            classNamePlaceholder="flashcardFormImagePlaceholder"
            placeholderText="300 x 300 transparent square images work best!"
            classNameImageContainer="flashcardFormGraphEditMode"
            classNameInput="input-field flashcardFormImage"
        } else if ((rotated && pictureBack==="fullpicture") || (!rotated && pictureFront==="fullpicture")) {
            classNamePlaceholder="flashcardFormImagePlaceholderLarge"
            placeholderText="300 x 370 transparent images work best!"
            classNameImageContainer="flashcardFormGraphEditModeLarge"
            classNameInput="input-field flashcardFormImageLarge" 
        }
        

        return (
            <div className="flashcardFormGraphContainerEditMode">
                {image ?
                    <img src={image} className={classNameImageContainer} alt="graph" />
                    :
                    <div className={classNamePlaceholder}>
                        <p className="flashcardFormImagePlaceholder">{placeholderText}</p>
                    </div>
                }
                <div className={classNameInput}>
                    <input className="chooseFileflashcardFormImage" type="file" onChange={this.handleImage} />
                </div>
            </div>
        )
    }

    getTextEditContainerNoGraph() {
        const { text, answer, rotated } = this.state

        let display
        if (rotated) {
            display=answer   
        } else {
            display=text
        }

        return (
            <div className="questionFlashcardFormContainerEditTextNoGraph">
                <CKEditor
                    data={display}
                    type="inline"
                    onChange={this.onEditorChange}
                    config={ {      toolbar: [ 
                        [  'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo', 
                        'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', 'NumberedList', 'BulletedList',
                        '-', 'Outdent', 'Indent', 'Table', 'HorizontalRule', 'SpecialChar' ] 
                    ]             } }
                />
                                                    
            </div>
        )

        
    }

    getTextEditContainerGraph() {
        const { text, answer, rotated } = this.state

        let display
        if (rotated) {
            display=answer   
        } else {
            display=text
        }

        

        return (
            <div className="flashcardFormBottomTextContainerEditMode">
                <CKEditor
                    data={display}
                    type="inline"
                    onChange={this.onEditorChange}
                    config={ {      toolbar: [ 
                        [  'Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', 
                        'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', 'NumberedList', 'BulletedList',
                        '-', 'Outdent', 'Indent', 'Table', 'HorizontalRule', 'SpecialChar' ] 
                    ]   }}
                />
                                                    
            </div>
        )

        
    }

    onEditorChange( evt ) {
        const {rotated, nr} = this.state
        const clean = evt.editor.getData()
    
        let newCards = this.state.cards
        

        if (rotated) {
            newCards[nr]["flashcardAnswer"] = clean
            this.setState({
                answer: clean,
                cards: newCards
            });
        } else {
            newCards[nr]["flashcardQuestion"] = clean
            this.setState({
                text: clean,
                cards: newCards
            });
        }

    }

    handleTheme = (event) => {
        const newFlashcards = Array.from(this.state.cards)

        newFlashcards.forEach((card) => {
            card.theme = event.target.value
        }) 
        
        this.setState({ theme: event.target.value })
    }

    handleType = (event) => {
        const {nr} = this.state
        const newFlashcards = Array.from(this.state.cards)

        newFlashcards[nr].type=event.target.value 
        
        this.setState({ 
            type: event.target.value,
            value: event.target.value 
        })
    }

    handleExamBoard = (event) => {
        const {nr} = this.state
        const examBoard=event.target.id 
        const newFlashcards = Array.from(this.state.cards)

        newFlashcards[nr][examBoard]=event.target.checked

        this.setState ({
            cards: newFlashcards,
            [examBoard] : event.target.checked
        })

    }

    getToggleFront () {
        const {graphCard} = this.state
        
        return (

            <div className="toggleFlashcardFormContainerPictureCard">
                <p className="flashcardFormPictureCardToggleLabel">No picture</p>
                <Toggle
                    checked={graphCard}
                    className="custom-classname"
                    icons={false}
                    onChange={this.togglePicture} />
                <p className="flashcardFormPictureCardToggleLabel">Picture</p>
            </div>
        )
    }
    
    getToggleBack () {
        const {graphCardBack} = this.state

        return (
            <div style={{display: "flex"}}>
                <p className="flashcardFormPictureCardToggleLabel">No picture</p>
                <Toggle
                    checked={graphCardBack}
                    className="custom-classname"
                    icons={false}
                    onChange={this.togglePictureBack} />
                <p className="flashcardFormPictureCardToggleLabel">Picture</p>
            </div>
        )
    }

    getPictureToggle () {
        const {rotated} = this.state

        if (rotated) {
            return (
                <select className="schoolDropdown" id="pictureBack" value={this.state.pictureBack} onChange={this.handlePictureToggle}>
                    <option value="no picture">No picture</option>
                    <option value="picture">Picture</option>
                    <option value="fullpicture">Full length picture</option>
                </select>
            )
        } else {
            return (
                <select className="schoolDropdown" id="pictureFront" value={this.state.pictureFront} onChange={this.handlePictureToggle}>
                    <option value="no picture">No picture</option>
                    <option value="picture">Picture</option>
                    <option value="fullpicture">Full length picture</option>
                </select>
            )
        }

    }

    handlePictureToggle = (event) => {
        const {nr, rotated} = this.state
        const newFlashcards = Array.from(this.state.cards)

        if (rotated) {
            newFlashcards[nr].pictureBack=event.target.value 
            
            this.setState({ 
                pictureBack: event.target.value,
                picture: event.target.value 
            })
        } else {
            newFlashcards[nr].pictureFront=event.target.value 
            
            this.setState({ 
                pictureFront: event.target.value,
                picture: event.target.value 
            })
        }

    }

    getTypeIdentifier () {
        const {type} = this.state
        let key1
        let key2
        if (type==="graph") {
            key1="bar_chart"
            key2="Graph"
        } else if (type==="definition") {
            key1="spellcheck"
            key2="Definition"
        } else if (type==="analysis") {
            key1="laptop"
            key2="Analysis"
        } else if (type==="calculation") {
            key1="euro"
            key2="Calculation"
        }


        // return (
        //     <div className="flashcardFormTypeIdentifierContainer">
        //         <i className="material-icons flashcardFormTypeIdentifier">{key1}</i>
        //         <p className="flashcardFormTypeIdentifier">{key2}</p>
        //     </div>
        // )
    }

    getExamBoards () {
        const examBoards=["Edexcel", "AQA", "OCR"]

        const containers = examBoards.map((examBoard, index) => {

            return (
                <div className="flashcardFormExamBoardContainer" key={`examBoard${index}`}>
                    <p className="flashcardFormExamBoardText">{examBoard}</p>
                    <input
                        className="flashcardFormCheckbox"
                        checked={this.state[examBoard]}
                        type="checkbox"
                        id={examBoard}
                        onChange={this.handleExamBoard}
                    />
                </div>
            )
        })

        return (
            <div>
                {containers}
            </div>
        )
    }

    getContainer () {
        const { rotated, editMode, pictureFront, pictureBack } = this.state
        
        let frontBack
        rotated 
        ? frontBack=pictureBack
        : frontBack=pictureFront

        
        if (editMode) {
            if (frontBack==="no picture") {
                return (
                    <div className="flashcardFormContainerOuterEditNoGraph">
                        {this.getTypeIdentifier ()}
                        {this.getTextEditContainerNoGraph()}
                    </div>
                )
            } else if (frontBack==="picture") {
                return (
                    <div className="flashcardFormGraphContainerOuter">
                        {this.getTypeIdentifier ()}
                        {this.getGraphEditContainer()}
                        {this.getTextEditContainerGraph()}
                    </div>
                )
            } else {
                return (
                    <div className="flashcardFormGraphContainerOuter">
                        {this.getTypeIdentifier ()}
                        {this.getGraphEditContainer()}
                    </div>
                )
            }
        }
    }

    getRenderContainer () {
        const { text, url, pictureFront, rotated } = this.state
        
        if (pictureFront==="no picture") {
            return (
                <div className="flashcardFormTextNoGraphContainer">
                    {this.getTypeIdentifier()} 
                    <div className="flashcardFormTextNoGraphTextContainer">
                        <p className="flashcardFormTextNoGraph" dangerouslySetInnerHTML={{__html: text}}></p>
                    </div>
                </div>
            )
        } else if (pictureFront==="picture") {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainer">
                        {url
                            ? <img src={url} className="flashcardFormGraph" alt="graph" />
                            : <div className="flashcardFormImagePlaceholder"></div>
                        }
                    </div>
                    <div className={`flashcardFormBottomTextContainer ${rotated}`}>
                        <p className="flashcardFormTextGraph" dangerouslySetInnerHTML={{__html: text}}></p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainerLarge">
                        {url
                            ? <img src={url} className="flashcardFormGraphLarge" alt="graph" />
                            : <div className="flashcardFormImagePlaceholderLarge"></div>
                        }
                    </div>
                </div>
            )
        }
        
    }

    getRenderContainerBack () {
        const { answer, urlBack, pictureBack,rotated } = this.state
        
        if (pictureBack==="no picture") {
            return (
                <div className="flashcardFormTextNoGraphContainer">
                    {this.getTypeIdentifier()} 
                    <div className="flashcardFormTextNoGraphTextContainer">
                        <p className="flashcardFormTextNoGraph" dangerouslySetInnerHTML={{__html: answer}}></p>
                    </div>
                </div>
            )
        } else if (pictureBack==="picture") {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainer">
                        {urlBack
                            ? <img src={urlBack} className="flashcardFormGraph" alt="graph" />
                            : <div className="flashcardFormImagePlaceholder"></div>
                        }
                    </div>
                    <div className={`flashcardFormBottomTextContainer ${!rotated}`}>
                        <p className="flashcardFormTextGraph" dangerouslySetInnerHTML={{__html: answer}}></p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainerLarge">
                        {urlBack
                            ? <img src={urlBack} className="flashcardFormGraphLarge" alt="graph" />
                            : <div className="flashcardFormImagePlaceholderLarge"></div>
                        }
                    </div>
                </div>
            )
        }
        
        
    }

    toggleSidebar = () => {
        const {sidebar} = this.state 
        this.setState ( {
            sidebar: sidebar=="" ? "display" : ""
        })

    }


    
        

    save = () => {
        const {series, cards} = this.state
        
        firebase.firestore().collection('flashcards')
            .where('flashcardSeries', '==', series)
            .get()
            .then(querySnapshot => {
                let seriesID=`${series}${(Math.random().toFixed(5)).substring(2,7)}`
                if (querySnapshot.docs.length===0) {
                    firebase.firestore().collection('flashcards')
                    .doc(seriesID)
                    .set(
                        {
                            cards: cards,
                            flashcardSeries: series,
                            school: this.props.profile.school,
                            student: this.props.auth.uid,
                            theme: this.state.theme,
                            createdAt: new Date(),   
                        }
                    ).then(()=> {
                        firebase.firestore().collection('flashcards')
                        .where('flashcardSeries', '==', seriesID)
                        .get().then(querySnapshot=> {
                            
                            this.setState({
                                newUrl: seriesID
                            })
                        })
                    })
                        // return <Redirect to={`/flashcardform/${seriesID}`}/>})
                    
                } else {
                    firebase.firestore().collection('flashcards').doc(this.props.match.params.id)
                    .set(
                        {
                            cards: cards,
                            flashcardSeries: series,
                            school: this.props.profile.school,
                            student: this.props.auth.uid,
                            theme: this.state.theme,
                            createdAt: new Date(),   
                        }
                    )

                    
                }
                
                
            })
        console.log("saved")
    }


    render() {

        const { rotated, nr, total, series, 
            cards, editMode, theme, newUrl,
            slide, sidebar, quiz, quizSubject} = this.state

        const {auth} = this.props
        
        let url
        if (auth.uid) {
            url= 'quizzes'
        } else {
            url= quizSubject
        }
        
        if (newUrl) {
            // window.location=`/flashcardform/${newUrl}`
            // return
            this.pushData(newUrl)
            return <Redirect to={`/flashcardform/${newUrl}`}/>
        }

        

        return (
            
            <div className="flashcardFormContainer">

                <div className="flashcardFormContainerLeft">

                    <div className="flashcardFormTitleBeta">
                        <div className="innerflashcardFormContainerTitleTextBeta">

                                 <div className="mcqGraphHeaderTopContainerThird">
                                    <Link to="/quizzes" className="material-icons mcqGraphHeaderTopContainerLeft">arrow_back</Link>
                                </div>
                                <div className="innerflashcardFormContainerTitleTextBeta">
                                    <p className="flashcardFormSeries ">{series}</p>
                                </div>
                                <Link className="flashcardFormBetaHeaderTopContainerThird" to={`/${url}/${quiz}`}>
                                    <i onClick className="material-icons mcqGraphHeaderTopContainerRight">touch_app</i>
                                </Link>

                                {quiz ?
                                <Link to={`/${url}/${quiz}`} className="flexColumnflashcardFormBetaQuizLink">
                                        <p className="bicep">💪</p>  
                                        <p className="bicepBetaLabel">Go to quiz</p>
                                </Link>
                                : null
                                }
                        </div>
                            
                        {/* <div className="toggleFlashcardFormContainer">
                            <Toggle
                                defaultChecked={this.state.editMode}
                                icons={false}
                                onChange={this.changeView} />
                            {editMode ? <p className="toggleViewRightFlashcardForm">Editing</p> :
                                <p className="toggleViewRightFlashcardForm">Edit</p>}
                            <div className="rotateButton">
                                <div className="btn-floating" onClick={this.save}>
                                    <i className="material-icons">save</i>
                                </div>
                                <p className="rotateCard"> Save</p>
                            </div>
                        </div> */}
                        
                    </div>

                    
                    <div className="flashcardFormContainerLeftMiddle">

                        <div className="flashcardFormContainer2" onClick={this.rotate}>
                            <div className={`theCardForm ${rotated} ${slide}`}>
                                <div className="flashcardFormFront">
                                    <div className="innerflashcardFormContainer">
                                        {editMode ?
                                            this.getContainer()
                                            :
                                            this.getRenderContainer()
                                        }
                                    </div>
                                </div>

                                <div className="flashcardFormBack">
                                    <div className="innerflashcardFormContainer">
                                        {editMode ?
                                            this.getContainer()
                                            :
                                            this.getRenderContainerBack()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flashcardFormTypeSelectionContainer">
                            <div className="flashcardFormTypeSelectionContainerAlignCentre">
                                
                                {editMode ?
                                    <div>
                                        <div className="flashcardFormExamBoardContainerVerticalFlex">
                                            {this.getExamBoards()}
                                        </div>
                                        {this.getPictureToggle()}
                                        <select className="schoolDropdown" id="type" value={this.state.value} onChange={this.handleType}>
                                            <option value="graph">Graph</option>
                                            <option value="definition">Definition</option>
                                            <option value="analysis">Analysis</option>
                                            <option value="calculation">Calculation</option>
                                        </select>
                                        <div className="rotateButton">
                                            <button className="btn-floating rotateButton" onClick={this.editRotate}><i className="material-icons waves-effect waves-circle waves-light">flip_camera_ios</i></button>
                                            <p className="rotateCard">Rotate card</p>
                                        </div>
                                        
                                    </div> 
                                    : null
                                }
                            </div>
                        </div>
                    </div>





                    <div className="flashcardFormButtonArrowContainer">
                        <div className="emptyThird">
                        </div>
                        <div className="flashcardNav">
                            {nr === 0 ?
                                <div className="btn-floating grey"><i className="material-icons">skip_previous</i></div>
                                :
                                <div className="btn-floating" onClick={this.previousCardNav}><i className="material-icons">skip_previous</i></div>}
                            <p className="flashcardNavPagination">{total ? `${nr + 1} / ${total}` : null}</p>
                            {nr + 1 === total ?
                                <div className="btn-floating grey"><i className="material-icons">skip_next</i></div>
                                :
                                <div className="btn-floating" onClick={this.nextCard}><i className="material-icons">skip_next</i></div>}
                        </div>
                        <div className="flashcardFormMenuButton">
                            <div className="btn-floating MenuWhite" onClick={this.toggleSidebar}>
                                <i className="material-icons menu">menu_open</i>
                            </div>

                        </div>
                    </div>


                </div>
                <div className={`flashcardFormContainerRight ${sidebar}`}>
                    <div>
                        <i className='material-icons toggleFlashcardSidebar'onClick={this.toggleSidebar}>close_fullscreen</i>
                    </div>
                    <div>                    
                        {editMode? 
                            <div className="flashcardFormThemeContainerEdit">
                                <i className="material-icons close" onClick={this.toggleSidebar}>highlight_off</i>
                                <p className="flashcardFormSelectTheme">Select theme</p>
                                <select className="flashcardFormThemeDropdown" id="theme" value={this.state.theme} onChange={this.handleTheme}>
                                            <option value="Theme 1">Theme 1</option>
                                            <option value="Theme 2">Theme 2</option>
                                            <option value="Theme 3">Theme 3</option>
                                            <option value="Theme 4">Theme 4</option>
                                </select>
                            </div>
                            :
                            <div>
                                {/* Can reinstate close button below */}
                                {/* <i className="material-icons close" onClick={this.toggleSidebar}>highlight_off</i> */}
                                <div className="flashcardFormThemeContainer">
                                    <p className="flashcardFormThemeContainerText">{theme}</p>
                                </div>
                            </div>
                            
                        }


                        <div className="droppableArea">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <ColumnBeta key="0" cards={cards} column="0" flashcardSelect={this.flashcardSelect} removeQuestion={this.removeQuestion} />
                            </DragDropContext>
                        </div>



                        {/* <div className="btn-floating flashcardFormAddQuestion" onClick={this.addQuestion}>
                            <i className="material-icons">add</i>
                        </div> */}
                    </div>
                </div>

            </div>


        );
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         createScore: (score, total, quizName, school, firstName, lastName) => dispatch(createScore(score, total, quizName, school, firstName, lastName))
//     }
// }

export default connect(mapStateToProps)(FlashcardFormBeta)
