import React from 'react';
import Answers from './answer';
import Popup from './Popup';
import Report from './report'
// import Footer from 'Footer';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { createScore } from '../../store/actions/quizscoreActions'
import CountUp from 'react-countup';
import { Howl } from 'howler'
import fanfare from '../../resources/397353__plasterbrain__tada-fanfare-g.flac'
import evolution from '../../resources/flyff_level_up.mp3'
import NeedHelp from './needHelp'
import Feedback from '../feedback/feedback'
import NegativeFeedback from '../feedback/negativeFeedback'
import XpBar from '../leveling/xpBar'
import FlagStudentList from './flagStudentList'
import MCQGraphBar from '../leveling/mcqGraphBar'
import NoFlag from '../teacherDash/noflag'
import Assigned from './assigned'
import Maths from './mathjax'
import Analytics from './analytics'


import './mcqGraph.scss';


class McqGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nr: 0,
            total: 0,
            showButton: false,
            questionAnswered: false,
            score: 0,
            displayPopup: 'none',
            answers2: [],
            question2: { __html: "Loading" },
            correct2: [],
            exposition: [],
            questionbank: [],
            numberClicked: 0,
            clearStyling: true,
            wrongRight: "INCORRECT",
            coins: 0,
            nextCoins: 0,
            streak: 0,
            losingStreak: 0,
            incorrectStreak: 0,
            needHelp: "off",
            negativeFeedback: false,
            liveLevel: 0,
            liveRequiredXp: 50,
            liveLastRequiredXp: 0,
            percentXp: 0,
            quizTaken: false,
            initLevel: 0,
            levelUp: false,
            bookmarked: false,
            flagged: false,
            flaggers: [],
            filteredFlags: [],
            performance: [],
            report: false
        }

        this.nextQuestion = this.nextQuestion.bind(this);
        this.handleShowButton = this.handleShowButton.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.handleIncreaseScore = this.handleIncreaseScore.bind(this)
        this.handleNumberClicked = this.handleNumberClicked.bind(this)
        this.handleStyling = this.handleStyling.bind(this);
        this.resetStreak = this.resetStreak.bind(this);
        this.toggleNeedHelp = this.toggleNeedHelp.bind(this)
        this.incrementXpBar = this.incrementXpBar.bind(this);
        this.setBookmark = this.setBookmark.bind(this);
        this.removeBookmark = this.removeBookmark.bind(this);
        this.checkBookmark = this.checkBookmark.bind(this);
        this.setFlag = this.setFlag.bind(this);
        this.checkFlag = this.checkFlag.bind(this);
        this.removeFlag = this.removeFlag.bind(this);


        this.fanfare = new Howl(
            {
                src: fanfare,
                volume: 0.5,
            }
        )

        this.evolution = new Howl(
            {
                src: evolution,
                volume: 0.5
            }
        )

    }

    async pushData(nr) {
        let route
        if (this.props.match && this.props.match.path === '/quizzesschool/:id') {
            route = this.props.profile.school
        } else {
            route = 'GradeIt'
        }

        let subject
        if (this.props.profile.subject === 'Economics') {
            subject = `mcqDatabase`
        } else {
            subject = `mcqDatabase${this.props.profile.subject}`
        }

        //Set subject in state in case user changes subject midway through quiz

        this.setState({
            subject: this.props.profile.subject
        })


        if (this.props.match && this.props.match.path !== '/bookmarks' && this.props.match.path !== '/bookmarks/:id' && this.props.match.path !== '/flagged') {

            firebase.firestore().collection(subject)
                .where('quizName', '==', this.props.match.params.id)
                .where('school', '==', route)
                .orderBy('position')
                .get()
                .then(querySnapshot => {
                    const mcqData = querySnapshot.docs.map(doc => doc.data());
                    mcqData.map((question, index) => mcqData[index]["questionID"] = querySnapshot.docs[index].id)

                    this.setState({
                        mcqData,
                        question2: { __html: mcqData[nr].questionText },
                        answers2: [
                            { __html: mcqData[nr].answer1 },
                            { __html: mcqData[nr].answer2 },
                            { __html: mcqData[nr].answer3 },
                            { __html: mcqData[nr].answer4 }
                        ],
                        correct2: mcqData[nr].correct,
                        graphQuestion: mcqData[nr].graphQuestion,
                        url: mcqData[nr].url,
                        exposition: [
                            mcqData[nr].exposition1,
                            mcqData[nr].exposition2,
                            mcqData[nr].exposition3,
                            mcqData[nr].exposition4
                        ],
                        exposition2: [
                            { __html: mcqData[nr].exposition1 },
                            { __html: mcqData[nr].exposition2 },
                            { __html: mcqData[nr].exposition3 },
                            { __html: mcqData[nr].exposition4 },
                        ],
                        correct: mcqData[nr].correct,
                        total: mcqData.length,
                        nr: this.state.nr + 1
                    })
                })
                .then(() => {
                    this.checkBookmark();
                    this.checkFlag();
                })
        } else if (this.props.match && this.props.match.path === '/bookmarks') {
            // needs to be made to fit all subjects
            const mcqData = this.props.profile[this.props.profile.subject + 'Bookmarks'].map(doc => doc.data)
            await this.setState({
                mcqData,
                question2: { __html: mcqData[nr].questionText },
                answers2: [
                    { __html: mcqData[nr].answer1 },
                    { __html: mcqData[nr].answer2 },
                    { __html: mcqData[nr].answer3 },
                    { __html: mcqData[nr].answer4 }
                ],
                correct2: mcqData[nr].correct,
                graphQuestion: mcqData[nr].graphQuestion,
                url: mcqData[nr].url,
                exposition: [
                    mcqData[nr].exposition1,
                    mcqData[nr].exposition2,
                    mcqData[nr].exposition3,
                    mcqData[nr].exposition4
                ],
                exposition2: [
                    { __html: mcqData[nr].exposition1 },
                    { __html: mcqData[nr].exposition2 },
                    { __html: mcqData[nr].exposition3 },
                    { __html: mcqData[nr].exposition4 },
                ],
                correct: mcqData[nr].correct,
                total: mcqData.length,
                nr: this.state.nr + 1
            })

            this.checkBookmark();
            this.checkFlag();
        } else if (this.props.match && this.props.match.path === '/bookmarks/:id') {
            // filters relevant bookmarks by quiz category
            let relevantBookmarks = this.props.profile[`${this.props.profile.subject}Bookmarks`]
                .filter(bookmark => bookmark.quizCategory === this.props.match.params.id);

            const mcqData = relevantBookmarks.map(doc => doc.data)
            await this.setState({
                mcqData,
                question2: { __html: mcqData[nr].questionText },
                answers2: [
                    { __html: mcqData[nr].answer1 },
                    { __html: mcqData[nr].answer2 },
                    { __html: mcqData[nr].answer3 },
                    { __html: mcqData[nr].answer4 }
                ],
                correct2: mcqData[nr].correct,
                graphQuestion: mcqData[nr].graphQuestion,
                url: mcqData[nr].url,
                exposition: [
                    mcqData[nr].exposition1,
                    mcqData[nr].exposition2,
                    mcqData[nr].exposition3,
                    mcqData[nr].exposition4
                ],
                exposition2: [
                    { __html: mcqData[nr].exposition1 },
                    { __html: mcqData[nr].exposition2 },
                    { __html: mcqData[nr].exposition3 },
                    { __html: mcqData[nr].exposition4 },
                ],
                correct: mcqData[nr].correct,
                total: mcqData.length,
                nr: this.state.nr + 1
            })

            this.checkBookmark()
            this.checkFlag()
        } else if (this.props.match && this.props.match.path === '/flagged') {
            // needs to be made to fit all subjects
            firebase.firestore().collection('schools')
                .where('school', '==', this.props.profile.school)
                .get()
                .then(querySnapshot => {
                    const school = querySnapshot.docs[0].data()
                    const flags = `school.${this.props.profile.subject}Flags`
                    let dynamicString = school[this.props.profile.subject + `Flags`]

                    const mcqDataUnfiltered = dynamicString.map(doc => doc.quizData)

                    if (mcqDataUnfiltered.length == 0) {
                        this.setState({ noFlags: true })
                        return
                    }

                    const mcqData = mcqDataUnfiltered.filter((question, index, self) => self.findIndex(t => (t.questionID === question.questionID)) === index)
                    this.setState({
                        mcqData,
                        question2: { __html: mcqData[nr].questionText },
                        answers2: [
                            { __html: mcqData[nr].answer1 },
                            { __html: mcqData[nr].answer2 },
                            { __html: mcqData[nr].answer3 },
                            { __html: mcqData[nr].answer4 }
                        ],
                        correct2: mcqData[nr].correct,
                        graphQuestion: mcqData[nr].graphQuestion,
                        url: mcqData[nr].url,
                        exposition: [
                            mcqData[nr].exposition1,
                            mcqData[nr].exposition2,
                            mcqData[nr].exposition3,
                            mcqData[nr].exposition4
                        ],
                        exposition2: [
                            { __html: mcqData[nr].exposition1 },
                            { __html: mcqData[nr].exposition2 },
                            { __html: mcqData[nr].exposition3 },
                            { __html: mcqData[nr].exposition4 },
                        ],
                        correct: mcqData[nr].correct,
                        total: mcqData.length,
                        nr: this.state.nr + 1
                    })
                })
                .then(() => {
                    this.checkBookmark();
                    this.checkFlag();
                    firebase.firestore().collection('schools')
                        .where('school', '==', this.props.profile.school)
                        .get()
                        .then(querySnapshot => {
                            const flags = querySnapshot.docs[0].data()[this.props.profile.subject + 'Flags']
                            const filteredFlags = flags.filter(flag => flag.quizData.questionID === this.state.mcqData[this.state.nr - 1].questionID)
                            const filteredFirst = filteredFlags.map(flag => flag.firstName)
                            const filteredLast = filteredFlags.map(flag => flag.lastName)
                            let flaggers = ""
                            for (var i = 0; i < filteredFlags.length; i++) {
                                var filteredFull = [filteredFirst[i] + " " + filteredLast[i]]
                                //Ternary makes sure comma is not added before first name
                                flaggers !== "" ?
                                    flaggers = flaggers + ", " + filteredFull
                                    :
                                    flaggers = flaggers + filteredFull
                            }
                            this.setState({
                                filteredFlags,
                                flaggers: flaggers
                            })
                            // const flaggers = []
                            // for (var i = 0; i < filteredFlags.length; i++) {
                            //     var filteredFull = [filteredFirst[i], filteredLast[i]].join(" ")
                            //     flaggers.push(`${filteredFull} `)
                            // }
                            // this.setState({
                            //     flaggers: flaggers
                            // })
                        })
                })
        } else if (this.props.questionArray) {
            //For diagnostics
            const mcqData = this.props.questionArray
            
            if (this.props.questionArray.length>0) {

                this.setState({
                    mcqData,
                    question2: { __html: mcqData[nr].questionText },
                    answers2: [
                        { __html: mcqData[nr].answer1 },
                        { __html: mcqData[nr].answer2 },
                        { __html: mcqData[nr].answer3 },
                        { __html: mcqData[nr].answer4 }
                    ],
                    correct2: mcqData[nr].correct,
                    graphQuestion: mcqData[nr].graphQuestion,
                    url: mcqData[nr].url,
                    exposition: [
                        mcqData[nr].exposition1,
                        mcqData[nr].exposition2,
                        mcqData[nr].exposition3,
                        mcqData[nr].exposition4
                    ],
                    exposition2: [
                        { __html: mcqData[nr].exposition1 },
                        { __html: mcqData[nr].exposition2 },
                        { __html: mcqData[nr].exposition3 },
                        { __html: mcqData[nr].exposition4 },
                    ],
                    correct: mcqData[nr].correct,
                    total: mcqData.length,
                    nr: this.state.nr + 1
                })
            } 

        }
        //For timer
        this.setState({
            response: Date.now(),
            eventStarted: Date.now()
        })
    }

    pushDataNR(nr) {

        let route
        if (this.props.match && this.props.match.path === '/quizzesschool/:id') {
            route = this.props.profile.school
        } else {
            route = 'GradeIt'
        }

        let subject
        if (this.state.subject === 'Economics') {
            subject = `mcqDatabase`
        } else {
            subject = `mcqDatabase${this.state.subject}`
        }

        if (this.props.match && this.props.match.path === '/flagged') {
            firebase.firestore().collection('schools')
                .where('school', '==', this.props.profile.school)
                .get()
                .then(querySnapshot => {
                    const flags = querySnapshot.docs[0].data()[this.state.subject + 'Flags']
                    const filteredFlags = flags.filter(flag => flag.quizData.questionID === this.state.mcqData[this.state.nr - 1].questionID)
                    const filteredFirst = filteredFlags.map(flag => flag.firstName)
                    const filteredLast = filteredFlags.map(flag => flag.lastName)

                    //Ternary makes sure comma is not added before first name
                    let flaggers = ""
                    for (var i = 0; i < filteredFlags.length; i++) {
                        var filteredFull = [filteredFirst[i] + " " + filteredLast[i]]
                        flaggers !== "" ?
                            flaggers = flaggers + ", " + filteredFull
                            :
                            flaggers = flaggers + filteredFull
                    }
                    this.setState({
                        filteredFlags,
                        flaggers: flaggers
                    })
                })
        }
        const { mcqData } = this.state

        this.setState({
            question2: { __html: mcqData[nr].questionText },
            answers2:
                [
                    { __html: mcqData[nr].answer1 },
                    { __html: mcqData[nr].answer2 },
                    { __html: mcqData[nr].answer3 },
                    { __html: mcqData[nr].answer4 }
                ],
            correct2: mcqData[nr].correct,
            graphQuestion: mcqData[nr].graphQuestion,
            url: mcqData[nr].url,
            exposition: [
                mcqData[nr].exposition1,
                mcqData[nr].exposition2,
                mcqData[nr].exposition3,
                mcqData[nr].exposition4
            ],
            exposition2: [
                { __html: mcqData[nr].exposition1 },
                { __html: mcqData[nr].exposition2 },
                { __html: mcqData[nr].exposition3 },
                { __html: mcqData[nr].exposition4 },
            ],
            correct: mcqData[nr].correct,
            total: mcqData.length,
            nr: this.state.nr + 1,
        })

        this.checkBookmark();
        this.checkFlag();

    }

    componentWillMount() {

        let { nr } = this.state;
        this.pushData(nr);
        if (this.props.match && (this.props.match.path !== '/bookmarks' && this.props.match.path !== '/bookmarks/:id' && this.props.match.path !== '/flagged')) {
            this.retrieveFlashcard()
        }

        let subjectString = ""

        if (this.props.profile.subject === 'Economics') {
            subjectString = ""
        } else {
            subjectString = this.props.profile.subject
        }

        firebase.firestore().collection(`studentProgress${subjectString}`)
            .doc(`${this.props.auth.uid}`)
            .get()
            .then((querySnapshot) => {
                const data = querySnapshot.data();
                const quizData = data.studentProgress;
                Object.keys(quizData).forEach(key => {
                    if (key === this.props.match.params.id) {
                        this.setState({
                            quizTaken: true
                        })
                    }
                })
            }).catch(err => {
                console.log(err.message);
            });

        firebase.firestore().collection('users')
            .doc(this.props.auth.uid)
            .update({
                lastLogged: new Date()
            })
    }

    componentDidMount() {
        this.incrementXpBar();
        this.setState({
            diagnostic: this.props.diagnostic
        })
    }

    retrieveFlashcard() {
        let quizzes
        if (this.props.profile.subject === 'Economics') {
            quizzes = `quizzes`
        } else {
            quizzes = `quizzes${this.props.profile.subject}`
        }

        //Only retrieve flashcards for quizzes created by GradeIt
        if (this.props.match.path !== "/quizzesschool/:id") {
            firebase.firestore().collection(quizzes)
                .where('quizName', '==', this.props.match.params.id)
                .where('school', '==', "GradeIt")
                .get()
                .then(querySnapshot => {
                    const data = querySnapshot.docs.map(doc => doc.data());
                    this.setState({ flashcard: data[0]['flashcard'] })
                })
        }
    }

    async nextQuestion() {
        let { nr, total, eventStarted, diagnostic } = this.state;
        const leveledUp = document.querySelector('.levelUpPopup');
        const endQuiz = document.querySelector('.endQuizPopup');
        const showInfo = document.querySelector('.avatarInfo');
        const finalAvatar = document.querySelector('.avatarContainer');

        let subjectString = ""

        if (this.state.subject === 'Economics') {
            subjectString = ""
        } else {
            subjectString = this.state.subject
        }

        if (nr === total) {
            if (!diagnostic) {
                this.setState({
                    displayPopup: 'flex'
                });
                if (this.props.match.path !== '/bookmarks' && this.props.match.path !== '/bookmarks/:id' && this.props.match.path !== '/flagged') {
                    this.props.createScore(this.state.score, this.state.total, this.props.match.params.id,
                        this.props.profile.school, this.props.profile.firstName, this.props.profile.lastName,
                        this.props.location.pathname, this.props.auth.uid, eventStarted + this.props.auth.uid,
                        this.props.profile[subjectString + 'class'],
                        this.state.subject, this.state.assigned
                    )
                }

                if (this.state.levelUp) {
                    endQuiz.classList.add('hidden');
                    leveledUp.classList.add('active');

                    this.evolution.play();

                    setTimeout(() => {
                        showInfo.classList.add('active');
                        finalAvatar.classList.add('final');
                    }, 7000);
                } else {
                    this.fanfare.play();
                }

                if (this.props.match.path !== '/bookmarks' && this.props.match.path !== '/bookmarks/:id' && this.props.match.path !== '/flagged') {
                    this.updateXp();
                } else {
                    return;
                }
            } else {
                this.setState({
                    analytics: true
                })
            }
        } else {
            // wait for pushData to go through for checkBookmark to avoid React's batch update
            await this.pushDataNR(nr);
            this.setState({
                showButton: false,
                questionAnswered: false,
                wrongRight: "INCORRECT"
            });

            this.checkBookmark();
            this.checkFlag();
        }

        this.setState({
            clearStyling: true,
            response: Date.now()
        })
        this.clearAnswers()
    }

    checkBookmark() {
        
        let tracker = 0;
        // needs to be updated to fit any subject, not just econ
        if (this.props.profile[this.state.subject + 'Bookmarks']) {
            const bookmark = this.props.profile[this.state.subject + 'Bookmarks'].map(bookmark => bookmark.data)
            for (var i = 0; i < this.props.profile[this.state.subject + 'Bookmarks'].length; i++) {

                if (bookmark[i] && bookmark[i].questionID === this.state.mcqData[this.state.nr - 1].questionID) {

                    this.setState({
                        bookmarked: true
                    })
                    tracker++;
                }
            }
            if (tracker === 0) {
                this.setState({
                    bookmarked: false
                })
            }
        } else {
            this.setState({
                bookmarked: false
            })
        }
    }

    checkFlag() {
        
        let tracker = 0;
        // needs to be updated to fit any subject, not just econ
        if (this.props.profile[this.state.subject + 'Flags']) {
            const flag = this.props.profile[this.state.subject + 'Flags'].map(flag => flag.quizData)
            for (var i = 0; i < this.props.profile[this.state.subject + 'Flags'].length; i++) {
                if (flag[i].questionID === this.state.mcqData[this.state.nr - 1].questionID) {
                    this.setState({
                        flagged: true
                    })
                    tracker++;
                }
            }
            if (tracker === 0) {
                this.setState({
                    flagged: false
                })
            }
        } else {
            this.setState({
                flagged: false
            })
        }

        if (this.props.match && this.props.match.path === '/flagged') {
            const flagResolved = document.querySelector('.flagResolved')
            const flagOriginal = document.querySelector('.flagContainer')
            flagResolved.classList.add('active')
            flagOriginal.classList.add('hidden')
        }
    }

    updateXp = () => {
        let subjectString = ""
        if (this.state.subject !== "Economics") {
            subjectString = this.state.subject
        }

        let { nextCoins } = this.state;
        let startingXp = this.props.profile[`${subjectString}xp`];
        let currentXp = parseInt(nextCoins) + parseInt(startingXp);

        firebase.firestore().collection('users')
            .doc(`${this.props.auth.uid}`)
            .update({ [`${subjectString}xp`]: currentXp })
    }

    clearAnswers() {
        const eventName = 'resetAnswers';
        const event = document.createEvent('HTMLEvents');
        event.initEvent(eventName, true, true);
        event.eventName = eventName;

        // Triggers reset answers event
        document.dispatchEvent(event);
    }

    handleShowButton() {
        this.setState({
            showButton: true,
            questionAnswered: true
        })
    }

    handleStartQuiz() {
        this.setState({
            displayPopup: 'none',
            nr: 1
        });
    }

    handleIncreaseScore(right) {
        const { nextCoins, streak, quizTaken, nr } = this.state

        let multiplier = 5


        if (streak === 0) {
            multiplier = 5
        } else if (streak === 1) {
            multiplier = 10
        } else if (streak === 2) {
            multiplier = 15
        } else if (streak >= 3) {
            multiplier = 20
        }


        if (quizTaken) {
            multiplier = Math.round(multiplier / 3);
        }

        //For diagnostics
        let newArray= Array.from(this.state.mcqData)
        // console.log(newArray[nr])

        if (right=="right") {
            newArray[nr-1]["answeredRight"]= "right"
            this.setState({
                score: this.state.score + 1,
                nextCoins: nextCoins + multiplier,
                streak: streak + 1,
                losingStreak: 0,
                mcqData: newArray
            });
        } else if (right=="wrong") {
            newArray[nr-1]["answeredRight"]= "wrong"
            this.setState({
                mcqData: newArray
            });
        }


        // this.setState({
        //     score: this.state.score + 1,
        //     nextCoins: nextCoins + multiplier,
        //     streak: streak + 1,
        //     losingStreak: 0,
        //     mcqData: newArray
        // });
        

        this.incrementXpBar();
    }


    incrementXpBar() {
        let subjectString = ""
        if (this.state.subject !== "Economics") {
            subjectString = this.state.subject
        }
        let { nextCoins, liveRequiredXp, liveLastRequiredXp, liveLevel } = this.state;

        let startingXp = this.props.profile[`${subjectString}xp`];

        let currentXp = parseInt(nextCoins) + parseInt(startingXp);

        // check live level and update if necessary (start at liveLevel 0 to deal with batch updates)
        if (currentXp < 50) {
            this.setState({
                liveLevel: 1,
                liveRequiredXp: 50,
                liveLastRequiredXp: 0
            })
        } else if (currentXp <= 1000) {
            this.setState({
                liveLevel: 2,
                liveRequiredXp: 1000,
                liveLastRequiredXp: 50
            })
        } else if (currentXp > 1000 && currentXp <= 2800) {
            this.setState({
                liveLevel: 3,
                liveRequiredXp: 2800,
                liveLastRequiredXp: 1000
            })
        } else if (currentXp > 2800 && currentXp <= 5400) {
            this.setState({
                liveLevel: 4,
                liveRequiredXp: 5400,
                liveLastRequiredXp: 2800
            })
        } else if (currentXp > 5400 && currentXp <= 8800) {
            this.setState({
                liveLevel: 5,
                liveRequiredXp: 8800,
                liveLastRequiredXp: 5400
            })
        } else if (currentXp > 8800 && currentXp <= 13000) {
            this.setState({
                liveLevel: 6,
                liveRequiredXp: 13000,
                liveLastRequiredXp: 8800
            });

        } else if (currentXp > 13000) {
            this.setState({
                liveLevel: 7,
                liveRequiredXp: 13000,
                liveLastRequiredXp: 13000
            })
        }

        // show you can't go beyond level 7
        let progressXp
        if (liveLevel === 7) {
            progressXp = 100;
        } else {
            progressXp = Math.round((currentXp - liveLastRequiredXp) / (liveRequiredXp - liveLastRequiredXp) * 100);
        }


        this.setState({
            percentXp: progressXp,
        })

        // check level up 
        const xpBar = document.querySelector('.rc-progress-circle.circleXpBarMcq');
        if (this.state.liveLevel > this.state.initLevel) {

            if (this.state.initLevel > 0) {
                const inter = setInterval(() => {
                    xpBar.classList.add('hidden');

                    setTimeout(() => {
                        xpBar.classList.remove('hidden')
                    }, 250);
                }, 500);

                setTimeout(() => {
                    clearInterval(inter)
                }, 1500)

                this.setState({
                    levelUp: true,
                    initLevel: this.state.liveLevel
                })
            } else {
                this.setState({
                    levelUp: false,
                    initLevel: this.state.liveLevel
                })
            }
        }

            // this.tm = setTimeout(this.incrementXpBar, 10);
        
    }

    resetCoins() {
        this.setState({ coins: this.state.nextCoins })
    }

    resetStreak() {
        this.setState({ streak: 0 })
    }

    getCoins() {
        const { coins, nextCoins } = this.state

        return (
            <div className="coinLabel">
                <CountUp
                    start={coins}
                    end={nextCoins}
                    duration={0.5}
                    useEasing={true}
                />
                <p className="coin">XP</p>
            </div>
        )
    }

    getStreak() {
        const { streak } = this.state

        return (
            <div className="coinLabel">
                <p className="coin">{streak} in a row</p>
            </div>
        )
    }

    handleNumberClicked(answer) {
        this.setState({
            numberClicked: answer - 1
        })
    }

    handleStyling() {
        this.setState({ wrongRight: "CORRECT" })
    }

    toggleNeedHelp = () => {
        this.setState({ needHelp: "acknowledged" })
    }

    losingStreakIncrement = () => {
        const { losingStreak, needHelp } = this.state
        this.setState({ losingStreak: losingStreak + 1 })
        if (losingStreak === 2 && needHelp === "off") {
            this.setState({ needHelp: "on" })
        }
    }

    loadLeaveWarning = () => {
        this.setState({ needHelp: "on" })
    }

    negativeFeedback = () => {
        if (!this.state.negativeFeedback) {
            this.setState({ negativeFeedback: true })
        } else {
            this.setState({ negativeFeedback: false })
        }
    }

    setFlag() {
        // add flag to school
        if (this.props.profile.school !== "individual") {
            firebase.firestore().collection('schools')
                .where('school', '==', this.props.profile.school)
                .get()
                .then(querySnapshot => {
                    const schoolId = querySnapshot.docs[0].id
                    const schoolFeedArray = querySnapshot.docs[0].data().schoolFeedArray || []


                    if (schoolFeedArray.length >= 1000) {

                        schoolFeedArray.shift()
                        schoolFeedArray.push({
                            type: 'flag',
                            createdAt: new Date(),
                            firstName: this.props.profile.firstName,
                            lastName: this.props.profile.lastName,
                            quizData: this.state.mcqData[this.state.nr - 1],
                            pathname: this.props.location.pathname,
                            user: this.props.auth.uid,
                            subject: this.state.subject
                        })
                    } else {

                        schoolFeedArray.push({
                            type: 'flag',
                            createdAt: new Date(),
                            firstName: this.props.profile.firstName,
                            lastName: this.props.profile.lastName,
                            quizData: this.state.mcqData[this.state.nr - 1],
                            pathname: this.props.location.pathname,
                            user: this.props.auth.uid,
                            subject: this.state.subject
                        })
                    }


                    firebase.firestore().collection('schools')
                        .doc(schoolId)
                        .update({
                            [this.state.subject + "Flags"]: firebase.firestore.FieldValue.arrayUnion({
                                createdAt: new Date(),
                                firstName: this.props.profile.firstName,
                                lastName: this.props.profile.lastName,
                                quizData: this.state.mcqData[this.state.nr - 1]
                            }),
                            schoolFeedArray

                        })
                })

            // add flag to user to then checkFlag 
            firebase.firestore().collection('users')
                .doc(`${this.props.auth.uid}`)
                .update({
                    [this.state.subject + "Flags"]: firebase.firestore.FieldValue.arrayUnion({
                        createdAt: new Date(),
                        quizData: this.state.mcqData[this.state.nr - 1]
                    })
                })

            this.setState({
                flagged: true
            })

            firebase.firestore().collection('users')
                .where("school", "==", this.props.profile.school)
                .where("student", "==", "teacher")
                .where("subject", "==", this.state.subject)
                .get()
                .then(querySnapshot => {
                    const teachers = querySnapshot.docs.map(doc => doc.data())
                    const teacherArray = teachers.map(teacher => teacher.flagEmails ? teacher.email : null)


                    firebase.firestore().collection('flags')
                        .add({
                            firstName: this.props.profile.firstName,
                            lastName: this.props.profile.lastName,
                            createdAt: new Date().toLocaleString(),
                            questionText: this.state.mcqData[this.state.nr - 1].questionText,
                            quizName: this.state.mcqData[this.state.nr - 1].quizName,
                            school: this.props.profile.school,
                            teacherEmails: teacherArray,
                        })
                        .then(() => {
                            console.log("flag logged")
                        })
                        .catch((err) => {
                            console.log(err.message);
                        })
                })
        }


    }

    removeFlag() {
        firebase.firestore().collection('schools')
            .where('school', '==', this.props.profile.school)
            .get()
            .then(querySnapshot => {
                const oldFlags = querySnapshot.docs[0].data()[this.state.subject + 'Flags']
                const newFlags = oldFlags.filter(flag => flag.quizData.questionID !== this.state.mcqData[this.state.nr - 1].questionID)
                const schoolId = querySnapshot.docs[0].id
                firebase.firestore().collection('schools')
                    .doc(schoolId)
                    .update({
                        [this.state.subject + "Flags"]: newFlags
                    })

                firebase.firestore().collection('users')
                    .doc(`${this.props.auth.uid}`)
                    .update({
                        [this.state.subject + "Flags"]: newFlags
                    })
            })

        this.setState({
            flagged: false
        })

        if (this.props.match.path === '/flagged') {
            const flagResolved = document.querySelector('.flagResolved')
            const flagOriginal = document.querySelector('.flagContainer')
            flagResolved.classList.remove('active')
            flagOriginal.classList.remove('hidden')
        }
    }

    setBookmark() {
        let quizCategory;
        let quizzes
        if (this.state.subject === 'Economics') {
            quizzes = `quizzes`
        } else {
            quizzes = `quizzes${this.state.subject}`
        }
        firebase.firestore().collection(quizzes)
            .where('quizName', '==', this.state.mcqData[this.state.nr - 1].quizName)
            .where('school', 'in', ['GradeIt', this.props.profile.school])
            .get()
            .then(querySnapshot => {
                const data = querySnapshot.docs.map(doc => doc.data());
                quizCategory = data[0].quizCategory;
            }).then(() => {
                firebase.firestore().collection('users')
                    .doc(`${this.props.auth.uid}`)
                    .update({
                        [this.state.subject + "Bookmarks"]: firebase.firestore.FieldValue.arrayUnion({
                            quizCategory: quizCategory,
                            quizName: this.state.mcqData[this.state.nr - 1].quizName,
                            data: this.state.mcqData[this.state.nr - 1],
                            createdAt: new Date()
                        })
                    })
            })

        this.setState({
            bookmarked: true
        });
        // const preMark = document.querySelector('.bookmarkContainer');
        // const postMark = document.querySelector('.bookmarkResolved');

        // preMark.classList.add('hidden');
        // postMark.classList.add('active');
    }

    removeBookmark() {
        const newMarks = this.props.profile[this.state.subject + 'Bookmarks'].filter(mark => mark.data.questionID !== this.state.mcqData[this.state.nr - 1].questionID)
        firebase.firestore().collection('users')
            .doc(`${this.props.auth.uid}`)
            .update({
                [this.state.subject + "Bookmarks"]: newMarks
            })
            .catch(err => console.log(err))

        // const preMark = document.querySelector('.bookmarkContainer');
        // const postMark = document.querySelector('.bookmarkResolved');

        // preMark.classList.remove('hidden');
        // postMark.classList.remove('active');

        this.setState({
            bookmarked: false
        });
    }

    // logPerformance = (correct, answer) =>{
    //     const {nr, total, eventStarted} = this.state
    //     const {school} = this.props.profile
    //     let subjectString = ""

    //     if (this.state.subject!=="Economics") {
    //         subjectString = this.state.subject
    //     }

    //     //JUAN HOW TO USE TERNARY FOR CONFIG
    //     /*const subjectString = this.props.profile.subject!=="Economics"
    //         ? this.props.profile.subject
    //         : '';*/

    //     /*const { student, school } = this.props.profile;
    //     const { path } = this.props.match;*/

    //     if (this.props.profile.student=="student" 
    //     && (this.props.profile.school!=="INSEEC M1" 
    //     && this.props.match.path !== '/bookmarks' 
    //     && this.props.match.path !== '/bookmarks/:id' 
    //     && this.props.match.path !== '/flagged')) {
    //         firebase.firestore().collection('dataLogging')
    //         .add({
    //             school: school[1]+ school[school.length-2] + school[2],
    //             questionNumber: nr,
    //             totalQuestions: total,
    //             studentID: this.props.auth.uid,
    //             eventID: eventStarted + this.props.auth.uid, 
    //             type: "quiz",
    //             topic: this.props.match.params.id,
    //             timePresented: this.state.response,
    //             timeAnswered: Date.now(),
    //             timeTaken: (Date.now()-this.state.response), 
    //             streak: this.state.streak,
    //             questionID: this.state.mcqData[nr-1].questionID,
    //             correct: correct,
    //             question: this.state.question2,
    //             answerClicked: answer,
    //             answer: this.state.answers2[answer-1],
    //             quizFinished: "no",
    //             xpRequired: +this.state.liveRequiredXp- parseInt(this.state.nextCoins) - parseInt(this.props.profile[`${subjectString}xp`]),
    //             xp: parseInt(this.state.nextCoins) + parseInt(this.props.profile[`${subjectString}xp`]),
    //             versionID: this.props.profile.versionID | 0,
    //             subject: this.state.subject
    //         })
    //     }
    // }

    toggleFlagStudentList = () => {
        const { flagStudentList } = this.state
        this.setState({ flagStudentList: flagStudentList ? false : true })
    }

    toggleReport = () => {
        this.setState({
            report: this.state.report ? false : true
        })
    }

    assigned = (boolean) => {
        this.setState({
            assigned: boolean,
            showAssign: true
        })
    }

    toggleAnalytics = () => {
        this.setState({
            analytics: this.state.analytics ? false : true
        })
    }

    render() {
        let subjectString = ""
        if (this.state.subject !== "Economics") {
            subjectString = this.state.subject
        }

        // console.log('...')
        let { nr, total, question2, answers2, correct2, showButton, questionAnswered,
            displayPopup, score, exposition, numberClicked,
            wrongRight, clearStyling, graphQuestion, exposition2,
            url, flashcard, needHelp, negativeFeedback, nextCoins, percentXp, liveLevel,
            liveLastRequiredXp, streak, liveRequiredXp, bookmarked, flagged,
            flagStudentList, report, noFlags, showAssign, diagnostic, analytics } = this.state;

        let startingXp = this.props.profile[`${subjectString}xp`];
        let currentXp = parseInt(nextCoins) + parseInt(startingXp);
        const { auth } = this.props;
        //  return null;
        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />
        return (
            <div>
                
                {/* {showButton && nr === total && !showAssign
                    ? <Assigned assigned={this.assigned} />
                    : null
                } */}
                {noFlags
                    ? <NoFlag mcqGraph={true} />
                    : null
                }
                {report ?
                    <Report
                        toggleReport={this.toggleReport}
                        question={question2}
                        quiz={this.props.match.params.id}
                    />
                    :
                    null
                }

                {analytics 
                    ? 
                        <Analytics 
                            toggleAnalytics={this.toggleAnalytics}
                            mcqData={this.state.mcqData}
                            finished={nr==total}
                        />
                    :   
                    null
                }


                <Popup path={this.props.match ? this.props.match.path : ""} style={{ display: displayPopup }} score={score} total={total} startQuiz={this.handleStartQuiz} quizName={this.props.match ? this.props.match.params.id : ""} userLevel={this.state.liveLevel} />
                <div className="quizHeaderMobile">
                    <MCQGraphBar
                        currentXp={currentXp}
                        percentXp={percentXp}
                        liveLevel={liveLevel}
                        liveRequiredXp={liveRequiredXp}
                        liveLastRequiredXp={liveLastRequiredXp}
                        streak={streak}
                        nr={nr}
                        total={total}
                        loadLeaveWarning={this.loadLeaveWarning}
                        flashcard={flashcard}
                    />
                </div>

                <div className="container">

                    {negativeFeedback
                        ? <NegativeFeedback negativeFeedback={this.negativeFeedback} nr={nr} quiz={this.props.location.pathname} />
                        : null}

                    {needHelp === "on" && this.props.match && this.props.match.path === '/quizzes/:id'
                        ? <NeedHelp flashcard={flashcard} toggleNeedHelp={this.toggleNeedHelp} />
                        : null
                    }

                    {flagStudentList
                        ? <FlagStudentList
                            toggleFlagStudentList={this.toggleFlagStudentList}
                            student={this.state.filteredFlags} />
                        : null
                    }

                    <div className="row">
                        <div className="col-lg-10 col-lg-offset-1">
                            {diagnostic
                            ?
                            <div className="flexRow">
                                <div className="flexRow mobileDiagnostics">
                                    <Link to='/diagnostic' className="diagnosticsInfoButton mobileDiagnostics" onClick={()=>window.location.reload()}>
                                        <i className="material-icons mobileDiagnostics">arrow_back</i>
                                        <p className="diagnosticsInfoButtonText mobileDiagnostics">BACK</p>
                                    </Link>
                                    <div className="diagnosticsInfoButton" onClick={this.toggleAnalytics}>
                                        <i className="material-icons white">analytics</i>
                                        <p className="diagnosticsInfoButtonText">View diagnostics</p>
                                    </div>
                                </div>
                                <div className="diagnosticsInfo">
                                    <p className="diagnosticsInfoText">{this.props.questionArray[nr-1]["topic"] || 0} | {this.props.questionArray[nr-1]["quizName"]}</p>
                                </div>
                            </div>
                            : 
                            null
                            }
                            <div className="topContainer">
                                {graphQuestion === "yes" ?
                                    <div className="fullWidth">
                                        <div className="flexRow">
                                            <div className="question">
                                                <div className="gamificationTopContainer">
                                                    <div className="flexMaxWidth">
                                                        <div className="xpStreakMcq">
                                                            <div className="xpBarMcq">
                                                                <XpBar
                                                                    currentXp={currentXp}
                                                                    percentXp={percentXp}
                                                                    liveLevel={liveLevel}
                                                                    liveRequiredXp={liveRequiredXp}
                                                                    liveLastRequiredXp={liveLastRequiredXp}
                                                                    streak={streak}
                                                                    nr={nr}
                                                                    total={total}
                                                                    toggleNeedHelp={this.toggleNeedHelp}
                                                                />
                                                                {/* <div className="gamificationContainer">
                                                                <i className="material-icons fire">whatshot</i>
                                                                {this.getStreak()}
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="questionFlashcard">

                                                        <div className="gamificationContainer transparent">
                                                            {flashcard ?
                                                                <Link to={`/flashcardformbeta/${flashcard}`} className="gamificationContainer">
                                                                    <i className="material-icons">style</i>
                                                                    <p className="coin">Flashcards</p>
                                                                </Link>
                                                                : null
                                                            }
                                                            {!diagnostic 
                                                            ?
                                                            bookmarked ?
                                                                <React.Fragment>
                                                                    <div className="bookmarkContainer hidden" onClick={this.setBookmark}>
                                                                        <i className="material-icons">bookmark</i>
                                                                        <p className="coin">Mark Question</p>
                                                                    </div>
                                                                    <div className="bookmarkResolved active" onClick={this.removeBookmark}>
                                                                        <i className="material-icons">backspace</i>
                                                                        <p className="coin">Remove Bookmark</p>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <div className="bookmarkContainer" onClick={this.setBookmark}>
                                                                        <i className="material-icons">bookmark</i>
                                                                        <p className="coin">Mark Question</p>
                                                                    </div>
                                                                    <div className="bookmarkResolved" onClick={this.removeBookmark}>
                                                                        <i className="material-icons">backspace</i>
                                                                        <p className="coin">Remove Bookmark</p>
                                                                    </div>
                                                                </React.Fragment>
                                                            : null
                                                            }
                                                            {!diagnostic
                                                            ?
                                                                this.props.profile.school == 'individual'
                                                                    ?
                                                                    null
                                                                    :
                                                                    <React.Fragment>
                                                                        {this.props.match && this.props.match.path === '/flagged'
                                                                            ?
                                                                            // teachers can always remove flags 
                                                                            <React.Fragment>
                                                                                <div className="flagContainer hidden" onClick={this.setFlag}>
                                                                                    <i className="material-icons">flag</i>
                                                                                    <p className="coin">Flag Question</p>
                                                                                </div>
                                                                                <div className="flagResolved active teacher" onClick={this.removeFlag}>
                                                                                    <i className="material-icons">cancel</i>
                                                                                    <p className="coin">Remove Flag</p>
                                                                                </div>
                                                                                <div className="flaggedBy" onClick={this.toggleFlagStudentList}>
                                                                                    <i className="material-icons">tour</i>
                                                                                    {/* <p className="coin">Flagged by: {this.state.flaggers.length}</p> */}
                                                                                    <p className="coin">{this.state.filteredFlags.length} student{this.state.filteredFlags.length <= 1 ? null : "s"} </p>
                                                                                </div>
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment>
                                                                                {flagged
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <div className="flagContainer hidden" onClick={this.setFlag}>
                                                                                            <i className="material-icons">flag</i>
                                                                                            <p className="coin">Flag Question</p>
                                                                                        </div>
                                                                                        <div className="flagResolved active">
                                                                                            <i className="material-icons">done</i>
                                                                                            <p className="coin">Flagged</p>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <React.Fragment>
                                                                                        <div className="flagContainer" onClick={this.setFlag}>
                                                                                            <i className="material-icons">flag</i>
                                                                                            <p className="coin">Flag Question</p>
                                                                                        </div>
                                                                                        <div className="flagResolved">
                                                                                            <i className="material-icons">done</i>
                                                                                            <p className="coin">Flagged</p>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                            : null
                                                            }
                                                            {/* <div className="streakMobile">
                                                                <i className="material-icons fire mobile">whatshot</i>
                                                                <p className="mcqStreak">{streak} in a row</p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mcqQuestionContainer">
                                                    <h4 className="gamification">Question {nr}/{total}</h4>
                                                    {this.state.subject === "gcseMaths"
                                                        ?
                                                        <Maths
                                                            question2={question2}
                                                            style={"mathsTextQuestionContainer"}
                                                            textStyle={"mcqQuestion"}
                                                        />
                                                        :
                                                        <p className="mcqQuestion" dangerouslySetInnerHTML={question2}></p>
                                                    }
                                                </div>
                                            </div>

                                            <div id="image">
                                                <img src={url} className="graph" alt="graph" />
                                            </div>
                                            <div className="mcqQuestionContainerMobile">
                                                {this.state.subject === "gcseMaths"
                                                    ?
                                                    <Maths
                                                        question2={question2}
                                                        style={"mathsTextQuestionContainer"}
                                                        textStyle={"mcqQuestionMobile"}
                                                        mobile
                                                    />
                                                    :
                                                    <p className="mcqQuestionMobile" dangerouslySetInnerHTML={question2}></p>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className="fullWidth">
                                        <div className="flexRow">
                                            <div className="questionContainer">
                                                <div className="gamificationTopContainer">
                                                    <div className="flexMaxWidth">
                                                        {/* <div className="gamificationContainer">
                                                        <button className="btn-floating coin pulse"><i className="material-icons coin">attach_money</i></button>
                                                        {this.getCoins()}
                                                    </div> */}
                                                        <div className="xpStreakMcq">
                                                            <div className="xpBarMcq">
                                                                <XpBar
                                                                    currentXp={currentXp}
                                                                    percentXp={percentXp}
                                                                    liveLevel={liveLevel}
                                                                    liveRequiredXp={liveRequiredXp}
                                                                    liveLastRequiredXp={liveLastRequiredXp}
                                                                    streak={streak}
                                                                />
                                                                {/* <div className="gamificationContainer">
                                                                <i className="material-icons fire">whatshot</i>
                                                                {this.getStreak()}
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!diagnostic 
                                                    ?
                                                        <div className="questionFlashcard">
                                                            <div className="gamificationContainer transparent">
                                                                {flashcard ?
                                                                    <Link to={`/flashcardformbeta/${flashcard}`} className="gamificationContainer">
                                                                        <i className="material-icons">style</i>
                                                                        <p className="coin">Flashcards</p>
                                                                    </Link>
                                                                    : null}
                                                                {bookmarked ?
                                                                    <React.Fragment>
                                                                        <div className="bookmarkContainer hidden" onClick={this.setBookmark}>
                                                                            <i className="material-icons">bookmark</i>
                                                                            <p className="coin">Mark Question</p>
                                                                        </div>
                                                                        <div className="bookmarkResolved active" onClick={this.removeBookmark}>
                                                                            <i className="material-icons">backspace</i>
                                                                            <p className="coin">Remove Bookmark</p>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div className="bookmarkContainer" onClick={this.setBookmark}>
                                                                            <i className="material-icons">bookmark</i>
                                                                            <p className="coin">Mark Question</p>
                                                                        </div>
                                                                        <div className="bookmarkResolved" onClick={this.removeBookmark}>
                                                                            <i className="material-icons">backspace</i>
                                                                            <p className="coin">Remove Bookmark</p>
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                                {this.props.profile.school == 'individual'
                                                                    ?
                                                                    null
                                                                    :
                                                                    <React.Fragment>
                                                                        {this.props.match && this.props.match.path === '/flagged'
                                                                            ?
                                                                            // teachers can always remove flags 
                                                                            <React.Fragment>
                                                                                <div className="flagContainer hidden" onClick={this.setFlag}>
                                                                                    <i className="material-icons">flag</i>
                                                                                    <p className="coin">Flag Question</p>
                                                                                </div>
                                                                                <div className="flagResolved active teacher" onClick={this.removeFlag}>
                                                                                    <i className="material-icons">cancel</i>
                                                                                    <p className="coin">Remove Flag</p>
                                                                                </div>
                                                                                <div className="flaggedBy" onClick={this.toggleFlagStudentList}>
                                                                                    <i className="material-icons">tour</i>
                                                                                    {/* <p className="coin">Flagged by: {this.state.flaggers}</p> */}
                                                                                    <p className="coin">{this.state.filteredFlags.length} student{this.state.filteredFlags.length <= 1 ? null : "s"}</p>
                                                                                </div>
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment>
                                                                                {flagged
                                                                                    ?
                                                                                    <React.Fragment>
                                                                                        <div className="flagContainer hidden" onClick={this.setFlag}>
                                                                                            <i className="material-icons">flag</i>
                                                                                            <p className="coin">Flag Question</p>
                                                                                        </div>
                                                                                        <div className="flagResolved active">
                                                                                            <i className="material-icons">done</i>
                                                                                            <p className="coin">Flagged</p>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    <React.Fragment>
                                                                                        <div className="flagContainer" onClick={this.setFlag}>
                                                                                            <i className="material-icons">flag</i>
                                                                                            <p className="coin">Flag Question</p>
                                                                                        </div>
                                                                                        <div className="flagResolved">
                                                                                            <i className="material-icons">done</i>
                                                                                            <p className="coin">Flagged</p>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                    :
                                                        null
                                                    }
                                                </div>
                                                <div className="mcqQuestionContainer">
                                                    <h4 className="gamification">Question {nr}/{total}</h4>
                                                    {this.state.subject === "gcseMaths"
                                                        ?
                                                        <Maths
                                                            question2={question2}
                                                            textStyle={"mcqQuestion"}
                                                            style={"mathsTextQuestionContainer"}
                                                        />
                                                        :
                                                        <p className="mcqQuestion" dangerouslySetInnerHTML={question2}></p>
                                                    }
                                                </div>
                                                <div className="mcqQuestionContainerMobile">
                                                    {this.state.subject === "gcseMaths"
                                                        ?
                                                        <Maths
                                                            question2={question2}
                                                            textStyle={"mcqQuestionMobile"}
                                                            style={"mathsTextQuestionContainer"}
                                                            mobile
                                                        />
                                                        :
                                                        <p className="mcqQuestionMobile" dangerouslySetInnerHTML={question2}></p>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>

                            <Answers
                                answers={answers2}
                                correct={correct2}
                                showButton={this.handleShowButton}
                                isAnswered={questionAnswered}
                                increaseScore={this.handleIncreaseScore}
                                exposition={exposition}
                                numberClicked={this.handleNumberClicked}
                                styling={this.handleStyling}
                                clearStyling={clearStyling}
                                resetStreak={this.resetStreak}
                                losingStreakIncrement={this.losingStreakIncrement}
                                maths={this.state.subject === "gcseMaths"}
                            // logPerformance={this.logPerformance}
                            />

                            {/* Exposition for non-Maths subjects  */}
                                {showButton && this.state.subject !== "gcseMaths" ?
                                    <div className="exposition-container">
                                        {showButton && wrongRight === "CORRECT" ? <div><p className="exposition"><span className="correct">{wrongRight}</span></p> <p className="exposition" dangerouslySetInnerHTML={exposition2[numberClicked]} /></div> : null}
                                        {showButton && wrongRight === "INCORRECT" ? <div><p className="exposition"><span className="incorrect">{wrongRight}</span></p> <p className="exposition" dangerouslySetInnerHTML={exposition2[numberClicked]} /></div> : null}
                                        {/* <Feedback negativeFeedback={this.negativeFeedback}/> */}
                                    </div> : null
                                }

                            {/* Exposition for Maths subjects  */}
                                {showButton && this.state.subject == "gcseMaths" ?
                                    <div className="exposition-container">
                                        {showButton && wrongRight === "CORRECT" ? 
                                            <div>
                                                <p className="exposition"><span className="correct">{wrongRight}</span></p>
                                                <Maths
                                                    question2={exposition2[numberClicked]}
                                                    textStyle={"exposition"}
                                                    style={"mathsTextQuestionContainer"}
                                                />
                                            </div> 
                                        : null}
                                        {showButton && wrongRight === "INCORRECT" ?
                                            <div>
                                                <p className="exposition"><span className="incorrect">{wrongRight}</span></p> 
                                                <Maths
                                                    question2={exposition2[numberClicked]}
                                                    textStyle={"exposition"}
                                                    style={"mathsTextQuestionContainer"}
                                                />
                                            </div> 
                                        : null}
                                        {/* <Feedback negativeFeedback={this.negativeFeedback}/> */}
                                    </div> : null
                                }

                            {!diagnostic 
                            ?
                                <div className="tagQuestionMobileContainer">
                                    {bookmarked ?
                                        <div className="tagButtonContainer" onClick={this.removeBookmark}>
                                            <i className="material-icons tagButton">backspace</i>
                                            <p className="tagButtonText">Remove bookmark</p>
                                        </div>
                                        :
                                        <div className="tagButtonContainer" onClick={this.setBookmark}>
                                            <i className="material-icons tagButton">bookmark</i>
                                            <p className="tagButtonText">Bookmark</p>
                                        </div>
                                    }
                                    {flagged ?
                                        <div className="tagButtonContainer">
                                            <i className="material-icons tagButton">done</i>
                                            <p className="tagButtonText">Remove flag</p>
                                        </div>
                                        :
                                        <div className="tagButtonContainer" onClick={this.setFlag}>
                                            <i className="material-icons tagButton">flag</i>
                                            <p className="tagButtonText">Flag</p>
                                        </div>
                                    }
                                    <div className="tagButtonContainer" onClick={this.toggleReport}>
                                        <i className="material-icons tagButton">bug_report</i>
                                        <p className="tagButtonText">Report</p>
                                    </div>
                                </div>
                            : null
                            }

                            <div id="submit">
                                {showButton && nr !== total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Next question</button> : null}
                                {showButton && nr === total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Finish quiz</button> : null}
                                {/* {showButton ? <button className="fancy-btn" onClick={this.nextQuestion}  >{nr===total ? 'Finish quiz' : 'Next question'}</button> : null} */}
                            </div>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (
            score, total, quizName, school,
            firstName, lastName, pathname, userId,
            eventId, classID, subject, assigned
        ) => dispatch(createScore(
            score, total, quizName, school, firstName,
            lastName, pathname, userId, eventId, classID, subject, assigned)
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McqGraph)