import React from 'react'
import firebase from '../auth/auth'


class AdminDash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalNumberOfUsers: 0,
            totalNumberOfSchools: 0
        }
    }

    componentDidMount() {
        this.callData();
    }

    callData = () => {

        firebase.firestore().collection("users")
            .get()
            .then(querySnapshot => {
                const queryData = querySnapshot.docs.map(doc => doc.data());
                this.setState({queryData : queryData}, this.parseData)
                })
    }

    parseData = () => {
        console.log('hi')
        const {queryData} = this.state
        let usersClean = []
        let schoolsClean = []
        let newTable = {}
        let active24Users = 0
        let yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000))

        queryData.map(user=> {
            if (
                (user.school!=="INSEEC M1")
                && (user.school!=="EBS")
                && (user.school!=="Ebs ")
                && (user.school!=="ebs")
                && (user.school!=="Esce")
                && (user.school!=="Test")
                && (user.school!=="ESCE")
                && (user.school!=="Esce ")
                && (user.school!=="Inseec")
                && (user.school!=="ESCE")
                && (user.school!=="ESCE ")
                && (user.school!=="BBA INSEEC")
                && (user.school!=="BBA INSEEC ")
                && (user.school!=="INSEEC")
                && (user.school!=="GradeIt")
                && (user.school!=="INSEEC1")
                && (user.school!=="INSEECA")
                && (user.school!=="INSEECB")
                && (user.school!=="INSEECC")
                && (user.school!=="INSEECYR3")
                && (user.school!=="INSEECYR3M")
                && (user.school!=="INSEEC YR3M")
                && (user.school!=="INSEECYR3F")
                && (user.school!=="individual")
                && (user.school!=="test school")
            )
                {
                    usersClean.push(user)
                    if(schoolsClean.indexOf(user.school)=== -1) {
                        schoolsClean.push(user.school)
                    }

                    if (!newTable[user.school]){
                        newTable[user.school]=[Math.random().toString(36).slice(2)]
                    } else {
                        newTable[user.school].push(Math.random().toString(36).slice(2))
                    }

                    //Count the number of active users in last 24 hours

                    if (user.lastLogged && (new Date (user.lastLogged.seconds*1000) > yesterday)) {
                        active24Users ++
                    }

                }
        })

        let numberOfActiveSchools=0
        let numberOfActiveSchools50=0
        Object.keys(newTable).map(school => {

            if (newTable[school].length>5) {
                numberOfActiveSchools++
            }

            if (newTable[school].length>50) {
                numberOfActiveSchools50++
            }
        })

        this.setState({
            totalNumberOfUsers: usersClean.length,
            totalNumberOfSchools: schoolsClean.length,
            numberOfActiveSchools,
            numberOfActiveSchools50,
            numberOfDormantSchools: schoolsClean.length-numberOfActiveSchools,
            active24Users
        })
    }

    
    render() {

        const {totalNumberOfUsers, totalNumberOfSchools, numberOfActiveSchools, 
            numberOfActiveSchools50, numberOfDormantSchools, active24Users} = this.state
       
        
        return (
            <div className="container">
                <div>
                    <p>Total number of users: </p><p>{totalNumberOfUsers}</p>
                </div>
                <div>
                    <p>Total number of schools: </p><p>{totalNumberOfSchools}</p>
                </div>
                <div>
                    <p>Total number of active schools (more than 5 users): </p><p>{numberOfActiveSchools}</p>
                </div>
                <div>
                    <p>Total number of active schools (more than 50 users): </p><p>{numberOfActiveSchools50}</p>
                </div>
                <div>
                    <p>Total number of dormant schools: </p><p>{numberOfDormantSchools}</p>
                </div>
                <div>
                    <p>Percentage of schools active: </p><p>{Math.round(numberOfActiveSchools*100/totalNumberOfSchools)}%</p>
                </div>
                <div>
                    <p>Numbers active users in last 24 hours: </p><p>{active24Users}</p>
                </div>
            </div>

        );
    }
}

export default AdminDash