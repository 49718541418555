import authReducer from './authReducer'
import progressReducer from './progressReducer'
import submitQuestionReducer from './mcqFormReducer'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import {firebaseReducer} from 'react-redux-firebase'

const rootReducer = combineReducers({
    auth: authReducer,
    progress: progressReducer,
    mcqForm: submitQuestionReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
});

export default rootReducer