import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import Calendar from 'react-calendar'
import CKEditor from 'ckeditor4-react';

class CalendarPopup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activity: '',
            noticeboardMessage: "Type your message here..."
        };
        
    }

    componentDidMount () {

    }

    render() {
                
        return (    <div className="popup-container">
                        <div className="container newTask noClass Calendar">
                            <div className="closeContainer">
                                <i className='material-icons' onClick={this.props.toggleCalendar}>close</i>
                            </div>
                            <div className="newTask100Container">
                                <div className="twoCalendarContainer newAssignment">
                                    <div className={`calendarContainer newAssignment true`}>
                                        <div className="calendarContainerSingle newAssignment">
                                                <Calendar
                                                    onChange={this.props.onChange}
                                                    value={this.props.value}
                                                    minDate={this.props.minDate}
                                                    maxDate={new Date(2147483647000)}
                                                
                                                />
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(CalendarPopup) 