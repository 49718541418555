export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(()=> {
            dispatch({ type: 'LOGIN_SUCCESS' })
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR', err })
        })
    } 
}

export const changeSubject = (UID, subject) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore= getFirestore();

        firestore.collection('users').doc(UID)
        .update({
            subject: subject
        }).then(() => {
            dispatch({type: 'CHANGE_SUBJECT', subject:subject}); 
        }).catch((err) => {
            dispatch({type: 'CHANGE_SUBJECT_ERROR', err})
        })
    }

}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase= getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS'})
        })
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp)=> {
            const versionID = Math.round(Math.random())+1

            //For form-based signup
            if (newUser.classID) {
                
                let newStudentObj = {}
                let subjectArray= ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                    //For science subjects
                    if (subjectArray.includes(newUser.subject)) {
                        subjectArray.map (subj => {
                            newStudentObj[subj+'class']=newUser.classID
                            newStudentObj[subj+'xp']=0
                        })
                        newStudentObj["firstName"]= newUser.firstName
                        newStudentObj["lastName"]= newUser.lastName
                        newStudentObj["initials"]= (newUser.firstName || '')[0] + (newUser.lastName || '')[0]
                        newStudentObj["email"]= newUser.email
                        newStudentObj["school"]= newUser.school
                        newStudentObj["student"]= newUser.student
                        newStudentObj["subscribed"]= false
                        //Available science subjects are added via signedInLinks in the navbar
                        newStudentObj["availableSubjects"]= [newUser.subject]
                        newStudentObj["subject"]= newUser.subject
                        newStudentObj["createdAt"]= new Date()
                        newStudentObj[newUser.subject+"Flags"]= []
                        newStudentObj[newUser.subject+"Bookmarks"]= []
                        newStudentObj["weeklyEmails"]= true
                        newStudentObj["flagEmails"]= true
                        newStudentObj["versionID"]= newUser.student==="teacher" ? 1 : versionID
                    } else {
                    //For non-science subjects
                        newStudentObj["firstName"]= newUser.firstName
                        newStudentObj["lastName"]= newUser.lastName
                        newStudentObj["initials"]= (newUser.firstName || '')[0] + (newUser.lastName || '')[0]
                        newStudentObj["email"]= newUser.email
                        newStudentObj["school"]= newUser.school
                        newStudentObj["student"]= newUser.student
                        newStudentObj["subscribed"]= false
                        newStudentObj["availableSubjects"]= [newUser.subject]
                        newStudentObj[newUser.subject==="Economics"?"class":newUser.subject+'class']=newUser.classID
                        newStudentObj[newUser.subject==="Economics"?"xp":newUser.subject+'xp']=0
                        newStudentObj["subject"]= newUser.subject
                        newStudentObj["createdAt"]= new Date()
                        newStudentObj[newUser.subject+"Flags"]= []
                        newStudentObj[newUser.subject+"Bookmarks"]= []
                        newStudentObj["weeklyEmails"]= true
                        newStudentObj["flagEmails"]= true
                        newStudentObj["versionID"]= newUser.student==="teacher" ? 1 : versionID
                    }
                return firestore.collection('users').doc(resp.user.uid).set(newStudentObj)
        } else {
            //For non form-based signup
            return firestore.collection('users').doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                initials: (newUser.firstName || '')[0] + (newUser.lastName || '')[0],
                email: newUser.email,
                school: newUser.school,
                student: newUser.student,
                subscribed: false,
                availableSubjects: [newUser.subject], 
                [`${newUser.subject ==="Economics"?"":newUser.subject}xp`]: 0,
                subject: newUser.subject,
                createdAt: new Date(),
                [`${newUser.subject ==="Economics"?"":newUser.subject}Flags`]: [],
                [`${newUser.subject ==="Economics"?"":newUser.subject}Bookmarks`]: [],
                weeklyEmails: true,
                flagEmails: true,
                [`${newUser.subject ==="Economics"?"":newUser.subject}class`]: "Unassigned",
                versionID: newUser.student==="teacher" ? 1 : versionID
            })
        }
        }).then(() => {
            const userID = getState().firebase.auth.uid
            if (newUser.student==="student") {
                //For form-based signup
                if (newUser.classID) {
                    return firestore.collection(`studentProgress${newUser.subject ==="Economics"?"":newUser.subject}`).doc(getState().firebase.auth.uid).set({
                        firstName: newUser.firstName,
                        lastName: newUser.lastName,
                        school: newUser.school,
                        user: userID,
                        studentProgress: {},
                        createdAt: new Date(),
                        [`${newUser.subject ==="Economics"?"":newUser.subject}class`]: newUser.classID
                    })
                } else {
                    //For non form-based signup
                    return firestore.collection(`studentProgress${newUser.subject ==="Economics"?"":newUser.subject}`).doc(getState().firebase.auth.uid).set({
                        firstName: newUser.firstName,
                        lastName: newUser.lastName,
                        school: newUser.school,
                        user: userID,
                        studentProgress: {},
                        createdAt: new Date(),
                        [`${newUser.subject ==="Economics"?"":newUser.subject}class`]: "Unassigned"
                    })
                }
            }
            })
            .then(()=> {
                const userID = getState().firebase.auth.uid
                //If science user, create studentProgress for other sciences and also add classes + XP to profile
                if (
                    newUser.subject.includes("gcseBiology")
                    || newUser.subject.includes("gcseChemistry")
                    || newUser.subject=="Physics"
                    ) 
                    {
                        const subjectArray = ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                        subjectArray.map(subj=> {
                            firebase.firestore().collection('studentProgress'+ subj)
                            .doc(userID)
                            .get()
                            .then(querySnapshot => {
                            if (querySnapshot.exists) {
                                console.log(subj+ "studentProgress record exists" + querySnapshot.data())
                            } else {
                                //If student progress doesn't exist
                                console.log(subj + "studentProgress record doesn't exist")
                                firebase.firestore().collection('studentProgress'+ subj)
                                .doc(userID)
                                .set( 
                                    {   createdAt: new Date(),
                                        firstName: newUser.firstName,
                                        lastName: newUser.lastName,
                                        school: newUser.school,
                                        [subj+"class"]: newUser.classID || "Unassigned",
                                        studentProgress: {},
                                        user: userID
                                    }
                                )
        
                                firebase.firestore().collection('users')
                                    .doc(userID)
                                    .update( 
                                        {   
                                            [subj+"xp"]: 0,
                                            [subj+"class"]: newUser.classID || "Unassigned",
                                        }
                                    )
    
                            }
                        })
                        })

                    }

            })
            .then(()=> {
                const userID = getState().firebase.auth.uid
                firebase.firestore().collection('schools').where('school', '==', newUser.school)
                .get()
                .then(querySnapshot => {
                    const school = querySnapshot.docs.map(doc => doc.data());
                    const id = querySnapshot.docs[0].id
                    
                    // If the classes object already exists for school, then add the student to the Unassigned object (this is subject specific)
                    // This isn't entirely necessarily- the class object in the schools table is only for the teacher's reference...
                    // All actual class-dependent data retrieval is completed through studentProgress + users tables
                    console.log(`${newUser.subject ==="Economics"?"":newUser.subject}classes`)
                    if (school[0][`${newUser.subject ==="Economics"?"":newUser.subject}classes`]) {
                        console.log('yo')
                        let classesCopy = JSON.parse(JSON.stringify(school[0][`${newUser.subject ==="Economics"?"":newUser.subject}classes`]))
                        classesCopy[newUser.classID||"Unassigned"].push({
                            firstName: newUser.firstName,
                            lastName: newUser.lastName,
                            school: newUser.school,
                            user: userID,
                            studentProgress: {},
                            createdAt: new Date(),
                        }) 
                        firestore.runTransaction(t => {
                            return t.get(firestore.collection('schools')
                                .doc(id))
                                .then(doc => {
                                    return t.update(firestore.collection('schools').doc(id), {[`${newUser.subject ==="Economics"?"":newUser.subject}classes`]: classesCopy})
                                })
                        })   
                    }
                })
            })
        .then(() => {
            const userID = getState().firebase.auth.uid
            if (!newUser.consent) {
                firebase.firestore().collection("globalUnsubscribe").
                doc(userID)
                .set({
                    email: newUser.email,
                    createdAt: new Date()
                })
                console.log("User added to global unsubscribe. Email address: " + newUser.email)
            }

            if (newUser.student ==="student") {
                firebase.firestore().collection('economicsData').doc('newUsers').update({
                    students: firebase.firestore.FieldValue.increment(1),
                    signups: firebase.firestore.FieldValue.increment(1)
                })
            } else {
                firebase.firestore().collection('economicsData').doc('newUsers').update({
                    teachers: firebase.firestore.FieldValue.increment(1),
                    signups: firebase.firestore.FieldValue.increment(1)
                })
            }
        }).then(() =>{
            const user = firebase.auth().currentUser;
            user.sendEmailVerification();
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' })
        }).catch(err => {
            dispatch({ type : 'SIGNUP_ERROR', err})
        })
    }
}

export const signUpManual = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        const versionID = Math.round(Math.random())+1

        firestore.collection('users').doc(newUser.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                initials: (newUser.firstName || '')[0] + (newUser.lastName || '')[0],
                email: newUser.email,
                school: newUser.school,
                student: newUser.student,
                subscribed: false,
                availableSubjects: [newUser.subject], 
                [`${newUser.subject ==="Economics"?"":newUser.subject}xp`]: 0,
                subject: newUser.subject,
                createdAt: new Date(),
                [`${newUser.subject ==="Economics"?"":newUser.subject}Flags`]: [],
                [`${newUser.subject ==="Economics"?"":newUser.subject}Bookmarks`]: [],
                weeklyEmails: true,
                flagEmails: true,
                [`${newUser.subject ==="Economics"?"":newUser.subject}class`]: "Unassigned",
                versionID: newUser.student==="teacher" ? 1 : versionID
            })
            .then(() => {
            
            if (newUser.student==="student")
            return firestore.collection(`studentProgress${newUser.subject ==="Economics"?"":newUser.subject}`).doc(newUser.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                school: newUser.school,
                user: newUser.uid,
                studentProgress: {},
                createdAt: new Date(),
                [`${newUser.subject ==="Economics"?"":newUser.subject}class`]: "Unassigned"
            }).then(()=> {
                firebase.firestore().collection('schools').where('school', '==', newUser.school)
                .get()
                .then(querySnapshot => {
                    const school = querySnapshot.docs.map(doc => doc.data());
                    const id = querySnapshot.docs[0].id
                    
                    // If the classes object already exists for school, then add the student to the Unassigned object (this is subject specific)
                    // This isn't entirely necessarily- the class object in the schools table is only for the teacher's reference...
                    // All actual class-dependent data retrieval is completed through studentProgress + users tables
                    console.log(`${newUser.subject ==="Economics"?"":newUser.subject}classes`)
                    if (school[0][`${newUser.subject ==="Economics"?"":newUser.subject}classes`]) {
                        console.log('yo')
                        let classesCopy = JSON.parse(JSON.stringify(school[0][`${newUser.subject ==="Economics"?"":newUser.subject}classes`]))
                        classesCopy["Unassigned"].push({
                            firstName: newUser.firstName,
                            lastName: newUser.lastName,
                            school: newUser.school,
                            user: newUser.uid,
                            studentProgress: {},
                            createdAt: new Date(),
                        }) 
                        firebase.firestore().collection('schools')
                        .doc(id)
                        .update({[`${newUser.subject ==="Economics"?"":newUser.subject}classes`]: classesCopy})
                    } 
                
                })
            })
        }).then(() => {
            if (newUser.student ==="student") {
                firebase.firestore().collection('economicsData').doc('newUsers').update({
                    students: firebase.firestore.FieldValue.increment(1),
                    signups: firebase.firestore.FieldValue.increment(1)
                })
            } else {
                firebase.firestore().collection('economicsData').doc('newUsers').update({
                    teachers: firebase.firestore.FieldValue.increment(1),
                    signups: firebase.firestore.FieldValue.increment(1)
                })
            }
        }).then(() =>{
            const user = firebase.auth().currentUser;
            user.sendEmailVerification();
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' })
        }).catch(err => {
            dispatch({ type : 'SIGNUP_ERROR', err})
        })
    }
}
