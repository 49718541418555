import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import './assign.scss';
import NewAssignment from './newAssignment'
import { getQuizzesTrial } from '../../helpers/getData';
import Spinner from '../spinner/spinner'
import NoClasses from './noClasses'


class Assign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newAssignment: true

        }

    }

    componentDidMount() {
        this.setState({subject: this.props.profile.subject}, this.getClasses)
        this.getQuizzesTrialProps()
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        getQuizzesTrial(props.profile.school, this.props.profile.subject || this.state.subject)
            .then((quizzes) => {
                
                //Stops quizzes from changing if user toggles subject on another tab
                if (!this.state.quizzes) {
                    this.setState({
                        quizzes,
                    });
                }
            })
    }

    toggleNewAssignment = () => {
        this.setState({
            newAssignment: this.state.newAssignment ? false : true
        })
    }

    getClasses = () => {
        const { subject } = this.state

        if (!this.props.profile.school) return;

        let subjectString=''
        if (subject ==="Economics") {
            subjectString=""
        } else {
            subjectString = subject
        }

        firebase.firestore().collection('schools').where('school', '==', this.props.profile.school)
            .get()
            .then(querySnapshot => {
                const school = querySnapshot.docs.map(doc => doc.data());
                let schoolID
                if (querySnapshot.docs[0].id) {
                    schoolID = querySnapshot.docs[0].id
                }
                
                let classes = {}
                //IF NO CLASSES EXIST
                if (!school[0][`${subjectString}classes`]) {
                    //FOR GCSE sciences only: 
                    //1) if no classes, but existing science classes exist, form class list from studentProgress
                    //2) add assignments object to each class for new subject
                    if (subject.includes("gcseBiology")
                        || subject.includes("gcseChemistry")
                        || subject=="Physics")
                    {
                        let subjectArray= ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                        let noClasses = true
                        subjectArray.map(subj => {
                            if (school[0][`${subj}classes`]) {
                                noClasses=false
                                firebase.firestore().collection('studentProgress'+subj)
                                .where('school', '==', this.props.profile.school)
                                .orderBy('lastName')
                                .get()
                                .then(querySnapshot => {
                                    const students = querySnapshot.docs.map(doc => doc.data());
                                    console.log(students)


                                    let newClassObj = {};
                                    students.map(student => {
                                    //Creates class key in newClassObj then adds student to the key
                                    if (!newClassObj[(student[subj+'class'])]) {
                                        newClassObj[(student[subj+'class'])]=[]
                                    }
                                    newClassObj[(student[subj+'class'])].push(student)
                                    })

                                    //Adds unassigned class if it doesn't already exit
                                    if(!newClassObj["Unassigned"]) {
                                        newClassObj["Unassigned"]=[]
                                    }

                                    //Adds empty classes to newClassObj
                                    Object.keys(school[0][`${subj}classes`]).map(nameClass =>{
                                        console.log(nameClass)
                                        if (school[0][`${subj}classes`][nameClass].length==0) {
                                            console.log(nameClass + "is emmpty")
                                            newClassObj[nameClass]=[]
                                        }
                                    })

                                    //Creates assignments object to each class for new subject
                                    //Adds empty assignments object and classes to school profile
                                    let assignmentsCopy = {}
                                    Object.keys(newClassObj).map(classID => {
                                        assignmentsCopy[classID] = {}
                                    }) 
                                    firebase.firestore()
                                    .runTransaction(t => {
                                        return t.get(firebase.firestore().collection('schools')
                                            .doc(schoolID))
                                            .then(doc => {
                                                return t.update(firebase.firestore().collection('schools').doc(schoolID), {
                                                    [subjectString+'classes']: newClassObj,
                                                    [`${subjectString}Assignments`] : assignmentsCopy
                                                })
                                            })
                                    })
                                    
                                    this.setState({
                                        classes: newClassObj,
                                        activeClass: "All students",
                                        noClasses: false,
                                        assignments: school[0][`${subjectString}Assignments`] || {}
                                        }, this.parseAssignments);
                                })
                            }
                        })
                        //If science teacher & no classes exist for any science, prompts teacher to construct classes
                        if (noClasses) {
                            classes = {"All students" : []} 
                            this.setState({
                            classes,
                            activeClass: "All students",
                            noClasses: true,
                            assignments: school[0][`${subjectString}Assignments`] || {}
                            }, this.parseAssignments);

                        }
                    //FOR NON-GCSE SCIENCE SUBJECTS: FORCES TEACHER TO CREATE CLASSES
                    } else {
                        classes = {"All students" : []} 
                        this.setState({
                        classes,
                        activeClass: "All students",
                        noClasses: true,
                        assignments: school[0][`${subjectString}Assignments`] || {}
                        }, this.parseAssignments);
                    }
                //IF CLASSES EXIST
                } else {
                    this.setState({
                        classes: school[0][`${subjectString}classes`],
                        activeClass: "All students",
                        noClasses: false,
                        assignments: school[0][`${subjectString}Assignments`] || {}
                    }, this.parseAssignments)
                }

                //Only proceed if the school has classes
                if (school[0][`${subjectString}classes`]) {
                    //If no assignments object on Firebase
                    if (!school[0][`${subjectString}Assignments`]) {
                        let assignmentsCopy = {}
                        Object.keys(school[0][`${subjectString}classes`]).map(classID => {
                            assignmentsCopy[classID] = {}
                        })
                        firebase.firestore().collection('schools')
                        .doc(schoolID)
                        .update({
                            [`${subjectString}Assignments`] : assignmentsCopy
                        })
                    //If assigments object exists, then check to see that all classes have an object
                    //created (empty or otherwise)
                    } else {
                        Object.keys(school[0][`${subjectString}classes`]).map(classID => {
                            if (!school[0][`${subjectString}Assignments`][classID]) {
                                let assignmentsCopy = school[0][`${subjectString}Assignments`]
                                assignmentsCopy[classID] = {}
                                console.log(assignmentsCopy)
                                firebase.firestore().collection('schools')
                                .doc(schoolID)
                                .update({
                                    [`${subjectString}Assignments`] : assignmentsCopy
                                })
                            }
                        })
                    }
                }
                
            }).then(()=>this.setState({loading: false}))
    }

    parseAssignments = (classID = "") => {
        const {assignments} = this.state

        //Cycles through assignments, sorting them into the correct date
        let dateObject = {}
        assignments.length>0 && assignments.map((assignment) => {
            if (assignment.activeClass===classID || assignment.activeClass==="All students") {
                if (!dateObject[assignment.startDate.seconds]) {
                    dateObject[assignment.startDate.seconds]=[assignment]
                } else {
                    let dateObjectCopy = dateObject[assignment.startDate.seconds]
                    dateObjectCopy.push(assignment)
                    dateObject[assignment.startDate.seconds]=dateObjectCopy
                }
            }
        })

        console.log(dateObject)

        this.setState({dateObject})

    }

    setLoading = () => {
        this.setState({loading: true})
    }


    render() {

        const {newAssignment, assignmentsArray, dateObject, loading, noClasses} = this.state

        dateObject && Object.keys(dateObject).map(timestamp => {
            console.log(new Date(dateObject[timestamp][0].startDate.seconds*1000).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))
        })

        return (
            <div className="mobileContainer assign">
                {loading
                ?   <Spinner />
                :   null
                }

                {noClasses
                ? <NoClasses />
                : null
                }

                {newAssignment 
                ?
                <NewAssignment
                    toggleNewAssignment={this.toggleNewAssignment}
                    classes={this.state.classes}
                    assignments={this.state.assignments}
                    quizzes={this.state.quizzes}
                    getClasses={this.getClasses}
                    setLoading={this.setLoading}
                    subject={this.state.subject}
                />
                :null
                }
                {/* Computer container below */}
                <div className="assignmentContainer">
                    { dateObject && Object.keys(dateObject).map((timestamp) =>{
                        return(
                            <div>
                                <div className="assignmentDateContainer">
                                <p>{new Date(dateObject[timestamp][0].startDate.seconds*1000).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                </div>
                                <div>
                                    {dateObject[timestamp].map(assignment => {
                                        return (
                                            <p>{assignment.activity}- {assignment.quiz}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        );
                    })
                    }
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps)(Assign)
