import React from 'react';
import CKEditor from 'ckeditor4-react';

import './mathjaxEditor.scss'
import MathJax from 'react-mathjax';

class ElementEditor extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            
        }
    }

    onEditorChange = (evt) => {
        
        const clean = evt.editor.getData()

        let spaceParse= clean.replace(/&nbsp;/g, " ")
        let openTagParse= spaceParse.replace(/<p>/g, "")
        let nTagParse= openTagParse.replace(String.raw`\n`, "")
        let closeTagParse= nTagParse.replace(/<\/?p[^>]*>/g, "")

        this.setState({
            editorInput: clean,
            editorOutput: closeTagParse 
        });

    }

    onEditorChangeMaths = (evt) => {
        
        const clean = evt.editor.getData()

        let spaceParse= clean.replace(/&nbsp;/g, " ")
        let MathJaxParse= spaceParse.replace("MATHJAX", "")
        let openTagParse= MathJaxParse.replace(/<p>/g, "")
        let forwardSlashParse= openTagParse.replace("\n", "")
        let closeTagParse= forwardSlashParse.replace(/<\/?p[^>]*>/g, "")


        this.setState({
            editorInput: clean,
            editorOutput: "<MathJax.Node inline formula=" + '"' + closeTagParse + '"' + " />",
            editorOutputMaths: closeTagParse
        });

    }

    saveElement = () => {

        const {parsedInput, numElement, elementType} = this.props

        //Do nothing if editor changes have not been made to specific element
        if(this.state.editorOutput) {
            let newElement = Array.from(parsedInput)
            newElement[numElement]=this.state.editorOutput

            //FOR SAVING INTO THE MAIN QUESTION OBJECT            
            let newString = ""
            newElement.map((element, index) => {

                if (!element.includes("MATHJAX")) {
                    if (element[element.length-1]==" ") {
                    newString = newString + element + " "
                    } else {
                    newString = newString + element
                    }
                } else {
                    let MathJaxParse= element.replace("MATHJAX", "")

                    // newString = newString + MathJaxParse
                    newString = newString + "<MathJax.Node inline formula={" + '"' + MathJaxParse + '"' + "} />"
                }
                console.log(newString)
            })
            newString.replace(/\n/g, "")
            

            // FOR REFRESHING THE PREVIEW
            if (this.state.editorOutput.includes("MathJax")) {
                newElement[numElement]="MATHJAX" + this.state.editorOutputMaths
            }
            
            this.props.onExternalEditorChangeQuestion(newString, newElement, this.props.editableElement)
        }     
    }

    deleteElement = () => {
        const {parsedInput, numElement, elementType} = this.props
        let newElement = Array.from(parsedInput)

        newElement.splice(numElement,1)

        let newString = ""
        newElement.map(element => {
            newString = newString + element
        })
        newString.replace(/\n/g, "")

        this.props.onExternalEditorChangeQuestion(newString, newElement, this.props.editableElement )    
    }

    addElement = (modal) => {
        const {parsedInput, insertionIndex} = this.props
        let newElement = Array.from(parsedInput)

        if (modal==="rtf") {
            newElement.splice(insertionIndex, 0, "Text goes here")
        } else if (modal==="equation") {
            newElement.splice(insertionIndex, 0, "<MathJax.Node inline formula={" + '"Equation goes here"'+ "} />")
        } else if (modal==="line break") {
            newElement.splice(insertionIndex, 0, "<br />")
        }

        let newString = ""
        newElement.map(element => {
            if (element.includes("MATHJAX")) {
                let MathJaxParse= element.replace("MATHJAX", "")
                newString = newString + "<MathJax.Node inline formula={" + '"' + MathJaxParse + '"' + "} />"
            } else {
                newString = newString + element
            }
        })
        newString.replace(/\n/g, "")

        if (modal==="equation") {
            newElement.splice(insertionIndex, 1, "MATHJAXNewEquation")
        }


        this.props.onExternalEditorChangeQuestion(newString, newElement, this.props.editableElement)
    }


    render() {

        const {parsedInput, numElement, elementType, addElement} = this.props

        if (addElement) {
            return (
                <div className="chooseElementTopContainer">
                    Choose element
                    <div className="chooseElementButtonContainer">
                        <div className="btn" onClick={()=>this.addElement("rtf")}>
                                TEXT
                        </div>
                        <div className="btn" onClick={()=>this.addElement("equation")}>
                                EQUATION
                        </div>
                        <div className="btn" onClick={()=>this.addElement("line break")}>
                                LINE BREAK
                        </div>
                    </div>
                </div>
            )
        } else {
            if (elementType==="rtf") {
                //For text (edited in CKEditor)
                return (
                    <div>
                        <p>Editor</p>
                        {numElement>-1
                        ?
                        <div className="elementEditorEditableContainer">
                            <CKEditor
                                data={parsedInput[numElement]}
                                type="inline"
                                onChange={this.onEditorChange}
                                // config={{
                                //     toolbar:['Bold'],
                                // }}
                            />
                        </div> 
                        :
                        null
                        }
                        <div className="btn" onClick={this.saveElement}>
                            SAVE
                        </div>
                        <div className="btn red" onClick={this.deleteElement}>
                            DELETE
                        </div>
                    </div>
                )
            } else if (elementType==="equation") {
                //For equations
                return(
                    <div>                    
                        <p>Editor</p>
                        {parsedInput[numElement] && numElement>-1
                        ?
                        <div className="elementEditorEditableContainer"> 
                            <CKEditor
                                data={parsedInput[numElement].replace("MATHJAX", "")}
                                type="inline"
                                onChange={this.onEditorChangeMaths}
                                // config={{
                                //     toolbar:['Bold'],
                                // }}
                            />
                        </div>
                        :
                        null
                        }
                        <div className="btn" onClick={this.saveElement}>
                            SAVE
                        </div>
                        <div className="btn red" onClick={this.deleteElement}>
                            DELETE
                        </div>
                    </div>
    
                )
            } else {
                //For line breaks!
                return (
                    <div>
                        <p>Editor</p>
                        {numElement>-1
                        ?
                            <div className="btn red" onClick={this.deleteElement}>
                                REMOVE LINE BREAK
                            </div>
                        :
                        null
                        }
                    </div>
                )
            }
        }
    }
}

export default ElementEditor