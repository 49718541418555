import React, { useState } from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Calendar from 'react-calendar'
import NewTask from './newTask.js'
import ColumnAssign from './columnAssign'
import { DragDropContext, useTouchSensor } from 'react-beautiful-dnd'
import NewMessage from './newMessage'
import DateContainer from './dateContainer'
import CalendarPopup from './calendarPopup';
import 'react-calendar/dist/Calendar.css'

class NewAssignment extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activity: '',
            finishDateActive: false,
            timeframe: "All assignments",
            classSelected: false,
            showCalendar: false,
        };
        
    }
    
    componentDidMount = () => {
        this.getQuizzes()
    }

    //Forces computer to re-render when new assignments are added
    componentWillReceiveProps (props) {
        this.props.assignments && this.onChangeStart (this.state.startDate)
    }
    
    
    handleEditText = (e) => {

        let { value } = e.target

        this.setState({
            title: value
        })
        
    }

    toggleClass = (event) => {

        this.setState ({
            activeClass: event.target.value,
            classSelected: true,
        }, ()=> {
            //Gets assignments for the assignment viewer. Note, this process is
            //different for other child components and uses different state variables
            if (this.props.assignments[this.state.activeClass]) (
                this.setState ({
                    assignmentsList: this.props.assignments[this.state.activeClass],
                    timeframe : "Active assignments"
                }, ()=> this.parseViewAssignmentsList("Active assignments"))
            )
            //Re-renders computer if class is changed
            if (this.state.startDate) {
                this.onChangeStart(this.state.startDate)
            }
        })
    }

    parseViewAssignmentsList = (e) => {
        
        const {timeframe, classSelected, assignmentsList} = this.state
        var dizzle = new Date().setHours(0,0,0,0)
        
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        let viewAssignmentsArray = []

        if (timeframe==="Past assignments") {
            Object.keys(this.props.assignments[this.state.activeClass]).map(date => {
                const dateObj= new Date(Date.parse(date))
                if (dateObj<dizzle) {
                    viewAssignmentsArray.push({[date]: this.props.assignments[this.state.activeClass][date], date:[date]})
                }
            })
            viewAssignmentsArray.sort(function(a,b) {
                return new Date(b.date) - new Date(a.date)
            })
        } else if ((timeframe==="Active assignments") || (e=="Active assignments")) {
            Object.keys(this.props.assignments[this.state.activeClass]).map(date => {
                const dateObj= new Date(Date.parse(date))
                if (dateObj>=dizzle) {
                    viewAssignmentsArray.push({[date]: this.props.assignments[this.state.activeClass][date], date:[date]})
                }
            })
            viewAssignmentsArray.sort(function(a,b) {
                return new Date(a.date) - new Date(b.date)
            })
        } else if (timeframe==="All assignments") {
            Object.keys(this.props.assignments[this.state.activeClass]).map(date => {
                const dateObj= new Date(Date.parse(date))
                viewAssignmentsArray.push({[date]: this.props.assignments[this.state.activeClass][date], date:[date]})
            })
            viewAssignmentsArray.sort(function(a,b) {
                return new Date(a.date) - new Date(b.date)
            })
        }

       this.setState({
           viewAssignmentsArray
       }, () => {if (!this.state.startDate) {
           this.onChangeStart(new Date(dizzle))
        }}
       )
    }

    getDropdown() {

        const { noClasses, classes } = this.props
        const { activeClass } = this.state

        if (noClasses) {
            return (
                <div>
                    <p>You must set up at least one class before assigning work</p>
                </div>
            )
        }

        if (classes && !noClasses) {            
            return (
                <div className="newAssignmentSelectContainer">
                    <p className='chooseClassText newAssignment'>Class:</p>
                    <select className="newAssignment" onChange={this.toggleClass} value={activeClass}>
                            <option>Select from list</option>
                        {Object.entries(classes).map((e) => {
                            return (
                            <option key={e[0]}>{e[0]}</option>
                            )
                            })
                        }
                    </select>
                </div>
            )
        }
    }

    getDateContainer = () => {
        const {startDate} = this.state
        return (
            <div className="newAssignmentSelectContainer">
                    <p className='chooseClassText newAssignment'>Date:</p>

                    <div className="chooseDateContainer newAssignment">
                        {startDate 
                        ? <p className='chooseDateContainer newAssignment'>{startDate.toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                        : <p className='chooseDateContainer newAssignment'>Click to select</p>
                        }
                        <i className='material-icons chooseDate'>calendar_today</i>
                    </div>
                </div>

        )


    }

    handleChange = (e) => {
        let { name, value } = e.target

        if (name==="activity" && value=="quiz") {
            this.setState({
                flashcard: "",
                category: "",
            })
        } else if (name==="activity" && value=="flashcards") {
            this.setState({
                quiz: "",
                category: "",
            })
        }

        if (name==="category") {
            this.setState({
                quiz: "",
                flashcard: "",
            })
        }

        //Flashcards must have quizName + flashcard properties added to the state in order
        //to render clean message for user
        if (name==="flashcard") {
            this.setState({
                [name]: this.props.quizzes[this.state.category][value]['flashcard'],
                //Quiz used to house the clean name of the flashcards- makes it easier to
                // render message
                quiz: this.props.quizzes[this.state.category][value]['quizName'],
            }, this.checkSubmittable)
        } else if (name==="quiz") {
            this.setState({
                [name]: this.props.quizzes[this.state.category][value]['quiz'],
                //Quiz used to house the clean name of the flashcards- makes it easier to
                // render message
                quiz: this.props.quizzes[this.state.category][value]['quizName'],
            }, this.checkSubmittable)
        } else {
                this.setState({
                    [name]: value,
                }, this.checkSubmittable)

        }
    }

    checkSubmittable = () => {
        
        let submittable = false
        if (this.state.startDate
            &&
            (this.state.activity==="flashcards" && this.state.flashcard) || (this.state.activity==="quiz" && this.state.quiz)) {
            submittable = true
        } 

        this.setState({
            submittable: submittable,
            userID: this.props.auth.uid,
            subject: this.props.subject,
            lastName: this.props.profile.lastName,
        })
    }

    onChangeStart = (value, event) => {
        // let startD = value.toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        this.setState ({
            startDate: value
        }, this.checkSubmittable)

        if (this.props.assignments[this.state.activeClass]
            && this.props.assignments[this.state.activeClass][value] 
            && (Object.keys(this.props.assignments[this.state.activeClass][value]).length>0)
            ) {
            let assignmentArray = []
            Object.keys(this.props.assignments[this.state.activeClass][value]).map(assignment => {
                if (assignment !=="message") {
                    assignmentArray.push(this.props.assignments[this.state.activeClass][value][assignment])
                } 
             })
            this.setState({
                activeAssignmentObject: this.props.assignments[this.state.activeClass][value],
                noticeboardMessage: this.props.assignments[this.state.activeClass][value]['message'],
                assignmentArray
            })
        } else {
            this.setState({
                activeAssignmentObject: false,
                noticeboardMessage: false,
            })
        }
    }

    onChangeFinish = (value, event) => {
        // let endD = value.toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        this.setState ({
            endDate: value
        }, this.checkSubmittable)
    }

    // changeViewStart = () => {
    //     if (this.state.startDateActive) {
    //         this.setState ({
    //             startDate: ""
    //         })
    //     }

    //     this.setState({
    //         startDateActive: this.state.startDateActive ? false : true 
    //     })
    // }

    changeViewFinish = () => {
        if (this.state.finishDateActive) {
            this.setState ({
                endDate: ""
            })
        }
        
        this.setState({
            finishDateActive: this.state.finishDateActive ? false : true
        })
    }

    getQuizzes = () => {
        const {quizzes} = this.props

        let categories =[]
        quizzes && Object.keys(quizzes).map(category =>{
            categories.push(category)
        })
        this.setState ({
            categories
        })
    }

    parseDroppable = () => {
        
        let assignmentArray = []
        Object.keys(this.state.activeAssignmentObject).map(assignment => {
            assignmentArray.push(this.state.activeAssignmentObject[assignment])
        })

        this.setState({
            assignmentArray
        })

    }



    assignmentAdded = (classID, dateObject) => {
        this.props.getClasses()
    }

    toggleNewTask = () => {
        this.setState({
            newTask: this.state.newTask ? false : true
        })
    }

    removeAssignment = (index, event) => {

        event.stopPropagation()
        this.props.setLoading()

        let subjectString =""
        if (this.props.subject!=="Economics") {
            subjectString = this.props.subject
        }

        firebase.firestore().collection('schools')
            .where('school', '==', this.props.profile.school)
            .get()
            .then((querySnapshot => {

                const { assignments } = this.props
                const {startDate, activeClass} = this.state
        
        
                //Assignments already assigned to active class on active date
                    let assignmentsCopy = this.props.assignments
                    if (Object.keys(assignments[activeClass][startDate]).length===1) {
                        console.log('Deleting final assignment on this date')
                        delete assignmentsCopy[activeClass][startDate]
                        console.log(assignmentsCopy)
        
                    } else {
                        console.log('Deleting assignment')
                        delete assignmentsCopy[activeClass][startDate][index+1]
                        console.log('unordered', assignmentsCopy)
                        let orderedAssignments={}
                        Object.keys(assignmentsCopy[activeClass][startDate]).map((num,index) => {
                            orderedAssignments[index+1] = assignmentsCopy[activeClass][startDate][num]
                        })
                        assignmentsCopy[activeClass][startDate]=orderedAssignments
                        console.log('ordered', assignmentsCopy)
                    }

                firebase.firestore().collection('schools')
                .doc(querySnapshot.docs[0].id)
                .update({
                    [subjectString+"Assignments"]: assignmentsCopy
                }).then(()=>{
                    this.setState({message: `Assigned "${this.state.quiz}" ${this.state.activity} to ${this.props.activeClass}`})
                }).then(()=>{
                    setTimeout (() => {
                        this.setState ({
                            assignmentsList: this.props.assignments[this.state.activeClass],
                        })
                        this.props.getClasses()
                        this.parseViewAssignmentsList()
                    }, 1000)
                })
                
            }))
    }

    onDragEnd = result => {
        const { destination, source} = result;
        let subjectString =""
        if (this.props.subject!=="Economics") {
            subjectString = this.props.subject
        }

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        this.props.setLoading()


        firebase.firestore().collection('schools')
            .where('school', '==', this.props.profile.school)
            .get()
            .then((querySnapshot => {

                //Re-arranges array which renders flashcards
                let assignmentArrayCopy= Array.from(this.state.assignmentArray)
                assignmentArrayCopy.splice(source.index, 1);
                assignmentArrayCopy.splice(destination.index, 0, this.state.assignmentArray[source.index])
                console.log(assignmentArrayCopy)

                const { assignments } = this.props
                const {startDate, activeClass} = this.state
                //Recreates object in the database
                    let assignmentsCopy = this.props.assignments
                    assignmentArrayCopy.map((assignment, index) => {
                        assignmentsCopy[activeClass][startDate][index+1]=assignment
                    })
                    console.log(assignmentsCopy)        

                firebase.firestore().collection('schools')
                .doc(querySnapshot.docs[0].id)
                .update({
                    [subjectString+"Assignments"]: assignmentsCopy
                }).then(()=>{
                    this.setState({message: `Assigned "${this.state.quiz}" ${this.state.activity} to ${this.props.activeClass}`})
                }).then(()=>{
                    this.props.getClasses()
                })
                
            }))

    }

    createMarkup(text) {
        return {__html: text};
    }

    toggleMessage = () => {
        this.setState({
            showMessage : this.state.showMessage ? false :true
        })
    }

    removeMessage = () => {
        this.props.setLoading()
        let subjectString =""
        if (this.props.subject!=="Economics") {
            subjectString = this.props.subject
        }

        firebase.firestore().collection('schools')
            .where('school', '==', this.props.profile.school)
            .get()
            .then((querySnapshot => {

                const {assignments} = this.props
                const {startDate, activeClass} = this.state
                
                let assignmentsCopy = assignments
                delete assignmentsCopy[activeClass][startDate]['message']

                firebase.firestore().collection('schools')
                .doc(querySnapshot.docs[0].id)
                .update({
                    [subjectString+"Assignments"]: assignmentsCopy
                }).then(()=>{
                    setTimeout (() => {
                        this.props.getClasses()
                        this.parseViewAssignmentsList()
                    }, 1000)
                })
            }))
    }

    toggleDateContainer = () => {
        this.setState({
            showDateContainer: this.state.showDateContainer ? false : true
        })
    }

    toggleTimeframe = (event) => {
        this.setState({
            timeframe: event.target.value,
        }, this.parseViewAssignmentsList)
    }

    getAssignmentsDropdown = () => {
        const {timeframe, classSelected, assignmentsList} = this.state

        return (
            <div className="viewAssignmentsTopContainer">
                <div className={`viewAssignmentsContainer${classSelected?"":" greyed"}`}>
                    <div className="newAssignmentSelectContainer timeframe">
                        <p className='chooseClassText newAssignment'>View:</p>
                        <select className="newAssignment" onChange={this.toggleTimeframe} value={timeframe}>
                                <option>Active assignments</option>
                                <option>Past assignments</option>
                                <option>All assignments</option>
                        </select>
                    </div>
                    <div className="scrollableViewAssignmentsContainer">
                        {assignmentsList ?
                            this.getViewAssignmentsContainer()               
                        : 
                        null
                        }
                    </div>
                </div>
                <div>
                    <div className={`calendarButton${classSelected?"":" greyed"}`} onClick={this.toggleCalendar}>
                        <i className="material-icons calendarButton">calendar_today</i>
                        <p className="calendarButton">View calendar</p>
                    </div>
                </div>
            </div>        
        )
    }

    toggleCalendar = () => {
        this.setState({
            showCalendar: this.state.showCalendar ? false : true
        })
    }

    getViewAssignmentsContainer = () => {

        const {viewAssignmentsArray} = this.state
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }


        return( 
            viewAssignmentsArray && viewAssignmentsArray.length > 0 ? 
                viewAssignmentsArray.map(objy => {

                    //Handles empty assignment objects
                        if (Object.keys(objy[Object.keys(objy)[0]]).length>0) {  
                            return (
                                <div className="assignmentDateContainer" onClick={()=>this.onChangeStart(new Date(Object.keys(objy)[0]))}>
                                    <p className="assignmentDateContainerText">{new Date(Object.keys(objy)[0]).toLocaleDateString(undefined, options)}</p>
                                </div>
                            )
                        }
                })
                : null
        )
    }



    render() {

        const {assignments, quizzes} = this.props
        const { categories, activity, startDate, activeActivity, value,
             startDateActive, finishDateActive, activeClass, activeAssignmentObject,
            assignmentArray, classSelected, newTask, showMessage, noticeboardMessage,
            showDateContainer, showCalendar } = this.state

        const laptop= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Flaptop.png?alt=media&token=3b65f2c5-9902-42e1-8065-18f40a3de4f5"

        //Today's date must be expressed at 00:00
        var d = new Date ()
        var dizzle = new Date(d.setHours(0,0,0,0))


        
        return (
                        <div className="newAssignment100Container">
                            {showDateContainer 
                                ? <DateContainer
                                    toggleDateContainer={this.toggleDateContainer}
                                    value={value}
                                    onChange={this.onChangeStart}
                                 />
                                : null
                            }
                            {showCalendar
                                ? <CalendarPopup
                                    onChange={this.onChangeStart}
                                    value={value}
                                    minDate={dizzle}
                                    toggleCalendar={this.toggleCalendar}
                                />
                                : null
                            }
                            {newTask 
                            ? <NewTask
                                setLoading={this.props.setLoading}
                                assignments={activeAssignmentObject}
                                assignmentsObject={assignments}
                                startDate={startDate}
                                activeClass={activeClass}
                                quizzes={quizzes}
                                toggleNewTask={this.toggleNewTask}
                                getClasses={this.props.getClasses}
                                subject={this.props.subject}
                                parseViewAssignmentsList={this.parseViewAssignmentsList}
                            />
                            : null
                            }
                            <div className="bigCalendarContainer newAssignment">
                                <div className="chooseClassContainer newAssignment top">
                                    {this.getDropdown()}
                                </div>
                                <div className="chooseClassContainer newAssignment top">
                                    {this.getAssignmentsDropdown()}
                                </div>
                                <div className={`chooseClassContainer newAssignment top dateSelection ${classSelected}`} onClick={this.toggleDateContainer}>
                                    {this.getDateContainer()}
                                </div>
                            </div>
                            {activeAssignmentObject ?
                                <div className="computerContainer">
                                    <img className="computer" src={laptop} />
                                    <div className="droppableArea newAssignment">
                                    <p className="computerHeader">{activeClass}'s tasks due on {startDate.toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}</p>
                                    <div className='noticeboardBoundingBox'>
                                        {showMessage 
                                            ?
                                            <NewMessage
                                                startDate = {startDate}
                                                activeClass = {activeClass}
                                                toggleMessage = {this.toggleMessage}
                                                setLoading = {this.props.setLoading}
                                                subject={this.props.subject}
                                                assignments={activeAssignmentObject}
                                                assignmentsObject={assignments}
                                                getClasses={this.props.getClasses}
                                                noticeboardMessage = {noticeboardMessage}
                                                parseViewAssignmentsList={this.parseViewAssignmentsList}
                                            />
                                            : null
                                        }
                                        {noticeboardMessage 
                                            ?   
                                            <div>
                                                <p className="computerTeacherMessage">Teacher's message:</p>
                                                <p className="computerTextMessage" dangerouslySetInnerHTML={this.createMarkup(noticeboardMessage)}></p>
                                            </div>
                                            :   null                                        
                                        }
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <ColumnAssign key="0" assignments={assignmentArray} column="0" removeAssignment={this.removeAssignment} />
                                        </DragDropContext>
                                    </div>
                                        <div className='assignComputerButtonContainer'>
                                            <div className="assignButton newAssignment" onClick={this.toggleNewTask}>
                                                <div className="assignButtonTextContainer">
                                                        <p className='assignButtonText'>Add task</p>
                                                        <p className="assignButtonDateText">Due {startDate.toLocaleString('en-us', { weekday: 'long', month: 'long', day: 'numeric' })}</p>
                                                    </div>
                                                    <i className="material-icons assignButton mobile">add</i>
                                            </div>
                                            {noticeboardMessage 
                                            ?
                                            <div className='twoButtonAssignContainer'>
                                            <div className="assignButton addMessage" onClick={this.toggleMessage}>
                                                <p className='assignButtonText'>Edit message</p>
                                                <i className="material-icons assignButton">message</i>
                                            </div>
                                            <div className="assignButton removeMessage" onClick={this.removeMessage}>
                                                <p className='assignButtonText'>Remove message</p>
                                                <i className="material-icons assignButton">remove</i>
                                            </div>
                                        </div>
                                            :
                                            <div className="assignButton addMessage" onClick={this.toggleMessage}>
                                                <p className='assignButtonText'>Add message</p>
                                                <i className="material-icons assignButton">message</i>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            :   
                                startDate
                                ?
                                <div className="computerContainer">
                                    <img className="computer" src={laptop} />
                                    <div className="droppableArea newAssignment">
                                        <p className="computerHeader">No tasks due for {activeClass} on {startDate.toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).toUpperCase()}</p>
                                        <div className="noticeboardBoundingBox">
                                            {showMessage 
                                            ?
                                            <NewMessage
                                                startDate = {startDate}
                                                activeClass = {activeClass}
                                                toggleMessage = {this.toggleMessage}
                                                setLoading = {this.props.setLoading}
                                                assignments={activeAssignmentObject}
                                                assignmentsObject={assignments}
                                                getClasses={this.props.getClasses}
                                                noticeboardMessage = {noticeboardMessage}
                                                subject={this.props.subject}
                                                parseViewAssignmentsList={this.parseViewAssignmentsList}
                                            />
                                            : null
                                            }
                                            {noticeboardMessage 
                                            ?   <div>
                                                    <p className="computerTeacherMessage">Teacher's message:</p>
                                                    <p className="computerTextMessage" dangerouslySetInnerHTML={this.createMarkup(noticeboardMessage)}></p>
                                                </div>
                                            :   null                                        
                                            }
                                        </div>
                                        <div className='assignComputerButtonContainer'>
                                            <div className="assignButton newAssignment" onClick={this.toggleNewTask}>
                                                <div className="assignButtonTextContainer">
                                                    <p className='assignButtonText'>Add task</p>
                                                    <p className="assignButtonDateText">Due {startDate.toLocaleString('en-us', { weekday: 'long', month: 'long', day: 'numeric' })}</p>
                                                </div>
                                                <i className="material-icons assignButton mobile">add</i>
                                            </div>
                                            {noticeboardMessage 
                                            ?
                                            <div className='twoButtonAssignContainer'>
                                                <div className="assignButton editMessage" onClick={this.toggleMessage}>
                                                    <p className='assignButtonText'>Edit message</p>
                                                    <i className="material-icons assignButton">message</i>
                                                </div>
                                                <div className="assignButton removeMessage" onClick={this.removeMessage}>
                                                    <p className='assignButtonText'>Remove message</p>
                                                    <i className="material-icons assignButton">remove</i>
                                                </div>
                                            </div>
                                            :
                                            <div className="assignButton addMessage" onClick={this.toggleMessage}>
                                                <p className='assignButtonText'>Add message</p>
                                                <i className="material-icons assignButton">message</i>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                            }                         

                        </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(NewAssignment) 