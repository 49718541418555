import React from 'react';
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link } from 'react-router-dom'
import './newQuizDialog.scss'




class NewQuizDialog extends React.Component {
    constructor(props) {
        super(props);
        
        this.state= {
            didLoad: false
        }
    }

    onLoad = () => {
        this.setState({
            didLoad: true
        })
    }
    
    render() {

        const style = this.state.didLoad ? {} : {visibility: 'hidden'}
       
        
        return (

            <div className="popup-container" style={style}>      
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNewQuiz">
                            <div className="btn-floating closePopup" onClick={this.props.toggleNewQuizDialog}><i className="material-icons">close</i></div>
                            <h5 className="needHelp">Top tip!</h5>
                            <img src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fnewquiz.gif?alt=media&token=4b24bdaf-6958-49da-ab52-7183b5a3cf79"
                                onLoad={this.onLoad} 
                                className="newQuiz" 
                                alt="newQuiz" />
                            <p className='popupNewQuiz'>New quizzes will appear under "My quizzes"</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default NewQuizDialog 
