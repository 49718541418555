import React from 'react';
import {connect} from 'react-redux'
import './spinner.scss'

class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshAvailable: false
        }   
    }

    componentDidMount () {
        setTimeout(() => {
            this.setState({
                refreshAvailable: true
            })
        }, 4000)
    }


    windowRefresh = () => {
        window.location.reload()
    }
    
    render() {

        
        return (
            <div className="popup-container spinner">
                <div className="container">
                    <div className="flexColumn">
                            <div className="preloader-wrapper big active">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                            {this.state.refreshAvailable
                            ? 
                            <div className="flexAuto spinnerReload">
                                <p className="spinnerReloadText">Click to reload</p>
                                <i className="material-icons spinnerReload" onClick={this.windowRefresh}>refresh</i>
                            </div>
                            : null 
                            }
                            
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth

    }
}


export default connect(mapStateToProps)(Spinner)