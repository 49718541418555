import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import {connect} from 'react-redux'
import { signOut, changeSubject } from '../../store/actions/authActions'
import firebase from '../auth/auth'
import SubjectMenu from './subjectMenu'

class SignedInLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme1total: 0,
            theme2total: 0,
            theme3total: 0,
            theme4total: 0,
            subjectMenu: false
        }
    }

    componentDidMount = () => {
        firebase.firestore().collection('studentProgressPhysics')
        .get()
        .then(querySnapshot=> {
            console.log(querySnapshot.docs.length)
        })
    }

    componentWillReceiveProps (nextProps) {

        if (this.props.profile.subject !== nextProps.profile.subject)
        this.setState({subject: nextProps.profile.subject})
        
    }

    handleChange = (e) => {
        let { id, name, value } = e.target

        this.setState({
            [name || id]: value
        })

        this.props.changeSubject(this.props.auth.uid, e.target.value)
    }

    getDropdown = () => {
        const {profile} = this.props

        return (
            <li className="subjectDropdownNavbar">
                <select className="subjectDropdownNavbar" onChange={this.handleChange} name="subject" value={this.state.subject}>
                    {profile.availableSubjects && profile.availableSubjects.map(subject =>{
                        return (<option key={subject}>{subject}</option>)
                    })}
                </select>
            </li>
        )
    }

    ManageScienceSubjects = () => {
        const {profile, auth} = this.props

        //If user is a GCSE science student, create student progress records in respective tables, if these don't already exist
        if (profile.student=="student"
            &&
            (profile.subject.includes("gcseBiology")
            || profile.subject.includes("gcseChemistry")
            || profile.subject=="Physics")
            ) 
            {   
                const subjectArray = ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                
                subjectArray.map(subj=> {
                    firebase.firestore().collection('studentProgress'+ subj)
                    .doc(auth.uid)
                    .get()
                    .then(querySnapshot => {
                    if (querySnapshot.exists) {
                        console.log(subj+ "studentProgress record exists" + querySnapshot.data())
                    } else {
                        //If student progress doesn't exist
                        console.log(subj + "studentProgress record doesn't exist")
                        firebase.firestore().collection('studentProgress'+ subj)
                        .doc(auth.uid)
                        .set( 
                            {   createdAt: new Date(),
                                firstName: profile.firstName,
                                lastName: profile.lastName,
                                school: profile.school,
                                //Auto-assign students to the science class on their profile.
                                [subj+"class"]: profile[profile.subject+'class'],
                                studentProgress: {},
                                user: auth.uid
                            }
                        )

                        firebase.firestore().collection('users')
                            .doc(auth.uid)
                            .update( 
                                {   
                                    [subj+"xp"]: 0,
                                    [subj+"class"]: profile[profile.subject+'class'],
                                }
                            )

                        if (profile.availableSubjects.length < 3){
                            firebase.firestore().collection('users')
                            .doc(auth.uid)
                            .update( 
                                {   
                                    availableSubjects: ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"],
                                }
                            )
                        }

                    }
                })
                })
                
            } else if (
            //If user is a GCSE science teacher, add XP to the other subjects, if this doesn't already exist.
            //Function also adds all sciences to available subjects
            (profile.student=="teacher")
            &&
            (profile.subject.includes("gcseBiology")
            || profile.subject.includes("gcseChemistry")
            || profile.subject=="Physics" ))
                {
                    const subjectArray = ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]

                    subjectArray.map(subj=> {

                            if(!profile[subj+"xp"]) {
                                firebase.firestore().collection('users')
                                .doc(auth.uid)
                                .get()
                                .then(querySnapshot => {
                                    const teacher = querySnapshot.data()
                                    if (!teacher[subj+"xp"] && teacher[subj+"xp"]!==0){
                                        console.log('No ' + subj + ' xp')
                                        firebase.firestore().collection('users')
                                        .doc(auth.uid)
                                        .update( 
                                            {   
                                                [subj+"xp"]: 0,
                                                availableSubjects: ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"],
                                            }
                                        )
                                    }
                                })
                            }
                    })
                }

    }

    getSubjectPopup = () => {
        const {profile} = this.props

        if (
            profile.subject.includes("gcseBiology")
            || profile.subject.includes("gcseChemistry")
            || profile.subject=="Physics"
        ) {

            let subjectName = ""
            if (profile.subject==="gcseBiologySingleScienceAQA") {
                subjectName="GCSE BIOLOGY"
            } else if (profile.subject==="gcseChemistrySingleScienceAQA") {
                subjectName="GCSE CHEMISTRY"
            } else if (profile.subject==="Physics"){
                subjectName="GCSE Physics"
            }

            return (
                <li>
                    <div className="navBarSubject" onClick={this.toggleSubjectMenu}>
                        <p className="navBarSubjectName">{subjectName}</p>
                        <i className="material-icons navBar">menu</i>
                    </div>
                </li>
            )
        }
    }

    toggleSubjectMenu = () => {
        this.setState ({
            subjectMenu: this.state.subjectMenu ? false : true
        })
    }

    render () {
        const {profile} = this.props
        const {subjectMenu} = this.state

        return (
            <div>
                {subjectMenu ? 
                    <SubjectMenu
                        toggleSubjectMenu={this.toggleSubjectMenu}
                    />
                    : null
                }
                <ul className="left">
                    {profile.subject && this.getSubjectPopup()}
                </ul>
                <ul className="right">
                    {/* {profile.admin && this.getDropdown()} */}
                    {profile.student && this.ManageScienceSubjects()}
                    <li><NavLink to='/' className="dashboardNav"> Dashboard</NavLink></li>
                    <li><NavLink to='/assign' className="dashboardNav"> Assignments</NavLink></li>
                    <li><NavLink to='/quizzes' className='quizzesNav'>Quizzes</NavLink></li>
                    {profile.student==="teacher" 
                    ? <li><NavLink to='/help' className='quizzesNav'>Guides</NavLink></li>
                    : null
                    }
                    <li><a onClick={this.props.signOut} href="/" >Logout</a></li>
                    <li><p className='btn btn-floating'>{this.props.profile.initials}</p></li>
                </ul>
            </div>
        )
    }
}

const mapStateToProps= (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        changeSubject: (UID, subject) => dispatch(changeSubject(UID, subject))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks)