import React from 'react';
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link } from 'react-router-dom'

import './quizzes.css'


class Bookmarks extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            themeOne: false,
            themeTwo: false,
            themeThree: false,
            themeFour: false
        };
    }
    
    componentDidMount() {
        this.checkThemes();
    }

    checkThemes() {
        if (this.props.profile[`${this.props.profile.subject}Bookmarks`])  {
            const bookmarkCategory = this.props.profile[`${this.props.profile.subject}Bookmarks`].map(bookmark => bookmark.quizCategory)
            const uniqueSet= new Set(bookmarkCategory)
            const backToArray = [...uniqueSet]

            this.setState({bookmarkCategory: backToArray})
        }
    }

    getBookmarks = () => {
        const {bookmarkCategory} = this.state

        return (
            <div className="flexColumnBookmarks"> 
                <Link to={'/bookmarks'}>
                    <button className="fancy-btn allBookmarks">See All</button>
                </Link>
                {bookmarkCategory && bookmarkCategory.map(category => {
                    return (
                        <Link to={`/bookmarks/${category}`}>
                            <button className="fancy-btn bookmarks">
                                <p className="bookmarkText">{category}</p>
                            </button>
                        </Link>
                    )
                })
                }
            </div>
        )

    }

    render() {
        

        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="createQuizPopup">
                            <div className="btn-floating closePopup" onClick={this.props.toggleBookmarks}><i className="material-icons">close</i></div>
                            {this.props.profile[`${this.props.profile.subject}Bookmarks`] &&
                            this.props.profile[`${this.props.profile.subject}Bookmarks`].length!==0
                            ? this.getBookmarks() 
                            : <p className="noBookmarks">You haven't added any bookmarks yet!</p>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, firstName, lastName) => dispatch(createScore(score, total, quizName, firstName, lastName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks)