import React, { Component } from 'react'
import { connect } from 'react-redux'
import {signIn} from '../../store/actions/authActions'
import firebase from '../auth/auth'

export class Verify extends Component {
    constructor(props) {
        super(props);
        this.state={
            emailSent: false
        }
        this.sendVerficationEmail = this.sendVerficationEmail.bind(this);
    }

    sendVerficationEmail() {
        
        this.setState({emailSent: true})
        const user = firebase.auth().currentUser;
        user.sendEmailVerification().then(()=>{
            console.log("Verification email sent")
        }).catch(function(error) {
            console.log(error)
          });
    }

    windowRefresh=()=> {
        window.location.reload()
    }


    render() {
        const { emailSent } = this.state;

        return (
            <div className="container">
                <h5 className="grey-text text-darken-3">Email sent to- {this.props.auth.email}</h5>
                <p>This will take up to 2 minutes- <b>please check your spam filter.</b></p> 
                <p>Please refresh your browser <b>AFTER</b> you've verified your account via the email link.</p>
                <p>If you haven't received an email, please click on the button below and check your inbox.</p>
                {emailSent
                    ? 
                    <div className="flexRow">
                        <button className="btn pink lighten-1 z-depth-0 greyed">Email resent, please check your inbox.</button>
                        <i className="material-icons refreshButton" onClick={this.windowRefresh}>autorenew</i>
                    </div>
                    : <button className="btn pink lighten-1 z-depth-0" onClick={this.sendVerficationEmail}>Resend verification email</button>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Verify)
