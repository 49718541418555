import React, { Component } from 'react'
import './dashboard.scss';
import NewProgress from './newProgress'
import NewAccuracy from './newAccuracy'
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom'
import firebase from '../auth/auth'
import XpBar from '../leveling/xpBar'
import { getQuizzesTrial } from '../../helpers/getData';

import school from '../../resources/school-solid.svg'


class NewDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.getQuizzesTrialProps();
        
        //Logs date of login
        firebase.firestore().collection('users')
        .doc(this.props.auth.uid)
        .update({
            lastLogged: new Date()
        })
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
        //Changed nextProps to props below
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        getQuizzesTrial(props.profile.school, props.profile.subject)
        .then((quizzes) => {
            this.setState({
                quizzes
            }, this.getStudents);
        });
    }

    getStudents() {
        const { props } = this;

        if (!props.profile.school) return;

        if(this.props.profile.student=="teacher" && this.props.profile.school==="individual") {
            this.setState({
                loaded: true,
                individualUser: true
            })
            return
        }

        let subjectString = ""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }
                
        firebase.firestore().collection(`studentProgress${subjectString}`)
            .doc(this.props.auth.uid)
            .get()
            .then(querySnapshot => {
                const { quizzes } = this.state;
                const studentProgress = querySnapshot.data().studentProgress;

                //Calculating attempts in each category
                let quizAttempts = {}            
                Object.keys(quizzes).forEach(category => {
                    quizAttempts[category] = {}
                    quizAttempts[category]['attempts']=0
                    quizAttempts[category]['total']= quizzes[category].length
                })

                Object.keys(studentProgress).forEach(quiz => {
                    Object.keys(quizzes).forEach(category => {
                        quizzes[category].map(quizN => {
                            if (quizN.quizName===quiz) {
                                quizAttempts[category]['attempts'] = quizAttempts[category]['attempts'] +1
                            }
                        })
                    })
                })

                //Calculating accuracy in each category
                let quizAccuracy = {}
                Object.keys(quizzes).forEach(category => {
                    quizAccuracy[category] = {}
                    quizAccuracy[category]['total'] =0
                    quizAccuracy[category]['numberOfAttempts'] =0
                    quizAccuracy[category]['average'] =0
                })


                Object.keys(studentProgress).forEach(quiz => {
                    // console.log(studentProgress, quiz)
                    Object.keys(quizzes).forEach(category => {
                        quizzes[category].map(quizN => {
                            if (quizN.quizName===quiz) {
                                quizAccuracy[category]['total'] = quizAccuracy[category]['total'] + studentProgress[quiz]
                                quizAccuracy[category]['numberOfAttempts'] = quizAccuracy[category]['numberOfAttempts'] + 1
                                quizAccuracy[category]['average'] = Math.round(quizAccuracy[category]['total']/quizAccuracy[category]['numberOfAttempts'])
                            }
                        })
                    })
                })
                

                this.setState({
                    quizAttempts,
                    quizAccuracy
                })

            })
        
            
    }





    render () {

        const { auth, profile } = this.props;


        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />

        if (auth.uid && auth.isLoaded) {

            return (
                <div className="mobileContainer">
                    <div className="container">
                        <div className="dashboardContainer">
                            <div className="dashboardHeaderStudent">
                                <div className="dashboardHeaderLeftContainer"> 
                                    <div className="ghostedImageStudent">
                                        <img src={school} className="school" alt="school" />
                                    </div>
                                    <div className="dashboardHeaderTextContainerStudent">
                                        <p className="dashboardName">Hello, {profile.firstName}</p>
                                        <p className="dashboardSchool">{profile.school}</p>
                                    </div>
                                </div>
                                <div className="dashboardHeaderRightContainer">
                                    <div className="greetingDashboardMobile">
                                        <p className="dashboardName mobile">Hello, {profile.firstName}</p>
                                    </div>
                                    <XpBar /> 
                                </div>
                            </div>
                            
                            <div className="dashboardContentContainer">
                                <div className="dashboardContainerLeft">
                                    <NewProgress
                                        quizAttempts={this.state.quizAttempts}
                                    />
                                </div>
                                <div className="dashboardContainerLeft">
                                    <NewAccuracy
                                        quizAccuracy={this.state.quizAccuracy}
                                     />
                                </div>
                                    
                            </div>
                            
                        </div>
                    </div>
            </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }
}

const mapStateToProps= (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

export default connect(mapStateToProps)(NewDashboard)