import React from 'react';
import firebase from '../auth/auth'
import { getFlashcards } from '../../helpers/getData';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Spinner from '../spinner/spinner'

class ChangeNamePopupFlashcards extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isSubmittable: true,
        };
        
    }
    
    
    submitNameChange = () => {
        const {title} = this.state
        
        this.setState({isSubmittable: true})
        
        if (title && title.indexOf("/") > -1) {
            console.log('string contains a slash')
            this.setState({
                namingError: "Names cannot contain a forward slash, please choose another name",
                isSubmittable: false
            })
        } else {
            this.renameFlashcards()
        }
                
    }

    renameFlashcards = () => {
        let flashcardID=`${this.state.title}${(Math.random().toFixed(8)).substring(2,10)}`
        this.setState({
            url: `/flashcardform/${flashcardID}`,
            processing: true
        })
        console.log('renaming quiz to ' + flashcardID)
        
        firebase.firestore().collection('flashcards'+this.props.profile.subject)
        .doc(flashcardID)
        .set({
            cards: this.props.cards,
            flashcardSeries: this.state.title,
            school: this.props.profile.school,
            student: this.props.auth.uid,
            theme: "",
            createdAt: new Date() 
        }).then(()=>{
            this.setState({flashcardRenamed: true})
        })
    }
    
    handleEditText = (e) => {

        let { value } = e.target

        this.setState({
            title: value
        })
        
    }

    home = () => {
        this.setState ({home: true})
    }
    
    render() {
        const { isSubmittable, home, processing, namingError,
        flashcardRenamed, url } = this.state

        if (home) {
            return <Redirect to='/quizzes'/>
        }

        if (flashcardRenamed) {
            window.location.href = url
        }
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popupRenameQuizNewMCQForm">
                            <div className="returnToQuizzesContainer">
                                <p className="returnToQuizzes">Return to quizzes</p>
                                <div className="btn-floating closePopup" onClick={this.home}>
                                    <i className="material-icons">exit_to_app</i>
                                </div>
                            </div>
                            <div className="marginTenTop">
                                <label className='select-label' htmlFor="School">Please choose a NEW name for these flashcards: </label>
                            </div>
                            <div className="ninetyPadded">
                                <input type="text" name="title" onChange={this.handleEditText} />
                                <div>
                                    <i className="material-icons green-text manageClasses" onClick={this.submitNameChange}>thumb_up_alt</i>
                                </div>
                            </div>
                            <div className="red-text center">
                                {!isSubmittable
                                ? <p>{namingError}</p>
                                : null
                                }
                            </div>
                            <div className="green-text center">
                                    {processing ? <Spinner/> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(ChangeNamePopupFlashcards) 