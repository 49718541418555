import React from 'react'
import { Link } from 'react-router-dom'
import SignedInLinks from './signedinlinks'
import SignedOutLinks from './signedoutlinks'
import { connect } from 'react-redux'
import logo from '../../resources/gradeIt.png'

import './navbar.scss'

const MobileNavBar = (props) => {
    const {auth, profile} = props;
    
    const links=auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />

    return (
        <nav className="nav-wrapperMobile">
            <div className="container">
                <Link to='/' className='left'><img src={logo} className="navbarLogo" alt="logo"/></Link>
                {auth.isLoaded && links}
            </div>
        </nav>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(MobileNavBar)