import React from 'react';
import Answers from './answer';
import Popup from './Popup';
import MobileNavBar from '../layout/mobileNavbar'
// import Footer from 'Footer';
import firebase from '../auth/auth'
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom'
import { createScore } from '../../store/actions/quizscoreActions'
import ReactPaginate from 'react-paginate';


import './mcqGraph.css';


class FatFinger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nr: 0,
            total: 0,
            showButton: false,
            questionAnswered: false,
            score: 0,
            displayPopup: 'none',
            answers2: [],
            question2: [],
            correct2: [],
            exposition: [],
            questionbank: [],
            numberClicked: 0,
            clearStyling: true,
            wrongRight: "INCORRECT"
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        // this.selectQuestion = this.selectQuestion.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleShowButton = this.handleShowButton.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.handleNumberClicked= this.handleNumberClicked.bind(this)

        

    }



    pushData(nr) {
        function shuffle(array) {
            let currentIndex = array.length,  randomIndex;
          
            // While there remain elements to shuffle...
            while (currentIndex != 0) {
          
              // Pick a remaining element...
              randomIndex = Math.floor(Math.random() * currentIndex);
              currentIndex--;
          
              // And swap it with the current element.
              [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }
          
            return array;
        }

        let quizString
        if (this.props.match.params.id[0]==this.props.profile.lastName[0] && this.props.match.params.id[2]==this.props.profile.firstName[0]) {
            quizString = Buffer.from(this.props.match.params.id.substring(3), "base64").toString()
            console.log(quizString)
        }

        firebase.firestore().collection('mcqDatabase')
        .where('quizName', '==', quizString)
        .orderBy('position')
        .get()
        .then(querySnapshot => {

            //The if block below randomly selects 13 questions from the 
            let mcqData= []
            const randomizedQuizzes = ["INSEEC 2.1", "INSEEC 2.11" , "INSEEC 2.2" ,"INSEEC 3.1" , "INSEEC 4.1" , "INSEEC 5.1" , "INSEEC 5.11"]

            if (randomizedQuizzes.includes(quizString) && !this.state.mcqData) {
                mcqData= shuffle(querySnapshot.docs.map(doc => doc.data())).slice(0,13)
            } else {
                mcqData = querySnapshot.docs.map(doc => doc.data()); 
            }
            
            this.setState({
            mcqData,
            question2: mcqData[nr].questionText,
            answers2: [
                mcqData[nr].answer1, 
                mcqData[nr].answer2,
                mcqData[nr].answer3,
                mcqData[nr].answer4],
            correct2: mcqData[nr].correct,
            graphQuestion: mcqData[nr].graphQuestion,
            url: mcqData[nr].url,
            exposition: [
                mcqData[nr].exposition1,
                mcqData[nr].exposition2,
                mcqData[nr].exposition3,
                mcqData[nr].exposition4],
                correct: mcqData[nr].correct,
                total: mcqData.length,
                nr: nr+1,
            quizString
            })

            if(!this.state.dataBank){
                const dataBank = {}
                for (let i=0; i<mcqData.length; i++) {
                    dataBank[`studentAnswer${i+1}`]=null
                }
                for (let i=0; i<mcqData.length; i++) {
                    dataBank[`questionAnswered${i+1}`]=false
                }
                this.setState({dataBank})
            }

            if (!this.state.classNames) {
            let { total } = this.state
            let classNames={}
            for (let i=0; i<total; i++) {
                classNames[i+1]=["","","",""]
            }
            this.setState({classNames})
            }

        })
    }

    pushData2 (nr) {
        const {mcqData} = this.state
        this.setState({
            mcqData,
            question2: mcqData[nr].questionText,
            answers2: [
                mcqData[nr].answer1, 
                mcqData[nr].answer2,
                mcqData[nr].answer3,
                mcqData[nr].answer4],
            correct2: mcqData[nr].correct,
            graphQuestion: mcqData[nr].graphQuestion,
            url: mcqData[nr].url,
            exposition: [
                mcqData[nr].exposition1,
                mcqData[nr].exposition2,
                mcqData[nr].exposition3,
                mcqData[nr].exposition4],
                correct: mcqData[nr].correct,
                total: mcqData.length,
                nr: nr+1
            })

    }

    componentWillMount() {
        this.pushData(0);
    }

    nextQuestion() {
        let { nr, total } = this.state;
        let subjectString =""

        if (this.state.subject==='Economics') {
            subjectString =""
        } else {
            subjectString=this.state.subject
        }

        if(nr === total){
            this.setState({
                displayPopup: 'flex'
            });
            this.props.createScore(this.state.score, this.state.total, this.state.quizString, 
                this.props.profile.school, this.props.profile.firstName, this.props.profile.lastName,
                this.props.location.pathname, this.props.auth.uid, new Date(), 
                this.props.profile[subjectString+'class'], 
                this.props.profile.subject, true)

        } else {
            this.pushData(nr);
            this.setState({
                showButton: false,
                questionAnswered: false,
                wrongRight: "INCORRECT"
            });
        }
        this.setState({clearStyling:true})
    }

    // handleChange = (e) => {
    //     this.selectQuestion(e.target.value)
    //     this.setState({
    //         [e.target.id]: parseInt(e.target.value)
    //     })
        
    // }

    // selectQuestion (nr) {
    //     this.pushData(nr)
    //     this.setState({clearStyling:true})
    // }

    handlePageClick = data => {
        let selected = data.selected;
        this.pushData2(selected)   
    }

    handleShowButton() {
        this.setState({
            showButton: true,
            questionAnswered: true
        })
    }

    handleStartQuiz() {
        this.setState({
            displayPopup: 'none',
            nr: 1
        });
    }

    handleNumberClicked(answer) {
        let {nr, dataBank} = this.state
        dataBank[`studentAnswer${nr}`]= answer
        this.setState({
            numberClicked: answer-1,
            dataBank 
        })
    }

    retrieveScore = (score) => {
        
        this.setState({
            score: score
        })
    }

    render() {
        let { nr, total, question2, answers2, correct2, questionAnswered, 
            displayPopup, score, graphQuestion, dataBank, classNames, mcqData, url} = this.state;
        
        const { auth } = this.props;
        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />
        return (
            <div>
                <MobileNavBar />
                <div className="container">

                    <Popup style={{display: displayPopup}} score={score} total={total} startQuiz={this.handleStartQuiz} quizName={this.state.quizString}/>

                    <div className="row">
                        <div className="col-lg-10 col-lg-offset-1">
                            <div className="topContainerFatFinger">
                            
                            {graphQuestion==="yes" ?
                                <div id="image">
                                    <img src={url} className="graph" alt="graph" />
                                </div> : null}
                                <div id="question">
                                    <h4>Question {nr}/{total}</h4>
                                    <p>{question2}</p>
                                </div>
                            </div>
                            <Answers 
                            answers={answers2} 
                            correct={correct2} 
                            showButton={this.handleShowButton} 
                            isAnswered={questionAnswered}
                            dataBank= {dataBank} 
                            numberClicked={this.handleNumberClicked}
                            nr={nr}
                            total={total}
                            classNames={classNames}
                            mcqData={mcqData}
                            retrieveScore={this.retrieveScore} 
                            />

                            <div id="submit">
                                {/* {showButton && nr!==total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Next question</button> : null} */}
                                {nr===total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Finish quiz</button> : null}
                                {/* <button className="fancy-btn" onClick={this.selectQuestion}  >Question 2</button> */}
                                <ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={total}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                onPageChange={this.handlePageClick}
                            />
                                

                                {/* {showButton ? <button className="fancy-btn" onClick={this.nextQuestion}  >{nr===total ? 'Finish quiz' : 'Next question'}</button> : null} */}
                            </div>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        );
    }
};

const mapStateToProps= (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, school, firstName, 
            lastName, pathname, userId, eventId, classID, subject,
            assigned) => dispatch(createScore(
                score, total, quizName, school, firstName, lastName,
                pathname, userId, eventId, classID, subject, assigned))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FatFinger)
