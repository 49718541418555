import React from 'react';
import './subjectMenu.scss'
import {connect} from 'react-redux'
import { signOut, changeSubject } from '../../store/actions/authActions'
import firebase from '../auth/auth'

class SubjectMenu extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            submitted: false           
        };
        
    }

    changeSubject = (UID,subject) => {
        firebase.firestore().collection('users').doc(UID)
        .update({
            subject: subject
        })
        .then(()=>window.location.reload())
    }

    handleChange = (e) => {
        let text = e.target.value;
        this.setState({
            feedback: text
        })
    }

    handleSubmit = () => {
        const { profile } = this.props

        firebase.firestore().collection('subjectSuggestions')
        .add({
        createdAt: new Date(),
        email: profile.email,
        firstName: profile.firstName,
        suggestion: this.state.feedback
        }).then(()=>this.setState({submitted: true}))
    }
    
    render() {
        const { profile } = this.props
        const { feedback, submitted } = this.state
        
        return (
            <div className="popup-container">
                <div className="container subjectMenu">
                    
                        <div className="popupSubjectMenu">
                        <div className="subjectMenuCloseContainer">
                            <i className="material-icons closeSubjectMenu" onClick={this.props.toggleSubjectMenu}>close</i>
                        </div>
                        <div>
                            <div className="subjectMenuChangeSubjectTextContainer">
                                <p className="subjectMenuChangeSubjectText">CHANGE SUBJECT</p>
                            </div>
                            {profile.availableSubjects && profile.availableSubjects.map(subject =>{

                                let subjectName = ""
                                let icon=""
                                if (subject==="gcseBiologySingleScienceAQA") {
                                    subjectName="GCSE BIOLOGY"
                                    icon="biotech"
                                } else if (subject==="gcseChemistrySingleScienceAQA") {
                                    subjectName="GCSE CHEMISTRY"
                                    icon="science"
                                } else if (subject==="Physics"){
                                    subjectName="GCSE PHYSICS"
                                    icon="bolt"
                                }
                                    return (
                                    <div className="subjectMenuSubjectContainer" onClick={()=>this.changeSubject(this.props.auth.uid, subject)}>
                                        <i className="material-icons subjectMenuSubjectIcon">{icon}</i>
                                        <p className="subjectMenuSubjectName">{subjectName}</p>
                                    </div>
                                    )
                                })
                            }

                            {(profile.student==="student" && profile.availableSubjects.indexOf("gcseBiologySingleScienceAQA" || "gcseChemistrySingleScienceAQA" || "Physics") > -1)
                                ?
                                    !submitted 
                                        ?
                                        <div>   
                                            <div className="feedbackTextAreaContainer subjectMenu">
                                                <p className="subjectMenuChangeSubjectText improve">Help us improve!</p>
                                                <textarea id="feedback" onChange={this.handleChange} className="feedbacktextArea improve" placeholder="Which subjects would you like to see?"/>
                                            </div>
                                            {feedback 
                                                ? 
                                                    <div className="submitButton container">
                                                        <div className="button submitImprovement" onClick={this.handleSubmit}><p className="needHelp submit"> SUBMIT </p></div>
                                                    </div>
                                                : null
                                            }

                                        </div>
                                        : 
                                        <div>
                                            <div className="feedbackTextAreaContainer subjectMenu">
                                                <p className="subjectSubmission bicep">💪</p>
                                                <p className="subjectSubmission text">We're on it</p>
                                            </div>
                                        </div>
                                : 
                                null
                            }
                        </div>

                            
                        </div>
                    </div>
                
            </div>
        );
    }
}

const mapStateToProps= (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        changeSubject: (UID, subject) => dispatch(changeSubject(UID, subject))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectMenu)