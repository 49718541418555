import React from 'react';
import { connect } from 'react-redux'
import { getQuizzesTrial, getFlashcards } from '../../helpers/getData';
import { Redirect, Link } from 'react-router-dom'

import './createQuizPopup.scss'

class CreateFlashcardsPopup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
           
        };
        
    }

    componentDidMount() {
        this.getQuizzesTrialProps()
        this.getFlashcardsProps()
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
        props.profile.school && this.getFlashcardsProps(props)
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        let quizArray= []

        getQuizzesTrial(props.profile.school, props.profile.subject)
            .then((quizzes) => {
                Object.entries(quizzes).map(category => {
                    category[1].map(quiz => {
                        quizArray.push({
                            quiz:quiz.quizName,
                            school: quiz.school,
                            userID: quiz.userID
                        })
                    })
                })
                this.setState({quizArray: quizArray})
            })
    }

    getFlashcardsProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        let flashcardArray= []

        getFlashcards(props.profile.school, props.profile.subject!=="Economics" ? props.profile.subject : "" )
            .then((flashcards) => {
                    Object.entries(flashcards).map(category => {
                        category[1].map(flashcard=>{
                            flashcardArray.push({
                                title: flashcard.flashcardSeries,
                                url: flashcard.id,
                                school: flashcard.school,
                                userID: flashcard.student,
                            
                            })
                        })

                    })
                    this.setState({flashcardArray})    
                }).catch(function(error) {
                    console.log(error)
                  })
                
            
    }



    handleChange = (e) => {
        const {quizArray} = this.state
        let { name, value} = e.target

        let school

        quizArray.forEach((quizName) => {
            if (quizName.quiz===value) {
                school = quizName.school
                
            }
        })


        this.setState({
            [name]: value,
            school: school
        })
    }

    
    
    render() {
        const {flashcardArray, school, quizSelected} = this.state
        const { toggleFlashcardEditor } = this.props

        //If quiz is a GradeIt quiz, urlBase= newmcqform... this forces non GradeIt users to rename quiz
        // let urlBase
        // if (school==='GradeIt') {
        //     urlBase="flashcardform"
        // } else {
        //     urlBase='newmcqformschool'
        // }

        let urlBase="flashcardform"

        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="createQuizPopup">
                            <div className="btn-floating closePopup" onClick={toggleFlashcardEditor}><i className="material-icons">close</i></div>
                            <div className="flexColumnWarning">
                                <Link to={`flashcardform/Blank flashcards48309`} className="createQuizButton">
                                    <p className="removeButton">CREATE NEW FLASHCARDS</p>
                                </Link>
                                <div className="marginTenTop">
                                    <p>or</p>
                                </div>

                                <div className="marginTenTop">
                                    <p>Modify existing flashcards</p>
                                </div>
                                {flashcardArray ? 
                                <select className="quizDropdown" onChange={this.handleChange} name="quizSelected">
                                    <option value="">Click to choose from flashcards</option>
                                    <option value="">---</option>
                                    {flashcardArray.map(flashcard => {
                                        //Content creators can only modify their own quizzes
                                        if (this.props.profile.contentCreator) {
                                            if (flashcard.userID===this.props.auth.uid) { 
                                                return (<option value={flashcard.url} key={flashcard.url}>{flashcard.title}</option>)
                                            }
                                        //Non content-creators can modify any quiz
                                        } else if (!this.props.profile.contentCreator) {
                                            return (<option value={flashcard.url} key={flashcard.url}>{flashcard.title}</option>)
                                        }
                                    })
                                    }
                                </select>
                                : null
                                }
                                {quizSelected
                                ? <Link to={`/${urlBase}/${quizSelected}`} className="createQuizButton">
                                    <p className="removeButton">MODIFY</p>
                                </Link>
                                : null 
                                }
                                

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(CreateFlashcardsPopup);
