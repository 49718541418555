import React, { Component } from 'react'
import '../home/home.scss'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Footer from '../layout/footer'
import firebase from '../auth/auth'

import MobileNavBar from '../layout/mobileNavbar'





class BestTutor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }

        this.bannerLoaded = this.bannerLoaded.bind(this);
    }

    bannerLoaded = () => {
        this.setState({ loading: false })
    }


    render() {
        const { auth } = this.props;
        const { loading } = this.state
        const books1 = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FProfile.png?alt=media&token=76985cd5-f493-4773-a960-5f5540163969"

        if (auth.uid) return <Redirect to='/dashboard' />

        return (
            <div>
                <MobileNavBar />
                <div className={`homeContainer${false}`}>

                    <div className="container section home tutor">

                        <div className="flexRow tutor white">


                            <div className="imgContainer">
                                <img className="profile" src={books1} alt="books" />
                            </div>

                            <div className="flexColumn home tutor">
                                <h2 className="headerBold banner tutor">Tom Davies</h2>
                                <p>Tom Davies is one of the most sought after tutors in London. He was previously a senior producer at BBC News. During this time, he tutored alongside his shifts on the 24-hour news channel. Since 2019, he has tutored full-time and often travels around the world to work with his international HNW clients.</p>

                                <div className="flexRow">
                                    <i className="material-icons tutorEmail">home</i>
                                    <a style={{ "display": "flex", "margin": "auto 0px" }} href="https://www.queensparktutors.com/about-5">Website</a>
                                </div>
                                <div className="flexRow">
                                    <img className="linkedin" src="https://static.wixstatic.com/media/6ea5b4a88f0b4f91945b40499aa0af00.png/v1/fill/w_48,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/6ea5b4a88f0b4f91945b40499aa0af00.png" onClick={window.open("https://www.linkedin.com/in/tom-davies-aa749424/")} alt="books" />
                                    <a style={{ "display": "flex", "margin": "auto 0px" }} href="https://www.linkedin.com/in/tom-davies-aa749424/">LinkedIn</a>
                                </div>
                                <div className="flexRow">
                                    <i className="material-icons tutorEmail">email</i>
                                    <p style={{ "display": "flex", "margin": "auto 0px" }}>tomdavies32@gmail.com</p>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps)(BestTutor)


