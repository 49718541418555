import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  SwitchTransition,
  CSSTransition,
  TransitionGroup,
} from "react-transition-group";

import "./homeSlider.scss";

const imgUrls = [
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FTruro-school-logo.png?alt=media&token=aab26162-0666-4ddd-8796-36483b3d21b0",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Fcoopers-school.png?alt=media&token=8e663cde-df12-4345-80b0-8f729799c7e1",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Fglyn.png?alt=media&token=ffccedae-f44f-44fa-9455-d1c251b7a3a6",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Fhartismere.png?alt=media&token=a5214599-5ae6-4bd0-b03b-efaa52457985",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FOswestry.png?alt=media&token=30c991f0-562b-49df-99ae-1d4a21fa8a4d",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2F743-7434442_adcote-school-on-twitter.png?alt=media&token=e45383f3-b0b0-471b-aba6-5a9d2f951f01",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FEnfield_Capital_A_(no_bg).png?alt=media&token=125c92af-0707-411c-8bde-f9b3f14e9d3e",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Femmanuel.png?alt=media&token=22c6f738-4e8a-41d8-86b0-ecb14d6ef43f",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2Ffoley.png?alt=media&token=8bc78e20-1002-4b79-95d0-9bd7abde7529",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FBSTlogolandscapecolour.png?alt=media&token=7f74f4fa-279e-4326-b1bd-d5de071efb2d",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FOswestry.png?alt=media&token=30c991f0-562b-49df-99ae-1d4a21fa8a4d",
  "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/storage%2FCheneyLogoSchoolLightSubtitle-copy.png?alt=media&token=31852952-e3bb-4ca3-b1a1-40d9d7764870",
];

const Arrow = ({ direction, clickFunction, iconarrow }) => (
  <div className={`slide-arrow ${direction}`} onClick={clickFunction}>
    <i className="material-icons">{iconarrow}</i>
  </div>
);

const ImageSlide = ({ imgs, state, statusState }) => (
  <SwitchTransition mode={"out-in"}>
    <CSSTransition
      // timeout={2000}
      timeout={{ enter: 10000, exit: 10000 }}
      key={state}
      addEndListener={(node, done) => {
        node.addEventListener("transitionend", done, false);
      }}
      classNames={statusState === "-1" ? "fadeout" : "fadein"}
      // unmountOnExit
    >
      <div className="imgConatiner">
        {state
          ? imgs.map((item, index) => (
              <div className="imgblock">
                <img src={item} alt={item} key={index} />
              </div>
            ))
          : imgs.map((item, index) => (
              <div className="imgblock">
                <img src={item} alt={item} key={index} />
              </div>
            ))}
      </div>
    </CSSTransition>
  </SwitchTransition>
);

const HomeSlider = () => {
  const [currentImageIndex, setcurrentImageIndex] = useState(0);
  const [numOfSlide, setnumOfSlide] = useState(3);
  const [activedot, setactivedot] = useState(0);
  const dots = Array.from(Array(imgUrls.length / numOfSlide).keys());
  const [state, setState] = React.useState(true);
  const [statusState, setstatusState] = React.useState("1");

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 6000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (isMobile) {
      setnumOfSlide(1);
    }
  }, [isMobile]);

  const previousSlide = () => {
    if (currentImageIndex <= imgUrls.length) {
      const lastIndex = imgUrls.length - numOfSlide;
      const shouldResetIndex = currentImageIndex === 0;
      const index = shouldResetIndex
        ? lastIndex
        : currentImageIndex - numOfSlide;
      setcurrentImageIndex(index);
      setState(!state);
      setstatusState("1");
    }

    if (activedot <= dots.length) {
      const lastdot = dots.length - 1;
      const shouldResetDotIndex = activedot === 0;
      const dotindex = shouldResetDotIndex ? lastdot : activedot - 1;
      setactivedot(dotindex);
    }
  };

  const nextSlide = () => {
    if (currentImageIndex <= imgUrls.length) {
      const lastIndex = imgUrls.length - numOfSlide;
      const shouldResetIndex = currentImageIndex === lastIndex;
      const index = shouldResetIndex ? 0 : currentImageIndex + numOfSlide;
      setcurrentImageIndex(index);
      setState(!state);
      setstatusState("-1");
    }

    if (activedot <= dots.length) {
      const lastdot = dots.length - 1;
      const shouldResetDotIndex = activedot === lastdot;
      const dotindex = shouldResetDotIndex ? 0 : activedot + 1;
      setactivedot(dotindex);
    }
  };

  return (
    <div className="conatiner">
      <div className="homeCarouselContainer">
            <Arrow
            direction="left"
            clickFunction={previousSlide}
            iconarrow="keyboard_arrow_left"
            />
            <ImageSlide
            imgs={imgUrls.slice(
                currentImageIndex,
                currentImageIndex + numOfSlide
            )}
            state={state}
            setState={setState}
            statusState={statusState}
            />

            <Arrow
            direction="right"
            clickFunction={nextSlide}
            iconarrow="keyboard_arrow_right"
            />
      </div>
      <div className="dots">
        {dots.map((item, index) =>
          index === activedot ? (
            <i className="material-icons activedot" key={index}>fiber_manual_record</i>
          ) : (
            <i className="material-icons" key={index}>fiber_manual_record</i>
          )
        )}
      </div>
    </div>
  );
};

export default HomeSlider;