import React, { Component } from 'react'
import { Circle } from 'rc-progress';

class NewAccuracyCircle extends Component {
    constructor() {
        super();
        this.state = {
            zeroPercent: 0,
            averageScore: 0,
        };
        this.increaseProgress = this.increaseProgress.bind(this);
    }

    componentDidMount() {
        this.increaseProgress();
    }


    increaseProgress() {
        let { averageScore, zeroPercent } = this.state;
        
        if (!averageScore) {
            averageScore = this.props.average;

            if (averageScore) {
                this.setState({ averageScore });
            }
        }

        // Increments state help render donut animation
        const newPercent = zeroPercent + 1;

        if (averageScore && newPercent >= averageScore + 1) {
            clearTimeout(this.tm);
            return;
        }

        if (averageScore && averageScore!==0){
            this.setState({
                zeroPercent: newPercent
            });
        }

        this.tm = setTimeout(this.increaseProgress, 10);
    }

    render() {
        let color
        if (this.state.zeroPercent<=25) {
            color="#a6a5c0"
        } else if (this.state.zeroPercent>25 && this.state.zeroPercent<=55) {
            color="#a6a5c0"
        } else if (this.state.zeroPercent>56 && this.state.zeroPercent<=80) {
            color="#7978a1"
        } else {
            color="#525174"
        }

        return (
            <div>
                    {this.state.averageScore===0 ?     
                        <div>
                            <Circle
                                percent="0"
                                strokeWidth="8"
                                trailWidth='8'
                                strokeLinecap="round"
                                strokeColor="#D9D9D9" /> 
                            <div className="circleLabel">
                                <p> 0% </p>
                            </div>
                        </div>
                        :
                        <div>
                            <Circle
                                percent={`${this.state.zeroPercent}`}
                                strokeWidth="8"
                                trailWidth='8'
                                strokeLinecap="round"
                                strokeColor={color} />
                            <div className="circleLabel">
                                <p> {this.state.zeroPercent}% </p>
                            </div>
                        </div>
                    }
            </div>
        )
    }
}

export default NewAccuracyCircle