import React from 'react';
import './quizzes.css'
import logo from '../../resources/gradeIt.png'
import firebase from '../auth/auth'
import Collapsible from 'react-collapsible';
import Theme from './theme'
import CreateQuizPopup from './createQuizPopup'
import CreateFlashcardsPopup from './createFlashcardsPopup'
import Bookmarks from './Bookmarks'
// import Macro from '../Macro landing page/macro'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { getQuizzesTrial, getStudentArray } from '../../helpers/getData';
import RemoveWarning from './removeWarning'
import Spinner from '../spinner/spinner'
import NotAvailable from '../teacherDash/notAvailable'
import VerifyTeacher from '../teacherDash/verifyTeacher'

class QuizLanding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quizzes: [],
            studentArray: {},
            editor: false,
            flashcardEditor: false,
            removeWarning: false,
            bookmarks: false
        }

        this.toggleBookmarks = this.toggleBookmarks.bind(this);
    }

    componentDidMount() {
        this.getQuizzesTrialProps()
        this.getHighScores();
        this.checkVerified()
        this.setState({
            subject: this.props.profile.subject
        })

        //Logs date of login
        firebase.firestore().collection('users')
        .doc(this.props.auth.uid)
        .update({
            lastLogged: new Date()
        })
    }

    componentWillReceiveProps(props) {
        props.auth && this.getHighScores(props);
        props.profile.school && this.getQuizzesTrialProps(props)
        // props.auth && this.getAttempts(props)

    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        getQuizzesTrial(props.profile.school, this.props.profile.subject)
            .then((quizzes) => getStudentArray(this.props.auth.uid, quizzes, this.props.profile.subject))
            .then(({ quizzes, studentArray }) => {
                studentArray = studentArray[0];
                
                this.setState({
                    quizzes,
                    studentArray
                });
            });
    }

    checkVerified = () => {
        firebase.firestore().collection('schools')
        .where('school', '==', this.props.profile.school)
        .get()
        .then(querySnapshot => {
            if (this.props.profile.student==="teacher" && querySnapshot.docs[0].data()[`unverified${this.props.profile.subject}teachers`]) {
                this.setState({
                    unverifiedTeachers: querySnapshot.docs[0].data()[`unverified${this.props.profile.subject}teachers`],
                    schoolID: querySnapshot.docs[0].id
                })
            }
        })
    }

    getHighScores(props) {
        props = props || this.props;

        // Calls quiz attempts database for specific user, ordered by quizName and percentage
        if (!(props.auth.uid && props.auth.isLoaded)) return;

        const quizPerformanceRef = firebase.firestore().collection('quizAttempts');

        quizPerformanceRef.where('user', '==', props.auth.uid)
            .orderBy('quizName').orderBy('percent', 'desc')
            .get().then(snapshot => {
                const result = snapshot.docs.map(doc => doc.data());

                //Push quiz attempts into arrays for each quiz 
                const categories = {};

                for (let i = 0; i < result.length; i++) {
                    //destructures: for each interation, quizName = result.quizName
                    const { quizName, percent } = result[i];

                    if (!(quizName in categories)) {
                        categories[quizName] = [];
                    }

                    categories[quizName].push(percent)
                }

                //Constructs object containing best score for each quiz
                const highScores = {};
                const keys = Object.keys(categories);
                console.log(categories)
                console.log(keys)
                const values = Object.values(categories);

                for (let i = 0, len = keys.length; i < len; i++) {
                    const quizName = keys[i];
                    const scores = values[i];

                    highScores[quizName] = Math.max(...scores) * 100;
                }

                this.setState({ highScores })
            })
    }

    toggleEditor = () => {
        const {editor} = this.state

        this.setState ({
            editor: editor ? false : true 
        })
    }

    toggleFlashcardEditor = () => {
        const {flashcardEditor} = this.state

        this.setState ({
            flashcardEditor: flashcardEditor ? false : true 
        })
    }

    toggleRemoveWarning = () => {
        const { removeWarning } = this.state
        this.setState ({
            removeWarning: removeWarning ? false : true
        })
    }

    retrieveIndex = (n) => {
        
        this.setState ({
            removalIndex: n
        })
    }

    retrieveQuizName = (q) => {
        this.setState ({
            removalQuizName: q
        })
    }

    refreshPage = () => {
        window.location.reload(false)
    }

    toggleBookmarks() {
        const {bookmarks} = this.state
        this.setState({
            bookmarks: bookmarks ? false : true
        })
    }

    toggleNotAvailable = () => {
        this.setState({notAvailable: this.state.notAvailable ? false : true })
    }

    getSubject = () => {

        const { subject } = this.props.profile
        let subjectName

        if (subject==="gcseBiologySingleScienceAQA") {
            subjectName = "GCSE Biology"
        } else if (subject==="Physics") {
            subjectName = "GCSE Physics"
        } else if (subject==="gcseChemistrySingleScienceAQA") {
            subjectName = "GCSE Chemistry"
        } else if (subject==="Economics") {
            subjectName = "Economics"
        } else {
            subjectName= ""
        }

        return(
            <p className='quizLandingHeaderText'>{subjectName}</p>
        )

    }

    verify = (teacher) => {
        console.log(teacher.firstName)
        const teacherArray= Array.from(this.state.unverifiedTeachers)
        const newArray=teacherArray.filter(teach=> 
            teach.ID!==teacher.ID
        )
        console.log(newArray)
        this.setState({unverifiedTeachers: newArray})

        firebase.firestore().collection('schools')
        .doc(this.state.schoolID)
        .update({
            [`unverified${this.props.profile.subject}teachers`]: newArray
        })

        firebase.firestore().collection('users')
        .doc(teacher.ID)
        .update({
            teacherNotVerified: false
        })
    } 

    reject = (teacher) => {
        console.log(teacher.firstName)
        const teacherArray= Array.from(this.state.unverifiedTeachers)
        const newArray=teacherArray.filter(teach=> 
            teach.ID!==teacher.ID
        )
        console.log(newArray)
        this.setState({unverifiedTeachers: newArray})

        firebase.firestore().collection('schools')
        .doc(this.state.schoolID)
        .update({
            [`unverified${this.props.profile.subject}teachers`]: newArray
        })
    } 

    render() {
        const { auth } = this.props;

        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />

        const { quizzes, studentArray, highScores, editor, removeWarning, removalIndex,
        removalQuizName, flashcardEditor, notAvailable, unverifiedTeachers  } = this.state;

        return (
            <div className="mobileContainer">
                <div className="quizLandingHeader">
                    <img src={logo} className="quizLandingHeaderLogo" alt="logo"/>
                    {this.getSubject()}
                </div>
                <div className="topThemeContainer">
                    {quizzes.length===0
                    ? <Spinner />
                    : null}
                    {editor
                        ? <CreateQuizPopup toggleEditor={this.toggleEditor} />
                        : null
                    }
                    {flashcardEditor
                        ? <CreateFlashcardsPopup toggleFlashcardEditor={this.toggleFlashcardEditor} />
                        : null
                    }
                    {removeWarning 
                        ? <RemoveWarning
                            refreshPage={this.refreshPage} 
                            toggleRemoveWarning={this.toggleRemoveWarning}
                            removalIndex = {removalIndex}
                            removalQuiz= {removalQuizName} />
                        : null
                    }
                    {this.state.bookmarks
                        ?
                        <Bookmarks toggleBookmarks={this.toggleBookmarks} />
                        :
                        null
                    }
                    {notAvailable
                    ? <NotAvailable toggleNotAvailable={this.toggleNotAvailable} />
                    :null
                    }
                    {unverifiedTeachers && unverifiedTeachers.length > 0 
                    ? <VerifyTeacher unverifiedTeachers={unverifiedTeachers} verify={this.verify} reject={this.reject}/>
                    : null
                    }

                    {!this.props.profile.tryDiagnostics
                    ?
                    <Link to='/diagnostic' className="quizLandingCreateQuizContainer pulse" onClick={this.toggleEditor} quizze={quizzes}>
                        <div className="quizLandingCreateQuizTextContainer">
                            <div className="flexCentre">
                            <i className='material-icons add'>analytics</i>
                            </div>
                            <p className="quizLandingCreateQuizDash diagnostics"> REVISION DIAGNOSTICS</p>
                            <div className="btn-floating quizzes pulse" onClick={this.toggleDraggableDialog}>
                            </div>
                            <p className="quizLandingCreateQuizDash diagnosticsNew">new</p>
                        </div>
                    </Link>
                    :
                    <Link to='/diagnostic' className="quizLandingCreateQuizContainer pulse" onClick={this.toggleEditor} quizze={quizzes}>
                        <div className="quizLandingCreateQuizTextContainer">
                            <div className="flexCentre">
                            <i className='material-icons add'>analytics</i>
                            </div>
                            <p className="quizLandingCreateQuizDash diagnostics"> REVISION DIAGNOSTICS</p>
                        </div>
                    </Link>
                    }


                    {this.props.profile.student==='teacher'
                    ? 
                        this.props.profile.school!=="individual" 
                            ?
                            <div className="quizLandingCreateQuizContainer" onClick={this.toggleEditor} quizze={quizzes}>
                                <div className="quizLandingCreateQuizTextContainer">
                                    <div className="flexCentre">
                                        <i className='material-icons add'>add</i>
                                    </div>
                                    <p className="quizLandingCreateQuizDash">Create/modify quizzes</p>
                                </div>
                            </div>
                            :
                            <div className="quizLandingCreateQuizContainer" onClick={this.toggleNotAvailable}>
                                <div className="quizLandingCreateQuizTextContainer">
                                    <div className="flexCentre">
                                        <i className='material-icons add'>add</i>
                                    </div>
                                    <p className="quizLandingCreateQuizDash">Create/modify quizzes</p>
                                </div>
                            </div>
                    : null
                    }
                    
                    {this.props.profile.admin
                    ? <div className="quizLandingCreateQuizContainer" onClick={this.toggleFlashcardEditor}>
                            <div className="quizLandingCreateQuizTextContainer">
                                <div className="flexCentre">
                                    <i className='material-icons add'>add</i>
                                </div>
                                <p className="quizLandingCreateQuizDash">Create/modify flashcards</p>
                            </div>
                        </div>
                    : null

                    }
                    <div className="quizLandingCreateQuizContainer" onClick={this.toggleBookmarks}>
                        <div className="quizLandingCreateQuizTextContainer">
                            <div className="flexCentre">
                                <i className='material-icons bookmark'>bookmark</i>
                            </div>
                            <p className="quizLandingCreateQuizDash">View bookmarks</p>
                        </div>
                    </div>
                    
                    
                    {Object.entries(quizzes).map(([category, quizzesList], index) => (
                        <div className="quizLandingFormContainer" key={index+category}>
                            
                            <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                    <p className="quizLandingThemeNameTextQuizDash">{category}</p>
                                    {studentArray && studentArray[category] &&
                                        <p className="quizLandingThemeInfo">{studentArray[category].themeAttempts || 0}/{quizzes[category].length} attempted</p>}
                                </div>
                            }>
                                <div className="categoryContainers">
                                    <Theme
                                        toggleRemoveWarning={this.toggleRemoveWarning}
                                        retrieveIndex={this.retrieveIndex}
                                        retrieveQuizName={this.retrieveQuizName}
                                        category={category}
                                        quizzes={quizzesList}
                                        // quizAttempts={this.state.quizAttempts}
                                        highScores={highScores}
                                        />
                                </div>
                            </Collapsible>
                        </div>
                    ))}
                    {this.props.profile.subject==='Economics'
                    ? <div className="quizLandingCreateQuizContainer">
                            <Link to='/econmaths' className="quizLandingCreateQuizTextContainer">
                                <div className="flexCentre">
                                    <i className='material-icons calculate'>calculate</i>
                                </div>
                                <p className="quizLandingCreateQuizDash">Economics for Mathematicians</p>
                            </Link>
                        </div>
                    : null
                    }

                        
                </div>
            </div>
        );     
    }
};

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(QuizLanding);

