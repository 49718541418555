import firebase from '../components/auth/auth';

export function getQuizzes() {
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('quizzes')
            .get()
            .then(querySnapshot => {
                const sort = (list, by) => {
                    return list.sort((a,b) => a[by] > b[by] ? 1 : -1)
                };

                const result = sort(
                    querySnapshot.docs.map(doc => doc.data()),
                    'quizCategory'
                );
                
                result.forEach(quiz => {
                    const { quizNumber } = quiz;

                    if (quizNumber) {
                        quiz.quizNumber = Number(quizNumber);
                    }
                });
                
                const quizzes = {};
                

                result.forEach(quiz => {
                    // Gets category and removes it from the object
                    const category = quiz.quizCategory;
                    delete quiz.quizCategory;

                    // inits list
                    
                    quizzes[category] = quizzes[category] || [];

                    // Adds the quizz
                    quizzes[category].push(quiz);
                    
                });

                // For each category, sort quiz list
                for (let category in quizzes) {
                    quizzes[category] = sort(quizzes[category], 'quizNumber');
                }

                
                resolve(quizzes);
            });
        });
}

export function getQuizzesTrial(school, subject='') {
    return new Promise((resolve, reject) => {
        let mcqDatabase
        if (subject==='Economics') {
            mcqDatabase=''
        } else {
            mcqDatabase=subject
        }
        firebase.firestore().collection(`quizzes${mcqDatabase}`)
            .where('school', 'in', ['GradeIt', school])
            .get()
            .then(querySnapshot => {
                const sort = (list, by) => {
                    return list.sort((a,b) => a[by] > b[by] ? 1 : -1)
                };

                const result = sort(
                    querySnapshot.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    })),
                    'quizCategory'
                );
                
                result.forEach(quiz => {
                    const { quizNumber } = quiz;

                    if (quizNumber) {
                        quiz.quizNumber = Number(quizNumber);
                    }
                });
                
                const quizzes = {};
            

                result.forEach(quiz => {
                    // Gets category and removes it from the object
                    const category = quiz.quizCategory;
                    delete quiz.quizCategory;

                    // inits list
                    if (category !== "INSEEC") {
                    quizzes[category] = quizzes[category] || [];

                    // Adds the quizz
                    quizzes[category].push(quiz);
                    }
                });

                // For each category, sort quiz list
                for (let category in quizzes) {
                    quizzes[category] = sort(quizzes[category], 'quizNumber');
                }

                
                resolve(quizzes);
            });
        });
}

export function getFlashcards(school, subject='') {
    return new Promise((resolve, reject) => {
        
        firebase.firestore().collection(`flashcards${subject}`)
            .where('school', 'in', ['GradeIt', school])
            .get()
            .then(querySnapshot => {
                const sort = (list, by) => {
                    return list.sort((a,b) => a[by] > b[by] ? 1 : -1)
                };

                const result = sort(
                    querySnapshot.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    })),
                    'category'
                );
                
                
                const flashcards = {};
            

                result.forEach(flashcard => {
                    // Gets category and removes it from the object
                    const category = flashcard.category;
                    delete flashcard.category;

                    // inits list
                    flashcards[category] = flashcards[category] || [];

                    // Adds the quizz
                    flashcards[category].push(flashcard);
                    
                });

                // // For each category, sort quiz list
                // for (let category in quizzes) {
                //     flashcards[category] = sort(flashcards[category], 'quizNumber');
                // }

                
                resolve(flashcards);
            });
        });
}


export function parseStudentArray(querySnapshot, quizzes) {
    const students = querySnapshot.docs.map(doc => doc.data());
    
    
    return students.map(student => {
        //constructs top level object, containing student names for relevant school
        const studentArray = {};
        
        //constructs separate theme objects for every student
        Object.keys(quizzes).forEach(category => {
            studentArray[category] = {}
        });
    
        //adds quiz objects to the relevant theme object, using state set in getQuizzes()
        //can change default to n/a
        Object.entries(quizzes).forEach(([category, quizzes]) => {
            quizzes.forEach(({ quizName }) => studentArray[category][quizName] = 0);
        });
    
        // puts student data into the relevant quiz objects within the relevant theme object
        var aggregateTotalScore = 0
        const aggregateScore = {};
        const attemps = {};
    
        for (let key in student.studentProgress) {
            for (let category in quizzes) {
                const quizList = quizzes[category];
                const includes = quizList.some(
                    ({ quizName }) => quizName === key
                );
    
                if (includes) {
                    // console.log(key)
                    studentArray[category][key] = student.studentProgress[key];
                    
                    aggregateScore[category] = 
                        (aggregateScore[category] || 0)
                        + student.studentProgress[key];
    
                    attemps[category] = 
                        (attemps[category] || 0)
                        + 1;
                }
    
                const progress = student.studentProgress[key];
    
                aggregateTotalScore +=
                    typeof progress === 'number'
                        ? progress
                        : 0 // Object.values(progress)[0];
            } 
        }
    
        //calculates the number of quizzes attempted
        studentArray.totalAttempts = Object.keys(student.studentProgress).length;
    
        //calculates the total number of quizzes                            
        studentArray.totalQuizzes = Object
            .values(quizzes)
            .reduce((total, quizzes) => total + quizzes.length, 0);
        
        //adds average accuracy and themeAccuracy to the studentArray
        studentArray.averageAccuracy = Math.round(
            aggregateTotalScore / Object.keys(student.studentProgress).length
        );
    
        // Adds additional data
        for (let category in quizzes) {
            const data = studentArray[category];
            const quizList = quizzes[category];
    
            data.themeAttempts = attemps[category];
            data.themeAccuracy = Math.round(
                aggregateScore[category] / attemps[category]
            );
            data.themeCompletion = Math.round(
                attemps[category] / 
                quizList.length * 100
            );
        }
    

        return studentArray;
    });
}

export function getStudentArray (uid, quizzes, subject='') {
    let subjectString =""
    if (subject !=="Economics") {
        subjectString=subject
    }
    return new Promise((resolve, reject) => {
        firebase.firestore().collection('studentProgress'+subjectString).where('user', '==', uid)
            .get()
            .then(querySnapshot => {
                const studentArray =  parseStudentArray(querySnapshot, quizzes);
                
                resolve({
                    studentArray, quizzes
                });
            });
    });
}
