import React, { Component, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import firebase from '../auth/auth'
import MobileNavBar from '../layout/mobileNavbar'
import PDFViewer from './pdfViewer'
import {Helmet} from "react-helmet";

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import "./pastPapers.scss"


class PastPapers extends Component {

    constructor(props) {
        super(props);
        this.state= {
            loading: true
        }

        
    }

    componentDidMount = () => {

        firebase.firestore().collection("pastPapers")
        .where("paper", "==", this.props.match.params.id)
        .get()
        .then(querySnapshot => {

            const pastPaper = querySnapshot.docs[0].data()
            
            let resourceType
            if (pastPaper.type =="pastPaper") {
                resourceType="past paper"
            } else if (pastPaper.type =="markScheme") {
                resourceType="mark scheme"
            }
            
            this.setState({
                url: pastPaper.url,
                type: resourceType
            })
        })
    }

    render () {
        const { url } = this.state

        return (
            <div>
                <Helmet>
                    <title>{this.props.match.params.id}</title>
                    <meta name="description" content={`View ${this.props.match.params.id}`} />
                </Helmet>               
                <div>
                <PDFViewer
                    url={url}
                />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps) (PastPapers)


