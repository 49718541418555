import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import Spinner from '../spinner/spinner'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import './verifyTeacher.scss'

class VerifyTeacher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    home = () => {
        this.setState ({home: true})
    }
    
    render() {

        const {unverifiedTeachers} = this.props
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth verifyTeacher">
                        <div className="popupNeedHelp verifyTeacher">
                            <h5 className="needHelp verifyTeacher">Do you know these guys?</h5>
                            {unverifiedTeachers.map(teacher => {
                                return (
                                    <div className="verifyTeacherContainer">
                                        <p className="verifyTeacherNameText">Name: {teacher.firstName} {teacher.lastName}</p>
                                        <p className="verifyTeacherNameText">Email: {teacher.email} </p>
                                        <div className="verifyButtonContainer">
                                            <div className="verifyButtonYes" onClick={() =>this.props.verify(teacher)}>
                                                <i className="material-icons verifyTeacher">done</i>
                                                <p className="verifyText">Yes, verify</p>
                                            </div>
                                            <div className="verifyButtonNo" onClick={() =>this.props.reject(teacher)}>
                                                <i className="material-icons verifyTeacher">close</i>
                                                <p className="verifyText">This is not a teacher</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(VerifyTeacher);