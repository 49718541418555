import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import Column from './column'
import { DragDropContext } from 'react-beautiful-dnd'
import Toggle from 'react-toggle'
import { storage } from '../auth/auth';
import CKEditor from 'ckeditor4-react';
// import DOMPurify from 'dompurify'
import ImageWarning from '../newMCQForm/imageWarning'
import './flashcardForm.scss';
import removeWarning from '../quizzes/removeWarning';
import ChangeNamePopupFlashcards from './changeNamePopupFlashcards'
import EditorPreview from '../newMCQForm/editorPreview';
import Editor from '../newMCQForm/editor';

class FlashcardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotated: false,
            mcqData: [],
            cards: [],
            editMode: false,
            slide: "",
            sidebar: "",
            imageWarning: false,

        }
        this.rotate = this.rotate.bind(this);
        this.nextCard = this.nextCard.bind(this);
        this.previousCard = this.previousCard.bind(this);
        this.flashcardSelect = this.flashcardSelect.bind(this);
        this.addQuestion = this.addQuestion.bind(this)
        this.removeQuestion = this.removeQuestion.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)
        this.previousCardNav = this.previousCardNav.bind(this)
        this.onEditorChange = this.onEditorChange.bind( this );
    }

    componentDidMount() {
        this.pushData();

        
        let rename=false
        if (window.location.pathname.substring(1,38)==='flashcardform/Blank%20flashcards48309') {
            this.setState({renameFlashcards: true})
        }

        // //If content creators or non-admins are creating a new quiz, they must rename it first
        // if ((!this.props.profile.admin && rename) || (this.props.profile.contentCreator && rename)) {
        //     this.setState({renameFlashcards:true})
        // }
        
    }


    pushData(id) {
        let { subject } = this.props.profile

        let flashcards

        if (subject==='Economics' || !subject) {
            flashcards= 'flashcards'
        } else {
            flashcards= `flashcards${subject}` 
        }

        let subjectString  =subject
        


        firebase.firestore().collection(flashcards)
            .doc(id || this.props.match.params.id)
            .get()
            .then(querySnapshot => {
                const mcqData = querySnapshot.data();
                const cards = []
                if (mcqData) {
                    mcqData.cards.map((card) => {
                        cards.push(card)
                    })

                    this.setState({
                        mcqData,
                        title: mcqData.flashcardSeries,
                        text: mcqData.cards[0].flashcardQuestion,
                        answer: mcqData.cards[0].flashcardAnswer,
                        series: mcqData.flashcardSeries,
                        url: mcqData.cards[0].url,
                        urlBack: mcqData.cards[0].urlBack,
                        graphCard: mcqData.cards[0].graphCard,
                        graphCardBack: mcqData.cards[0].graphCardBack,
                        type: mcqData.cards[0].type,
                        total: mcqData.cards.length,
                        nr: 0,
                        theme: mcqData.cards[0].theme,
                        Edexcel: mcqData.cards[0].Edexcel,
                        OCR: mcqData.cards[0].OCR,
                        AQA: mcqData.cards[0].AQA,
                        value: mcqData.cards[0].type,
                        pictureFront: mcqData.cards[0].pictureFront,
                        pictureBack: mcqData.cards[0].pictureBack,  
                        cards,
                        newUrl: null,
                        quiz: mcqData.quiz
                    })

                } else {
                    //If new flashcards are being created
                    cards.push({
                        "value":"definition",
                        "flashcardName":"test",
                        "flashcardSeries":"Blank flashcards",
                        "url":"",
                        "graphCard":false,
                        "urlBack":"",
                        "pictureBack":"no picture",
                        "theme":"Theme 1",
                        "flashcardQuestion":"<p>Question 1</p>\n",
                        "type":"definition",
                        "graphCardBack":false,
                        "flashcardAnswer":"Answer goes here...",
                        "AQA":true,
                        "OCR":true,
                        "Edexcel":true,
                        "flashcardTitle":"test",
                        "pictureFront":"no picture",
                        "createdAt": new Date()
                    }) 
                    this.setState({cards})
                }
            })

        firebase.firestore().collection(`categories${subjectString}`)
        .orderBy('category', 'asc')
        .get()
        .then(querySnapshot => {
            const categories = querySnapshot.docs.map(query=> query.data().category)
            this.setState({categories})
        })
    }

    previousCardNav() {
        const { nr, cards } = this.state
        let nr1 = nr - 1
        this.setState({
            nr: nr1,
            title: cards[nr - 1].flashcardTitle,
            text: cards[nr - 1].flashcardQuestion,
            answer: cards[nr - 1].flashcardAnswer,
            url: cards[nr - 1].url,
            urlBack: cards[nr - 1].urlBack,
            graphCard: cards[nr - 1].graphCard,
            graphCardBack: cards[nr - 1].graphCardBack,
            type: cards[nr-1].type,
            theme: cards[nr-1].theme,
            rotated: false,
            Edexcel: cards[nr-1].Edexcel,
            OCR: cards[nr-1].OCR,
            AQA: cards[nr-1].AQA,
            value:cards[nr-1].type,
            pictureFront: cards[nr-1].pictureFront,
            pictureBack: cards[nr-1].pictureBack,   
        })
        this.slideLeft()
        if (this.state.editMode) {
            this.save() 
        }
    }

    nextCard() {
        const { nr, cards } = this.state
        let nr1 = nr + 1
        this.setState({
            nr: nr1,
            text: cards[nr + 1].flashcardQuestion,
            answer: cards[nr + 1].flashcardAnswer,
            url: cards[nr + 1].url,
            urlBack: cards[nr + 1].urlBack,
            graphCard: cards[nr + 1].graphCard,
            graphCardBack: cards[nr + 1].graphCardBack,
            type: cards[nr+1].type,
            theme: cards[nr+1].theme,
            rotated: false,
            Edexcel: cards[nr+1].Edexcel,
            OCR: cards[nr+1].OCR,
            AQA: cards[nr+1].AQA,
            value: cards[nr+1].type,
            pictureFront: cards[nr+1].pictureFront,
            pictureBack: cards[nr+1].pictureBack,
        })
        this.slideRight()
        if (this.state.editMode) {
            this.save() 
        }
    }

    slideRight() {
        this.setState(
            {slide:"right"}
        )
        setTimeout(() => {
            this.setState({slide:"hiddenLeft"})
                setTimeout(()=> {
                    this.setState({slide:"rightReturn"})
                    setTimeout(()=> {
                        this.setState({slide:"finished"})
                    }, 200)
                }, 300)
            }, 100)
    }

    slideLeft() {
        this.setState(
            {slide:"left"}
        )
        setTimeout(() => {
            this.setState({slide:"hiddenRight"})
                setTimeout(()=> {
                    this.setState({slide:"leftReturn"})
                    setTimeout(()=> {
                        this.setState({slide:"finished"})
                    }, 200)
                }, 300)
            }, 100)
    }

    previousCard(cards) {
        const { nr } = this.state;

        const nnr = nr === 0
            ? 0
            : nr - 1;

        const card = cards[nnr];

        return {
            nr: nnr,
            text: card.flashcardQuestion,
            answer: card.flashcardAnswer,
            url: card.url,
            urlBack: card.urlBack,
            graphCard: card.graphCard,
            graphCardBack: card.graphCardBack,
            type: card.type,
            theme: card.theme,
            rotated: false,
            Edexcel: card.Edexcel,
            OCR: card.OCR,
            AQA: card.AQA,
            value: card.type,
            pictureFront: card.pictureFront,
            pictureBack: card.pictureBack,     
        };
    }

    flashcardSelect(index) {
        const { cards } = this.state
        this.setState({
            nr: index,
            text: cards[index].flashcardQuestion,
            answer: cards[index].flashcardAnswer,
            url: cards[index].url,
            urlBack: cards[index].urlBack,
            graphCard: cards[index].graphCard,
            graphCardBack: cards[index].graphCardBack,
            type: cards[index].type,
            theme: cards[index].theme,
            rotated: false,
            Edexcel: cards[index].Edexcel,
            OCR: cards[index].OCR,
            AQA: cards[index].AQA,
            value: cards[index].type,
            pictureFront: cards[index].pictureFront,
            pictureBack: cards[index].pictureBack,     
        })
        if (this.state.editMode) {
            this.save() 
        }
    }

    addQuestion() {
        const { cards } = this.state
        const newCards = Array.from(this.state.cards)

        newCards.push({
            flashcardAnswer: "Answer goes here...",
            flashcardQuestion: `Question ${this.state.total + 1}`,
            flashcardSeries: this.state.series,
            graphCard: false,
            graphCardBack: false,
            type: "definition",
            theme: cards[0].theme,
            url: "",
            urlBack: "",
            Edexcel: true,
            OCR: true,
            AQA: true,
            value: "definition",
            pictureFront: "no picture",
            pictureBack: "no picture",
            createdAt: new Date()   
        })
        const newState = {
            ...this.state,
            cards: newCards,
            total: newCards.length
        }
        this.setState(newState)
        this.save()
    }

    removeQuestion(index, event) {
        event.stopPropagation();

        if (this.state.total===1) {
            const { cards } = this.state
            const newCards = []
            newCards.push({
                flashcardAnswer: "Answer goes here...",
                flashcardQuestion: `Question 1`,
                flashcardSeries: this.state.flashcardSeries,
                graphCard: false,
                graphCardBack: false,
                type: "definition",
                theme: cards[0].theme,
                url: "",
                urlBack: "",
                Edexcel: true,
                OCR: true,
                AQA: true,
                value: "definition",
                pictureFront: "no picture",
                pictureBack: "no picture",   
             })
             const newState = {
                ...this.state,
                cards: newCards,
                total: newCards.length
            }
            this.setState(newState);
        }

        else {

            const newCards = Array.from(this.state.cards)

            newCards.splice(index, 1)

            const previous = this.previousCard(newCards);

            const newState = {
                cards: newCards,
                total: newCards.length,
                ...previous
            }
            this.setState(newState);
        }
        this.save()

    }

    rotate() {
        const { rotated, editMode } = this.state

        if (rotated) {
            this.setState ({slide:""})
        }

        if (!editMode) {
            rotated ? this.setState({ rotated: false }) : this.setState({ rotated: true })
        }
    }

    editRotate = () => {
        const { rotated } = this.state
        rotated ? this.setState({ rotated: false }) : this.setState({ rotated: true })
    }

    onDragEnd = result => {
        const { destination, source} = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const newFlashcardIds = Array.from(this.state.cards)
        newFlashcardIds.splice(source.index, 1);
        newFlashcardIds.splice(destination.index, 0, this.state.cards[source.index])

        const newState = {
            ...this.state,
            nr: destination.index,
            cards: newFlashcardIds,
            // title: newFlashcardIds[destination.index].flashcardTitle,
            text: newFlashcardIds[destination.index].flashcardQuestion,
            answer: newFlashcardIds[destination.index].flashcardAnswer,
            // series: newFlashcardIds[destination.index].flashcardSeries,
            url: newFlashcardIds[destination.index].url,
            urlBack: newFlashcardIds[destination.index].urlBack,
            graphCard: newFlashcardIds[destination.index].graphCard,
            graphCardBack: newFlashcardIds[destination.index].graphCardBack,
            type: newFlashcardIds[destination.index].type,
            theme: newFlashcardIds[destination.index].theme,
            rotated: false,
            Edexcel: newFlashcardIds[destination.index].Edexcel,
            OCR: newFlashcardIds[destination.index].OCR,
            AQA: newFlashcardIds[destination.index].AQA,
            value: newFlashcardIds[destination.index].type,
            pictureFront: newFlashcardIds[destination.index].pictureFront,
            pictureBack: newFlashcardIds[destination.index].pictureBack,
        }

        this.setState(newState)
        this.save()

    }

    handleChange(event) {
        const newFlashcards = Array.from(this.state.cards)
        newFlashcards.forEach((card) => {
            card.flashcardSeries = event.target.value
        }
        )
        this.setState({ series: event.target.value })
    }

    handleTextChange(event) {
        const { nr } = this.state

        let newCards = this.state.cards
        newCards[nr][event.target.id] = event.target.value

        if (event.target.id === "flashcardQuestion") {
            this.setState({
                text: event.target.value,
                cards: newCards
            })
        } else if (event.target.id === "flashcardAnswer") {
            this.setState({
                answer: event.target.value,
                cards: newCards
            })
        } else {
            this.setState({
                answer: event.target.value,
                cards: newCards
            })
        }
    }


    changeView = () => {
        this.setState({
            editMode: this.state.editMode === true ? false : true,
        });
        this.save()
    }

    togglePicture = () => {
        const { nr, graphCard } = this.state
        let newCards = this.state.cards

        graphCard
            ? newCards[nr].graphCard = false
            : newCards[nr].graphCard = true

        this.setState({
            graphCard: this.state.graphCard === true ? false : true,
            cards: newCards
        });
    }

    togglePictureBack = () => {
        const { nr, graphCardBack } = this.state
        let newCards = this.state.cards

        graphCardBack ? newCards[nr].graphCardBack = false : newCards[nr].graphCardBack = true

        this.setState({
            graphCardBack: this.state.graphCardBack === true ? false : true,
            cards: newCards
        });
    }

    handleImage = e => {

        const { nr, rotated } = this.state
        const { subject } = this.props.profile
        let imagesDatabase

        if (subject==='Economics') {
            imagesDatabase= 'flashcardimages'
        } else {
            imagesDatabase= `flashcardimages${subject}` 
        }

        let newCards = this.state.cards
        let key= (Math.random().toFixed(9)).substring(2,11)

        if (e.target.files[0]) {
            const image = e.target.files[0];
            if (image.size < 2097152 ) {
                console.log('image saved')
                const uploadTask = storage.ref(`${imagesDatabase}/${image.name}${key}`).put(image);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // progrss function ....
                        // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    (error) => {
                        // error function ....
                        console.log(error);
                    },
                    () => {
                        // complete function ....
                        storage.ref(imagesDatabase).child(`${image.name}${key}`).getDownloadURL().then(url => {

                            if (rotated) {
                                newCards[nr].urlBack = url
                                this.setState({
                                    urlBack: url,
                                    cards: newCards
                                })
                            } else {
                                newCards[nr].url = url
                                this.setState({
                                    url: url,
                                    cards: newCards
                                })
                            }
                            this.save()
                        })
                    }
                );
            } else {
                this.setState({imageWarning: true})
            }
        }
    }

    toggleImageWarning = () => {
        const {imageWarning} = this.state

        this.setState ( {
            imageWarning: imageWarning ? false : true
        })
    }

    getGraphEditContainer() {
        const { url, urlBack, rotated, pictureBack, pictureFront } = this.state

        let image
        let classNamePlaceholder
        let classNameImageContainer
        let classNameInput
        let placeholderText


        if (rotated) {
            urlBack
                ? image = urlBack
                : image = null

        } else {
            url
                ? image = url
                : image = null
        }


        if ((rotated && pictureBack==="picture") || (!rotated && pictureFront==="picture")) {
            classNamePlaceholder="flashcardFormImagePlaceholder"
            placeholderText="300 x 300 transparent square images work best! 2MB max file size. JPG and PNG file types only."
            classNameImageContainer="flashcardFormGraphEditMode"
            classNameInput="input-field flashcardFormImage"
        } else if ((rotated && pictureBack==="fullpicture") || (!rotated && pictureFront==="fullpicture")) {
            classNamePlaceholder="flashcardFormImagePlaceholderLarge"
            placeholderText="300 x 400 transparent images work best! 2MB max file size. JPG and PNG file types only."
            classNameImageContainer="flashcardFormGraphEditModeLarge"
            classNameInput="input-field flashcardFormImageLarge" 
        }
        

        return (
            <div className="flashcardFormGraphContainerEditMode">
                {image ?
                    <img src={image} className={classNameImageContainer} alt="graph" />
                    :
                    <div className={classNamePlaceholder}>
                        <p className="flashcardFormImagePlaceholder">{placeholderText}</p>
                    </div>
                }
                <div className={classNameInput}>
                    <input className="chooseFileflashcardFormImage" type="file" accept='.png, .jpg' onChange={this.handleImage} />
                </div>
            </div>
        )
    }

    getTextEditContainerNoGraph() {
        const { text, answer, rotated } = this.state
        const { subject } = this.props.profile

        let display
        if (rotated) {
            display=answer   
        } else {
            display=text
        }

        return (
            <div className="questionFlashcardFormContainerEditTextNoGraph">
                {subject.includes("Maths") 
                    ? <EditorPreview 
                        question2={display}
                        style={"mathsExpositionPreviewContainer"}
                        textStyle={"mathsFlashcardText"}
                        toggleEditor={this.toggleEditor}
                        editableElement={rotated ? "flashcardAnswer" : "flashcardQuestion"}
                    />
                    :
                    <CKEditor
                        data={display}
                        type="inline"
                        onChange={this.onEditorChange}
                        config={ {      toolbar: [ 
                            [  'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo', 
                            'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', 'NumberedList', 'BulletedList',
                            '-', 'Outdent', 'Indent', 'Table', 'HorizontalRule', 'SpecialChar' ] 
                        ]             } }
                    />
                }
                                                    
            </div>
        )

        
    }

    getTextEditContainerGraph() {
        const { text, answer, rotated } = this.state
        const { subject } = this.props.profile

        let display
        if (rotated) {
            display=answer   
        } else {
            display=text
        }

        

        return (
            <div className="flashcardFormBottomTextContainerEditMode">
                {subject.includes("Maths") 
                    ? <EditorPreview 
                        question2={display}
                        style={"mathsExpositionPreviewContainer"}
                        textStyle={"mathsFlashcardText"}
                        toggleEditor={this.toggleEditor}
                        editableElement={rotated ? "flashcardAnswer" : "flashcardQuestion"}
                    />
                    :
                    <CKEditor
                        data={display}
                        type="inline"
                        onChange={this.onEditorChange}
                        config={ {      toolbar: [ 
                            [  'Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', 
                            'Bold', 'Italic', 'Strike', '-', 'RemoveFormat', 'NumberedList', 'BulletedList',
                            '-', 'Outdent', 'Indent', 'Table', 'HorizontalRule', 'SpecialChar' ] 
                        ]   }}
                    />
                }                                    
            </div>
        )

        
    }

    onEditorChange( evt ) {
        const {rotated, nr} = this.state
        const clean = evt.editor.getData()
    
        let newCards = this.state.cards
        

        if (rotated) {
            newCards[nr]["flashcardAnswer"] = clean
            this.setState({
                answer: clean,
                cards: newCards
            });
        } else {
            newCards[nr]["flashcardQuestion"] = clean
            this.setState({
                text: clean,
                cards: newCards
            });
        }

    }

    handleTheme = (event) => {
        const newFlashcards = Array.from(this.state.cards)

        newFlashcards.forEach((card) => {
            card.theme = event.target.value
        }) 
        
        this.setState({ theme: event.target.value })
    }

    handleType = (event) => {
        const {nr} = this.state
        const newFlashcards = Array.from(this.state.cards)

        newFlashcards[nr].type=event.target.value 
        
        this.setState({ 
            type: event.target.value,
            value: event.target.value 
        })
    }

    handleExamBoard = (event) => {
        const {nr} = this.state
        const examBoard=event.target.id 
        const newFlashcards = Array.from(this.state.cards)

        newFlashcards[nr][examBoard]=event.target.checked

        this.setState ({
            cards: newFlashcards,
            [examBoard] : event.target.checked
        })

    }

    getToggleFront () {
        const {graphCard} = this.state
        
        return (

            <div className="toggleFlashcardFormContainerPictureCard">
                <p className="flashcardFormPictureCardToggleLabel">No picture</p>
                <Toggle
                    checked={graphCard}
                    className="custom-classname"
                    icons={false}
                    onChange={this.togglePicture} />
                <p className="flashcardFormPictureCardToggleLabel">Picture</p>
            </div>
        )
    }
    
    getToggleBack () {
        const {graphCardBack} = this.state

        return (
            <div style={{display: "flex"}}>
                <p className="flashcardFormPictureCardToggleLabel">No picture</p>
                <Toggle
                    checked={graphCardBack}
                    className="custom-classname"
                    icons={false}
                    onChange={this.togglePictureBack} />
                <p className="flashcardFormPictureCardToggleLabel">Picture</p>
            </div>
        )
    }

    getPictureToggle () {
        const {rotated} = this.state

        if (rotated) {
            return (
                <select className="schoolDropdown" id="pictureBack" value={this.state.pictureBack} onChange={this.handlePictureToggle}>
                    <option value="no picture">No picture</option>
                    <option value="picture">Picture</option>
                    <option value="fullpicture">Full length picture</option>
                </select>
            )
        } else {
            return (
                <select className="schoolDropdown" id="pictureFront" value={this.state.pictureFront} onChange={this.handlePictureToggle}>
                    <option value="no picture">No picture</option>
                    <option value="picture">Picture</option>
                    <option value="fullpicture">Full length picture</option>
                </select>
            )
        }

    }

    handlePictureToggle = (event) => {
        const {nr, rotated} = this.state
        const newFlashcards = Array.from(this.state.cards)

        if (rotated) {
            newFlashcards[nr].pictureBack=event.target.value 
            
            this.setState({ 
                pictureBack: event.target.value,
                picture: event.target.value 
            })
        } else {
            newFlashcards[nr].pictureFront=event.target.value 
            
            this.setState({ 
                pictureFront: event.target.value,
                picture: event.target.value 
            })
        }

    }

    getTypeIdentifier () {
        const {type} = this.state
        let key1
        let key2
        if (type==="graph") {
            key1="bar_chart"
            key2="Graph"
        } else if (type==="definition") {
            key1="spellcheck"
            key2="Definition"
        } else if (type==="analysis") {
            key1="laptop"
            key2="Analysis"
        } else if (type==="calculation") {
            key1="euro"
            key2="Calculation"
        }


        // return (
        //     <div className="flashcardFormTypeIdentifierContainer">
        //         <i className="material-icons flashcardFormTypeIdentifier">{key1}</i>
        //         <p className="flashcardFormTypeIdentifier">{key2}</p>
        //     </div>
        // )
    }

    getExamBoards () {
        const examBoards=["Edexcel", "AQA", "OCR"]

        const containers = examBoards.map((examBoard, index) => {

            return (
                <div className="flashcardFormExamBoardContainer" key={`examBoard${index}`}>
                    <p className="flashcardFormExamBoardText">{examBoard}</p>
                    <input
                        className="flashcardFormCheckbox"
                        checked={this.state[examBoard]}
                        type="checkbox"
                        id={examBoard}
                        onChange={this.handleExamBoard}
                    />
                </div>
            )
        })

        return (
            <div>
                {containers}
            </div>
        )
    }

    getContainer () {
        const { rotated, editMode, pictureFront, pictureBack } = this.state
        
        let frontBack
        rotated 
        ? frontBack=pictureBack
        : frontBack=pictureFront

        
        if (editMode) {
            if (frontBack==="no picture") {
                return (
                    <div className="flashcardFormContainerOuterEditNoGraph">
                        {this.getTypeIdentifier ()}
                        {this.getTextEditContainerNoGraph()}
                    </div>
                )
            } else if (frontBack==="picture") {
                return (
                    <div className="flashcardFormGraphContainerOuter">
                        {this.getTypeIdentifier ()}
                        {this.getGraphEditContainer()}
                        {this.getTextEditContainerGraph()}
                    </div>
                )
            } else {
                return (
                    <div className="flashcardFormGraphContainerOuter">
                        {this.getTypeIdentifier ()}
                        {this.getGraphEditContainer()}
                    </div>
                )
            }
        }
    }

    toggleEditor = (editableText, editorPreviewText, editableElement ) => {
        this.setState ({
            showEditor: this.state.showEditor ? false : true,
            editableText: editableText,
            editorPreviewText: editorPreviewText,
            editableElement: editableElement

        })
    }
    
    //Handles changing of ALL elements for Maths, not just the question!
    onExternalEditorChangeQuestion = (inputText, cleanArray, editableElement) => {
        const { nr, focused } = this.state

        console.log(editableElement)

        var cardsNew = this.state.cards

        let option
        if (editableElement=="flashcardQuestion") {
            option = "text"
        } else {
            option = "answer"
        }


        cardsNew[nr][editableElement] = inputText
        this.setState({
            cards: cardsNew,
            editableText: cleanArray,
            editorPreviewText: inputText,
            [option]: inputText
        })

    }

    getRenderContainer () {
        const { text, url, pictureFront, rotated } = this.state
        const {subject} = this.props.profile
        
        if (pictureFront==="no picture") {
            return (
                <div className="flashcardFormTextNoGraphContainer">
                    {this.getTypeIdentifier()} 
                    <div className="flashcardFormTextNoGraphTextContainer">
                        {subject.includes("Maths") 
                            ? <EditorPreview 
                                question2={text}
                                style={"mathsExpositionPreviewContainer"}
                                textStyle={"mathsFlashcardText"}
                                toggleEditor={this.toggleEditor}
                                editableElement="flashcardQuestion"
                            />
                            : <p className="flashcardFormTextNoGraph" dangerouslySetInnerHTML={{__html: text}}></p>
                        }
                        
                    </div>
                </div>
            )
        } else if (pictureFront==="picture") {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainer">
                        {url
                            ? <img src={url} className="flashcardFormGraph" alt="graph" />
                            : <div className="flashcardFormImagePlaceholder"></div>
                        }
                    </div>
                    <div className={`flashcardFormBottomTextContainer ${rotated}`}>
                        {subject.includes("Maths") 
                            ? 
                            <EditorPreview 
                                question2={text}
                                style={"mathsExpositionPreviewContainer"}
                                textStyle={"mathsFlashcardTextPicture"}
                                toggleEditor={this.toggleEditor}
                                editableElement="flashcardQuestion"
                            />
                            :
                            <p className="flashcardFormTextGraph" dangerouslySetInnerHTML={{__html: text}}></p>
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainerLarge">
                        {url
                            ? <img src={url} className="flashcardFormGraphLarge" alt="graph" />
                            : <div className="flashcardFormImagePlaceholderLarge"></div>
                        }
                    </div>
                </div>
            )
        }
        
    }

    getRenderContainerBack () {
        const { answer, urlBack, pictureBack } = this.state
        const {subject} = this.props.profile
        
        if (pictureBack==="no picture") {
            return (
                <div className="flashcardFormTextNoGraphContainer">
                    {this.getTypeIdentifier()} 
                    <div className="flashcardFormTextNoGraphTextContainer">
                        {subject.includes("Maths") 
                            ? <EditorPreview 
                                question2={answer}
                                style={"mathsExpositionPreviewContainer"}
                                textStyle={"mathsFlashcardText"}
                                toggleEditor={this.toggleEditor}
                                editableElement="flashcardAnswer"
                            />
                            :
                            <p className="flashcardFormTextNoGraph" dangerouslySetInnerHTML={{__html: answer}}></p>
                        }
                    </div>
                </div>
            )
        } else if (pictureBack==="picture") {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainer">
                        {urlBack
                            ? <img src={urlBack} className="flashcardFormGraph" alt="graph" />
                            : <div className="flashcardFormImagePlaceholder"></div>
                        }
                    </div>
                    <div className={`flashcardFormBottomTextContainer`}>
                        {subject.includes("Maths") 
                            ? <EditorPreview 
                                question2={answer}
                                style={"mathsExpositionPreviewContainer"}
                                textStyle={"mathsFlashcardText"}
                                toggleEditor={this.toggleEditor}
                                editableElement="flashcardAnswer"
                            />
                            :
                            <p className="flashcardFormTextGraph" dangerouslySetInnerHTML={{__html: answer}}></p>
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className="flashcardFormGraphContainerOuter">
                    {this.getTypeIdentifier()}
                    <div className="flashcardFormGraphContainerLarge">
                        {urlBack
                            ? <img src={urlBack} className="flashcardFormGraphLarge" alt="graph" />
                            : <div className="flashcardFormImagePlaceholderLarge"></div>
                        }
                    </div>
                </div>
            )
        }
        
        
    }

    toggleSidebar = () => {
        const {sidebar} = this.state 
        this.setState ( {
            sidebar: sidebar==="" ? "display" : ""
        })

    }


    
        

    save = () => {
        const {series, cards} = this.state
        const { subject } = this.props.profile

        let flashcards=''
        if (subject==='Economics') {
            flashcards= 'flashcards'
        } else {
            flashcards= `flashcards${subject}` 
        }
        
        const newFlashcards = Array.from(this.state.cards)
        
        firebase.firestore().collection(flashcards)
            .where('flashcardSeries', '==', series)
            .get()
            .then(querySnapshot => {
                let seriesID=`${series}${(Math.random().toFixed(8)).substring(2,10)}`
                if (querySnapshot.docs.length===0) {
                    firebase.firestore().collection(flashcards)
                    .doc(seriesID)
                    .set(
                        {
                            cards: newFlashcards,
                            flashcardSeries: series,
                            school: this.props.profile.school,
                            student: this.props.auth.uid,
                            theme: this.state.theme,
                            createdAt: new Date(),   
                        }
                    ).then(()=> {
                        firebase.firestore().collection(flashcards)
                        .where('flashcardSeries', '==', seriesID)
                        .get().then(querySnapshot=> {
                            
                            this.setState({
                                newUrl: seriesID
                            })
                        })
                    })
                        // return <Redirect to={`/flashcardform/${seriesID}`}/>})
                    
                } else {
                    firebase.firestore().collection(flashcards).doc(this.props.match.params.id)
                    .update(
                        {
                            cards: cards,
                            flashcardSeries: series,
                            school: this.props.profile.school,
                            student: this.props.auth.uid,
                            theme: this.state.theme,
                            createdAt: new Date(),   
                        }
                    )

                    
                }
                
                
            })
        console.log("saved")
        var d = new Date()
        let minutes
        
        if (d.getMinutes()<10) {
            minutes=`0${d.getMinutes()}`
        } else {
            minutes=d.getMinutes()
        }

        this.setState({autosave: `Saved at ${d.getHours()}:${minutes}`})
    }


    render() {

        const { rotated, nr, total, series, 
            cards, editMode, theme, newUrl,
            slide, sidebar, imageWarning,
            renameFlashcards, autosave, categories,
            quiz, showEditor} = this.state

        const {auth} = this.props
        
        if (newUrl) {
            // window.location=`/flashcardform/${newUrl}`
            // return
            this.pushData(newUrl)
            return <Redirect to={`/flashcardform/${newUrl}`}/>
        }

        // if (this.props.profile && this.props.profile!=="admin") {
        //     return <Redirect to={`/flashcardformbeta/${this.props.match.params.id}`}/>
        // }

        let url
        if (auth.uid) {
            url= 'quizzes'
        } else {
            url= 'trialmcq'
        }

        

        return (
            
            <div className="flashcardFormContainer">

                {imageWarning
                ? <ImageWarning 
                    toggleImageWarning={this.toggleImageWarning} 
                    />
                : null
                }
                {renameFlashcards 
                ? <ChangeNamePopupFlashcards cards={cards} />
                : null
                }
                {showEditor
                ? 
                <Editor
                    toggleEditor={this.toggleEditor}
                    field="question"
                    cleanArray={this.state.editableText}
                    editorPreviewText={this.state.editorPreviewText}
                    onExternalEditorChangeQuestion={this.onExternalEditorChangeQuestion}
                    editableElement={this.state.editableElement} 
                />
                : 
                null
                }

                <div className="flashcardFormContainerLeft">
                    <div>
                        <div className="flashcardFormFlexContainer">
                            <div className="flashcardFormTitle">
                                <div className="innerflashcardFormContainerTitleText">
                                    {editMode ?
                                        <form className="titleFlashcardFormContainer">
                                            <input type="text" id="series" value={series}
                                                onChange={this.handleChange} />
                                        </form>
                                        :
                                        <div className="innerflashcardFormContainerTitleText">
                                            <p className="flashcardFormSeries ">{series}</p>
                                        </div>
                                    }
                                </div>
                                {quiz ?
                                <div className="flexColumnflashcardFormBetaQuizLink">
                                        <Link to={`/${url}/${quiz}`} className="bicep" >
                                            <p className="bicep">💪</p>
                                        </Link>
                                        <p className="bicepBetaLabel">Go to quiz</p>
                                </div>
                                : null
                                }
                            </div>
                            <div className="flashcardFormToggleContainerFlex">
                                <div className="toggleFlashcardFormContainer">
                                    <div className="toggleFlashcardFormContainer50Left">
                                        <Toggle
                                            defaultChecked={this.state.editMode}
                                            icons={false}
                                            onChange={this.changeView} />
                                        {editMode ? <p className="toggleViewRightFlashcardForm">Editing</p> :
                                            <p className="toggleViewRightFlashcardForm">Edit</p>}
                                    </div>
                                    <div className="toggleFlashcardFormContainer50Right">
                                        <div className="rotateButton">
                                            <div className="btn-floating flashcardFormSave" onClick={this.save}>
                                                <i className="material-icons">save</i>

                                            </div>
                                            <div className="flashcardFormSaveTextContainer">
                                                <p className="rotateCard"> Save</p>
                                                {autosave ?
                                                    <p className="flashcardFormAutosaveText">{autosave}</p>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="flashcardFormContainerLeftMiddle">

                        <div className="flashcardFormMiddleFlexContainer">
                            <div className="flashcardFormContainer2" onClick={this.rotate}>
                                <div className={`theCardForm ${rotated} ${slide}`}>
                                    <div className="flashcardFormFront">
                                        <div className="innerflashcardFormContainer">
                                            {editMode ?
                                                this.getContainer()
                                                :
                                                this.getRenderContainer()
                                            }
                                        </div>
                                    </div>

                                    <div className="flashcardFormBack">
                                        <div className="innerflashcardFormContainer">
                                            {editMode ?
                                                this.getContainer()
                                                :
                                                this.getRenderContainerBack()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flashcardFormButtonArrowContainer">
                                    <div className="flashcardNav">
                                        {nr === 0 ?
                                            <div className="btn-floating grey"><i className="material-icons">skip_previous</i></div>
                                            :
                                            <div className="btn-floating" onClick={this.previousCardNav}><i className="material-icons">skip_previous</i></div>}
                                        <p className="flashcardNavPagination">{total ? `${nr + 1} / ${total}` : null}</p>
                                        {nr + 1 === total ?
                                            <div className="btn-floating grey"><i className="material-icons">skip_next</i></div>
                                            :
                                            <div className="btn-floating" onClick={this.nextCard}><i className="material-icons">skip_next</i></div>}
                                    </div>
                                    <div className="flashcardFormMenuButton">
                                        <div className="btn-floating white" onClick={this.toggleSidebar}>
                                            <i className="material-icons menu">menu_open</i>
                                        </div>
                                    </div>
                                </div>
                        </div>

                        {editMode
                        ? 
                            <div className="flashcardFormTypeSelectionContainer">
                                <div className="flashcardFormTypeSelectionContainerAlignCentre">
                                    
                                    {editMode ?
                                        <div>
                                            {/* <div className="flashcardFormExamBoardContainerVerticalFlex">
                                                {this.getExamBoards()}
                                            </div> */}
                                            {this.getPictureToggle()}
                                            {/* <select className="schoolDropdown" id="type" value={this.state.value} onChange={this.handleType}>
                                                <option value="graph">Graph</option>
                                                <option value="definition">Definition</option>
                                                <option value="analysis">Analysis</option>
                                                <option value="calculation">Calculation</option>
                                            </select> */}

                                            <div className="rotateButtonEdit">
                                                <button className="btn-floating rotateButtonEdit" onClick={this.editRotate}><i className="material-icons waves-effect waves-circle waves-light">flip_camera_ios</i></button>
                                                <p className="rotateCard">Rotate card</p>
                                            </div>
                                            
                                        </div> 
                                        : null
                                    }
                                </div>
                            </div>
                        : null
                        }
                        
                    </div>








                </div>
                <div className={`flashcardFormContainerRight ${sidebar}`}>
                    {editMode
                        ?
                            this.props.profile.school==="GradeIt"
                                ?
                                <div className="flashcardFormThemeContainerEdit">
                                    <i className="material-icons close" onClick={this.toggleSidebar}>highlight_off</i>
                                    <p className="flashcardFormSelectTheme">Category</p>
                                    <select className="flashcardFormThemeDropdown" id="theme" value={this.state.theme} onChange={this.handleTheme}>
                                                {categories.map(category => {
                                                    return (<option value={category}>{category}</option>)
                                                })}
                                    </select>
                                </div>
                                :
                                null
                            
                        :   this.props.profile.school==="GradeIt"
                            ?
                                <div>
                                    <i className="material-icons close" onClick={this.toggleSidebar}>highlight_off</i>
                                    <div className="flashcardFormThemeContainer">
                                        <p className="flashcardFormThemeContainerText">{theme}</p>
                                    </div>
                                </div>
                            : null
                        
                    }


                    <div className="droppableArea">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Column key="0" cards={cards} column="0" flashcardSelect={this.flashcardSelect} removeQuestion={this.removeQuestion} />
                        </DragDropContext>
                    </div>



                    <div className="btn-floating flashcardFormAddQuestion" onClick={this.addQuestion}>
                        <i className="material-icons">add</i>
                    </div>
                </div>

            </div>


        );
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         createScore: (score, total, quizName, school, firstName, lastName) => dispatch(createScore(score, total, quizName, school, firstName, lastName))
//     }
// }

export default connect(mapStateToProps)(FlashcardForm)
