import React, { Component } from 'react'
import { connect } from 'react-redux'
import {signIn} from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import firebase from '../auth/auth'
import './signin.css'

export class Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            emailSent: false,
        }
        this.toggleState = this.toggleState.bind(this)
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    toggleState () {
        var auth = firebase.auth();
        var emailAddress = this.state.email;
        auth.sendPasswordResetEmail(emailAddress).then(() => {
            this.setState({emailSent: true})
            console.log("Password reset email sent")
        }).catch(function(error) {
            // An error happened.});
            console.log(error)
        })
    }

    render() {
        const { auth } = this.props;
        if (auth.isLoaded && auth.uid) return <Redirect to='/' />

        return (
            <div className="container">
                <form className="white">
                    <div className="signInHeader">
                        <h5 className="grey-text text-darken-3">Recover password</h5>
                    </div>
                    <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <p onClick={this.toggleState} className="btn pink lighten-1 z-depth-0">Reset password</p>
                        <div className="green-text center">
                            { this.state.emailSent ? <p>Password reset has been sent. Please check your email inbox</p> : null }
                        </div>
                    </div>

                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Password)
