import React, { Component } from 'react'
import Collapsible from 'react-collapsible';

import './quizview.css'

class Quizview extends Component {
    state = {}

    constructor(props) {
        super(props);
        this.mapQuizzes = this.mapQuizzes.bind(this);
    }

    getColor(value) {
        if (value >= 66) {
            return "green"
        } else if (value > 25 && value < 66) {
            return "#cc5200"
        } else {
            return "red"
        }
    }

    // Each student object in the studentArray object was structured name: data.
    mapQuizzes([category, quizList], index) {
        //We need this because the data object is an empty array when the props are 
        //not populated. This converts the empty array into an empty object for 
        //consistency, though this is not actually used.
        /*const categories = Object.entries(student.studentProgress || [])
            .filter(([category, quizList]) => typeof quizList === 'object');*/

        const { students } = this.props;
        let subjectString = ""
        if (this.props.subject!=="Economics") {
            subjectString = this.props.subject
        }
        console.log(subjectString)

        // const { totalAttempts, totalQuizzes } = student;
        const completionPercentage = 0 // this.calculateCompletion(totalAttempts, totalQuizzes);
        const completeList = [] // categories.reduce((completeList, [, quizList]) => [...completeList, ...quizList], [])
        const averageAccuracy = 0 // this.calculateAccuracy(completeList);

        // console.log({categories, completeList , averageAccuracy})

        //console.log({ student })

        let completionColor = this.getColor(0)
        let accuracyColor = this.getColor(0)

        const studentTrigger =
            <div className="studentName">
                <p className="studentNameText">{category}</p>
                {/* Can add quiz completion and quiz accuracy */}
                {/*<p className="completion" style={{ color: completionColor }}>Completion {completionPercentage}%</p>
                <p className="accuracy" style={{ color: accuracyColor }}>Accuracy {averageAccuracy}%</p>*/}
            </div>

        return (
            <div key={index} className="view">
                <Collapsible trigger={studentTrigger} className="student">

                    {/* The data object is structured {themename:attributes} */}
                    {/* {Object.entries(student).map(([themename, attributes], index) => {*/}
                    {quizList.map(({ quizName }, index) => {
                        const completionPercentage = 0 /* this.calculateCompletion(
                            quizList.filter(({ quizScore }) => quizScore && quizScore > 0).length,
                            quizList.length
                        );*/

                        const accuracy = 0 // this.calculateAccuracy(quizList);

                        let themeCompletionColor = this.getColor(0)
                        let themeAccuracyColor = this.getColor(0);


                        let themeTrigger =
                            <div className="themeName">
                                <p className="themeNameText">{quizName}</p>
                                {/* <p className="completion">Completion {completionPercentage}%</p> */}
                                {/* Can add quiz completion and quiz accuracy */}
                                {/*<p className="themeAccuracy" style={{ color: themeAccuracyColor }}>Accuracy {accuracy} %</p>
                                <p className="themeCompletion" style={{ color: themeCompletionColor }}>Completion {completionPercentage} %</p>*/}
                            </div>
                        return (
                            <div key={index}>
                                <Collapsible trigger={themeTrigger}>
                                    <ul className="quizAttemptsContainer">
                                        {/* The attributes object is structured {quizName: } */}
                                        {students.map((student, index) => {
                                            const { name, studentProgress } = student;

                                        // console.log(subjectString+'studentProgress')

                                            const quizScore = studentProgress ? studentProgress[quizName] || 0 : 0;
                                            let quizAccuracyColor = this.getColor(0);


                                            if (student.studentProgress) {
                                                return (
                                                    <div className="questionAttempts" key={`questionAttempts${index}`}>
                                                        <li className="classViewQuestion" key={`classViewQuestion${index}`}>{name}</li>
                                                        <li className="classViewAttempt" style={{ color: quizAccuracyColor }} key={`classViewAttempt${index}`}>{quizScore}%</li>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </ul>
                                </Collapsible >
                            </div>
                        )
                    })}
                </ Collapsible>
            </div>
        );
    }

    onSortChange = (event) => {
        this.setState({ sort: event.target.value })
    }

    getData() {
        if (!this.state.sort) {
            return Object.entries(this.props.quizzes);
        }

        const sort = {};

        if (this.state.sort.match(/Accuracy/)) {
            sort.attr = 'averageAccuracy';
        } else {
            sort.attr = 'completion';
        }

        if (this.state.sort.match(/Asc/)) {
            sort.order = 1;
        } else {
            sort.order = -1;
        }

        return this.props.quizzes.sort((studentA, studentB) => {
            return (studentA[sort.attr] || 0) > (studentB[sort.attr] || 0) ? sort.order : -sort.order;
        });
        
    }

    render() {
        const { students, quizzes, loading } = this.props;
        

        // console.log('sorted', Object.keys(sortedByAccuracy || {}))

        return (
            <div className="teacherDashStudentDataContainer quizView">

                {/* The code below waits for the studentArray props to be populated.
                It will iterate through each student in the studentArray object,
                passing down each student object into the mapStudent function. */}
                {quizzes && students &&
                    this.getData().map(this.mapQuizzes)}

                {this.props.individualUser ?
                    <div className="flexColumnTeacherData">
                        <div className="classviewPlaceholder">
                            <p className="noStudentsTextPlaceholder">No student data available to individual users</p>
                        </div>
                        
                    </div>
                : null
                }
            </div>
        )
    }
}

export default Quizview