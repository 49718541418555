
import { createScore } from '../../store/actions/quizscoreActions'
// import './newQuizDialog.scss'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import firebase from '../auth/auth'
import Toggle from 'react-toggle'
import Spinner from '../spinner/spinner'
import StudentData from './studentData'
import './studentFeed.scss';




class StudentFeed extends React.Component {
    constructor(props) {
        super(props);
        
        this.state= {
            testsArray:[],
            flagsArray:[],
            loaded: false
        }
    }

    componentDidMount () {
        this.getFeed()
    }
    
    getFeed() {
            firebase.firestore().collection('schools').where('school','==', this.props.profile.school)
            .get()
            .then(snapshot => {
                const feedData = snapshot.docs[0].data().schoolFeedArray
                const studentData = feedData.filter(feed => feed.user == this.props.userId)

                this.setState({
                    studentData: studentData ? studentData.reverse() : []},
                    ()=>{this.setState({loading:false})
                })
            })
    }

    toggleContainerWidth = (entry) => {

            this.setState({
                fullWidth: entry,
                displayConfig: true,
                loaded: true,                
            })
    }
    
    render() {
       const {studentData, fullWidth, loaded} = this.state

       let dynamicClassName

       if (fullWidth) {
            dynamicClassName = "studentFeedContainer centre"
       } else {
            dynamicClassName = "studentFeedContainer"
       }
        
        return (

            <div className="popup-container">      
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupStudentFeed">
                            <div className="btn-floating closePopup studentFeed" onClick={this.props.toggleShowFeed}><i className="material-icons">close</i></div>
                            <div className="flexRowStudentFeed">
                                <StudentData 
                                    userId={this.props.userId}
                                    toggleContainerWidth={this.toggleContainerWidth}
                                    loaded={loaded} 
                                />
                                {loaded ?
                                <div className={dynamicClassName}>
                                    <div className="teacherFeedTitleContainer">
                                        <p className="feedTitleStudentFeed">Latest activity</p>
                                        <i className="material-icons closeStudentFeed" onClick={this.props.toggleShowFeed}>close</i>
                                    </div>
                                    <div className="teacherFeedFeedContainer">
                                        {studentData && studentData.map(entry => {
                                            if (entry.type==='quiz') {
                                            return (
                                                <div className="flexRow studentFeed" key={entry.quizName+entry.createdAt}>
                                                    <div className="btn-floating teacherFeed popup">
                                                    <Link to={entry.pathname} className="teacherFeedLink"><i className="material-icons">create</i></Link>
                                                    </div>
                                                    <div className="teacherFeedTextContainer">
                                                        <p className="teacherFeedMain">{entry.firstName} {entry.lastName} scored {entry.percent}% on <span><Link to={entry.pathname}>{entry.quizName}</Link></span> </p>
                                                        <p className="teacherFeedSecondary">{new Date(entry.createdAt.seconds*1000).toLocaleString()}</p>
                                                    </div>                              
                                                </div>                        
                                            )
                                            } else if (entry.type==='flag') {
                                                return (
                                                    <div className="flexRow studentFeed" key={entry.quizName+entry.createdAt}>
                                                        <div className="btn-floating teacherFeed popup">
                                                            {this.props.flags 
                                                            ? <Link to='/flagged' className="teacherFeedLink"><i className="material-icons">flag</i></Link>
                                                            : 
                                                            <div className="teacherFeedLink" onClick={this.props.toggleFlagPopup}>
                                                                <i className="material-icons">flag</i>
                                                            </div>
                                                            }
                                                        </div>
                                                        <div className="teacherFeedTextContainer">
                                                            {this.props.flags
                                                                ? <p className="teacherFeedMain"> {entry.firstName} {entry.lastName} flagged a question on <span><Link to='/flagged'>{entry.quizData.quizName}</Link></span> </p>
                                                                : <p className="teacherFeedMain"> {entry.firstName} {entry.lastName} flagged a question on <span className='teacherFeedLink' onClick={this.props.toggleFlagPopup}>{entry.quizData.quizName}</span> </p>
                                                            }
                                                            <p className="teacherFeedSecondary">{new Date(entry.createdAt.seconds*1000).toLocaleString()}</p>
                                                        </div>                              
                                                    </div>
                                                )
                                            }
                                        }
                                        )}
                                        
                                        
                                    </div>
                                </div>
                                :
                                <Spinner />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}
    
export default connect(mapStateToProps)(StudentFeed)