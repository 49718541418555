import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom'
import { submitQuestion } from '../../store/actions/mcqFormAction'
import firebase from '../auth/auth'
import QuizDropdown from './quizDropdown'
import Collapsible from 'react-collapsible';
import FatFinger from './mcqFormFatFinger'
import { Redirect } from 'react-router-dom'
import {storage} from '../auth/auth';
import './mcqForm.css'

export class MCQForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quizzes: [],
            image: null,
            url: "",
            progress: 0,
            graphQuestion: "no",
            formComplete: "no",
            answer1: null, 
            answer2: null,
            answer3: null, 
            answer4: null,
            correct: null,
            exposition1: null,
            exposition2: null,
            exposition3: null,
            exposition4: null,
            questionText: null,
            quizName: null,
            preview: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
        this.getQuizzes();
    }

    getQuizzes() {
        firebase.firestore().collection('quizzes')
            .get()
            .then(querySnapshot => {
                const result = querySnapshot.docs.map(doc => doc.data());
                // console.log(result)
                const quizzes = [];

                result.forEach(quiz => {
                    const match = quiz.quizCategory.match(/Theme (\d+)/);

                    if (!match) return;

                    // Converts the string to number with +
                    const themeNumber = +match[1];

                    // Gets index (for example, the index for Theme 1 is 0, Theme 4 is 3)
                    const index = themeNumber - 1;

                    // inits list
                    quizzes[index] = quizzes[index] || [];

                    // Adds the quizz
                    quizzes[index].push(quiz);
                });

                this.setState({ quizzes })
            })
            
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
        const { answer1, answer2, answer3, answer4,
            correct, exposition1, exposition2,
            exposition3, exposition4, questionText,
            quizName, graphQuestion, url, difficulty} = this.state

            if ((answer1 && answer2 && answer3 && answer4 && exposition1 && 
                exposition2 && exposition3 && exposition4
                && quizName && questionText && correct && difficulty && graphQuestion==="no") ||
                (answer1 && answer2 && answer3 && answer4 && exposition1 && 
                    exposition2 && exposition3 && exposition4
                    && quizName && questionText && correct && difficulty && graphQuestion==="yes" && url)) {
                    this.setState ({formComplete: "yes"})
                } else {
                    this.setState ({formComplete: "no"})
                }
        
    }


    handleImage = e => {
        if (e.target.files[0]) {
          const image = e.target.files[0];
          this.setState(() => ({image}));
        }
    }

    handleUpload = () => {
        const {image} = this.state;
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        uploadTask.on('state_changed', 
        (snapshot) => {
          // progrss function ....
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.setState({progress});
        }, 
        (error) => {
             // error function ....
          console.log(error);
        }, 
      () => {
          // complete function ....
          storage.ref('images').child(image.name).getDownloadURL().then(url => {
              
              this.setState({url});
          })
      }
      );
    }

    handleChangeNumber = (e) => {
        this.setState({
            [e.target.id]: parseInt(e.target.value)
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.submitQuestion(this.state)
    }

    togglePreview = () => {
        this.setState ({preview:true})
    }

    render() {
        const { auth } = this.props;
        // const { authError } = this.props;
        const { graphQuestion, formComplete, preview } = this.state;
        
        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />

        if (!preview) {
            return (
                <div className="container">
                    <form onSubmit={this.handleSubmit} className="white">
                        <h5 className="grey-text text-darken-3">Add question</h5>
                        <div className="input-field">
                            <label className="graphQuestionLabel" htmlFor="graphQuestion"> Graph question: </label>
                            <br/>
                            <select className="graphQuestion" id="graphQuestion" value={this.state.value} onChange={this.handleChange}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                            </select>
                        </div>
                        {graphQuestion==="yes" ? <div className="input-field">
                            <input className="chooseFile" type="file" onChange={this.handleImage} />
            {this.state.image ? <p className="uploadButton" onClick={this.handleUpload}>Upload</p> : <p className="uploadButtonGreyed" onClick={this.handleUpload}>Upload</p> }
                            <br/>
                            <progress value={this.state.progress} max="100"/>
                            <br/>
                            <img src={this.state.url || 'http://via.placeholder.com/300x300'} alt="Uploaded images" height="300" width="300"/> 
                        </div> : null}
                        <div className="input-field">
                            <label className="graphQuestionLabel" htmlFor="difficulty">Difficulty</label>
                            <br/>
                            <select className="graphQuestion" id="difficulty" value={this.state.value} onChange={this.handleChange}>
                                <option value="Default">Please choose the difficulty level</option>
                                <option value="1">Easy</option>
                                <option value="2">Medium</option>
                                <option value="3">Hard</option>
                            </select>
                        </div>
                        <div className="input-field">
                            <label htmlFor="questionText">Type your question here...</label>
                            <input type="text" id="questionText" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="answer1">Answer 1</label>
                            <input type="text" id="answer1" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="answer2">Answer 2</label>
                            <input type="text" id="answer2" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="answer3">Answer 3</label>
                            <input type="text" id="answer3" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="answer4">Answer 4</label>
                            <input type="text" id="answer4" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label className="graphQuestionLabel" htmlFor="correct">Correct Answer?</label>
                            <br/>
                            <select className="graphQuestion" id="correct" value={this.state.value} onChange={this.handleChangeNumber}>
                                <option value="Default">Please choose the correct answer</option>
                                <option value="1">Answer 1</option>
                                <option value="2">Answer 2</option>
                                <option value="3">Answer 3</option>
                                <option value="4">Answer 4</option>
                            </select>
                        </div>
                        <div className="input-field">
                            <label htmlFor="exposition1">Exposition 1</label>
                            <input type="text" id="exposition1" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="exposition1">Exposition 2</label>
                            <input type="text" id="exposition2" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="exposition3">Exposition 3</label>
                            <input type="text" id="exposition3" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="exposition4">Exposition 4</label>
                            <input type="text" id="exposition4" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <label htmlFor="quizName">Quiz Name?</label>
                            <input type="text" id="quizName" onChange={this.handleChange}/>
                        </div>
                        {/* <div>
                            {this.state.quizzes.map((quiz, index) => (
                                <div className="quizLandingFormContainer">
                                    <Collapsible trigger={`Theme ${index+1}`}>
                                        <div className="categoryContainers">
                                            <QuizDropdown
                                                quizzes={quiz}
                                                quizAttempts={this.state.quizAttempts}
                                                highScores={this.state.highScores} />
                                        </div>
                                    </Collapsible>
                                </div>
                            ))}
                        </div> */}
                        <div className="submitDiv">
                            { formComplete==="yes" ? <button className="btn pink lighten-1 z-depth-0 waves-effect waves-light">Submit</button> : 
                            <p className="greyedOutButton">Submit </p>}
                            {/* { formComplete==="yes" ? <p className="btn pink lighten-1 z-depth-0" onClick={this.togglePreview}>Preview</p> : 
                            <p className="greyedOutButton">Preview </p>} */}
                        </div>
                        <div className="red-text">
                            { formComplete==="no" ? <p className="warning">You must fill out all fields before you submit. Upload an image for graph questions.</p> : null}
                        </div>

                    </form>
                </div>
            )
        } else {
            return (
                <FatFinger data={this.state} />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitQuestion: (question) => dispatch(submitQuestion(question))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MCQForm)
