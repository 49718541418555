import React, { Component } from 'react';
import { Circle } from 'rc-progress';
import firebase from '../auth/auth';
import {connect} from 'react-redux';
import { Switch, Route } from "react-router-dom";

import './xp.scss';


class XpBar extends Component {
    constructor() {
        super();
        this.state = {
            level: 1,
            lastRequiredXp: 0,
            requiredXp: 50,
            zeroPercent: 0,
            avatar: ""
        };
        this.checkLevel = this.checkLevel.bind(this);
        this.increaseProgress = this.increaseProgress.bind(this);
    }

    componentDidMount() {
        this.checkLevel();
        this.increaseProgress();
    }

    increaseProgress() {
        let subjectString = ""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }

        let progressXp;
        if (this.state.level === 6){
            progressXp = 100;
        } else { 
            progressXp = Math.round((this.props.profile[`${subjectString}xp`] - this.state.lastRequiredXp) / (this.state.requiredXp - this.state.lastRequiredXp) * 100);
        }

        const newPercent = this.state.zeroPercent + 1;

        if (newPercent >= progressXp + 1) {
            clearTimeout(this.tm);
            return;
        }

        if (progressXp !== 0) {
            this.setState({
                zeroPercent: newPercent
            })
        }

        this.tm = setTimeout(this.increaseProgress, 10);
    }

    checkLevel() {
        let subjectString = ""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }



        const powellPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fjerome_powell.png?alt=media&token=802c4a84-5705-4a5a-8985-2a47ce599b9d";
        const lagardePic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fchristine_lagarde.png?alt=media&token=c1c4b35a-2622-4b6a-bfe6-ffbe5a71443f";
        const baileyPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fandrew_bailey.png?alt=media&token=ab8d1fa8-8bc8-4080-86a8-e963521556a9";
        const buffettPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fwarren_buffett.png?alt=media&token=e35ea2cf-48e3-4f8d-84f0-fe868aff6bef";
        const sunakPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Frishisunak2.png?alt=media&token=e51a4f8c-104d-43ff-9600-189493d94774";
        
        const attenboroughPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FDavid%20Attenborough.png?alt=media&token=dea48d0c-0d5a-4b14-bbc9-d9b8757d9da5"
        const darwinPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FCharles%20Darwin.png?alt=media&token=53aabff3-5d22-4e6c-ae3d-a15f50fc32d3"
        const lamarkePic ="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FJean%20Baptiste%20Lamark.png?alt=media&token=4c79d787-f1fd-4eaf-b7f4-34ae2921900e"
        const mendelPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FGregor%20Mendel.png?alt=media&token=91de7689-fb31-4186-929f-b59f7270c9c1"
        const mariePic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FMarie%20Curie.png?alt=media&token=7c74e345-fb65-4576-8b66-54b409eb5cb3"

        const rutherfordPic ="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2FErnest%20Rutherford.png?alt=media&token=20f77fc2-2d83-4096-ae57-b133ac5d2b0e"
        const faradayPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2FMichael%20Faraday.png?alt=media&token=6e6cefb9-ac02-45be-8154-46848fc8a932"
        const galileoPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2Fgallelio.png?alt=media&token=85757962-52c4-47f1-8049-cc8268db140c"
        const einsteinPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2Feinstien.png?alt=media&token=1950a6af-86ab-48a5-8a9a-fe26f9d7d3f5"
        const newtonPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2FIssac%20Newton.png?alt=media&token=5edba455-80b0-4a20-981a-642fedef10dd"

        const nobelPic= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FAlfred%20Nobel.png?alt=media&token=0d415535-0ea2-4986-b99f-eca6981958bb"
        const avogadroPic= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FAvogadro.png?alt=media&token=6732e488-8e46-4bd1-861a-8fbdbc46bfbd"
        const mendeleevPic= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FMendeleev.png?alt=media&token=710a393c-67bf-4ca3-84f7-9e8754d53e9c"

        let level1pic
        if (this.props.profile.subject==="Economics") {
            level1pic = baileyPic
        } else if (this.props.profile.subject.includes("Biology")) {
            level1pic = lamarkePic
        } else if (this.props.profile.subject.includes("Physics")) {
            level1pic = rutherfordPic
        } else if (this.props.profile.subject.includes("Chemistry")) {
            level1pic = rutherfordPic
        }

        let level2pic
        if (this.props.profile.subject==="Economics") {
            level2pic = sunakPic
        } else if (this.props.profile.subject.includes("Biology")) {
            level2pic = mendelPic
        } else if (this.props.profile.subject.includes("Physics")) {
            level2pic = faradayPic
        } else if (this.props.profile.subject.includes("Chemistry")) {
            level2pic = mariePic
        }

        let level3pic
        if (this.props.profile.subject==="Economics") {
            level3pic = lagardePic
        } else if (this.props.profile.subject.includes("Biology")) {
            level3pic = attenboroughPic
        } else if (this.props.profile.subject.includes("Physics")) {
            level3pic = galileoPic
        } else if (this.props.profile.subject.includes("Chemistry")) {
            level3pic = nobelPic
        }

        let level4pic
        if (this.props.profile.subject==="Economics") {
            level4pic = buffettPic
        } else if (this.props.profile.subject.includes("Biology")) {
            level4pic = mariePic
        } else if (this.props.profile.subject.includes("Physics")) {
            level4pic = einsteinPic
        } else if (this.props.profile.subject.includes("Chemistry")) {
            level4pic = avogadroPic
        }

        let level5pic
        if (this.props.profile.subject==="Economics") {
            level5pic = powellPic
        } else if (this.props.profile.subject.includes("Biology")) {
            level5pic = darwinPic
        } else if (this.props.profile.subject.includes("Physics")) {
            level5pic = newtonPic
        } else if (this.props.profile.subject.includes("Chemistry")) {
            level5pic = mendeleevPic
        }



        // Determine static level 
        if (this.props.profile[`${subjectString}xp`] >= 50 && this.props.profile[`${subjectString}xp`] <= 1000){
            this.setState({
                level: 2,
                requiredXp: 1000,
                lastRequiredXp: 50,
                avatar: level1pic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 1000 && this.props.profile[`${subjectString}xp`] <= 2800){
            this.setState({
                level: 3,
                requiredXp: 2800,
                lastRequiredXp: 1000,
                avatar: level2pic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 2800 && this.props.profile[`${subjectString}xp`] <= 5400){
            this.setState({
                level: 4,
                requiredXp: 5400,
                lastRequiredXp: 2800,
                avatar: level3pic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 5400 && this.props.profile[`${subjectString}xp`] <= 8800){
            this.setState({
                level: 5,
                requiredXp: 8800,
                lastRequiredXp: 5400,
                avatar: level4pic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 8800){
            this.setState({
                level: 6,
                requiredXp: 13000,
                lastRequiredXp: 8800,
                avatar: level5pic
            })
        } 
    }

    
    render() {
        let subjectString = ""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }

        let progressXp;
        if (this.state.level === 6){
            progressXp = 100;
        } else { 
            progressXp = Math.round((this.props.profile[`${subjectString}xp`] - this.state.lastRequiredXp) / (this.state.requiredXp - this.state.lastRequiredXp) * 100);
        }
        let { currentXp, percentXp, liveLevel, liveRequiredXp, liveLastRequiredXp, streak } = this.props;
        
        //Color circle differently based on how full it is (other options are gradient or same color throughout)
        let color
        if (this.state.zeroPercent<=25) {
            color="#f0f0f5"
        } else if (this.state.zeroPercent>25 && this.state.zeroPercent<=55) {
            color="#a6a5c0"
        } else if (this.state.zeroPercent>56 && this.state.zeroPercent<=80) {
            color="#7978a1"
        } else {
            color="#525174"
        }

        let liveColor
        if (percentXp<=25) {
            liveColor="#f0f0f5"
        } else if (percentXp>25 && percentXp<=55) {
            liveColor="#a6a5c0"
        } else if (percentXp>56 && percentXp<=80) {
            liveColor="#7978a1"
        } else {
            liveColor="#525174"
        }

        let activeStreak
        if (streak!=0) {
            activeStreak= "btn-floating pulse streakContainer"
        } else {
            activeStreak= "noStreakContainer"
        }

        return ( 
            <Switch>
                <Route exact path="/">
                    <div className="xpContainerDashboard">
                        <div className="xpBarDashboard">
                            <div className="dashboardXpCircleContainer"> 
                                <Circle
                                    className={"circleXpBarDashboard"}
                                    percent={`${this.state.zeroPercent}`}
                                    strokeWidth="8"
                                    trailWidth='8'
                                    strokeLinecap="round"
                                    strokeColor={color} />
                                {this.state.level === 6
                                ?
                                <div className="circleXpLabelDashboard">
                                    <p className="xpProgressDashboardBoldMax"> MAX </p>
                                </div>
                                : 
                                <div className="circleXpLabelDashboard">
                                    <p className="xpProgressDashboardBold"> {this.state.requiredXp - this.props.profile[`${subjectString}xp`]} XP </p>
                                    <p className="xpProgressDashboard"> required</p>
                                </div>
                                }
                            </div>
                            <div className="levelInfoDashboard">
                                <p className="levelTextDashboard"> Level {this.state.level} </p>
                                {this.state.level !== 6  ?
                                <p className="xpTextDashboard"> {this.props.profile[`${subjectString}xp`] - this.state.lastRequiredXp} XP</p>
                                :
                                <p className="xpTextDashboard"> {this.props.profile[`${subjectString}xp`]} XP</p>
                                }
                            </div> 
                            <div className="avatarDashboard">
                                <img src={this.state.avatar} className="responsive-img dashAvatar" />
                            </div>
                        </div>
                    </div>
                </Route> 
                <Route path="/quizzes">
                    <div className="xpContainerMcq">
                        <div className="xpBarMcqGraph">
                            <div className="mcqXpCircleContainer"> 
                                {currentXp > this.props.profile[`${subjectString}xp`] 
                                ?  
                                <Circle 
                                className={"circleXpBarMcq"}
                                percent={`${percentXp}`}
                                strokeWidth="12"
                                trailWidth='12'
                                strokeLinecap="round"
                                strokeColor={liveColor} />
                                :
                                <Circle 
                                className={"circleXpBarMcq"}
                                percent={`${this.state.zeroPercent}`}
                                strokeWidth="12"
                                trailWidth='12'
                                strokeLinecap="round"
                                strokeColor={liveColor} />
                                }
                            </div>
                            {liveLevel === 6
                            ?
                            <div className="levelInfoMcq">
                                <p className="levelTextMcq"> LEVEL {liveLevel} </p> 
                                <p className="xpTextMcq"> {currentXp} XP </p>
                            </div>
                            :
                            <div className="levelInfoMcq">
                                <p className="levelTextMcq"> LEVEL {liveLevel} </p> 
                                <p className="xpTextMcq"> {liveRequiredXp - currentXp} XP required</p>
                            </div>
                            }
                        </div>

                        <div className="blueStreakContainerMcq">
                            <div className="gamificationContainerXpBar">
                            <div className={activeStreak}>
                                <i className="material-icons fire">whatshot</i>
                            </div>
                                <p className="mcqStreak">{streak} in a row</p>
                            </div>
                        </div>
                    </div>
                </Route>
                <Route path="/quizzesschool">
                    <div className="xpContainerMcq">
                        <div className="xpBarMcqGraph">
                            <div className="mcqXpCircleContainer"> 
                                {currentXp > this.props.profile[`${subjectString}xp`] 
                                ?  
                                <Circle 
                                className={"circleXpBarMcq"}
                                percent={`${percentXp}`}
                                strokeWidth="12"
                                trailWidth='12'
                                strokeLinecap="round"
                                strokeColor={liveColor} />
                                :
                                <Circle 
                                className={"circleXpBarMcq"}
                                percent={`${this.state.zeroPercent}`}
                                strokeWidth="12"
                                trailWidth='12'
                                strokeLinecap="round"
                                strokeColor={liveColor} />
                                }
                            </div>
                            {liveLevel === 6
                            ?
                            <div className="levelInfoMcq">
                                <p className="levelTextMcq"> LEVEL {liveLevel} </p> 
                                <p className="xpTextMcq"> {currentXp} XP </p>
                            </div>
                            :
                            <div className="levelInfoMcq">
                                <p className="levelTextMcq"> LEVEL {liveLevel} </p> 
                                <p className="xpTextMcq"> {liveRequiredXp - currentXp} XP required</p>
                            </div>
                            }
                        </div>

                        <div className="blueStreakContainerMcq">
                            <div className="gamificationContainerXpBar">
                                <div className={activeStreak}><i className="material-icons fire">whatshot</i></div>
                                <p className="mcqStreak">{streak} in a row</p>
                            </div>
                        </div>

                    </div>
                </Route>
                {/* <Route path="/bookmarks">
                    <div className="xpContainerMcq">
                        <div className="xpBarMcqGraph">
                            <div className="mcqXpCircleContainer"> 
                                {currentXp > this.props.profile[`${subjectString}xp`] 
                                ?  
                                <Circle 
                                className={"circleXpBarMcq"}
                                percent={`${percentXp}`}
                                strokeWidth="12"
                                trailWidth='12'
                                strokeLinecap="round"
                                strokeColor={liveColor} />
                                :
                                <Circle 
                                className={"circleXpBarMcq"}
                                percent={`${this.state.zeroPercent}`}
                                strokeWidth="12"
                                trailWidth='12'
                                strokeLinecap="round"
                                strokeColor={liveColor} />
                                }
                            </div>
                            {liveLevel === 7
                            ?
                            <div className="levelInfoMcq">
                                <p className="levelTextMcq"> LEVEL {liveLevel} </p> 
                                <p className="xpTextMcq"> {currentXp - liveLastRequiredXp} XP </p>
                            </div>
                            :
                            <div className="levelInfoMcq">
                                <p className="levelTextMcq"> LEVEL {liveLevel} </p> 
                                <p className="xpTextMcq"> {liveRequiredXp - currentXp} XP required</p>
                            </div>
                            }
                        </div>
                        <div className="blueStreakContainerMcq">
                            <div className="gamificationContainerXpBar">
                                <i className="material-icons fire">whatshot</i>
                                <p className="mcqStreak">{streak} in a row</p>
                            </div>
                        </div>
                    </div>
                </Route> */}
            </Switch>
        )
    }
}

const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,
    }
}


export default connect(mapStateToProps)(XpBar)