import React from 'react';
import './theme.css'
import Collapsible from 'react-collapsible';

class Theme extends React.Component {
    onClick = (url) => {
        window.location.href=url
    }

    render() {
        const { quizzes, nonSpecificSubjectString } = this.props;

        const quizzesHTML = quizzes.map(({ quizName, testMode, unlocked, flashcard }, index) => {
            const classNames = ['innerThemeContainer2'];
            const classQuizName = ['quizName2'];
            
            let hrefBase;

            if (unlocked !== true) {
                classNames.push('lockedTrial');
                classQuizName.push('lockedTrial');
            }
            
            if (unlocked) {
                hrefBase = `/${nonSpecificSubjectString}/`;
            } else {
                hrefBase = '/signup';
            }

            // return (
            //     <div className={classNames.join(' ')} key={index}>
            //         <li key={index} className='themeListContainer'>
            //             <a href={unlocked ? hrefBase + quizName : hrefBase} className={classQuizName.join(' ')}>
            //                 {quizName}
            //             </a></li>
            //         {/* {highScore ? <p className="innerHighScore">{Math.round(highScore)} % </p> : <p className="innerHighScore"> -- % </p>} */}
            //     </div>)
            
            
            if (unlocked) {
                return (
                    <Collapsible trigger={
                    <div className={classNames.join(' ')} key={index}>
                        <li key={index} className={classQuizName.join(' ')}>
                            <p className="marginAutoQuizLandingTrial">{quizName}</p>
                        </li>


                    </div>
                    }>  
                        {unlocked && 
                            <div className="quizLandingQuizNameContainer" onClick={()=>this.onClick(hrefBase+quizName)}>
                                <li key={index} className='themeListContainer'>
                                    {/* <div className="flexRow">
                                        <div className="btn-floating">
                                            <i className="material-icons">
                                                help
                                            </i>
                                        </div>
                                    </div> */}
                                    <a href={hrefBase + quizName} className="quizLandingQuizName">
                                        Quiz
                                    </a>
                                </li>
                                
                            </div>
                        }
                        {unlocked && flashcard ?
                        <div className="quizLandingQuizNameContainer" onClick={()=>this.onClick(`/${nonSpecificSubjectString}flashcards/${flashcard}`)}>
                            <div>
                                <a href={`/${nonSpecificSubjectString}flashcards/${flashcard}`} className="quizLandingQuizName">Flashcards</a>
                            </div>
                            <div className="quizLandingflashcardIconFlexRow">
                                <i className="material-icons">
                                    style
                                </i>
                            </div>
                        </div>
                        :   null
                        }
                        
                    </Collapsible>
                )
            } else {
                return (
                    <div className={classNames.join(' ')} key={index}>
                        <li key={index} className={classQuizName.join(' ')}>
                            <p className="quizNameLockedTrial">{quizName}</p>
                        </li>
                    </div>
                )
            }


        });

        return (
            <ul className="bigContainer">
                {quizzesHTML}
                {/* <li onClick={this.checkAnswer} className={classNames[3]} data-id="4"><span>D</span> <p>{answers[3]}</p></li> */}
            </ul>
        );
    }
}

export default Theme