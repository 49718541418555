export const submitQuestion = (question) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        // const firebase = getFirebase();
        const firestore = getFirestore();

        const userID= getState().firebase.auth.uid;
        firestore.collection('mcqDatabase').add({
            answer1: question.answer1,
            answer2: question.answer2,
            answer3: question.answer3,
            answer4: question.answer4,
            correct: question.correct,
            exposition1: question.exposition1,
            exposition2: question.exposition2,
            exposition3: question.exposition3,
            exposition4: question.exposition4,
            questionText: question.questionText,
            quizName: question.quizName,
            user: userID,
            url: question.url,
            graphQuestion: question.graphQuestion,
            difficulty: question.difficulty,
            createdAt: new Date()
        }).then(() => {
            dispatch({type: 'CREATE_QUESTION', question:question}); 
        }).catch((err) => {
            dispatch({type: 'CREATE_QUESTION_ERROR', err})
        })
    }


}
