import React from 'react';
import { Droppable } from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'

// {/*flashcardSelect={this.props.flashcardSelect} removeQuestion={this.props.removeQuestion}*/}

export default class Column extends React.Component {
    constructor(props) {
        super(props);
    }

    onClick = (index, event) => {
        this.props.toggleDeleteWarning(index, event)
        this.props.retrieveIndex(index)
    }


    render () {
        return (
            <Droppable droppableId="0">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {this.props.question.map((question, index) => 
                            <Draggable draggableId={question.questionText + question.answer1 + question.exposition4 + question.randomID + question.questionID} 
                            index={index} 
                            key={question.questionText + question.answer1 + question.exposition4 + question.randomID + question.questionID}
                            >
                            {(provided) => (  
                            <div className="flashcardPreviewMCQForm" onClick={() => this.props.questionSelect(index)}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                            >
                                <div className="dragItem">
                                    <i className="material-icons">drag_handle</i>
                                </div>
                                <p className="flashcardPreviewText">{index +1}</p>
                                {/* <div className="btn-floating flashcardFormRemoveQuestion" onClick={(event) => this.props.removeQuestion(index, event)}> */}
                                <div className="btn-floating flashcardFormRemoveQuestion" onClick={(event)=> this.onClick(index, event)}>
                                                <i className="material-icons flashcardFormRemoveQuestion">delete</i>
                                </div>
                                
                            </div>
                            )
                            }
                            </Draggable>
                            )}

                        
                        {provided.placeholder}
                    </div>
                )}
                
            </Droppable> 
        )
    }
}