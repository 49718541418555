import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from '../auth/auth';

import { signOut } from '../../store/actions/authActions'


export class SignupError extends Component {
    constructor(props) {
        super(props);
        this.state={
            messageSent: false
        }
    }

    messageTeam = () => {
        firebase.firestore().collection('signupErrors')
        .add({
            uid: this.props.auth.uid,
            email: this.props.auth.email,
            createdAt: new Date(),
        }).then(() => {
            console.log("Message sent");
            this.setState({
                messageSent: true
            })
        }).catch((err) => {
            console.log(err.message);
        })
    }

    render() {

        const {messageSent} = this.state

        return (
            <div>
                <div className="container">
                    <h4 className="grey-text text-darken-3">Uh-oh. Something's gone wrong 😞</h4> 
                    <h5 className="grey-text"><br></br>Please sign up using another email address or press the button below and a member of the team will get back to you within 24 hours!</h5> 

                    <div className={`signOutButton message ${messageSent}`} onClick={messageSent ? null: this.messageTeam}>
                        <p className="signOutButtonText">Message team</p>
                        <i className="material-icons signOutButton">send</i>
                    </div>

                    {messageSent ?
                    <div>
                        <p className="green-text center">Message sent...someone will be in touch soon!</p>
                    </div>
                    : null
                    }

                    
                    <div className="signOutButton" onClick={this.props.signOut}>
                        <p className="signOutButtonText">Signout</p>
                        <i className="material-icons signOutButton">logout</i>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupError)
