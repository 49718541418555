import React from 'react';
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import firebase from '../auth/auth'
import { Link } from 'react-router-dom'
import '../newMCQForm/newQuizDialog.scss'




class TryDiagnostics extends React.Component {
    constructor(props) {
        super(props);
        
        this.state= {
            didLoad: false
        }
    }

    onLoad = () => {
        this.setState({
            didLoad: true
        })
    }
    
    render() {

        const style = this.state.didLoad ? {} : {visibility: 'hidden'}
       
        
        return (

            <div className="popup-container" style={style}>      
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNewQuiz">
                            <div className="btn-floating closePopup" onClick={this.props.toggleTryDiagnostics}><i className="material-icons">close</i></div>
                            <h5 className="needHelp diagnostics">Try our revision diagnostics quizzes!</h5>
                            <img src="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fdiagnostics.gif?alt=media&token=f13a129c-40fb-4357-8015-6cb060ed3bd1"
                                onLoad={this.onLoad} 
                                className="newQuiz" 
                                alt="newQuiz" 
                            />
                            <Link to='/quizzes' className="diagnosticsGoButton" >
                                    <p className="diagnosticsGoButtonText">Let's go 💪</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default TryDiagnostics