import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import firebase from '../auth/auth'
import StudentFeed from './StudentFeed'
import Toggle from 'react-toggle'
import Spinner from '../spinner/spinner'
import CustomiseFeed from './customiseFeed'
import './teacherFeed.scss';


class TeacherFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testsArray:[],
            flagsArray:[],
            loading: true,
            customView: false,
            activeClass: ""
        }
    }

    //Good for loading APIs
    componentDidMount () {
        this.getFeed()
    }

    //Potential Database performance issues- perhaps move to cloud function?
    getFeed = (classSelector = "") => {

        let classes
        let subjectString = ""

        if (this.props.profile.subject==="Economics") { 
        classes = "classes"
        subjectString = "Economics"
        } else {
            classes = this.props.profile.subject + "classes"
            subjectString = this.props.profile.subject
        }
        

        if (this.props.profile.school!=="individual") {
            firebase.firestore().collection('schools').where('school','==', this.props.profile.school)
            .get()
            .then(snapshot => {
                let feedData
                let classData
                let feedNotAvailable

                if (snapshot.docs[0].data().schoolFeedArray) {
                
                    feedData = snapshot.docs[0].data().schoolFeedArray.filter(entry => entry.subject==subjectString )

                    classData= snapshot.docs[0].data()[classes]

                    if (!classSelector) {
                        console.log("class selector empty", classSelector)
                        let feedNotAvailable = true
                        feedData.map(entry => {
                            
                            if (entry.subject===this.props.profile.subject) {
                                feedNotAvailable = false
                            }
                        })
                    } else {
                        console.log("class selector true", classSelector)
                        feedData.map(entry => {
                            let feedNotAvailable = true
                            if (entry.subject===this.props.profile.subject) {
                                feedNotAvailable = false
                            }

                            let feedCopy = Array.from(snapshot.docs[0].data().schoolFeedArray)
                            feedData = feedCopy.filter(entry => entry.classID == classSelector)
                        })
                    }
                }
                this.setState({
                    feedData: feedData ? feedData.reverse() : [],
                    classData: classData,
                    feedNotAvailable
                    },
                    ()=>{this.setState({loading:false})
                })
            })
        } else {
            this.setState ({
                individualUser: true,
                loading: false
            })
        }
    }

    showStudentFeed = (user) => {
        this.setState({
            feedId: user,
            showFeed: true
        })
    }

    toggleShowFeed = () => {
        this.setState({
            showFeed: false
        })
    }

    sendEmail = () => {
        const {profile} = this.props
        firebase.firestore().collection('onboarding')
        .add({
            createdAt: new Date(),
            email: profile.email,
            firstName: profile.firstName,
        }).then(()=>this.setState({emailSent: true}))

    }

    toggleCustomView = () => {
        this.setState({
            customView: this.state.customView ? false : true
        })
    }

    toggleClass = (c) => {
        this.setState({
            activeClass: c
        })
    }

    render () {
        const {emailSent, feedData, classData, activeClass, individualUser, feedNotAvailable} = this.state
        const {subject} = this.props.profile
        const newNotifications = this.props.newQuizzes + this.props.newFlags
            

        return (
            <div className="teacherFeedContainer">
                {this.state.showFeed 
                ?
                <StudentFeed userId={this.state.feedId} toggleShowFeed={this.toggleShowFeed}/>
                :
                null
                }

                {this.state.customView
                ? <CustomiseFeed 
                toggleCustomView={this.toggleCustomView}
                classData={classData} 
                toggleClass={this.toggleClass}
                activeClass={this.state.activeClass}
                getFeed={this.getFeed} />
                : null}
                

                <div className="teacherFeedTitleContainer">
                    
                    <div className="teacherFeedTitleTextContainer">
                        <p className="feedTitle">FEED</p>
                        {newNotifications !==0 && !individualUser
                        ?<div class="btn-floating waves-effect waves-light red teacherFeedNotification">
                        <p className='teacherFeedNotificationNumber'>{newNotifications}</p>
                        </div>
                        : null
                        }
                    </div>
                    {/* THIS WILL REINSTATE CUSTOMISE CLASSES */}
                    {/* <i className='material-icons feedList' onClick={this.toggleCustomView}>list</i> */}
                    
                    
                </div>
                <div className="teacherFeedFeedContainer">
                    {individualUser
                        ? 
                            <div className="teacherFeedPlaceholder">
                                <p className="teacherFeedPlaceholderText">No feed available for individual users</p>
                            </div>
                        : null
                    }
                    {feedData && feedData.map(entry => {
                            // if (entry.subject===subject){
                            // if ((entry.subject===subject) && (activeClass==="" || entry.classID===activeClass)) {
                            // MUST UNCOMMENT CURLY BRACE AT BOTTOM!
                                
                                if (entry.type==='quiz') {
                                return (
                                    <div className="flexRowTeacherFeed" key={entry.quizName+entry.createdAt}>
                                        <div className="btn-floating teacherFeed">
                                        <Link to={entry.pathname} className="teacherFeedLink"><i className="material-icons">create</i></Link>
                                        </div>
                                        <div className="teacherFeedTextContainer">
                                            <p className="teacherFeedMain"><span className="teacherFeedStudentLink" onClick={() => this.showStudentFeed(entry.user)}>{entry.firstName} {entry.lastName}</span> scored {entry.percent}% on <span><Link to={entry.pathname}>{entry.quizName}</Link></span> </p>
                                            <p className="teacherFeedSecondary">{new Date(entry.createdAt.seconds*1000).toLocaleString()}</p>
                                        </div>                              
                                    </div>                        
                                )
                                } else if (entry.type==='flag') {
                                    return (
                                        <div className="flexRowTeacherFeed" key={entry.quizName+entry.date}>
                                            <div className="btn-floating teacherFeed">
                                                {this.props.flags 
                                                ? <Link to='/flagged' className="teacherFeedLink"><i className="material-icons">flag</i></Link>
                                                : 
                                                <div className="teacherFeedLink" onClick={this.props.toggleFlagPopup}>
                                                    <i className="material-icons">flag</i>
                                                </div>
                                                }
                                            </div>
                                            <div className="teacherFeedTextContainer">
                                                {this.props.flags
                                                    ? <p className="teacherFeedMain"><span className="teacherFeedStudentLink" onClick={() => this.showStudentFeed(entry.user)}> {entry.firstName} {entry.lastName} </span> flagged a question on <span><Link to='/flagged'>{entry.quizData.quizName}</Link></span> </p>
                                                    : <p className="teacherFeedMain"><span className="teacherFeedStudentLink" onClick={() => this.showStudentFeed(entry.user)}> {entry.firstName} {entry.lastName} </span> flagged a question on <span className='teacherFeedLink' onClick={this.props.toggleFlagPopup}>{entry.quizData.quizName}</span> </p>
                                                }
                                                <p className="teacherFeedSecondary">{new Date(entry.createdAt.seconds*1000).toLocaleString()}</p>
                                            </div>                              
                                        </div>
                                    )
                                }
                            // } 
                        }
                        
                    )}
                    
                    {this.state.loading ?
                    <Spinner />
                    :
                    null
                    }
                    {(feedData&&feedData.length === 0 || feedNotAvailable)
                    ? <div className="teacherFeedPlaceholder">
                            <div className="teacherFeedPlaceholderContainer">
                            <i className="material-icons teacherFeedPlaceholder">person_add</i>                        
                            <p className="teacherFeedPlaceholderText title">Student activity will appear here</p>
                            </div>
                            <p className="teacherFeedPlaceholderText sub">Option 1 (recommended)</p>
                            <p className="teacherFeedPlaceholderText">You can create classes + sign up students using a customised link. This will save you having to manually assign all of your students to classes. </p>
                            <Link to='/manageclasses' className="btn centre teacherFeed onboarding"> 
                                <p className="onboarding">Go to manage classes</p>
                            </Link>
                            <p className="teacherFeedPlaceholderText sub">Option 2</p>
                            <p className="teacherFeedPlaceholderText">Students can sign up directly via the main signup page </p>
                            <div className="btn centre teacherFeed onboarding" onClick={this.sendEmail}> 
                                <p className="onboarding">Receive onboarding email</p>
                             </div>
                            {emailSent ? <p className="green-text">You should receive an email within 1 minute. Please remove us from your spam filter and add us to your list of trusted senders.</p>:null}
                        
                    </div>
                    : null    
                    }
                    {/* <Link> View more </Link> */}
                </div>
            </div>
        )
    }

    
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}
    
export default connect(mapStateToProps)(TeacherFeed)