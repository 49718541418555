import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { signUpManual } from '../../store/actions/authActions'
import MobileNavBar from '../layout/mobileNavbar'
import firebase from '../auth/auth'
import './signup.scss'

export class ManualSignUp extends Component {
    state = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        school: "",
        student: "",
        subject: "",
        legal: false,
        formComplete: false,
        schools: []
    }

    componentDidMount() {
        this.getSchools();
    }

    getSchools() {
        firebase.firestore().collection('schools').orderBy('school')
            .get()
            .then(querySnapshot => {
                let schools = [] 
                
                querySnapshot.docs.map(doc => {
                    if (doc.data().unapproved) {
                        return;
                    } else {
                    schools.push(doc.data().school)
                    }
                })
                

                this.setState({ schools })
            })
    }

    handleChange = (e) => {
        let { id, name, value, type, checked } = e.target

        if (this.handleExceptions(name, value)) {
            return;
        }

        if (type === 'checkbox') {
            value = checked;
        }

        this.setState({
            [name || id]: value
        })
    }

    handleExceptions(input, value) {
        let exception = false;

        if (input === 'school' && value === 'other') {
            this.setState({
                newSchool: true
            });

            exception = true;
        }

        return exception;
    }

    handleNameInput = (e) => {
        String.prototype.capitalize = function () {
            return this.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
        };

        var input = e.target.value.capitalize()

        this.setState({
            [e.target.id]: input
        })

    }

    handleSubmit = (e) => {
        e.preventDefault()

        const { email, password, firstName, lastName,
            school, subject, student, legal } = this.state

        if (email && password && firstName && lastName
            && school && subject && student && legal && school!== "GradeIt") {
            this.props.signUpManual(this.state)
            this.setState({isProcessing:true})
        }

        if (email && password && firstName && lastName
            && school && subject && student && legal && school &&
            this.state.newSchool===true && this.state.school && school!== "GradeIt") {
            firebase.firestore().collection('schools').add({
                school:school,
                createdAt: new Date(),
                unapproved: true
            }).then(()=>{
                console.log("School added")
            }).catch(function(error) {
                console.log(error)
              });
        }

        if (!firstName) {
            this.setState({ firstNameMissing: true })
        } else {
            this.setState({ firstNameMissing: false })
        }
        if (!lastName) {
            this.setState({ lastNameMissing: true })
        } else {
            this.setState({ lastNameMissing: false })
        }
        if (!school) {
            this.setState({ schoolMissing: true })
        } else {
            this.setState({ schoolMissing: false })
        }
        if (!subject) {
            this.setState({ subjectMissing: true })
        } else {
            this.setState({ subjectMissing: false })
        }
        if (!student) {
            this.setState({ studentMissing: true })
        } else {
            this.setState({ studentMissing: false })
        }
        if (!legal) {
            this.setState({ legalMissing: true })
        } else {
            this.setState({ legalMissing: false })
        } 

    }

    // //makes this asynchrous so createStudentProgress and signUp can BOTH be completed ahead of re-direct
    // nowSignUp () {
    //     this.props.signUp(this.state)
    // }

    render() {
        const { auth, authError } = this.props;
        const { firstNameMissing, lastNameMissing, schoolMissing, subjectMissing,
            studentMissing, isProcessing, schools, newSchool, legalMissing } = this.state

        return (
            <div>
                <MobileNavBar />
                <div className="container">
                    <form onSubmit={this.handleSubmit} className="white">
                        <div className="signUpHeader">
                            <h5 className="grey-text text-darken-3">Sign Up</h5>
                            <Link to='/signin' className="alreadyUser" >I am already a user </Link>
                        </div>
                        <div className="input-field">
                            <label htmlFor="email">UID</label>
                            <input type="email" id="uid" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" onChange={this.handleChange} />
                        </div>
                        <div className="input-field">
                            <label htmlFor="firstName">First Name</label>
                            <input type="text" id="firstName" onChange={this.handleNameInput} />
                        </div>
                        <div className="input-field">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" id="lastName" onChange={this.handleNameInput} />
                        </div>
                        <div className="input-field">
                            <label className={newSchool ? '' : 'select-label'} htmlFor="School">School</label>
                            {newSchool
                                ? (
                                    <>
                                    <input type="text" name="school" onChange={this.handleChange} />
                                    <span className="get-schools-list" onClick={() => this.setState({ newSchool: false })}>
                                        Choose school from dropdown
                                    </span>
                                    </>
                                )
                                : (
                                    <select className="schoolDropdown" onChange={this.handleChange} name="school">
                                        <option value="">Click here to choose from options</option>
                                        <option value="other">Add new school</option>
                                        <option value="individual">Individual user</option>
                                        <option value="">---</option>
                                        {schools.map(school => {if (school!=="individual" && school!== "GradeIt") return <option key={school}>{school}</option>}
                                        )}
                                    </select>
                                )
                            }
                        </div>
                        <div className="input-field">
                            <label className='select-label' htmlFor="School">Subject</label>      
                                    <select className="schoolDropdown" onChange={this.handleChange} name="subject">
                                        <option value="">Click here to choose from options</option>
                                        <option value="Economics">A-level Economics</option>
                                        <option value="gcseBiologySingleScienceAQA">GCSE Biology</option>
                                    </select>
                        </div>
                        <div className="radio">
                            <label className="studentTeacherQuestion" htmlFor="student"> I am a: </label>
                            <br />
                            <input className="radioButton" type="radio" id="student" name="student" value="student" onChange={this.handleChange} />
                            <label className="studentTeacherRadio">
                                Student
                            </label>
                        </div>
                        <div className="radio">
                        <input className="radioButton" type="radio" id="student" name="student" value="teacher" onChange={this.handleChange} />
                            <label className="studentTeacherRadio">
                                Teacher
                            </label>
                        </div>
                        <div className="input-field mt-1">
                                <input type="checkbox" id="legal" name="legal" onChange={this.handleChange}/>
                                <label htmlFor="legal" className="termsLabel">
                                    I agree to the <a href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/legal%2FGradeIt%20Terms%20and%20Conditions.docx?alt=media&token=dfe1d534-9c69-433f-be77-b0418bf8e86e" download>Terms and Conditions</a> and <a href="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/legal%2FGradeIt%20Privacy%20Policy.docx?alt=media&token=97be433c-9e42-422a-af64-54727f1f34cc" download>Privacy Policy</a>.                        
                                </label>
                        </div>
                        <div className="input-field">
                            <button className="btn pink lighten-1 z-depth-0 waves-effect waves-light">Submit</button>
                        </div>
                        <div className="red-text center">
                            {authError ? <p>{authError}</p> : null}
                            {firstNameMissing ? <p> Please enter your first name</p> : null}
                            {lastNameMissing ? <p> Please enter your last name</p> : null}
                            {schoolMissing ? <p> Please select or add your school</p> : null}
                            {subjectMissing ? <p> Please select your subject</p> : null}
                            {studentMissing ? <p> Please select whether you are a student or a teacher</p> : null}
                            {legalMissing ? <p> You need to agree to our Terms and Conditions and Privacy Policy</p> : null}
                        </div>
                        <div className="green-text center">
                            {isProcessing ? <p>Processing...please wait 💪</p> : null}
                        </div>

                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signUpManual: (newUser) => dispatch(signUpManual(newUser))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualSignUp)
