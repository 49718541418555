import React from 'react';
import { connect } from 'react-redux'

class NotAvailable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    
    render() {

        const {toggleNotAvailable} = this.props

        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp">
                            <div className="btn-floating closePopup" onClick={toggleNotAvailable}>
                                <i className="material-icons">close</i>
                            </div>
                            <h5 className="needHelp">Sorry 😞</h5>
                            <p className="needHelp">This feature is not available for individual users</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default NotAvailable;