import React from 'react';


class Answers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnswered: false,
            classNames: ['', '', '', ''],
            numberClicked: 0,
        }
        
        this.checkAnswer = this.checkAnswer.bind(this);
    }
    
    checkAnswer(e) {

        let { nr, dataBank, total, mcqData } = this.props;
        let elem = e.currentTarget;
        let { numberClicked} = this.props;
        let answer = Number(elem.dataset.id);
        
        const updatedClassNames = {...this.props.classNames}

        //code below is currently designed to highlight student's answer in green.
        //We want styling as per the actual app i.e red=incorrect, green=correct


        if (dataBank[`studentAnswer${nr}`]===null) {         
            numberClicked(answer)
            this.props.showButton();
            this.setState({numberClicked: answer})
            updatedClassNames[`${nr}`][answer-1]="right"
        } else {
            numberClicked(answer)
            this.props.showButton();
            if (this.state.classNames[`${nr}`][answer-1]==="") {
                updatedClassNames[`${nr}`][0]=""
                updatedClassNames[`${nr}`][1]=""
                updatedClassNames[`${nr}`][2]=""
                updatedClassNames[`${nr}`][3]=""
                updatedClassNames[`${nr}`][answer-1] = "right"
            }
            this.setState({numberClicked: answer})
            }
            this.setState({classNames:updatedClassNames})

            var score=0
            for (let i=1; i<=total; i++) {
                if (mcqData[i-1]["correct"]===dataBank[`studentAnswer${[i]}`]) {
                    score=score+1
                }
            }
            this.setState({score})
            this.props.retrieveScore(score)

            
    }
        
    
    render() {
        let { answers, nr } = this.props;
        let { classNames } = this.state;
        
        // let transition = {
        //     transitionName: "example",
        //     transitionEnterTimeout: 500,
        //     transitionLeaveTimeout: 300
        // }

        // if (!classNames || !nr ){        
        //     return (
        //         <div id="answers">
        //             <ul>
        //                 <li onClick={this.checkAnswer} className={`classNames${nr}`[0]} data-id="1"><span>A</span> <p>{answers[0]}</p></li>
        //                 <li onClick={this.checkAnswer} className={`classNames${nr}`[1]} data-id="2"><span>B</span> <p>{answers[1]}</p></li>
        //                 <li onClick={this.checkAnswer} className={`classNames${nr}`[2]} data-id="3"><span>C</span> <p>{answers[2]}</p></li>
        //                 <li onClick={this.checkAnswer} className={`classNames${nr}`[3]} data-id="4"><span>D</span> <p>{answers[3]}</p></li>
        //             </ul>
        //         </div>
        //     );
        // } else {
        //     return (
        //         <div id="answers">
        //             <ul>
        //                 <li onClick={this.checkAnswer} className={classNames[nr][0]} data-id="1"><span>A</span> <p>{answers[0]}</p></li>
        //                 <li onClick={this.checkAnswer} className={classNames[nr][1]} data-id="2"><span>B</span> <p>{answers[1]}</p></li>
        //                 <li onClick={this.checkAnswer} className={classNames[nr][2]} data-id="3"><span>C</span> <p>{answers[2]}</p></li>
        //                 <li onClick={this.checkAnswer} className={classNames[nr][3]} data-id="4"><span>D</span> <p>{answers[3]}</p></li>
        //             </ul>
        //         </div>
        //     );
        // }

    

        if (!classNames || !nr ){        
            return (
                <div id="answers">
                    <ul>
                        <li onClick={this.checkAnswer} className={classNames[0]} data-id="1"><span>A</span> <p>{answers[0]}</p></li>
                        <li onClick={this.checkAnswer} className={classNames[1]} data-id="2"><span>B</span> <p>{answers[1]}</p></li>
                        <li onClick={this.checkAnswer} className={classNames[2]} data-id="3"><span>C</span> <p>{answers[2]}</p></li>
                        <li onClick={this.checkAnswer} className={classNames[3]} data-id="4"><span>D</span> <p>{answers[3]}</p></li>
                    </ul>
                </div>
            );
        } else {
            return (
                <div id="answers">
                    <ul>
                        <li onClick={this.checkAnswer} className={classNames[0]} data-id="1"><span>A</span> <p>{answers[0]}</p></li>
                        <li onClick={this.checkAnswer} className={classNames[1]} data-id="2"><span>B</span> <p>{answers[1]}</p></li>
                        <li onClick={this.checkAnswer} className={classNames[2]} data-id="3"><span>C</span> <p>{answers[2]}</p></li>
                        <li onClick={this.checkAnswer} className={classNames[3]} data-id="4"><span>D</span> <p>{answers[3]}</p></li>
                    </ul>
                </div>
            );
        }


    }
}

export default Answers