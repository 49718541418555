import React from 'react';
import firebase from '../auth/auth'
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link } from 'react-router-dom'

import './mcqGraph.scss';


class Popup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            time: 'start',
            title: 'Welcome to Quizz',
            text: 'This is a quiz application built using ReactJS. <br /><br /> Currently it\'s loaded with CSS questions from W3Scools, but you can easily load any type of questions into it. <br /><br /> It will dynamically load the question->answers pair and upload them into the components.' ,
            // buttonText: 'Start the quiz',
            buttonText: 'Restart',
            upvoted: false
        };
        
        this.popupHandleTryAgain = this.popupHandleTryAgain.bind(this);
        this.popupHandleTryAnother = this.popupHandleTryAnother.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.upvote = this.upvote.bind(this);
    }
    
    popupHandleTryAgain() {
        // let { time } = this.state;
        
        // if(time === 'start'){
        //     this.setState({
        //         time: 'end',
        //         title: 'Congratulations!',
        //         buttonText: 'Restart'
        //     });
            
        //     this.props.startQuiz();
        // } else {
                        
            window.location.reload();// restart the application
        
    }

    popupHandleTryAnother() {
        // let { time } = this.state;
        
        // if(time === 'start'){
        //     this.setState({
        //         time: 'end',
        //         title: 'Congratulations!',
        //         buttonText: 'Restart'
        //     });
            
        //     this.props.startQuiz();
        // } else {
            this.props.history.push('/')
        
    }

    upvote() {
        firebase.firestore().collection('quizzes')
        .where('quizName', '==', this.props.quizName)
        .get()
        .then(querySnapshot => {
            const quizID = querySnapshot.docs[0].id
            firebase.firestore().collection('quizzes')
            .doc(quizID)
            .update({
                upvotes: firebase.firestore.FieldValue.increment(1)
            })
        })

        this.setState({
            upvoted: true
        })
    }

    handleNext() {
        const leveledUp = document.querySelector('.levelUpPopup');
        const endQuiz = document.querySelector('.endQuizPopup');
        const showInfo = document.querySelector('.avatarInfo');

        showInfo.classList.remove('active');
        leveledUp.classList.remove('active');
        endQuiz.classList.remove('hidden');
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            text: 'You got: <strong>' + this.props.score + '</strong> out of <strong>' +this.props.total +'</strong> questions right.'
        })
        
    }
    
    createMarkup(text) {
        return {__html: text};
    }
    
    
    render() {
        let { text } = this.state;
        let { style, userLevel } = this.props;

        const powellPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fjerome_powell.png?alt=media&token=802c4a84-5705-4a5a-8985-2a47ce599b9d";
        const lagardePic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fchristine_lagarde.png?alt=media&token=c1c4b35a-2622-4b6a-bfe6-ffbe5a71443f";
        const baileyPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fandrew_bailey.png?alt=media&token=ab8d1fa8-8bc8-4080-86a8-e963521556a9";
        const buffettPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fwarren_buffett.png?alt=media&token=e35ea2cf-48e3-4f8d-84f0-fe868aff6bef";
        const sunakPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Frishisunak2.png?alt=media&token=e51a4f8c-104d-43ff-9600-189493d94774";
        
        const attenboroughPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FDavid%20Attenborough.png?alt=media&token=dea48d0c-0d5a-4b14-bbc9-d9b8757d9da5"
        const darwinPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FCharles%20Darwin.png?alt=media&token=53aabff3-5d22-4e6c-ae3d-a15f50fc32d3"
        const lamarkePic ="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FJean%20Baptiste%20Lamark.png?alt=media&token=4c79d787-f1fd-4eaf-b7f4-34ae2921900e"
        const mendelPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FGregor%20Mendel.png?alt=media&token=91de7689-fb31-4186-929f-b59f7270c9c1"
        const mariePic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FMarie%20Curie.png?alt=media&token=7c74e345-fb65-4576-8b66-54b409eb5cb3"

        const rutherfordPic ="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2FErnest%20Rutherford.png?alt=media&token=20f77fc2-2d83-4096-ae57-b133ac5d2b0e"
        const faradayPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2FMichael%20Faraday.png?alt=media&token=6e6cefb9-ac02-45be-8154-46848fc8a932"
        const galileoPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2Fgallelio.png?alt=media&token=85757962-52c4-47f1-8049-cc8268db140c"
        const einsteinPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2Feinstien.png?alt=media&token=1950a6af-86ab-48a5-8a9a-fe26f9d7d3f5"
        const newtonPic="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/imagesPhysics%2FIssac%20Newton.png?alt=media&token=5edba455-80b0-4a20-981a-642fedef10dd"

        const nobelPic= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FAlfred%20Nobel.png?alt=media&token=0d415535-0ea2-4986-b99f-eca6981958bb"
        const avogadroPic= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FAvogadro.png?alt=media&token=6732e488-8e46-4bd1-861a-8fbdbc46bfbd"
        const mendeleevPic= "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FMendeleev.png?alt=media&token=710a393c-67bf-4ca3-84f7-9e8754d53e9c"

        let prevAvatar;
        let newAvatar;
        let economist;
        let economistText;

        const gregorMendel = <ul className="economistList">
            <li className="economistInfo">• The father of modern genetics</li>
            <li className="economistInfo">• Coined the terms "recessive" and "dominant"</li>
            <li className="economistInfo">• Loved peas</li>
        </ul>

        const davidAttenborough = <ul className="economistList">
        <li className="economistInfo">• Only person to have won a BAFTA in black and white, colour, HD and 3D</li>
        <li className="economistInfo">• Has 32 degrees</li>
        <li className="economistInfo">• Hates rats</li>
        </ul>

        const charlesDarwin = <ul className="economistList">
        <li className="economistInfo">• Founder of evolutionary biology</li>
        <li className="economistInfo">• Wrote "On the Origin of Species"</li>
        <li className="economistInfo">• Married his cousin</li>
        </ul>

        const jeanLamarck = <ul className="economistList">
        <li className="economistInfo">• Carried out influential work on evolution and genetic inheritance</li>
        <li className="economistInfo">• Named over 1500 species of organisms</li>
        <li className="economistInfo">• Not Charles Darwin</li>
        </ul>

        const marieCurieChem = <ul className="economistList">
        <li className="economistInfo">• Discovered the elements polonium and radium</li>
        <li className="economistInfo">• Paved the way for X-ray technology</li>
        <li className="economistInfo">• Could see through walls</li>
        </ul>

        const marieCurie = <ul className="economistList">
        <li className="economistInfo">• Championed the use of radioactivity in medicine</li>
        <li className="economistInfo">• Paved the way for X-ray technology</li>
        <li className="economistInfo">• Could see through walls</li>
        </ul>

        const andrewBailey = <ul className="economistList">
        <li className="economistInfo">• Governor of the Bank of England</li>
        <li className="economistInfo">• Slashed interest rates to 0.1% in 2020</li>
        <li className="economistInfo">• Rebooted quantitative easing in the UK</li>
        <li className="economistInfo">• Not as handsome as his predecessor Mark Carney</li>
        </ul>

        const rishiSunak = <ul className="economistList">
            <li className="economistInfo">• Chancellor of the Exchequer</li>
            <li className="economistInfo">• Former Chief Secretary to the Treasury</li>
            <li className="economistInfo">• Campaigned for ethnic minority figures to be featured on UK coins</li>
            <li className="economistInfo">• Favorite basketball slang term is "bank is open"</li>
        </ul>

        const christineLagarde = <ul className="economistList">
            <li className="economistInfo">• President of the European Central Bank</li>
            <li className="economistInfo">• Former managing director of the International Monetary Fund</li>
            <li className="economistInfo">• #2 on Forbes's 2019 Most Powerful Women list</li>
            <li className="economistInfo">• Probably doesn't vacation in Greece</li>
        </ul>

        const warrenBuffett = <ul className="economistList">
            <li className="economistInfo">• Investor and CEO of Berkshire Hathaway</li>
            <li className="economistInfo">• Adheres to value investing and founder centrism</li>
            <li className="economistInfo">• Has pledged to give away 99% of his wealth to philanthropic causes </li>
            <li className="economistInfo">• Will definitely still afford his daily McDonalds</li>
        </ul>

        const jeromePowell = <ul className="economistList">
            <li className="economistInfo">• 16th Chair of the U.S. Federal Reserve</li>
            <li className="economistInfo">• Supporter of the Dodd-Frank Act</li>
            <li className="economistInfo">• Critic of the concept of "too big to fail"</li>
            <li className="economistInfo">• Supercharged Fed as backup lender to the world</li>
        </ul>

        const ernestRutherford = <ul className="economistList">
        <li className="economistInfo">• Performed the gold foil experiment</li>
        <li className="economistInfo">• Discovered that nearly all of an atom's mass is found in the nucleus</li>
        <li className="economistInfo">• Discovered that an atom's nucleus is positively charged</li>
        </ul>

        const michaelFaraday = <ul className="economistList">
        <li className="economistInfo">• Discovered the "generator effect"</li>
        <li className="economistInfo">• Performed experiments with magnets and electric conductors</li>
        <li className="economistInfo">• Discovered that a current can be induced in a wire using a magnet</li>
        </ul>

        const galileoGalilei  = <ul className="economistList">
        <li className="economistInfo">• Major force behind the scientific revolution</li>
        <li className="economistInfo">• Developed one of the first telescopes</li>
        <li className="economistInfo">• Discovered craters on the moon</li>
        </ul>

        const albertEinstein  = <ul className="economistList">
        <li className="economistInfo">• Linked diffusion to the idea of Brownian motion</li>
        <li className="economistInfo">• E=mc^2 equation paved the way for nuclear power</li>
        <li className="economistInfo">• Theory of relativity linked space and time to help us understand black holes</li>
        </ul>

        const isaacNewton = <ul className="economistList">
        <li className="economistInfo">• Defined Newton's laws of motion</li>
        <li className="economistInfo">• Defined the Law of Gravity</li>
        <li className="economistInfo">• Also contributed to monetary economics!?!</li>
        </ul>

        const amedeoAvogadro = <ul className="economistList">
        <li className="economistInfo">• Discovered that the same volume of different gases have the same number of particles</li>
        <li className="economistInfo">• Avogadro's constant is 6.02214076×10^23</li>
        <li className="economistInfo">• Nothing to do with avocados</li>
        </ul>

        const alfredNobel = <ul className="economistList">
        <li className="economistInfo">• Swedish chemist and businessman</li>
        <li className="economistInfo">• Invented dynamite</li>
        <li className="economistInfo">• The Nobel prize is named after him</li>
        </ul>

        const dmitriMendeleev = <ul className="economistList">
        <li className="economistInfo">• Arranged elements according to atomic weight and reactivity</li>
        <li className="economistInfo">• Suggested some properties of elements are linked to atomic weight</li>
        <li className="economistInfo">• Pioneer of the modern periodic table</li>
        </ul>

        if (userLevel === 2) {
            if (this.props.profile.subject==="Economics") {
                newAvatar = baileyPic
                economist = "Andrew Bailey"
                economistText = andrewBailey
            } else if (this.props.profile.subject.includes("Biology")) {
                newAvatar = lamarkePic
                economist = "Jean-Baptiste Lamarck"
                economistText = jeanLamarck
            } else if (this.props.profile.subject.includes("Physics")) {
                newAvatar = rutherfordPic
                economist = "Ernest Rutherford"
                economistText = ernestRutherford
            }  else if (this.props.profile.subject.includes("Chemistry")) {
                newAvatar = rutherfordPic
                economist = "Ernest Rutherford"
                economistText = ernestRutherford
            }
        } else if (userLevel === 3) {
            if (this.props.profile.subject==="Economics") {
                prevAvatar = baileyPic
                newAvatar = sunakPic
                economist = "Rishi Sunak"
                economistText = rishiSunak
            } else if (this.props.profile.subject.includes("Biology")) {
                prevAvatar = lamarkePic
                newAvatar = mendelPic
                economist = "Gregor Mendel"
                economistText = gregorMendel
            } else if (this.props.profile.subject.includes("Physics")) {
                prevAvatar = rutherfordPic
                newAvatar = faradayPic
                economist = "Michael Faraday"
                economistText = michaelFaraday
            } else if (this.props.profile.subject.includes("Chemistry")) {
                prevAvatar = rutherfordPic
                newAvatar = mariePic
                economist = "Marie Curie"
                economistText = marieCurieChem
            }
        } else if (userLevel === 4) {
            if (this.props.profile.subject==="Economics") {
                prevAvatar = sunakPic
                newAvatar = lagardePic
                economist = "Christine Lagarde"
                economistText = christineLagarde
            } else if (this.props.profile.subject.includes("Biology")) {
                prevAvatar = mendelPic
                newAvatar = attenboroughPic
                economist = "David Attenborough"
                economistText = davidAttenborough
            } else if (this.props.profile.subject.includes("Physics")) {
                prevAvatar = faradayPic
                newAvatar = galileoPic
                economist = "Galileo Galilei"
                economistText = galileoGalilei
            } else if (this.props.profile.subject.includes("Chemistry")) {
                prevAvatar = mariePic
                newAvatar = avogadroPic
                economist = "Amedeo Avogadro"
                economistText = amedeoAvogadro
            }
        } else if (userLevel === 5) {
            if (this.props.profile.subject==="Economics") {
                prevAvatar = lagardePic
                newAvatar = buffettPic
                economist = "Warren Buffett"
                economistText = warrenBuffett
            } else if (this.props.profile.subject.includes("Biology")) {
                prevAvatar = attenboroughPic
                newAvatar = mariePic
                economist = "Marie Curie"
                economistText = marieCurie
            } else if (this.props.profile.subject.includes("Physics")) {
                prevAvatar = galileoPic
                newAvatar = einsteinPic
                economist = "Albert Einstein"
                economistText = albertEinstein
            } else if (this.props.profile.subject.includes("Chemistry")) {
                prevAvatar = avogadroPic
                newAvatar = nobelPic
                economist = "Alfred Nobel"
                economistText = alfredNobel
            }
        } else if (userLevel === 6) {
            if (this.props.profile.subject==="Economics") {
                prevAvatar = buffettPic
                newAvatar = powellPic
                economist = "Jerome Powell"
                economistText = jeromePowell
            } else if (this.props.profile.subject.includes("Biology")) {
                prevAvatar = mariePic
                newAvatar = darwinPic
                economist = "Charles Darwin"
                economistText = charlesDarwin
            } else if (this.props.profile.subject.includes("Physics")) {
                prevAvatar = einsteinPic
                newAvatar = newtonPic
                economist = "Isaac Newton"
                economistText = isaacNewton
            } else if (this.props.profile.subject.includes("Chemistry")) {
                prevAvatar = nobelPic
                newAvatar = mendeleevPic
                economist = "Dmitri Mendeleev"
                economistText = dmitriMendeleev
            }
        } else if (userLevel === 7) {
            if (this.props.profile.subject==="Economics") {           
                newAvatar = powellPic
            } else if (this.props.profile.subject.includes("Biology")) {
                newAvatar = darwinPic
            } else if (this.props.profile.subject.includes("Physics")) {
                newAvatar = newtonPic
            } else if (this.props.profile.subject.includes("Chemistry")) {
                newAvatar = mendeleevPic
            }
            
        }
        
        return (
            <div className="popup-container mobile" style={style}>
                <div className="container levelUp">
                    <div className="col-md-8 col-md-offset-2 levelUp">
                        <div className="popup endQuiz">
                            <div className="completionTag">
                                <h1>Quiz Completed</h1>
                                {/* {this.state.upvoted 
                                ?
                                <p>Quiz upvoted!</p>
                                :
                                <button className="fancy-btn" onClick={this.upvote}><i className="material-icons">arrow_upward</i></button>
                                } */}
                            </div>
                            <div className="levelUpPopup">
                                <p>Congratulations! You reached <strong>level {this.props.userLevel}</strong>!</p>
                                <div className="avatarInfoContainer">
                                    <div className="avatarContainer">
                                        {userLevel === 2 
                                        ?
                                        <React.Fragment>
                                            <div className="fade-inQuick"> 
                                                <img src={newAvatar} className="responsive-img newAvatar" /> 
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="fade-out"> 
                                                <img src={prevAvatar} className="responsive-img prevAvatar" />
                                            </div>
                                            <div className="fade-in"> 
                                                <img src={newAvatar} className="responsive-img newAvatar" /> 
                                            </div>
                                        </React.Fragment>
                                        } 
                                    </div>
                                    <div className="avatarInfo">
                                        <p className="evolutionMessage">You've evolved into:</p>
                                        <p className="economistMessage"><strong>{economist}</strong></p>
                                        {economistText}
                                        <button className="fancy-btn endQuiz" onClick={this.handleNext}>Next</button>
                                    </div>
                                </div>
                                    
                            </div>
                            <div className="endQuizPopup"> 
                                <p dangerouslySetInnerHTML={this.createMarkup(text)} />
                                {this.props.path === '/flagged'
                                ?
                                null
                                :
                                <button className="fancy-btn" onClick={this.popupHandleTryAgain}>Try again</button>
                                }
                                <Link to='/quizzes' className="fancy-btn" >Try another quiz</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, firstName, lastName) => dispatch(createScore(score, total, quizName, firstName, lastName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup)