import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import CKEditor from 'ckeditor4-react';

class NoClasses extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            activity: '',
            noticeboardMessage: "Type your message here..."
        };
        
    }

    componentDidMount () {

    }

    render() {
        
        return (    <div className="popup-container">
                        <div className="container newTask noClass">
                            <div className="newTask100Container">
                                <p className="newTaskTitle">
                                    Please create a class
                                </p>
                                <p className="noClassText">
                                    You must add at least one class in order to proceed
                                </p>
                                <div className='twoButtonAssignContainer'>
                                    <Link to={'/manageclasses'} className="assignButton noClasses">
                                        <p className='assignButtonText'>Manage classes</p>
                                        <i className="material-icons assignButton">manage_accounts</i>
                                    </Link>
                                    <Link to={'/'} className="assignButton noClasses">
                                        <p className='assignButtonText'>Return home</p>
                                        <i className="material-icons assignButton">home</i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(NoClasses) 