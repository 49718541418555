const initState = {
    progress: [
        {id: '1', completion: 60, accuracy: 72 }
    ]
}

const submitQuestionReducer = (state=initState, action) => {
    switch (action.type) {
        case 'CREATE_QUESTION':
            console.log("created question", action.question)
            return state;
        case 'CREATE_QUESTION_ERROR':
            console.log('create question error', action.err);
            return state;
        default:
            return state
    }
}

export default submitQuestionReducer