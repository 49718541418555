import React from 'react';
import MathJax from 'react-mathjax'

import './mathjax.scss'


class Maths extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cleanArray: []
        }
    }

    parseInput(input) {
        let secondParse = []

        if (input) {
            const firstParse = input.__html.split(/(<MathJax.Node )/g)

            firstParse.map(substring => {

                if (substring.includes("inline formula")) {
                    let splitString = substring.split(" />")
                    let inlineFormula = splitString[0].split("\"")

                    secondParse.push(`MATHJAX${inlineFormula[1]}`, ` ${splitString[1]}`)

                } else if (substring.includes("MathJax.Node")) {
                    return
                } else {
                    secondParse.push(substring)
                }
            })
        }

        return secondParse;
    }

    render() {
        const cleanArray = this.parseInput(this.props.question2);
        const { textStyle } = this.props

        return (
            cleanArray
                ?
                <p className={textStyle}>
                    <MathJax.Provider>
                        {cleanArray.map(substring => {
                            if (substring.includes("MATHJAX")) {
                                let cleanString = substring.replace('MATHJAX', '')
                                return (
                                    <MathJax.Node inline formula={cleanString} />
                                )
                            } else {
                                return (
                                    // <span>{substring}</span>
                                    <span dangerouslySetInnerHTML={{__html: substring}}></span>
                                    
                                )
                            }
                        })}
                    </MathJax.Provider>
                </p>
                :
                <div></div>
        )

    }
}

export default Maths
