import React from 'react';
import firebase from '../auth/auth'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { storage } from '../auth/auth';
import CKEditor from 'ckeditor4-react';
import Toggle from 'react-toggle'
import Column from './column'
import DeleteWarning from './deleteWarningMCQForm'
import DeleteQuizWarning from './deleteQuizWarning'
import ImageWarning from './imageWarning'
import { DragDropContext } from 'react-beautiful-dnd'
import ChangeNamePopupMCQForm from './changeNamePopupMCQForm'
import { getQuizzesTrial } from '../../helpers/getData';
import Spinner from '../spinner/spinner'
import NewQuizDialog from './newQuizdialog'
import EditorPreview from './editorPreview';
import Editor from './editor';

import './newMCQForm.scss';
import NewDraggable from './newDraggable';


class newMCQForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 1,
            nr: 0,
            question: [],
            showExposition1: false,
            showExposition2: false,
            showExposition3: false,
            showExposition4: false,
            focused: false,
            admin: false,
            submittable: true,
            title: "",
            deleteWarning: false,
            deleteQuizWarning: false,
            imageWarning: false,
            renameQuiz: false,
            numberSaved: 0,
            numberChecked: 0,
            saveComplete: true,
            saved: false,
            showEditor: false
        }
    }

    componentDidMount() {
        this.checkRename()
        this.setState({
            subject: this.props.profile.subject,
            mcqDatabase:
                this.props.profile.subject === 'Economics'
                    ? 'mcqDatabase'
                    : `mcqDatabase${this.props.profile.subject}`,
            quizzesDatabase:
                this.props.profile.subject === 'Economics'
                    ? 'quizzes'
                    : `quizzes${this.props.profile.subject}`
        })

        getQuizzesTrial(this.props.profile.school, this.props.profile.subject)
            .then((quizzes) => {
                let quizArray = []

                Object.entries(quizzes).map(category => {
                    category[1].map(quiz => {
                        quizArray.push(quiz.quizName)
                    })
                })

                this.setState({
                    quizArray,
                    numberOfQuizzes: quizArray.length
                })
            })

        if (!this.props.profile.seenNewQuizDialog) {
            this.setState({ newQuiz: true })
        }

        if (!this.props.profile.seenNewDraggable) {
            this.setState({ newDraggable: true })
        }


        if (window.location.pathname.substring(1, 12) !== 'newmcqform/') {
            console.log('autosave triggered')
            setInterval(() => this.checkSubmittable("saveButton"), 120000)
        } else {
            console.log('autosave not triggered')
        }


    }

    // TRIED TO REMOVE 4th MAY
    // componentWillReceiveProps (nextProps) {
    //     if (this.props.profile.subject !== nextProps.profile.subject) {
    //         this.setState({subject: nextProps.profile.subject})
    //     }
    // }

    componentWillMount() {
        let { nr } = this.state;
        this.pushData(nr);

    }

    checkRename(props) {
        let rename = false
        if (window.location.pathname.substring(1, 12) === 'newmcqform/') {
            rename = true
        }

        //If content creators or non-admins are creating a new quiz, they must rename it first
        if ((!this.props.profile.admin && rename) || (this.props.profile.contentCreator && rename)) {
            this.setState({ renameQuiz: true })
        }
    }


    pushData(nr) {

        let route
        if (this.props.match.path === '/newmcqformschool/:id') {
            route = this.props.profile.school
        } else {
            route = 'GradeIt'
        }

        //1
        //Prevents subject from changing when props are updated by the user toggling subjects        
        let subject
        if (!this.state.subject) {
            subject = this.props.profile.subject
        } else {
            subject = this.state.subject
        }

        let { mcqDatabase, quizzesDatabase } = this.state

        if (subject === 'Economics') {
            mcqDatabase = 'mcqDatabase'
            quizzesDatabase = 'quizzes'
        } else {
            mcqDatabase = `mcqDatabase${subject}`
            quizzesDatabase = `quizzes${subject}`
        }


        firebase.firestore().collection(mcqDatabase)
            .where('quizName', '==', this.props.match.params.id)
            .where('school', '==', route)
            .orderBy('position')
            .get()
            .then(querySnapshot => {

                const mcqData = querySnapshot.docs.map(doc => doc.data());

                //Iterates through Array of question objects + adds document ID to the objectß
                mcqData.map((question, index) => mcqData[index]["questionID"] = querySnapshot.docs[index].id)

                this.setState({
                    admin: this.props.profile.admin,
                    question: mcqData,
                    questionOriginal: mcqData,
                    correct: mcqData[nr].correct,
                    total: mcqData.length,
                    title: this.props.match.params.id
                })
            })

    }



    preview = () => {
        const { nr, title } = this.state
        if (title !== this.props.match.params.id) {
            this.setState({ difficultyWarning: "You have renamed a quiz- please save before previewing" })
        } else {
            return new Promise((resolve, reject) => {
                this.checkSubmittable("preview")
            })
        }

    }

    renameQuiz = () => {
        let url = `/newmcqformschool/${this.state.title}`
        console.log('renaming quiz')
        this.save('rename')
    }

    checkSubmittable = (modifier) => {
        const { question, title, quizArray } = this.state
        this.setState({ submittable: true })

        if (!this.props.profile.admin) {
            let quizOk = true
            quizArray.map((quizName) => {
                if (title === quizName && title !== this.props.match.params.id) {
                    console.log('Please choose a NEW name')
                    quizOk = false
                    this.setState({ difficultyWarning: "This quiz title is already taken, please choose another title" })
                }
                if (title.indexOf("/") > -1) {
                    console.log('string contains a slash')
                    quizOk = false
                    this.setState({ difficultyWarning: "Names cannot contain a forward slash, please choose another name" })
                }
                this.setState({ numberChecked: ++this.state.numberChecked })
                this.checkFinished(quizOk, title, modifier)
            })
        } else {
            let saveAble = true
            this.setState({ tagWarning: false })
            this.setState({ difficultyWarning: false })
            if (title.indexOf("/") > -1) {
                console.log('string contains a slash')
                this.setState({ difficultyWarning: "Names cannot contain a forward slash, please choose another name" })
                saveAble = false
            }
            // question.map((q, index)=> {
            //     if (!q.tag) {
            //         this.setState({
            //             tagWarning: "question " + (index+1) + " not tagged",
            //             saved: false
            //         })
            //         saveAble=false
            //         return
            //     }
            // })

            question.map((q, index) => {
                if (q.difficulty === 0) {
                    saveAble = false
                    this.setState({
                        difficultyWarning: "question " + (index + 1) + ", difficulty not set",
                        saved: false
                    })
                    return
                }
            })
            if (saveAble) {
                this.save(modifier)
            }
        }

    }

    checkFinished = (quizOk, title, modifier) => {
        const { numberChecked, numberOfQuizzes, isSubmittable } = this.state
        console.log('checking', quizOk)
        if (numberChecked === numberOfQuizzes && quizOk) {
            this.setState({
                numberChecked: 0,
                numberSaved: 0,
                isSubmittable: true,
                processing: true,
            })
            this.save(modifier)
            console.log('check complete, quiz submittable')
        } else if (numberChecked === numberOfQuizzes && !quizOk) {
            this.setState({
                numberChecked: 0,
                numberSaved: 0,
                isSubmittable: false,
                saved: false
            })
            console.log('check complete, quiz not submittable')
        }
    }

    checkSaved = (modifier) => {
        const { numberSaved, question, quizAdded, nr } = this.state
        var d = new Date()
        let minutes

        if (d.getMinutes() < 10) {
            minutes = `0${d.getMinutes()}`
        } else {
            minutes = d.getMinutes()
        }

        if (modifier === 'rename') {
            if (numberSaved === question.length && quizAdded) {
                this.setState({
                    redirect: true,
                    numberSaved: 0,
                    saved: `Saved at ${d.getHours()}:${minutes}`
                })
            } else {
                this.setState({
                    saveComplete: false,
                })
            }
        } else if (modifier === 'saveButton') {
            if (numberSaved === question.length && quizAdded) {
                if (this.state.title !== this.props.match.params.id) {
                    this.setState({
                        redirect: true,
                    })
                    return
                }
                this.setState({
                    saveComplete: true,
                    numberSaved: 0,
                    saved: `Saved at ${d.getHours()}:${minutes}`
                })
                this.pushData(nr)

            }
            // else { 
            //     if (!this.state.saveComplete) {
            //         console.log("BWOT")
            //         this.setState ({
            //             saveComplete: false,
            //         })
            //     }
            // }
        } else if (modifier === 'preview') {
            if (numberSaved === question.length) {
                console.log('previewing, save complete', numberSaved)
                this.setState({
                    saveComplete: true,
                    numberSaved: 0,
                    saved: `Saved at ${d.getHours()}:${minutes}`
                },
                    () => { this.openWindow() })
            }
            // else {
            //     console.log('previewing, save not complete', numberSaved)
            //     this.setState ({
            //         saveComplete: false,
            //     })
            // }
        }
    }

    openWindow = () => {
        const { nr } = this.state
        console.log('opening new window')
        //Opens preview window for admin user
        if (this.props.profile.admin) {
            window.open("/quizzes/" + this.state.title, "_blank")
            //Opens preview window for non admin user (only contains school's)
        } else if (this.props.profile.student === 'teacher') {
            window.open("/quizzesschool/" + this.state.title, "_blank")
        }
        this.pushData(nr)

        if (!this.state.saveComplete) {
            setTimeout(() => {
                this.checkSubmittable("saveButton");
            }, 3000)
        }

    }


    save = (modifier) => {
        console.log('save triggered')
        const { question, questionOriginal, title,
            subject } = this.state

        let { mcqDatabase, quizzesDatabase } = this.state

        this.setState({ saveComplete: false })

        if (subject === 'Economics') {
            mcqDatabase = 'mcqDatabase'
            quizzesDatabase = 'quizzes'
        } else {
            mcqDatabase = `mcqDatabase${subject}`
            quizzesDatabase = `quizzes${subject}`
        }


        let questionIDArray = []

        question.map((q, index) => {
            q.quizName = title
            q.position = index
            questionIDArray.push(q.questionID)
        })


        //Handling redundant questions
        //Only Admins can delete GradeIt questions
        if (this.props.profile.admin) {
            questionOriginal.map(q => {
                if (
                    q.questionID !== 'GKx1uJtJWR83k3PZCI49'
                    && q.questionID !== 'ORl4Qd0iGJbyIAAHQdYz'
                    && q.questionID !== 'HVCo169hz0j1FYmNdu8c'
                    && q.questionID !== 'dR3XD2yuykV5V9WHLrTA'
                    && q.questionID !== 'KD9NkMryecJWnOLyde0P'
                    && questionIDArray.indexOf(q.questionID) === -1
                    && q.school === "GradeIt") {
                    firebase.firestore().collection(mcqDatabase)
                        .doc(q.questionID)
                        .delete()
                        .then(() => console.log("Admin deleted:" + q.questionText))
                        .catch(err => console.log(err))
                }
            })
            //Teachers can delete their own questions
        } else if (this.props.profile.student === 'teacher') {
            questionOriginal.map(q => {
                if (q.questionID !== 'GKx1uJtJWR83k3PZCI49'
                    && q.questionID !== 'ORl4Qd0iGJbyIAAHQdYz'
                    && q.questionID !== 'HVCo169hz0j1FYmNdu8c'
                    && q.questionID !== 'dR3XD2yuykV5V9WHLrTA'
                    && q.questionID !== 'KD9NkMryecJWnOLyde0P'
                    && questionIDArray.indexOf(q.questionID) === -1
                    && q.school === this.props.profile.school) {
                    console.log('deleted', q.school, this.props.profile.school)
                    firebase.firestore().collection(mcqDatabase)
                        .doc(q.questionID)
                        .delete()
                        .then(() => console.log("Teacher deleted:" + q.questionText))
                        .catch(err => console.log(err))
                }
            })
        }

        //Saving individual questions
        question.map(q => {
            const obj = {
                questionText: q.questionText,
                answer1: q.answer1,
                answer2: q.answer2,
                answer3: q.answer3,
                answer4: q.answer4,
                correct: q.correct,
                createdAt: new Date(),
                difficulty: q.difficulty,
                exposition1: q.exposition1,
                exposition2: q.exposition2,
                exposition3: q.exposition3,
                exposition4: q.exposition4,
                graphQuestion: q.graphQuestion,
                quizName: title,
                position: q.position,
                school: q.school,
                tag: q.tag || '',
                url: q.url,
                user: q.user
            }

            //Only admins can modify GradeIt questions
            if (this.props.profile.admin) {
                //If question exists and the quiz has not been renamed, overwrite existing question
                if (q.questionID
                    && title == this.props.match.params.id
                    && q.questionID !== 'GKx1uJtJWR83k3PZCI49'
                    && q.questionID !== 'ORl4Qd0iGJbyIAAHQdYz'
                    && q.questionID !== 'HVCo169hz0j1FYmNdu8c'
                    && q.questionID !== 'dR3XD2yuykV5V9WHLrTA'
                    && q.questionID !== 'KD9NkMryecJWnOLyde0P') {
                    firebase.firestore().collection(mcqDatabase)
                        .doc(q.questionID)
                        .update(obj)
                        .then(() => {
                            console.log('number saved +1')
                            this.setState(prevState => ({ numberSaved: prevState.numberSaved + 1 }))
                            this.checkSaved(modifier)
                        })
                        .catch(err => console.log(err))
                    //If question is newly created, add to mcqDatabase
                } else {
                    firebase.firestore().collection(mcqDatabase)
                        .add(obj)
                        .then(() => {
                            console.log('number saved +1')
                            this.setState(prevState => ({ numberSaved: prevState.numberSaved + 1 }))
                            this.checkSaved(modifier)
                        })
                        .catch(err => console.log(err))
                }


                console.log("saved")
            } else if (this.props.profile.student === "teacher") {
                // Teachers will either overwrite their OWN existing questions or add new questions
                const objNew = {
                    questionText: q.questionText,
                    answer1: q.answer1,
                    answer2: q.answer2,
                    answer3: q.answer3,
                    answer4: q.answer4,
                    correct: q.correct,
                    createdAt: new Date(),
                    difficulty: q.difficulty,
                    exposition1: q.exposition1,
                    exposition2: q.exposition2,
                    exposition3: q.exposition3,
                    exposition4: q.exposition4,
                    graphQuestion: q.graphQuestion,
                    quizName: title,
                    position: q.position,
                    school: this.props.profile.school,
                    tag: q.tag || '',
                    url: q.url,
                    user: q.user
                }
                if (q.questionID && q.school === this.props.profile.school
                    && title == this.props.match.params.id) {
                    //Overwrite existing question
                    firebase.firestore().collection(mcqDatabase)
                        .doc(q.questionID)
                        .update(objNew)
                        .then(() => {
                            this.setState(prevState => ({ numberSaved: prevState.numberSaved + 1 }))
                            this.checkSaved(modifier)
                        })
                        .catch(err => console.log(err))
                } else if (q.questionID && q.school === this.props.profile.school
                    && title !== this.props.match.params.id) {
                    firebase.firestore().collection(mcqDatabase)
                        .add(objNew)
                        .then(() => {
                            this.setState(prevState => ({ numberSaved: prevState.numberSaved + 1 }))
                            this.checkSaved(modifier)
                        })
                        .catch(err => console.log(err))
                } else {
                    //Add new question
                    firebase.firestore().collection(mcqDatabase)
                        .add(objNew)
                        .then(() => {
                            this.setState(prevState => ({ numberSaved: prevState.numberSaved + 1 }))
                            this.checkSaved(modifier)
                        })
                        .catch(err => console.log(err))
                }
            }
        })

        firebase.firestore().collection(quizzesDatabase)
            .where('school', 'in', ['GradeIt', this.props.profile.school])
            .get()
            .then(querySnapshot => {
                let quizArray = []
                let quizNames = []
                let quizGradeIt = []

                const quizzes = querySnapshot.docs.map(doc => doc.data());
                quizzes.map(quiz => {
                    quizArray.push({
                        quizName: quiz.quizName,
                        quizCategory: quiz.quizCategory,
                        school: quiz.school
                    })
                    quizNames.push(quiz.quizName)
                    if (quiz.school === 'GradeIt') {
                        quizGradeIt.push(quiz.quizName)
                    }
                })

                //If non-GradeIt teacher
                if (this.props.profile.student === "teacher" && !this.props.profile.admin) {

                    //If quiz already exists 
                    quizArray.map(quiz => {
                        if (title === quiz.quizName) {
                            //Teacher is modifying a GradeIt quiz, created new quiz in My quizzes
                            if (quiz.quizCategory !== "My quizzes") {
                                console.log('Teacher is modifying a GradeIt quiz, created new quiz in My quizzes')
                                firebase.firestore().collection(quizzesDatabase)
                                    .add({
                                        quizCategory: 'My quizzes',
                                        quizName: title,
                                        school: this.props.profile.school,
                                        createdAt: new Date(),
                                        userID: this.props.auth.uid
                                    })
                                    .then(() => {
                                        this.setState({ quizAdded: true },
                                            () => { this.checkSaved(modifier) })
                                    }).catch(err => console.log(err))
                                //Teacher is modifying one of their own quizzes, no action required
                            } else if (quiz.quizCategory === 'My quizzes') {
                                console.log('Teacher is modifying one of their own quizzes, no action required')
                                this.setState({ quizAdded: true },
                                    () => { this.checkSaved(modifier) })
                            }
                        }
                    })

                    //If quiz doesn't exist
                    if (quizNames.indexOf(title) === -1) {
                        console.log('Teacher is creating a new quiz, created new quiz in My quizzes')
                        firebase.firestore().collection(quizzesDatabase)
                            .add({
                                quizCategory: 'My quizzes',
                                quizName: title,
                                school: this.props.profile.school,
                                createdAt: new Date(),
                                userID: this.props.auth.uid
                            })
                            .then(() => {
                                this.setState({ quizAdded: true },
                                    () => { this.checkSaved(modifier) })
                            })
                            .catch(err => console.log(err))
                    }
                }

                //If GradeIt Admin
                if (this.props.profile.admin) {
                    //If quiz already exists in GradeIt array
                    quizArray.map(quiz => {
                        if (title === quiz.quizName && quiz.school === "GradeIt") {
                            console.log('Admin is modifying a GradeIt quiz, no action required')
                            this.setState({ quizAdded: true },
                                () => { this.checkSaved(modifier) })
                        } else if (title === quiz.quizName && quiz.school === "GradeIt") {
                            console.log('Admin is creating a new quiz, created a neq quiz in My quizzes')
                            firebase.firestore().collection(quizzesDatabase)
                                .add({
                                    quizCategory: 'My quizzes',
                                    quizName: title,
                                    school: this.props.profile.school,
                                    createdAt: new Date(),
                                    userID: this.props.auth.uid
                                })
                                .then(() => {
                                    this.setState({
                                        quizAdded: true
                                    },
                                        () => {
                                            this.checkSaved(modifier)
                                        })
                                })
                                .catch(err => console.log(err))
                        }
                    })
                    //If quiz doesn't exist in GradeIt array
                    if (quizGradeIt.indexOf(title) === -1) {
                        console.log('Admin is creating a new quiz, created a new quiz in the relevant category')
                        firebase.firestore().collection(quizzesDatabase)
                            .add({
                                quizCategory: 'My quizzes',
                                quizName: title,
                                school: this.props.profile.school,
                                createdAt: new Date(),
                                userID: this.props.auth.uid
                            })
                            .then(() => {
                                this.setState({ quizAdded: true },
                                    () => { this.checkSaved(modifier) })
                            })
                            .catch(err => console.log(err))

                    }
                }


            })

    }



    pushData2(nr) {
        this.setState({
            nr: nr + 1
        })

    }

    handleChange = (e) => {
        let { nr } = this.state
        var questionNew = this.state.question
        questionNew[nr][e.target.id] = e.target.value
        this.setState({
            question: questionNew
        })
    }

    handleTitleChange = (e) => {
        let { value } = e.target

        this.setState({
            title: value
        })
    }

    correct = (key) => {
        let { nr } = this.state
        var questionNew = this.state.question
        questionNew[nr]["correct"] = key
        this.setState({
            question: questionNew
        })
    }

    handlePageClick = data => {
        let selected = data.selected - 1;
        this.pushData2(selected)
    }


    showExposition = (expositionNumber) => {
        let dynamicKey = `showExposition${expositionNumber}`
        this.setState({
            showExposition1: false,
            showExposition2: false,
            showExposition3: false,
            showExposition4: false,
            [dynamicKey]: true,
            focused: expositionNumber
        })
    }

    getExposition = () => {
        const { question, nr, focused, subject } = this.state

        let exposition = question[nr] && question[nr][`exposition${focused}`]

        return (
            <div>
                {subject!=="gcseMaths" 
                ?
                    <div className="exposition-container">
                        <div>
                            {focused === question[nr].correct
                                ? <p className="exposition"><span className="correct">Correct:</span></p>
                                : <p className="exposition"><span className="incorrect">Incorrect:</span></p>}

                        </div>
                        <div className="editMCQFormContainer">
                            <CKEditor
                                data={exposition}
                                type="inline"
                                onChange={this.onEditorChange}
                                config={{
                                    toolbar:['Bold'],
                                }}

                            />
                        </div>

                    </div>
                : 
                    <div className="exposition-container">
                        <div>
                            {focused === question[nr].correct
                                ? <p className="exposition"><span className="correct">Correct:</span></p>
                                : <p className="exposition"><span className="incorrect">Incorrect:</span></p>}

                        </div>
                        <div className="editMCQFormContainer">
                            <EditorPreview
                                question2={exposition}
                                style={"mathsExpositionPreviewContainer"}
                                textStyle={"mathsExpositionText"}
                                toggleEditor={this.toggleEditor}
                                editableElement={`exposition${focused}`}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }

    //Handles CK editor for non-Maths subjects
    onEditorChange = (evt) => {
        const { nr, focused } = this.state
        const clean = evt.editor.getData()

        let newQuestions = this.state.question


        newQuestions[nr][`exposition${focused}`] = clean

        this.setState({
            question: newQuestions
        });
    }

    handleImage = e => {

        const { nr, question } = this.state
        const { subject } = this.state
        let imagesDatabase

        if (subject === 'Economics') {
            imagesDatabase = 'images'
        } else {
            imagesDatabase = `images${subject}`
        }
        let newQuestions = this.state.question
        let key = (Math.random().toFixed(9)).substring(2, 11)

        if (e.target.files[0]) {
            const image = e.target.files[0];
            if (image.size < 2097152) {
                console.log('image saved')
                const uploadTask = storage.ref(`${imagesDatabase}/${image.name}${key}`).put(image);
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // progrss function ....
                        // const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    },
                    (error) => {
                        // error function ....
                        console.log(error);
                    },
                    () => {
                        // complete function ....
                        storage.ref(imagesDatabase).child(`${image.name}${key}`).getDownloadURL().then(url => {

                            newQuestions[nr].url = url
                            this.setState({
                                question: newQuestions
                            })

                        })
                    }
                );
            } else {
                this.setState({ imageWarning: true })
            }
        }
    }

    getToggle() {
        const { question, nr } = this.state

        let checked
        if ((question[nr] && question[nr].graphQuestion) === "yes") {
            checked = true
        } else if ((question[nr] && question[nr].graphQuestion) === "no") {
            checked = false
        }



        return (

            <div className="toggleMCQForm">
                <p>No picture</p>
                <Toggle
                    checked={checked || false}
                    className="custom-classname"
                    icons={false}
                    onChange={this.togglePicture} />
                <p>Picture</p>
            </div>
        )
    }

    togglePicture = () => {
        const { question, nr } = this.state

        let newQuestions = this.state.question


        if ((question[nr] && question[nr].graphQuestion) === "yes") {
            newQuestions[nr].graphQuestion = "no"
        } else {
            newQuestions[nr].graphQuestion = "yes"
        }

        this.setState({ question: newQuestions })

    }

    questionSelect = (index) => {
        this.setState({ nr: index })
    }

    onDragEnd = result => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const newQuestions = Array.from(this.state.question)
        newQuestions.splice(source.index, 1);
        newQuestions.splice(destination.index, 0, this.state.question[source.index])

        this.setState({
            question: newQuestions,
            nr: destination.index
        })
        // this.save()

    }

    getTagger = () => {
        const { admin, nr, question, subject } = this.state

        let classNameAdmin

        if (subject === 'Economics') {

            let classNameDefinition = "MCFormTagger btn grey"
            let classNameGraph = "MCFormTagger btn grey"
            let classNameCalculation = "MCFormTagger btn grey"
            let classNameAnalysis = "MCFormTagger btn grey"

            if ((question[nr] && question[nr]["tag"] == "definition")) {
                classNameDefinition = "MCFormTagger btn"
            } else if (question[nr] && (question[nr]["tag"] == "graph")) {
                classNameGraph = "MCFormTagger btn"
            } else if (question[nr] && (question[nr]["tag"] == "calculation")) {
                classNameCalculation = "MCFormTagger btn"
            } else if (question[nr] && (question[nr]["tag"] == "analysis")) {
                classNameAnalysis = "MCFormTagger btn"
            }

            return (
                <div>
                    {admin
                        ?
                        <div className='flexRow'>
                            <div>
                                <div className={classNameDefinition || "MCFormTagger btn grey"} onClick={() => this.changeTag(1)}> <p className="MCQFormTagger">Definition</p></div>
                                <div className={classNameGraph || "MCFormTagger btn grey"} onClick={() => this.changeTag(2)}> <p className="MCQFormTagger">Graph</p></div>
                                <div className={classNameCalculation || "MCFormTagger btn grey"} onClick={() => this.changeTag(3)}> <p className="MCQFormTagger">Calculation</p></div>
                                <div className={classNameAnalysis || "MCFormTagger btn grey"} onClick={() => this.changeTag(4)}> <p className="MCQFormTagger">Analysis</p></div>
                            </div>
                            {this.getDifficulty()}
                        </div>
                        :
                        null
                    }
                </div>
            )

        } else {

            let classNameKnowledge = "MCFormTagger btn grey"
            let classNameApplication = "MCFormTagger btn grey"
            let classNameAnalysis = "MCFormTagger btn grey"

            if ((question[nr] && question[nr]["tag"] == "knowledge")) {
                classNameKnowledge = "MCFormTagger btn"
            } else if (question[nr] && (question[nr]["tag"] == "application")) {
                classNameApplication = "MCFormTagger btn"
            }
            // else if (question[nr] && (question[nr]["tag"]=="analysis")) {
            //     classNameAnalysis = "MCFormTagger btn"
            // }
            return (
                <div>
                    {admin
                        ?
                        <div className='flexRow'>
                            {(subject === "Physics")
                                ?
                                <div>
                                    <div className={classNameKnowledge || "MCFormTagger btn grey"} onClick={() => this.changeTag(1)}> <p className="MCQFormTagger">Knowledge</p></div>
                                    <div className={classNameApplication || "MCFormTagger btn grey"} onClick={() => this.changeTag(2)}> <p className="MCQFormTagger">Application</p></div>
                                    {/* <div className={classNameAnalysis || "MCFormTagger btn grey"} onClick={()=>this.changeTag(3)}> <p className="MCQFormTagger">Analysis</p></div> */}
                                </div>
                                : null
                            }
                            {this.getDifficulty()}
                        </div>
                        :
                        null
                    }
                </div>
            )

        }

    }

    changeTag = (n) => {
        let { nr, subject } = this.state
        var questionNew = this.state.question

        if (subject === 'Economics') {
            if (n === 1) {
                questionNew[nr]["tag"] = "definition"
            } else if (n === 2) {
                questionNew[nr]["tag"] = "graph"
            } else if (n === 3) {
                questionNew[nr]["tag"] = "calculation"
            } else if (n == 4) {
                questionNew[nr]["tag"] = "analysis"
            }
        } else if (subject === 'Physics') {
            if (n === 1) {
                questionNew[nr]["tag"] = "knowledge"
            } else if (n === 2) {
                questionNew[nr]["tag"] = "application"
            } else if (n === 3) {
                questionNew[nr]["tag"] = "analysis"
            }
        }

        this.setState({
            question: questionNew
        })
    }

    getDifficulty = () => {
        const { question, nr } = this.state

        return (
            <div className='newMCQFormDifficultyContainer'>
                <p className='newMCQFormDifficultyLabel'>
                    Choose difficulty
                </p>
                <select name="difficulty" value={question[nr]['difficulty']} onChange={this.handleDifficulty} className='newMCQFormDifficultyDropdown'>
                    <option value=""></option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                    <option value='7'>7</option>
                    <option value='8'>8</option>
                </select>
            </div>

        )
    }

    handleDifficulty = (e) => {
        const { nr } = this.state
        let { value } = e.target

        var questionNew = this.state.question
        questionNew[nr]['difficulty'] = +value

        this.setState({ question: questionNew })
    }

    addQuestion = () => {
        const { nr, total, question } = this.state
        const { profile, match, auth } = this.props

        let newQuestions = Array.from(this.state.question)
        newQuestions[total] = {
            questionText: "Question goes here...",
            answer1: "Answer goes here...",
            answer2: "Answer goes here...",
            answer3: "Answer goes here...",
            answer4: "Answer goes here...",
            correct: 1,
            createdAt: new Date(),
            difficulty: 0,
            exposition1: "Explanation for A goes here",
            exposition2: "Explanation for B goes here",
            exposition3: "Explanation for C goes here",
            exposition4: "Explanation for D goes here",
            graphQuestion: "no",
            quizName: match.params.id,
            url: "",
            user: auth.uid,
            tag: "",
            school: profile.school,
            randomID: (Math.random().toFixed(5)).substring(2, 7)
        }
        this.setState({
            question: newQuestions,
            total: total + 1,
            nr: total
        })
    }

    previousQuestion = (questions) => {
        const { nr } = this.state;

        const nnr = nr === 0
            ? 0
            : nr - 1;

        const question = questions[nnr];

        return {
            nr: nnr,
            questionText: question.questionText,
            answer1: question.answer1,
            answer2: question.answer2,
            answer3: question.answer3,
            answer4: question.answer4,
            correct: question.correct,
            createdAt: new Date(),
            difficulty: question.difficulty,
            exposition1: question.exposition1,
            exposition2: question.exposition2,
            exposition3: question.exposition3,
            exposition4: question.exposition4,
            graphQuestion: question.graphQuestion,
            quizName: question.quizName,
            url: question.url,
            user: question.user,
        };
    }

    removeQuestion = (index, event) => {
        const { nr, total, question } = this.state
        const { profile, match, auth } = this.props
        event.stopPropagation();

        if (this.state.total === 1) {
            const { question } = this.state
            const newQuestion = []
            newQuestion.push({
                questionText: "Question goes here...",
                answer1: "Answer goes here...",
                answer2: "Answer goes here...",
                answer3: "Answer goes here...",
                answer4: "Answer goes here...",
                correct: 1,
                createdAt: new Date(),
                difficulty: 0,
                exposition1: "Explanation for A goes here",
                exposition2: "Explanation for B goes here",
                exposition3: "Explanation for C goes here",
                exposition4: "Explanation for D goes here",
                graphQuestion: "no",
                quizName: match.params.id,
                url: "",
                user: auth.uid,
                tag: "",
                randomID: (Math.random().toFixed(5)).substring(2, 7)
            })
            const newState = {
                ...this.state,
                question: newQuestion,
                total: newQuestion.length
            }
            this.setState(newState);
        }

        else {

            const newQuestion = Array.from(this.state.question)

            newQuestion.splice(index, 1)

            //Automatically moves to previous card
            const previous = this.previousQuestion(newQuestion);

            const newState = {
                question: newQuestion,
                total: newQuestion.length,
                ...previous
            }
            this.setState(newState);
        }
        this.toggleDeleteWarning()

    }

    retrieveIndex = (n) => {

        this.setState({
            removalIndex: n
        })
    }

    toggleDeleteWarning = () => {
        const { deleteWarning } = this.state

        this.setState({
            deleteWarning: deleteWarning ? false : true
        })
    }

    toggleImageWarning = () => {
        const { imageWarning } = this.state

        this.setState({
            imageWarning: imageWarning ? false : true
        })
    }

    toggleDeleteQuizWarning = () => {
        const { deleteQuizWarning } = this.state

        this.setState({
            deleteQuizWarning: deleteQuizWarning ? false : true
        })
    }

    toggleNewQuizDialog = () => {

        this.setState({
            newQuiz: false
        })

        firebase.firestore().collection('users')
            .doc(this.props.auth.uid)
            .update({
                seenNewQuizDialog: true
            })

    }

    toggleDraggableDialog = () => {

        if (!this.state.viewDraggable) {
            this.setState({
                viewDraggable: true
            })

            firebase.firestore().collection('users')
                .doc(this.props.auth.uid)
                .update({
                    seenNewDraggable: true
                })
        } else {
            this.setState({
                viewDraggable: false
            })
        }
    }

    getCKEditorQuestion = () => {
        let { nr, question, subject } = this.state

        return (
            <div className="mathsQuestionPreviewContainer">
                {subject!=="gcseMaths" 
                ?
                    <CKEditor
                        data={question[nr] ? question[nr].questionText : "Question goes here..."}
                        type="inline"
                        onChange={this.onEditorChangeQuestion}
                        config={{
                            toolbar:["Bold"],
                        }}
                    />
                :   
                    <EditorPreview 
                        question2={question[nr] ? question[nr].questionText : "Question goes here..."}
                        style={"mathsQuestionPreviewContainer"}
                        textStyle={"mathsQuestionText"}
                        toggleEditor={this.toggleEditor}
                        editableElement="questionText"
                    />
                } 
            </div>
        )
    }

    //Handles question change for non-Maths subjects 
    onEditorChangeQuestion = (evt) => {
        const { nr, focused } = this.state
        const clean = evt.editor.getData()

        var questionNew = this.state.question
        questionNew[nr]["questionText"] = clean
        this.setState({
            question: questionNew
        })

    }

    //Handles changing of ALL elements for Maths, not just the question!
    onExternalEditorChangeQuestion = (inputText, cleanArray, editableElement) => {
        const { nr, focused } = this.state

        console.log(editableElement)

        var questionNew = this.state.question
        questionNew[nr][editableElement] = inputText
        this.setState({
            question: questionNew,
            editableText: cleanArray,
            editorPreviewText: inputText,
        })

    }

    getCKEditor = (answerNumber) => {

        let { total, nr, question, subject } = this.state

        return (
            <div className="mathsAnswerContainer">
            {subject!=="gcseMaths"
            ?
            <div className="newMCQFormAnswersCKEditor">
                <CKEditor
                    data={question[nr] ? question[nr][`answer${answerNumber}`] : "Answer goes here..."}
                    type="inline"
                    // onChange={this.onEditorChangeAnswers1}
                    onChange={(evt) => this.onEditorChangeAnswers1(answerNumber, evt)}
                    config={{
                        toolbar:["Bold"],
                    }}
                />
            </div>
            :
            <div className="newMCQFormAnswersCKEditor">
                <EditorPreview
                    question2={question[nr] ? question[nr][`answer${answerNumber}`] : "Answer goes here..."}
                    style={"mathsAnswerContainer"}
                    textStyle={"mathsAnswerText"}
                    toggleEditor={this.toggleEditor}
                    editableElement={`answer${answerNumber}`}
                />
            </div>
            }
            </div>
        )
    }


    onEditorChangeAnswers1 = (answerNumber, evt) => {
        const { nr, focused } = this.state
        const clean = evt.editor.getData()

        let newQuestions = this.state.question

        newQuestions[nr][`answer${answerNumber}`] = clean

        this.setState({
            question: newQuestions
        });

    }

    toggleEditor = (editableText, editorPreviewText, editableElement ) => {
        this.setState ({
            showEditor: this.state.showEditor ? false : true,
            editableText: editableText,
            editorPreviewText: editorPreviewText,
            editableElement: editableElement

        })
    }



    render() {
        let { total, nr, question, focused, admin,
            title, deleteWarning, removalIndex, imageWarning,
            renameQuiz, redirect, tagWarning, difficultyWarning,
            saveComplete, saved, deleteQuizWarning, newQuiz,
            newDraggable, viewDraggable, showEditor } = this.state

        if (redirect) {
            window.location.href = `/newmcqformschool/${title}`;
        }

        if (this.props.auth.isLoaded && this.props.profile.school == 'individual') return <Redirect to='/' />

        return (

            <div>
                {renameQuiz
                    ? <ChangeNamePopupMCQForm
                        changeTitle={this.handleTitleChange}
                        renameQuiz={this.renameQuiz}
                    />
                    : null}

                {deleteWarning
                    ? <DeleteWarning
                        toggleDeleteWarning={this.toggleDeleteWarning}
                        removeQuestion={this.removeQuestion}
                        removalIndex={removalIndex}
                    />
                    : null
                }

                {deleteQuizWarning
                    ? <DeleteQuizWarning
                        toggleDeleteQuizWarning={this.toggleDeleteQuizWarning}
                        title={title}
                    />
                    : null
                }

                {imageWarning
                    ? <ImageWarning
                        toggleImageWarning={this.toggleImageWarning}
                    />
                    : null
                }

                {!saveComplete
                    ? <Spinner checkSubmittable={this.checkSubmittable} />
                    : null}

                {newQuiz
                    ? <NewQuizDialog toggleNewQuizDialog={this.toggleNewQuizDialog} />
                    : null}

                {viewDraggable
                    ? <NewDraggable toggleDraggableDialog={this.toggleDraggableDialog} />
                    : null
                }

                {showEditor
                    ? 
                    <Editor 
                        toggleEditor={this.toggleEditor}
                        field="question"
                        cleanArray={this.state.editableText}
                        editorPreviewText={this.state.editorPreviewText}
                        onExternalEditorChangeQuestion={this.onExternalEditorChangeQuestion}
                        editableElement={this.state.editableElement}
                    />
                    : null
                }


                <div className="newMCQFormTopContainer">
                    <div className="input-field newMCQForm">
                        <p className="NewMCQFormTitleLabel">Quiz title</p>
                        <input type="text" id="title" placeholder="Title goes here..." value={title} onChange={this.handleTitleChange} />
                    </div>

                    <div className='flexColumnNewMCQFormTopContainer'>
                        <div className="newMCQFormSaveContainerFlexRow">
                            <div className='flexRowNewMCQFormSaveContainer'>
                                <div className="btn-floating newMCQFormSave" onClick={(() => this.checkSubmittable("saveButton"))}>
                                    <i className="material-icons">save</i>
                                </div>
                                <div className="newMCQFormSaveContainerFlexColumn">
                                    <p className="NewMCQFormSave">SAVE</p>
                                </div>
                            </div>
                            <div className='flexRowNewMCQFormSaveContainer'>
                                <div className="btn-floating newMCQFormSave" onClick={this.preview}>
                                    <i className="material-icons">preview</i>
                                </div>
                                <div className="newMCQFormSaveContainerFlexColumn">
                                    <p className="NewMCQFormPreview">PREVIEW</p>
                                </div>
                            </div>
                            <div className='flexRowNewMCQFormSaveContainer'>
                                <div className="btn-floating newMCQFormSave" onClick={this.toggleDeleteQuizWarning}>
                                    <i className="material-icons">delete</i>
                                </div>
                                <div className="newMCQFormSaveContainerFlexColumn">
                                    <p className="NewMCQFormPreview">DELETE QUIZ</p>
                                </div>
                            </div>
                        </div>
                        <div className="newMCQFormSaveContainerFlexRow">
                            {saved
                                ? <p className="NewMCQFormSaved">{saved}</p>
                                : null
                            }
                            {tagWarning
                                ? <p className="NewMCQFormTagWarning">{tagWarning}</p>
                                : null}
                            {difficultyWarning
                                ? <p className="NewMCQFormTagWarning">{difficultyWarning}</p>
                                : null}
                        </div>
                    </div>

                </div>

                <div className="bigContainerNewMCQForm">
                    <div className="flexRow">
                        <div className="row">
                            <div className="col-lg-10 col-lg-offset-1">
                                <div className="topContainer">
                                    {question[nr] && question[nr].graphQuestion === "yes" ?
                                        <div className="fullWidth">
                                            <div className="flexRow NewMCQForm">
                                                <div className="question">
                                                    <div className="gamificationTopContainerMCQForm">

                                                        <h4 className="gamification">Question {nr + 1}/{total}</h4>
                                                        {this.getToggle()}
                                                    </div>
                                                    {this.getTagger()}

                                                    <div className="mcqQuestionContainerMCQForm">
                                                        {this.getCKEditorQuestion()}
                                                        {/* <textarea id="questionText" onChange={this.handleChange} className="MCQFormQuestionText" value={question[nr] ? question[nr].questionText: ""}/> */}
                                                    </div>
                                                </div>

                                                <div className="imageMCQForm">
                                                    {question[nr].url
                                                        ? <img src={question[nr].url} className="graph" alt="graph" />
                                                        :
                                                        <div className="flashcardFormImagePlaceholderMCQForm">
                                                            <div className="flexColumnPlaceHolderTextMCQForm">
                                                                <p className='placeHolderTextMCQForm'>•300 x 300 images work best</p>
                                                                <p className='placeHolderTextMCQForm'>•JPG or PNG files only</p>
                                                                <p className='placeHolderTextMCQForm'>•2MB max file size</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <input
                                                        className="chooseFileflashcardFormImageMCQForm"
                                                        type="file"
                                                        accept='.png, .jpg'
                                                        onChange={this.handleImage} />
                                                </div>
                                                <div className="mcqQuestionContainerMobileMCQForm">
                                                    {this.getCKEditorQuestion()}
                                                    {/* <textarea id="questionText" onChange={this.handleChange} className="MCQFormQuestionTextMobile" value={question[nr] ? question[nr].questionText: "null"}/> */}
                                                </div>
                                            </div>

                                        </div>
                                        :
                                        <div className="fullWidth">
                                            <div className="flexRow NewMCQForm">
                                                <div className="questionContainer">
                                                    <div className="gamificationTopContainerMCQForm">

                                                        <h4 className="gamification">Question {nr + 1}/{total}</h4>
                                                        {this.getToggle()}
                                                    </div>
                                                    {this.getTagger()}
                                                    <div className="mcqQuestionContainerMCQForm">
                                                        {this.getCKEditorQuestion()}
                                                        {/* <textarea id="questionText" onChange={this.handleChange} className="MCQFormQuestionText" value={question[nr] ? question[nr].questionText: "null"}/> */}
                                                    </div>
                                                    <div className="mcqQuestionContainerMobileMCQForm">
                                                        {this.getCKEditorQuestion()}
                                                        {/* <textarea id="questionText" onChange={this.handleChange} className="MCQFormQuestionTextMobile" value={question[nr] ? question[nr].questionText: "null"}/> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>


                                <div id="answers">
                                    <ul>
                                        <li onChange={this.handleChange} onClick={() => { this.showExposition(1) }} data-id="1"><span onClick={() => { this.correct(1) }}>{question[nr] && question[nr].correct == 1 ? <span className="spanLetter">✔️</span> : <span className="spanLetter">A</span>}</span>{this.getCKEditor(1)}</li>
                                        <li onChange={this.handleChange} onClick={() => { this.showExposition(2) }} data-id="2"><span onClick={() => { this.correct(2) }}>{question[nr] && question[nr].correct == 2 ? <span className="spanLetter">✔️</span> : <span className="spanLetter">B</span>}</span>{this.getCKEditor(2)}</li>
                                        <li onChange={this.handleChange} onClick={() => { this.showExposition(3) }} data-id="3"><span onClick={() => { this.correct(3) }}>{question[nr] && question[nr].correct == 3 ? <span className="spanLetter">✔️</span> : <span className="spanLetter">C</span>}</span>{this.getCKEditor(3)}</li>
                                        <li onChange={this.handleChange} onClick={() => { this.showExposition(4) }} data-id="4"><span onClick={() => { this.correct(4) }}>{question[nr] && question[nr].correct == 4 ? <span className="spanLetter">✔️</span> : <span className="spanLetter">D</span>}</span>{this.getCKEditor(4)}</li>

                                    </ul>
                                </div>


                                {focused ? this.getExposition(focused) : null}
                            </div>
                        </div>
                        <div className='flexColumn'>
                            <div className="droppableAreaMCQForm">
                                {newDraggable
                                    ?
                                    <div>
                                        <div className="btn-floating newQuizFormDraggable pulse" onClick={this.toggleDraggableDialog}>
                                        </div>
                                    </div>
                                    :
                                    null}
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Column key="0"
                                        question={question}
                                        column="0"
                                        questionSelect={this.questionSelect}
                                        toggleDeleteWarning={this.toggleDeleteWarning}
                                        retrieveIndex={this.retrieveIndex}
                                    />
                                </DragDropContext>

                            </div>
                            <div className="MCQFormRightContainer">
                                <div className="btn-floating newMCQForm">
                                    <i className="material-icons" onClick={this.addQuestion}>add</i>
                                </div>
                                <p className='addQuestionButtonLabelNewMcqForm'>Add question</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, school, firstName, lastName) => dispatch(createScore(score, total, quizName, school, firstName, lastName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(newMCQForm)
