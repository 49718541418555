import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import Spinner from '../spinner/spinner'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

class NoFlag extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    home = () => {
        this.setState ({home: true})
    }
    
    render() {

        const {mcqGraph, toggleFlagPopup} = this.props
        const {home} = this.state

        if (home) {
            return <Redirect to='/'/>
        }
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp">
                            {mcqGraph 
                            ? 
                            <div className="returnToQuizzesContainer">
                                <div className="btn-floating closePopup" onClick={this.home}>
                                    <i className="material-icons">exit_to_app</i>
                                </div>
                            </div>
                            : 
                                <div className="btn-floating closePopup" onClick={toggleFlagPopup}>
                                    <i className="material-icons">close</i>
                                </div>
                            }
                            <h5 className="needHelp">Good job!</h5>
                            <p className="needHelp">You don't have any new flagged questions from students</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(NoFlag);