import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Route, Redirect, Link } from 'react-router-dom'
import Spinner from '../spinner/spinner'
import CategoryMenu from './categoryMenu'
import McqGraph from '../mcq/mcqGraph'
import RestrictionsWarning from './restrictionsWarning'


import './diagnostic.scss';
import restrictionsWarning from './restrictionsWarning';


class Diagnostic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            category: "",
            categoryArray: [],
            numberOfQuestions: 25,
            categoryNumber: 0
        }



    }

    componentDidMount() {

        let subject
        this.props.profile.subject == "Economics"
            ? subject = ""
            : subject = this.props.profile.subject


        //Retrieves themes
        let themeArray = []
        firebase.firestore().collection("quizzes" + subject)
            .where("school", "==", "GradeIt")
            .get()
            .then(querySnapshot => {
                querySnapshot.docs.map(doc => {
                    if (!themeArray.includes(doc.data()["quizCategory"])) {
                        themeArray.push(doc.data()["quizCategory"])
                    }
                })
                let themeArraySorted = themeArray.sort()
                console.log(themeArraySorted)

                firebase.firestore().collection("schools")
                .where("school", "==", this.props.profile.school)
                .get()
                .then(querySnapshot => {
                    querySnapshot.docs.map(school => {
                        if (!school.data().unapproved) {
                            if ((school.data()[`${subject}restrictions`])
                            &&
                            (school.data()[`${subject}restrictions`]["Global"])
                            &&
                            (school.data()[`${subject}restrictions`]["Global"]["active"]===true)
                            ) {
                                console.log("1")
                                this.setState({
                                    restrictions: school.data()[`${subject}restrictions`]["Global"]
                                })
                            } else if ((school.data()[`${subject}restrictions`])
                                &&
                                (school.data()[`${subject}restrictions`][this.props.profile[`${subject}class`]])
                                &&
                                (Object.entries(school.data()[`${subject}restrictions`][this.props.profile[`${subject}class`]]).length > 0)
                                ) {
                                    console.log("2")
                                    this.setState({
                                        restrictions: school.data()[`${subject}restrictions`][this.props.profile[`${subject}class`]]
                                    }) 
                            } 
                            else {
                                this.setState({
                                    restrictions: {}
                                })
                            }
                        }
                    })
                })
                

                this.setState({
                    themeArraySorted,
                    loaded: true
                })
            })
        
        //Prevents users from seeing the "Try diagnostics dialog more than once"
        if(!this.props.profile.tryDiagnostics) {
            firebase.firestore().collection('users')
                .doc(this.props.auth.uid)
                .update({
                    tryDiagnostics: true
            })

            firebase.firestore().collection('economicsData').doc('newUsers').update({
                triedDiagnostics: firebase.firestore.FieldValue.increment(1),
            })
        }



    }

    toggleCategoryMenu = () => {
        this.setState({
            categoryMenu: this.state.categoryMenu ? false : true
        })
    }

    addTopic = (category) => {

        if (category == "All topics") {
            let newCategoryArray = Array.from(this.state.categoryArray)
            this.state.themeArraySorted.map(cat => newCategoryArray.push(cat))

            // Clears topic menu list
            let newMenuArray = []

            this.setState({
                categoryArray: newCategoryArray,
                themeArraySorted: newMenuArray,
                categoryMenu: false,
                numberOfTopics: newCategoryArray.length
            })
        } else {
            let newCategoryArray = Array.from(this.state.categoryArray)
            newCategoryArray.push(category)

            let newMenuArray = Array.from(this.state.themeArraySorted)
            newMenuArray = newMenuArray.filter(item => item !== category)

            this.setState({
                categoryArray: newCategoryArray,
                themeArraySorted: newMenuArray,
                numberOfTopics: newCategoryArray.length
            })
        }
    }

    removeTopic = (category) => {
        let newCategoryArray = Array.from(this.state.categoryArray)
        console.log(newCategoryArray)
        let newCategoryArray2 = newCategoryArray.filter(item => item !== category)
        console.log(newCategoryArray2)

        let newMenuArray = Array.from(this.state.themeArraySorted)
        newMenuArray.push(category)
        let newMenuArraySorted = newMenuArray.sort()


        this.setState({
            categoryArray: newCategoryArray2,
            themeArraySorted: newMenuArraySorted,
            numberOfTopics: newCategoryArray2.length
        })
    }

    incrementQuestions = (modifier) => {
        this.setState({
            numberOfQuestions: modifier == "add" ? this.state.numberOfQuestions + 1 : this.state.numberOfQuestions - 1
        })
    }

    handleChange = (e) => {
        let { id, name, value, type, checked } = e.target

        if (value > 0) {
            this.setState({
                [name || id]: value
            }, () => name == "school" ? this.checkSimilar() : null)
        }

    }

    checkRestrictions = () => {
        let restrictionsArray = []
        let restrictionsCheck = {}
        let totallyRestricted = []
        let restrictionsWarning = false

        this.state.restrictions && Object.entries(this.state.restrictions).map(theme => {


            //If statement prevents function from mapping through "active" key:value pair, which would return "true" or "false" rather than an array
            if(theme[1].length>0) {
                let counter = 0
                theme[1].map(quiz =>{
                    if (quiz.visibility===false) {
                        restrictionsArray.push(quiz.quizName)
                        counter++
                    }
                })
                restrictionsCheck[theme[0]]= counter
            }
        })

        //Check whether one of the selected categories has been restricted
        console.log(restrictionsCheck)

        let restrictionsCounter = 0

        //Only apply restrictions if they exist

        if (Object.entries(this.state.restrictions).length>0) {
            this.state.categoryArray.map(theme => {
                if (this.state.restrictions[theme].length==restrictionsCheck[theme]) {
                    console.log("All quizzes restricted", theme)
                    restrictionsWarning = true
                    totallyRestricted.push(theme)
                    restrictionsCounter++
                } else if (restrictionsCheck[theme]==0) {
                    console.log(theme + " is completely unrestricted")
                } else {
                    console.log(theme + " is partially restricted")
                    restrictionsWarning = true
                }
            })
        }

        if (restrictionsWarning==false) {
            this.startQuiz(restrictionsArray)   
        } else {
            this.setState({
                restrictionsWarning: true,
                restrictionsArray: restrictionsArray,
                totallyRestrictedThemes: totallyRestricted,
                totallyRestricted: restrictionsCounter == this.state.categoryArray.length ? true : false,
            })
        }
    }

    triggerQuizWithRestrictions = () => {
        console.log("yohoahoy")
        this.setState({
            restrictionsWarning : this.state.restrictionsWarning ? false:true
        }, this.startQuiz(this.state.restrictionsArray))
        
    }


    startQuiz = (restrictionsArray) => {
        if(this.state.restrictionsWarning) {
            this.setState({restrictionsWarning: false})
        } 

        this.setState({
            loaded: false
        })

        let subject
        this.props.profile.subject == "Economics"
            ? subject = ""
            : subject = this.props.profile.subject

        let questionArray = []
        let finalQuestionArray = []

        console.log('this.state.categoryNumber', this.state.categoryNumber)
        
        const allCategoriesPromises = this.state.categoryArray.map((category) => {
            //MUST LOOP THROUGH this.state.categoryArray in here
            return firebase.firestore().collection("quizzes" + subject)
                .where("school", "==", "GradeIt")
                .where("quizCategory", "==", category)
                .get()
                .then(querySnapshot => {
                    let mcqData = querySnapshot.docs.map(doc => doc.data().quizName);
    
                    const promises = mcqData.map(quiz => {
                        return firebase.firestore().collection("mcqDatabase" + subject)
                            .where("school", "==", "GradeIt")
                            .where("quizName", "==", quiz)
                            .get()
                            .then(quizSnapshot => {
                                quizSnapshot.docs.map(question => {
                                    if(!restrictionsArray.includes(question.data()["quizName"])) {
                                    questionArray.push(question.data())
                                    //Add theme name
                                    questionArray[questionArray.length-1]["topic"] = category
                                    console.log(question.data()["quizName"])
                                    } else {
                                        console.log(question.data()["quizName"], "excluded")
                                    }
                                })
                            })
                    })
    
                    return Promise.all(promises)
                })
        })

        console.log('allCategoriesPromises', allCategoriesPromises)

        Promise.all(allCategoriesPromises).then(() => this.addNumber(questionArray))
    }

    addNumber = (questionArray) => {

        let finalQuestionArray = []
        let questionNumbers = []


        if(questionArray.length > this.state.numberOfQuestions) {
            while (questionNumbers.length < this.state.numberOfQuestions) {
                let newNumber = Math.round(Math.random()*questionArray.length)
                if (!questionNumbers.includes(newNumber)) {
                    finalQuestionArray.push(questionArray[newNumber])
                    questionNumbers.push(newNumber)
                } 
            }
        } else {
            finalQuestionArray = questionArray
        }

        this.setState({
            loaded: true,
            questionArray: finalQuestionArray
        }, this.quizReady)

             
    }


    quizReady = () => {


        this.setState({
            quizReady: true,
        })

    }

    toggleSettingsMenu = () => {
        this.setState({
            settingsMenu : this.state.settingsMenu ? false:true
        })
    }

    toggleRestrictionsWarning = () => {
        this.setState({
            restrictionsWarning : this.state.restrictionsWarning ? false:true
        })
    }


    render() {

        const { loaded, themeArraySorted, categoryArray, categoryMenu, numberOfQuestions, quizReady, questionArray, restrictionsWarning } = this.state
        let subjectName

        if (this.props.profile.subject && (
            this.props.profile.subject.includes("gcseBiology")
            || this.props.profile.subject.includes("gcseChemistry")
            || this.props.profile.subject=="Physics"
        )) {
            if (this.props.profile.subject==="gcseBiologySingleScienceAQA") {
                subjectName="Biology"
            } else if (this.props.profile.subject==="gcseChemistrySingleScienceAQA") {
                subjectName="Chemistry"
            } else if (this.props.profile.subject==="Physics"){
                subjectName="Physics"
            } else {
                subjectName= this.props.profile.subject
            }
        }

        return (
            <div>
                {restrictionsWarning
                ? 
                <RestrictionsWarning 
                    restrictionsWarning={this.toggleRestrictionsWarning}
                    triggerQuizWithRestrictions={this.triggerQuizWithRestrictions}
                    totallyRestrictedThemes= {this.state.totallyRestrictedThemes}
                    totallyRestricted={this.state.totallyRestricted}
                    startQuiz={this.startQuiz}

                />
                : 
                null
                }

                {categoryMenu
                    ?
                    <CategoryMenu
                        themeArraySorted={themeArraySorted}
                        toggleCategoryMenu={this.toggleCategoryMenu}
                        addTopic={this.addTopic}
                    />
                    :
                    null}

                { loaded ?
                    quizReady
                        ?
                        <McqGraph 
                            questionArray={questionArray}
                            diagnostic={true} 
                        />
                        :
                            !loaded
                            ?
                            <Spinner />
                            :
                            <div>
                                <Route exact path={"/diagnostic"}>
                                    {!quizReady
                                    ?
                                    <nav className="bottom-nav-wrapper">
                                            {this.props.profile.student && this.props.profile.subject ?
                                                <> 
                                                    <div className="bottomNavButtonContainer">
                                                        <Link to="/" className={`material-icons bottomNav`}>home</Link>
                                                        <p className="mobileNavBarLabel">Home</p>
                                                    </div>
                                                    <div className="bottomNavButtonContainer">
                                                        <Link to="/quizzes" className={`material-icons bottomNav`}>psychology</Link>
                                                        <p className="mobileNavBarLabel">Quizzes</p>
                                                    </div>
                                                    <div className="bottomNavButtonContainer">
                                                        {this.props.profile.student==="student" 
                                                            ? 
                                                                <>
                                                                <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                                                <p className="mobileNavBarLabel">Assignments</p>
                                                                </>
                                                            :
                                                                <> 
                                                                <Link to='/mobilefeed' className={`material-icons bottomNav`}>dynamic_feed</Link>
                                                                <p className="mobileNavBarLabel">Feed</p>
                                                                </>
                                                        }
                                                    </div>
                                                    {this.props.profile.student==="student" 
                                                    ? 
                                                    null
                                                    : 
                                                    <div className="bottomNavButtonContainer">
                                                        <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                                        <p className="mobileNavBarLabel">Assignments</p>
                                                    </div>
                                                    }
                                                    <div className="bottomNavButtonContainer">
                                                            <a onClick={this.toggleSubjectMenu} className={`material-icons bottomNav`}>autorenew</a>
                                                            <p className="mobileNavBarLabel">{this.props.subjectName}</p>
                                                    </div>
                                                    </>
                                            : null}
                                        </nav>
                                        : null
                                        }

                                    </Route>
                                <div className="container">
                                    <div className="flexColumn halfWidth">
                                        <p className="diagnosticsHeading">Number of questions</p>
                                        <div className="diagnosticsSixtyfive questions">
                                            <div className="flexRow diagnostics input">
                                                <button className={`btn-floating diagnostics left ${numberOfQuestions > 1}`} onClick={() => this.incrementQuestions()}><i className="material-icons">remove</i></button>
                                                <div className="input-field diagnostics">
                                                    <input className="diagnostics" type="text" id="numberOfQuestions" onChange={this.handleChange} value={numberOfQuestions} />
                                                </div>
                                                <button className="btn-floating diagnostics right" onClick={() => this.incrementQuestions("add")}><i className="material-icons">add</i></button>
                                            </div>
                                        </div>

                                        <div className="flexRow">
                                            <p className="diagnosticsHeading">Test me on...</p>
                                            {this.props.profile.student=="teacher" 
                                            ? 
                                            <Link to="/restrictions" className="flexRow diagnosticsTeacherSettings">
                                                <div className="flexColumn">
                                                    <p className="diagnosticsTeacherText">Configure settings for my school</p>
                                                    <p className="diagnosticsTeacherText bottom">(Available for teachers only)</p>
                                                </div>
                                                <i className="material-icons TeacherText">settings</i>
                                            </Link>
                                            : null
                                            }
                                        </div>
                                        <div className="diagnosticsSixtyfive">
                                            {categoryArray.map(category => {
                                                return (
                                                    <div className="diagnosticsCategoryButton green" key={category}>
                                                        <p className="diagnosticsCategoryButtonText">{category}</p>
                                                        <p className="diagnosticsCategoryButtonText plus" onClick={() => this.removeTopic(category)}>x</p>
                                                    </div>
                                                )
                                            })
                                            }
                                            {themeArraySorted.length > 0
                                                ?
                                                <div className="diagnosticsCategoryButton" onClick={this.toggleCategoryMenu}>
                                                    <p className="diagnosticsCategoryButtonText">Add topics</p>
                                                    <p className="diagnosticsCategoryButtonText plus">+</p>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        {categoryArray.length > 0
                                            ?
                                            <div className="diagnosticsGoButton" onClick={this.checkRestrictions}>
                                                <p className="diagnosticsGoButtonText">Let's go 💪</p>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                : 
                <Spinner />
                }    
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Diagnostic)