import React from 'react';
import firebase from '../auth/auth'
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link, Redirect } from 'react-router-dom'
import './deleteWarning.scss'
import Spinner from '../spinner/spinner'


class DeleteQuizWarning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberDeleted: 0,
            quizDeleted: false,
            processing: false,
            redirect: false
        }
        
    }

    deleteQuiz = () => {
        const { subject } = this.props.profile
        this.setState({processing:true})

        let { mcqDatabase, quizzesDatabase } = this.state

        if (subject==='Economics') {
            mcqDatabase= 'mcqDatabase'
            quizzesDatabase= 'quizzes'
        } else {
            mcqDatabase= `mcqDatabase${subject}`
            quizzesDatabase= `quizzes${subject}` 
        }

        firebase.firestore().collection(quizzesDatabase)
            .where('school', '==', this.props.profile.school)
            .where('quizName', '==', this.props.title)
            .get()
            .then(querySnapshot => {
                if(querySnapshot.docs[0]) {
                    firebase.firestore().collection(quizzesDatabase)
                    .doc(querySnapshot.docs[0].id)
                    .delete()
                    .catch(err => console.log(err))
                    this.setState({quizDeleted:true},
                    ()=>this.checkComplete())
                } else {
                    this.setState({quizDeleted:true},
                        ()=>this.checkComplete())
                }      
            })
            .catch(err => console.log(err))
        
        // Deleting a quiz will delete the questions for the relevant quiz
        // and the relevant school
        firebase.firestore().collection(mcqDatabase)
            .where('school', '==', this.props.profile.school)
            .where('quizName', '==', this.props.title)
            .get()
            .then(snapshot => {
                const result = snapshot.docs.map(doc => doc.id);
                console.log(result.length)
                this.setState({targetNumber: result.length})
                if (result.length > 0) {
                    result.map(questionID => {
                        console.log(questionID)
                    })
                    
                    result.map(questionID => {
                        firebase.firestore().collection(mcqDatabase)
                        .doc(questionID)
                        .delete()
                        .then(() => {
                            console.log("Admin deleted:" + questionID)
                            this.setState({
                                numberDeleted: this.state.numberDeleted + 1
                            },
                            ()=>this.checkComplete())
                            
                        }).catch(err => console.log(err))
                    })
                } else if (result.length===0) {
                    this.checkComplete()
                }
            })
    }

    checkComplete = () => {
        const {numberDeleted, targetNumber, quizDeleted} = this.state
        
        if (numberDeleted===targetNumber && quizDeleted ) {
            console.log('finished')
            this.setState({redirect: true})
        } else {
            console.log('incomplete')
        }
    }
    
    render() {
       const { toggleDeleteQuizWarning }=this.props
       const { processing, redirect }=this.state

       if (redirect) {
           return <Redirect to='/quizzes' />
       }
        
        return (

            <div className="popup-container">
                {processing ?
                    <Spinner/>
                    :null
                }
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp">
                            <div className="btn-floating closePopup" onClick={toggleDeleteQuizWarning}><i className="material-icons">close</i></div>
                            <h5 className="needHelp">Are you sure?</h5>
                                <div className="fancy-btn needHelp" onClick={this.deleteQuiz}>
                                    <i className="material-icons needHelp">delete</i>
                                    <p className="needHelp">Yes, delete</p>
                                </div>
                                <p className="needHelp">This cannot be reversed</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(DeleteQuizWarning) 