import React from 'react';
import { Droppable } from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'

// {/*flashcardSelect={this.props.flashcardSelect} removeQuestion={this.props.removeQuestion}*/}

export default class ColumnBeta extends React.Component {
    render () {
        return (
            <Droppable droppableId="0">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {/* {this.props.cards.map((card, index) => <CardComponent key={`card${index}`} card={card} index={index}/>)} */}
                        {this.props.cards.map((card, index) => 
                            <Draggable draggableId={card.flashcardQuestion} index={index} key={card.flashcardQuestion} >
                            {(provided) => (  
                            <div className="flashcardPreview" onClick={(event) => this.props.flashcardSelect(index, event)}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                            >
                                {/* <div className="dragItem">
                                    <i className="material-icons">drag_handle</i>
                                </div> */}
                                <p className="flashcardPreviewText" dangerouslySetInnerHTML={{__html: card.flashcardQuestion}}></p>
                                {/* <div className="btn-floating flashcardFormRemoveQuestion" onClick={(event) => this.props.removeQuestion(index, event)}>
                                                <i className="material-icons flashcardFormRemoveQuestion">delete</i>
                                </div> */}
                                
                            </div>
                            ) }
                            </Draggable>
        )}

                        
                        {provided.placeholder}
                    </div>
                )}
                
            </Droppable> 
        )
    }
}