import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'



import './restrictions.scss';


class GlobalWarning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }



    }

  


    render() {


        return(
            <div className="popup-container">
                <div className="container subjectMenu">
                    <div className="popupSubjectMenu">
                        <div className="subjectMenuCloseContainer">
                            <i className="material-icons closeCategoryMenu" onClick={this.props.toggleGlobalWarning}>close</i>
                        </div>
                        <div>
                            <div className="subjectMenuChangeSubjectTextContainer">
                                <div className="categoryMenuflexColumn">

                                    <div className="paddedRestrictionsWarning">
                                    <p className="categoryMenuCategoryName restrictionsWarning centre">WARNING!</p>
                                    {this.props.globalRestrictions
                                    ?
                                    <p className="categoryMenuCategoryName restrictionsWarning">Are you sure you want to deactivate global restrictions? All classes will revert back to their individual restrictions.</p>
                                    :
                                    <p className="categoryMenuCategoryName restrictionsWarning">Activating global restrictions will override all restrictions for all classes until deactivated</p>
                                    }
                                        <div className="verifyButtonNo centre" onClick={()=>this.props.applyGlobalRestrictions(this.props.globalRestrictions)}>
                                            <i className="material-icons verifyTeacher">public</i>
                                            <p className="verifyText">I understand, proceed</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalWarning)