import React from 'react';
import {Howl} from 'howler'
import correctSound from '../../resources/243701__ertfelda__correct.wav'
import incorrectSound from '../../resources/351565__bertrof__game-sound-incorrect-organic-violin.wav'


class Answers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnswered: false,
            classNames: ['', '', '', ''],
            numberClicked: 0, 
        }
        
        this.checkAnswer = this.checkAnswer.bind(this);

        this.fail = new Howl (
            {
                src: incorrectSound,
                volume: 0.1
            }
        )
        this.success = new Howl (
            {src: correctSound}
        )
    }
    
    checkAnswer(e) {

        let { isAnswered } = this.props;
        
        if(!isAnswered) {
            let elem = e.currentTarget;
            let { correct, increaseScore, numberClicked, styling, resetStreak} = this.props;
            let answer = Number(elem.dataset.id);
            let updatedClassNames = this.state.classNames;

            if(answer === correct){
                updatedClassNames[answer-1] = 'right';
                increaseScore();
                this.setState({numberClicked: answer})
                numberClicked(answer)
                styling()

                this.success.play()
            }
            else {
                updatedClassNames[answer-1] = 'wrong';
                this.setState({numberClicked: answer})
                numberClicked(answer)
                resetStreak()
                this.props.losingStreakIncrement()
                this.fail.play()
            }
            
            this.setState({
                classNames: updatedClassNames,
            })
            this.props.showButton();
            
        }
    }

    
    // shouldComponentUpdate() {
    //     this.setState({
    //         classNames: ['', '', '', '']
    //     });
    // }

    resetAnswers = () => {
        this.setState({
            classNames: ['', '', '', '']
        });
    }

    componentDidMount() {
        // Handles reset answers event
        document.addEventListener('resetAnswers', this.resetAnswers);
    }

    componentWillUnmount() {
        // Avoids adding multiple handlers
        document.removeEventListener('resetAnswers', this.resetAnswers);
    }
    
    render() {
        let { answers } = this.props;
        let { classNames } = this.state;
        
        // let transition = {
        //     transitionName: "example",
        //     transitionEnterTimeout: 500,
        //     transitionLeaveTimeout: 300
        // }
        
        return (
            <div id="answers">
                <ul>
                    <li onClick={this.checkAnswer} className={classNames[0]} data-id="1"><span className="spanLetter">A</span> <p dangerouslySetInnerHTML={answers[0]}></p></li>
                    <li onClick={this.checkAnswer} className={classNames[1]} data-id="2"><span className="spanLetter">B</span> <p dangerouslySetInnerHTML={answers[1]}></p></li>
                    <li onClick={this.checkAnswer} className={classNames[2]} data-id="3"><span className="spanLetter"> C</span> <p dangerouslySetInnerHTML={answers[2]}></p></li>
                    <li onClick={this.checkAnswer} className={classNames[3]} data-id="4"><span className="spanLetter">D</span> <p dangerouslySetInnerHTML={answers[3]}></p></li>
                </ul>
            </div>
        );
    }
}

export default Answers