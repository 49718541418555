import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom'
import { submitQuestion } from '../../store/actions/mcqFormAction'
import firebase from '../auth/auth'
import { Redirect } from 'react-router-dom'
import {storage} from '../auth/auth';
import './resourceUpload.scss'

export class ResourceDirectory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            image: null,
            progress: 0,
            formComplete: "no",
            resources: []
        }

    }

    componentDidMount() {
        this.refreshFeed()
    }

    refreshFeed = () => {
        firebase.firestore().collection("pastPapers")
        .orderBy('createdAt', "desc")
        .get()
        .then(querySnapshot => {
            let resources = []
            querySnapshot.docs.map(doc => {
                resources.push(doc.data())
            })
            this.setState({
                resources
            })
        })
    }


    render() {
        const { auth } = this.props;
        // const { authError } = this.props;
        const { resources } = this.state;
        
            return (
                    <div className="container 50">
                            <div className="directoryTopContainer">
                                <h5 className="grey-text text-darken-3 resourceDirectory">View resources</h5>
                                <i className="material-icons resourceDirectory refresh" onClick={this.refreshFeed}>refresh</i>
                            </div>
                            <div className="directoryContainer">
                                {resources.map((resource) =>{
                                    return(
                                        <div className="directoryRow">
                                            <p className="tableText paper">Resource name: {resource.paper}</p>
                                            <div className="flexRow">
                                                <a href={resource.url} target="_blank">Link </a>
                                                <p className="resourceType">  | {resource.type} | {resource.year} | {resource.user} | {resource.uid} </p>
                                            </div>
                                            <div className="tableRow">
                                                <p className="tableText">{resource.level}</p>
                                                <p className="tableText">{resource.examBoard}</p>
                                                <p className="tableText">{resource.subject}</p>
                                                <p className="tableText">{resource.month}</p>
                                                <p className="tableText">{resource.tier}</p>
                                            </div>
                                        </div>
                                    )
                                })

                                }
                            </div>

                            
                    </div>
            )
        
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitQuestion: (question) => dispatch(submitQuestion(question))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDirectory)
