import React from 'react'
import firebase from '../auth/auth'
import { Switch, Route, Link } from 'react-router-dom'
import SignedInLinks from './signedinlinks'
import SignedOutLinks from './signedoutlinks'
import SubjectMenu from './subjectMenu'
import { connect } from 'react-redux'
import Settings from '../teacherDash/settings'
import logo from '../../resources/gradeIt.png'

import './bottomNavbar.scss'

class BottomNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSettings: false
        }
    }

    toggleSettings = () => {
        this.setState({
            showSettings: this.state.showSettings ? false : true
        })
    }

    toggleSubjectMenu = () => {
        if(this.props.profile.subject!=="Economics") {
            this.setState ({
                subjectMenu: this.state.subjectMenu ? false : true
            })
        }
    }

    ManageScienceSubjects = () => {
        const {profile, auth} = this.props

        //If user is a GCSE science student, create student progress records in respective tables, if these don't already exist
        if (profile.student=="student"
            &&
            (profile.subject.includes("gcseBiology")
            || profile.subject.includes("gcseChemistry")
            || profile.subject=="Physics")
            ) 
            {   
                const subjectArray = ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]
                
                subjectArray.map(subj=> {
                    firebase.firestore().collection('studentProgress'+ subj)
                    .doc(auth.uid)
                    .get()
                    .then(querySnapshot => {
                    if (querySnapshot.exists) {
                        // console.log(subj+ "studentProgress record exists" + querySnapshot.data())
                    } else {
                        //If student progress doesn't exist
                        // console.log(subj + "studentProgress record doesn't exist")
                        firebase.firestore().collection('studentProgress'+ subj)
                        .doc(auth.uid)
                        .set( 
                            {   createdAt: new Date(),
                                firstName: profile.firstName,
                                lastName: profile.lastName,
                                school: profile.school,
                                //Auto-assign students to the science class on their profile.
                                [subj+"class"]: profile[profile.subject+'class'],
                                studentProgress: {},
                                user: auth.uid
                            }
                        )

                        firebase.firestore().collection('users')
                            .doc(auth.uid)
                            .update( 
                                {   
                                    [subj+"xp"]: 0,
                                    [subj+"class"]: profile[profile.subject+'class'],
                                }
                            )

                        if (profile.availableSubjects.length < 3){
                            firebase.firestore().collection('users')
                            .doc(auth.uid)
                            .update( 
                                {   
                                    availableSubjects: ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"],
                                }
                            )
                        }

                    }
                })
                })
                
            } else if (
            //If user is a GCSE science teacher, add XP to the other subjects, if this doesn't already exist.
            //Function also adds all sciences to available subjects
            (profile.student=="teacher")
            &&
            (profile.subject.includes("gcseBiology")
            || profile.subject.includes("gcseChemistry")
            || profile.subject=="Physics" ))
                {
                    const subjectArray = ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"]

                    subjectArray.map(subj=> {

                            if(!profile[subj+"xp"]) {
                                firebase.firestore().collection('users')
                                .doc(auth.uid)
                                .get()
                                .then(querySnapshot => {
                                    const teacher = querySnapshot.data()
                                    if (!teacher[subj+"xp"] && teacher[subj+"xp"]!==0){
                                        console.log('No ' + subj + ' xp')
                                        firebase.firestore().collection('users')
                                        .doc(auth.uid)
                                        .update( 
                                            {   
                                                [subj+"xp"]: 0,
                                                availableSubjects: ["gcseBiologySingleScienceAQA", "gcseChemistrySingleScienceAQA", "Physics"],
                                            }
                                        )
                                    }
                                })
                            }
                    })
                }

    }

    render() {

        const {auth, profile} = this.props;
        const {showSettings, subjectMenu} = this.state
        
        const links=auth.uid ? <SignedInLinks profile={profile} /> : <SignedOutLinks />
        
        let homeActive=''
        let quizzesActive=''
        let settingsActive=''

        if (this.props.path==="/quizzes") {
            quizzesActive="active"
        } else if (this.props.path==="/") {
            homeActive="active"
        } else if (this.props.path==="/settings") {
            settingsActive="active"
        }

        let subjectName

        if (profile.subject && (
            profile.subject.includes("gcseBiology")
            || profile.subject.includes("gcseChemistry")
            || profile.subject=="Physics"
        )) {
            if (profile.subject==="gcseBiologySingleScienceAQA") {
                subjectName="Biology"
            } else if (profile.subject==="gcseChemistrySingleScienceAQA") {
                subjectName="Chemistry"
            } else if (profile.subject==="Physics"){
                subjectName="Physics"
            } else {
                subjectName= profile.subject
            }
        }
    

        return (
            
            <Switch>
                {subjectMenu ? 
                    <SubjectMenu
                        toggleSubjectMenu={this.toggleSubjectMenu}
                    />
                    : null
                }
                {profile.student && this.ManageScienceSubjects()}
                {showSettings ? <Settings toggleSettings={this.toggleSettings} /> : null}            
                <Route exact path="/">
                    <nav className="bottom-nav-wrapper">
                        {this.props.profile.student && this.props.profile.subject ?
                            <> 
                                <div className="bottomNavButtonContainer">
                                    <Link to="/" className={`material-icons bottomNav active`}>home</Link>
                                    <p className="mobileNavBarLabel active">Home</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    <Link to="/quizzes" className={`material-icons bottomNav`}>psychology</Link>
                                    <p className="mobileNavBarLabel">Quizzes</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    {profile.student==="student" 
                                        ? 
                                            <>
                                            <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                            <p className="mobileNavBarLabel">Assignments</p>
                                            </>
                                        :
                                            <> 
                                            <Link to='/mobilefeed' className={`material-icons bottomNav`}>dynamic_feed</Link>
                                            <p className="mobileNavBarLabel">Feed</p>
                                            </>
                                    }
                                </div>
                                {profile.student==="student" 
                                ? 
                                null
                                : 
                                <div className="bottomNavButtonContainer">
                                    <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                    <p className="mobileNavBarLabel">Assignments</p>
                                </div>
                                }

                                <div className="bottomNavButtonContainer">
                                        <a onClick={this.toggleSubjectMenu} className={`material-icons bottomNav`}>autorenew</a>
                                        <p className="mobileNavBarLabel">{subjectName}</p>
                                </div>
                                </>
                        : null}
                    </nav>
                </Route>
                <Route exact path="/verify">
                    <nav className="bottom-nav-wrapper">
                        <div className="bottomNavButtonContainer">
                            <Link to="/" className={`material-icons bottomNav`}>home</Link>
                        </div>
                        <div className="bottomNavButtonContainer">
                            <Link to="/quizzes" className={`material-icons bottomNav`}>psychology</Link>
                            <p className="mobileNavBarLabel">Quizzes</p>
                        </div>
                        <div className="bottomNavButtonContainer">
                            <a className={`material-icons bottomNav`} onClick={this.toggleSettings}>settings</a>
                        </div>
                    </nav>
                </Route>
                <Route exact path={"/manageclasses"}>
                    <nav className="bottom-nav-wrapper">
                    {this.props.profile.student && this.props.profile.subject ?
                            <> 
                                <div className="bottomNavButtonContainer">
                                    <Link to="/" className={`material-icons bottomNav`}>home</Link>
                                    <p className="mobileNavBarLabel">Home</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    <Link to="/quizzes" className={`material-icons bottomNav`}>psychology</Link>
                                    <p className="mobileNavBarLabel">Quizzes</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    {profile.student==="student" 
                                        ? 
                                            <>
                                            <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                            <p className="mobileNavBarLabel">Assignments</p>
                                            </>
                                        :
                                            <> 
                                            <Link to='/mobilefeed' className={`material-icons bottomNav`}>dynamic_feed</Link>
                                            <p className="mobileNavBarLabel">Feed</p>
                                            </>
                                    }
                                </div>
                                {profile.student==="student" 
                                ? 
                                null
                                : 
                                <div className="bottomNavButtonContainer">
                                    <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                    <p className="mobileNavBarLabel">Assignments</p>
                                </div>
                                }

                                <div className="bottomNavButtonContainer">
                                        <a onClick={this.toggleSubjectMenu} className={`material-icons bottomNav`}>autorenew</a>
                                        <p className="mobileNavBarLabel">{subjectName}</p>
                                </div>
                                </>
                        : null}
                    </nav>
                </Route>
                <Route exact path={"/teacherdata"}>
                <nav className="bottom-nav-wrapper">
                    {this.props.profile.student && this.props.profile.subject ?
                            <> 
                                <div className="bottomNavButtonContainer">
                                    <Link to="/" className={`material-icons bottomNav`}>home</Link>
                                    <p className="mobileNavBarLabel">Home</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    <Link to="/quizzes" className={`material-icons bottomNav`}>psychology</Link>
                                    <p className="mobileNavBarLabel">Quizzes</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    {profile.student==="student" 
                                        ? 
                                            <>
                                            <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                            <p className="mobileNavBarLabel">Assignments</p>
                                            </>
                                        :
                                            <> 
                                            <Link to='/mobilefeed' className={`material-icons bottomNav`}>dynamic_feed</Link>
                                            <p className="mobileNavBarLabel">Feed</p>
                                            </>
                                    }
                                </div>
                                {profile.student==="student" 
                                ? 
                                null
                                : 
                                <div className="bottomNavButtonContainer">
                                    <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                    <p className="mobileNavBarLabel">Assignments</p>
                                </div>
                                }
                                <div className="bottomNavButtonContainer">
                                        <a onClick={this.toggleSubjectMenu} className={`material-icons bottomNav`}>autorenew</a>
                                        <p className="mobileNavBarLabel">{subjectName}</p>
                                </div>
                                </>
                        : null}
                    </nav>
                </Route>
                <Route exact path="/quizzes">
                <nav className="bottom-nav-wrapper">
                        {this.props.profile.student && this.props.profile.subject ?
                            <> 
                                <div className="bottomNavButtonContainer">
                                    <Link to="/" className={`material-icons bottomNav`}>home</Link>
                                    <p className="mobileNavBarLabel">Home</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    <Link to="/quizzes" className={`material-icons bottomNav active`}>psychology</Link>
                                    <p className="mobileNavBarLabel active">Quizzes</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    {profile.student==="student" 
                                        ? 
                                            <>
                                            <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                            <p className="mobileNavBarLabel">Assignments</p>
                                            </>
                                        :
                                            <> 
                                            <Link to='/mobilefeed' className={`material-icons bottomNav`}>dynamic_feed</Link>
                                            <p className="mobileNavBarLabel">Feed</p>
                                            </>
                                    }
                                </div>
                                {profile.student==="student" 
                                ? 
                                null
                                : 
                                <div className="bottomNavButtonContainer">
                                    <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                    <p className="mobileNavBarLabel">Assignments</p>
                                </div>
                                }
                                <div className="bottomNavButtonContainer">
                                        <a onClick={this.toggleSubjectMenu} className={`material-icons bottomNav`}>autorenew</a>
                                        <p className="mobileNavBarLabel">{subjectName}</p>
                                </div>
                                </>
                        : null}
                    </nav>

                </Route>
                <Route exact path="/mobilefeed">
                <nav className="bottom-nav-wrapper">
                        {this.props.profile.student && this.props.profile.subject ?
                            <> 
                                <div className="bottomNavButtonContainer">
                                    <Link to="/" className={`material-icons bottomNav`}>home</Link>
                                    <p className="mobileNavBarLabel">Home</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    <Link to="/quizzes" className={`material-icons bottomNav`}>psychology</Link>
                                    <p className="mobileNavBarLabel">Quizzes</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    {profile.student==="student" 
                                        ? 
                                            <>
                                            <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                            <p className="mobileNavBarLabel">Assignments</p>
                                            </>
                                        :
                                            <> 
                                            <Link to='/mobilefeed' className={`material-icons bottomNav active`}>dynamic_feed</Link>
                                            <p className="mobileNavBarLabel active">Feed</p>
                                            </>
                                    }
                                </div>
                                {profile.student==="student" 
                                ? 
                                null
                                : 
                                <div className="bottomNavButtonContainer">
                                    <Link to='/assign' className={`material-icons bottomNav`}>event</Link>
                                    <p className="mobileNavBarLabel">Assignments</p>
                                </div>
                                }
                                <div className="bottomNavButtonContainer">
                                        <a onClick={this.toggleSubjectMenu} className={`material-icons bottomNav`}>autorenew</a>
                                        <p className="mobileNavBarLabel">{subjectName}</p>
                                </div>
                                </>
                        : null}
                    </nav>
                </Route>
                <Route exact path="/assign">
                <nav className="bottom-nav-wrapper">
                        {this.props.profile.student && this.props.profile.subject ?
                            <> 
                                <div className="bottomNavButtonContainer">
                                    <Link to="/" className={`material-icons bottomNav`}>home</Link>
                                    <p className="mobileNavBarLabel">Home</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    <Link to="/quizzes" className={`material-icons bottomNav`}>psychology</Link>
                                    <p className="mobileNavBarLabel">Quizzes</p>
                                </div>
                                <div className="bottomNavButtonContainer">
                                    {profile.student==="student" 
                                        ? 
                                            <>
                                            <Link to='/assign' className={`material-icons bottomNav active`}>today</Link>
                                            <p className="mobileNavBarLabel active">Assignments</p>
                                            </>
                                        :
                                            <> 
                                            <Link to='/mobilefeed' className={`material-icons bottomNav`}>dynamic_feed</Link>
                                            <p className="mobileNavBarLabel">Feed</p>
                                            </>
                                    }
                                </div>
                                {profile.student==="student" 
                                ? 
                                null
                                : 
                                <div className="bottomNavButtonContainer">
                                    <Link to='/assign' className={`material-icons bottomNav active`}>event</Link>
                                    <p className="mobileNavBarLabel active">Assignments</p>
                                </div>
                                }
                                <div className="bottomNavButtonContainer">
                                        <a onClick={this.toggleSubjectMenu} className={`material-icons bottomNav`}>autorenew</a>
                                        <p className="mobileNavBarLabel">{subjectName}</p>
                                </div>
                                </>
                        : null}
                    </nav>
                </Route>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(BottomNavBar)