import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {connect} from 'react-redux';
import { Switch, Route } from "react-router-dom";
import { Line } from 'rc-progress';

import './xp.scss';


class MCQGraphBar extends Component {
    constructor() {
        super();
        this.state = {
            level: 1,
            lastRequiredXp: 0,
            requiredXp: 50,
            zeroPercent: 0,
            avatar: "",
            show: true,
            scrollPos: 0
        };
        this.checkLevel = this.checkLevel.bind(this);
        this.increaseProgress = this.increaseProgress.bind(this);
    }

    componentDidMount() {
        this.checkLevel();
        this.increaseProgress();
        // window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        // window.removeEventListener('scroll', this.handleScroll)
    }

    // handleScroll = () => {
    //     this.setState({
    //         scrollPos: document.body.getBoundingClientRect().top,
    //         show: 
    //         (document.body.getBoundingClientRect().top > this.state.scrollPos) 
    //         || (document.body.getBoundingClientRect().top > document.documentElement.clientHeight*-0.12)
    //     })
    // }

    increaseProgress() {
        let subjectString =""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }

        let progressXp;
        if (this.state.level === 6){
            progressXp = 100;
        } else { 
            progressXp = Math.round((this.props.profile[`${subjectString}xp`] - this.state.lastRequiredXp) / (this.state.requiredXp - this.state.lastRequiredXp) * 100);
        }

        const newPercent = this.state.zeroPercent + 1;

        if (newPercent >= progressXp + 1) {
            clearTimeout(this.tm);
            return;
        }

        if (progressXp !== 0) {
            this.setState({
                zeroPercent: newPercent
            })
        }

        this.tm = setTimeout(this.increaseProgress, 10);
    }

    checkLevel() {
        let subjectString =""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }

        const powellPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fjerome_powell.png?alt=media&token=802c4a84-5705-4a5a-8985-2a47ce599b9d";
        const lagardePic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fchristine_lagarde.png?alt=media&token=c1c4b35a-2622-4b6a-bfe6-ffbe5a71443f";
        const baileyPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fandrew_bailey.png?alt=media&token=ab8d1fa8-8bc8-4080-86a8-e963521556a9";
        const buffettPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Fwarren_buffett.png?alt=media&token=e35ea2cf-48e3-4f8d-84f0-fe868aff6bef";
        const sunakPic = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2Frishisunak2.png?alt=media&token=e51a4f8c-104d-43ff-9600-189493d94774";
        
        // Determine static level 
        if (this.props.profile[`${subjectString}xp`] > 50 && this.props.profile[`${subjectString}xp`] <= 1000){
            this.setState({
                level: 2,
                requiredXp: 1000,
                lastRequiredXp: 50,
                avatar: baileyPic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 1000 && this.props.profile[`${subjectString}xp`] <= 2800){
            this.setState({
                level: 3,
                requiredXp: 2800,
                lastRequiredXp: 1000,
                avatar: sunakPic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 2800 && this.props.profile[`${subjectString}xp`] <= 5400){
            this.setState({
                level: 4,
                requiredXp: 5400,
                lastRequiredXp: 2800,
                avatar: lagardePic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 5400 && this.props.profile[`${subjectString}xp`] <= 8800){
            this.setState({
                level: 5,
                requiredXp: 8800,
                lastRequiredXp: 5400,
                avatar: buffettPic
            })
        } else if (this.props.profile[`${subjectString}xp`] > 8800){
            this.setState({
                level: 6,
                requiredXp: 13000,
                lastRequiredXp: 8800,
                avatar: powellPic
            })
        } 
    }

    
    render() {
        let subjectString = ""
        if (this.props.profile.subject!=="Economics") {
            subjectString = this.props.profile.subject
        }
        
        let progressXp;
        if (this.state.level === 7){
            progressXp = 100;
        } else { 
            progressXp = Math.round((this.props.profile[`${subjectString}xp`] - this.state.lastRequiredXp) / (this.state.requiredXp - this.state.lastRequiredXp) * 100);
        }
        let { currentXp, percentXp, liveLevel, liveRequiredXp,
             liveLastRequiredXp, streak, nr, total, flashcard} = this.props;
        
        //Color circle differently based on how full it is (other options are gradient or same color throughout)
        let color
        if (this.state.zeroPercent<=25) {
            color="#a6a5c0"
        } else if (this.state.zeroPercent>25 && this.state.zeroPercent<=55) {
            color="#a6a5c0"
        } else if (this.state.zeroPercent>56 && this.state.zeroPercent<=80) {
            color="#7978a1"
        } else {
            color="#525174"
        }

        let liveColor
        if (percentXp<=25) {
            liveColor="#a6a5c0"
        } else if (percentXp>25 && percentXp<=55) {
            liveColor="#a6a5c0"
        } else if (percentXp>56 && percentXp<=80) {
            liveColor="#7978a1"
        } else {
            liveColor="#525174"
        }
        

        let elements =
                    <div className="container">
                        {this.state.show ?
                            <div className="xpContainerMcqMobile">
                                <div className="xpBarMcqGraphMobile">
                                    <div className="mcqXpCircleContainerMobile">
                                        <div className="mcqGraphHeaderTopContainer">
                                            <div className="mcqGraphHeaderTopContainerThird">
                                                <Link to="/quizzes" className="material-icons mcqGraphHeaderTopContainerLeft">arrow_back</Link>
                                            </div>
                                            <div className="mcqGraphHeaderQuestionContainer">
                                                <p className="mcqGraphHeaderQuestion">{nr}/{total}</p>
                                            </div>
                                            <div className="mcqGraphHeaderTopContainerThird">
                                                {flashcard ? 
                                                <i onClick={this.props.loadLeaveWarning} className="material-icons mcqGraphHeaderTopContainerRight">style</i>
                                                :
                                                null
                                                }
                                            </div>
                                        </div>
                                        {currentXp > this.props.profile[`${subjectString}xp`]
                                        ?
                                        <div className="barContainerMobile">
                                            <Line percent={percentXp} strokeWidth="4" trailWidth='4' strokeColor={liveColor} />
                                            {liveLevel === 6
                                            ?
                                            <div className="mcqGraphHeaderBottomContainer">
                                                

                                                <div className="flexLeftAlign">
                                                    <div className={streak ? 'btn-floating mobileStreak pulse' :""}>
                                                        <i className={streak? "material-icons fireMobile streak" : "material-icons fireMobile"}>whatshot</i>
                                                    </div>
                                                    <p className="mcqStreakMobile">{streak} {streak ? "in a row" : ""}</p>
                                                </div>

                                                
                                            <p className="xpTextMcqMobile"> LEVEL {liveLevel}: {currentXp} XP </p>
                                            </div>
                                            :
                                            <div className="mcqGraphHeaderBottomContainer">


                                                <div className="flexLeftAlign">                                                
                                                    <div className={streak ? 'btn-floating mobileStreak pulse' :""}>
                                                        <i className={streak? "material-icons fireMobile streak" : "material-icons fireMobile"}>whatshot</i>
                                                    </div>
                                                    <p className="mcqStreakMobile">{streak} {streak ? "in a row" : ""}</p>
                                                </div>

                                                
                                            <p className="xpTextMcqMobile"> LEVEL {liveLevel}: {liveRequiredXp - currentXp} XP required</p>
                                            </div>
                                            }
                                        </div>    
                                        :
                                        <div className="barContainerMobile">
                                            <Line percent={`${this.state.zeroPercent}`} strokeWidth="4" trailWidth='4' strokeColor={liveColor} />
                                            {liveLevel === 6
                                            ?
                                            <div className="mcqGraphHeaderBottomContainer">
                                                <div className="flexLeftAlign"> 
                                                    <i className="material-icons fireMobile">whatshot</i>
                                                    <p className="mcqStreakMobile">{streak}</p>
                                                </div>
                                            <p className="xpTextMcqMobile"> LEVEL {liveLevel}: {currentXp} XP </p>
                                            </div>
                                            :
                                            <div className="mcqGraphHeaderBottomContainer">

                                            <div className="flexLeftAlign"> 
                                                <i className="material-icons fireMobile">whatshot</i>
                                                <p className="mcqStreakMobile">{streak}</p>
                                            </div>
                                            <p className="xpTextMcqMobile"> LEVEL {liveLevel}: {liveRequiredXp - currentXp} XP required</p>
                                            </div>
                                        }
                                        </div>
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        : null
                        }
                    </div>
                    
                    let bookmarkElements =
                    <div className="container">
                        {this.state.show ?
                            <div className="xpContainerMcqMobile">
                                <div className="xpBarMcqGraphMobile">
                                    <div className="mcqXpCircleContainerMobile">
                                        <div className="mcqGraphHeaderTopContainer">
                                            <div className="mcqGraphHeaderQuestionContainer">
                                                <p className="mcqGraphHeaderQuestion">Question {nr}/{total}</p>
                                            </div>
                                            <div className="mcqGraphHeaderTopContainerHalf">
                                                <Link to="/quizzes" className="material-icons mcqGraphHeaderTopContainerLeft">clear</Link>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        : null
                        }
                    </div> 


        return ( 
            <Switch> 
                <Route path="/quizzes">
                    {elements}                    
                </Route>
                <Route path="/quizzesschool">
                    {elements} 
                </Route>
                <Route path="/bookmarks">
                    {bookmarkElements} 
                </Route>
            </Switch>
        )
    }
}

const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,
    }
}


export default connect(mapStateToProps)(MCQGraphBar)