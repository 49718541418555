import React, { Component } from 'react'
import './mobileTeacherFeed.scss';

import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import firebase from '../auth/auth'
import { getQuizzes, getQuizzesTrial, getStudentArray, parseStudentArray } from '../../helpers/getData';
import TeacherFeed from '../teacherFeed/teacherFeed'
import NoFlag from '../teacherDash/noflag'
import Settings from '../teacherDash/settings'
import CreateQuizPopup from '../quizzes/createQuizPopup'
import VerifyTeacher from '../teacherDash/verifyTeacher'

class MobileTeacherFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentView: 'students',
            activeClass: "All students",
            flags: false,
            flagPopup: false,
            editor: false,
            settings: false,
            newQuizzes: 0, 
            newFlags: 0
        }
    }

    componentDidMount() {
        this.getQuizzesTrialProps()
        this.getStudents("All students");
        // this.getClasses()
        this.checkFlags()
        this.checkNotifications()
    }

    componentWillReceiveProps(props) {
        props.profile.school && this.getQuizzesTrialProps(props)
        // props.auth && this.getAttempts(props)
    }

    getQuizzesTrialProps (props) {
        props = props || this.props;

        if (!props.profile.school) return;

        getQuizzesTrial(props.profile.school, this.props.profile.subject)
        .then((quizzes) => getStudentArray(this.props.auth.uid, quizzes))
        .then(({ quizzes, studentArray }) => {
            this.setState({
                quizzes
            });
        });
    }

    checkNotifications() {
        firebase.firestore().collection('users')
        .doc(this.props.auth.uid)
        .get()
        .then(querySnapshot => {
            const profile = querySnapshot.data()
            if (!profile.lastLogged) {
                firebase.firestore().collection('users')
                .doc(this.props.auth.uid)
                .update({
                    lastLogged: new Date()
                })
            } else {
                firebase.firestore().collection('schools')
                .where('school', '==', this.props.profile.school)
                .get()
                .then(querySnapshot => {
                    if (this.props.profile.student==="teacher" &&  querySnapshot.docs[0].data()[`unverified${this.props.profile.subject}teachers`]) {
                        this.setState({
                            unverifiedTeachers: querySnapshot.docs[0].data()[`unverified${this.props.profile.subject}teachers`],
                            schoolID: querySnapshot.docs[0].id
                        })
                    }

                    const feed = querySnapshot.docs[0].data().schoolFeedArray
                    if (feed){ 
                        const newFeed = feed.filter(feed => feed.createdAt > this.props.profile.lastLogged)
                        const quizFeed = newFeed.filter(feed => feed.type == 'quiz')
                        const flagFeed = newFeed.filter(feed => feed.type == 'flag')
                        let quizCount = 0
                        let flagCount = 0
                        
                        for (var i = 0; i < quizFeed.length; i++) {
                            quizCount++;
                        }
                        for (var z = 0; z < flagFeed.length; z++) {
                            flagCount++;
                        }
                        this.setState({
                            newQuizzes: quizCount,
                            newFlags: flagCount
                        })
                    }
                })
                .then(() => {
                    firebase.firestore().collection('users')
                    .doc(this.props.auth.uid)
                    .update({
                        lastLogged: new Date()
                    })
                })
            }
        })
    }

    checkFlags() {
        firebase.firestore().collection('schools')
        .where('school', '==', this.props.profile.school)
        .get()
        .then(querySnapshot => {
            const school = querySnapshot.docs[0].data()
            if (school.EconomicsFlags && school.EconomicsFlags.length > 0) {
                this.setState({
                    flags: true
                })
            }
        })
    }

    toggleFlagPopup = () => {
        this.setState({flagPopup: this.state.flagPopup ? false : true })
    }

    toggleEditor = () => {
        this.setState({editor: this.state.editor ? false : true })
    }

    toggleSettings = () => {
        this.setState({settings: this.state.settings ? false : true })
    }

    // getClasses() {
    //     const { props } = this;

    //     if (!props.profile.school) return;

    //     firebase.firestore().collection('schools').where('school', '==', props.profile.school)
    //         .get()
    //         .then(querySnapshot => {
    //             const school = querySnapshot.docs.map(doc => doc.data());
    //             if (querySnapshot.docs[0].id) {
    //                 const id = querySnapshot.docs[0].id
    //             }
                
    //             let classes = {}
    //             if (!school[0].classes) {
    //                 classes = {"All students" : []} 
    //                 this.setState({
    //                     classes,
    //                     activeClass: "All students",
    //                     noClasses: true,
    //                 });
    //             } else {
    //                 this.setState({
    //                     classes: school[0].classes,
    //                     activeClass: "All students",
    //                     noClasses: false,
    //                 })
    //             }
    //         })
    // }

    //This runs when auth updates
    //***THIS ISN'T RUNNING AT ALL NOW*/
    /*componentWillReceiveProps(props) {
        this.getStudents();
    }*/

    getStudents(classSelector) {
        const { props } = this;

        if (!props.profile.school) return;

        let dynamicQuery
        if (classSelector === "All students") {
            dynamicQuery = firebase.firestore().collection('studentProgress').where('school', '==', props.profile.school).orderBy('lastName')

        } else {
            dynamicQuery = firebase.firestore().collection('studentProgress').where('school', '==', props.profile.school).where('class','==', classSelector).orderBy('lastName')
        }

        firebase.firestore().collection('users').where('school', '==', props.profile.school).where('student', "==", "student").orderBy('lastName')
            .get()
            .then(querySnapshot => {
                const students = querySnapshot.docs.map(doc => doc.data());

                this.setState({
                    students
                });

                return students;
            })
            .then((students) => {
                // component calls quizlist
                // which quiz they want

                
                dynamicQuery
                    .get()
                    .then(querySnapshot => {
                        const { quizzes } = this.state;
                        const studentProgress = querySnapshot.docs.map(doc => doc.data());

                        students.forEach(student => {
                            student.name = `${student.firstName} ${student.lastName}`;
                        });

                        studentProgress.forEach(student => {

                            const currentStudent = students.find(({ firstName, lastName, school }) => {
                                return student.firstName === firstName &&
                                    student.lastName === lastName &&
                                    student.school === school;
                            });

                            if (!currentStudent) {
                                console.error('Student not found!', { student })
                                return;
                            }

                            const completedQuizzes = student.studentProgress;

                            // currentStudent.studentProgress = student.studentProgress;
                            currentStudent.studentProgress = JSON.parse(JSON.stringify(quizzes));

                            let userScore = 0;

                            // puts student data into the relevant quiz objects within the relevant theme object
                            for (let quizName in completedQuizzes) {
                                let quizScore = completedQuizzes[quizName];

                                quizScore = typeof quizScore === 'number'
                                    ? quizScore
                                    : 0 // Object.values(progress)[0];

                                for (let category in currentStudent.studentProgress) {
                                    const quizzesList = currentStudent.studentProgress[category];

                                    if (typeof quizzesList !== 'object') continue;

                                    const quiz = quizzesList.find(
                                        (quiz) => quiz.quizName === quizName
                                    );

                                    if (quiz) {
                                        quiz.quizScore = quizScore;
                                    }
                                }

                                currentStudent.studentProgress[quizName] = quizScore;
                                userScore += quizScore;
                            }

                            currentStudent.totalAttempts = 0;

                            //calculates the number of quizzes attempted
                            Object.values(currentStudent.studentProgress).forEach(quizList => {
                                if (typeof quizList !== 'object') return;

                                quizList.forEach(({ quizScore = 0 }) => {
                                    if (quizScore) {
                                        currentStudent.totalAttempts++;
                                    }
                                });
                            });

                            //calculates the total number of quizzes                            
                            currentStudent.totalQuizzes = Object.values(quizzes)
                                .reduce((total, quizzes) => total + quizzes.length, 0);

                            //adds average accuracy and themeAccuracy to the studentArray
                            currentStudent.averageAccuracy = Math.round(
                                userScore / currentStudent.totalAttempts
                            );

                            currentStudent.completion = Math.round(
                                currentStudent.totalAttempts / currentStudent.totalQuizzes * 100
                            );
                        })

                        this.setState({ students });
                    })
            });
    }

    changeView = () => {
        this.setState({
            currentView: this.state.currentView === 'students' ? 'quizzes' : 'students'
        });
    }

    getDropdown() {

        const { noClasses, classes, activeClass } = this.state

        if (noClasses) {
            return (
                <select className="selectSortManageClasses" onChange={this.toggleClass}>
                    <option>All students</option>
                </select>
            )
        }

        if (!noClasses) {            
            return (
                <select className="selectSortManageClasses" onChange={this.toggleClass} value={activeClass}>
                        <option>All students</option>
                    {Object.entries(classes).map((e) => {
                        return (
                        <option key={e[0]}>{e[0]}</option>
                        )
                        })
                    }
                </select>
            )
        }
    }

    toggleClass = (event) => {
        this.setState ({
            activeClass: event.target.value
        })

        this.getStudents(event.target.value)
    }

    verify = (teacher) => {
        console.log(teacher.firstName)
        const teacherArray= Array.from(this.state.unverifiedTeachers)
        const newArray=teacherArray.filter(teach=> 
            teach.ID!==teacher.ID
        )
        console.log(newArray)
        this.setState({unverifiedTeachers: newArray})

        firebase.firestore().collection('schools')
        .doc(this.state.schoolID)
        .update({
            [`unverified${this.props.profile.subject}teachers`]: newArray
        })

        firebase.firestore().collection('users')
        .doc(teacher.ID)
        .update({
            teacherNotVerified: false
        })
    } 

    reject = (teacher) => {
        console.log(teacher.firstName)
        const teacherArray= Array.from(this.state.unverifiedTeachers)
        const newArray=teacherArray.filter(teach=> 
            teach.ID!==teacher.ID
        )
        console.log(newArray)
        this.setState({unverifiedTeachers: newArray})

        firebase.firestore().collection('schools')
        .doc(this.state.schoolID)
        .update({
            [`unverified${this.props.profile.subject}teachers`]: newArray
        })
    } 

    render() {
        const { auth, profile } = this.props;

        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />

        const { currentView, students, quizzes, checked, 
            classes, flagPopup, editor, settings, unverifiedTeachers } = this.state;

        return (
            <div className="flexRow" id="flexRow">
                {flagPopup
                ? <NoFlag toggleFlagPopup={this.toggleFlagPopup} />
                :null
                }
                {editor
                ? <CreateQuizPopup toggleEditor={this.toggleEditor} />
                :null
                }
                {settings
                ? <Settings toggleSettings={this.toggleSettings} />
                :null
                }
                {unverifiedTeachers && unverifiedTeachers.length > 0 
                ? <VerifyTeacher unverifiedTeachers={unverifiedTeachers} verify={this.verify} reject={this.reject}/>
                : null
                }
                
                
                <div className="teacherDashboardContainer">
                    <div className="containerTeacherDashRow">
                        <div className="teacherDashSidebarContainer">
                            <div className="classContainerTeacherDashMobile">
                                    <TeacherFeed 
                                        flags={this.state.flags}
                                        toggleFlagPopup={this.toggleFlagPopup}
                                        newQuizzes={this.state.newQuizzes}
                                        newFlags={this.state.newFlags}/>                  
                            </div>
                        </div>
                    </div>                        
                        
                </div>
                
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile

    }
}

export default connect(mapStateToProps)(MobileTeacherFeed)
