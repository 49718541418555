import React from 'react';
import { connect } from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link } from 'react-router-dom'
import MathJax from 'react-mathjax'

import './mathjaxEditor.scss'
import EditorPreview from './editorPreview';
import ElementEditor from './elementEditor';




class Editor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cleanArray: []
        }

    }


    parseInput(input) {

        let secondParse = []

        let combinedString = ""
        if (input) {
            input.map(substring => {
                if (!substring.includes("MATHJAX") && substring !== "<br />") {
                    combinedString = combinedString.concat(substring)
                } else if (substring == "<br />") {
                    if (!combinedString == "") {
                        secondParse.push(combinedString)
                    }
                    secondParse.push(substring)
                    combinedString = ""
                } else {
                    if (!combinedString == "") {
                        secondParse.push(combinedString)
                    }
                    secondParse.push(substring)
                    combinedString = ""
                }
            })
            secondParse.push(combinedString)

        }

        return secondParse;
    }

    toggleAddElement = () => {
        this.setState({
            addElement: this.state.addElement ? false : true
        })
    }

    render() {

        const { textStyle, style, cleanArray, editorPreviewText } = this.props

        const parsedInput = this.parseInput(cleanArray);

        return (

            <div className="popup-container">
                <div className="container">
                    <div className="editorContainer">
                        <div className="editorCloseContainer"><i className="material-icons closeEditor" onClick={this.props.toggleEditor}>close</i></div>
                        <div className="editorTopHalf">
                            <p>Preview element</p>
                            <EditorPreview
                                question2={editorPreviewText}
                                style={"mathsAnswerContainer"}
                                textStyle={"mathsAnswerText"}
                                embedded={true}
                            />
                        </div>
                        <div className="editorBottomHalf">
                            <div className="editorBottom50">
                                {parsedInput.map((substring, index) => {
                                    if (substring.includes("MATHJAX") || substring.includes("MathJax")) {
                                        let cleanString = substring.replace('MATHJAX', '')
                                        
                                        return (
                                            <div>
                                                {index == 0 ?
                                                    <div onClick={() => this.setState({ insertionIndex: 0, addElement: true })}>
                                                        <i className="material-icons">add</i>
                                                    </div>
                                                    : null
                                                }
                                                <div onClick={() => this.setState({ numElement: index, addElement: false, elementType: "equation" })}>
                                                    <MathJax.Provider>
                                                        <MathJax.Node inline formula={cleanString} />
                                                    </MathJax.Provider>
                                                </div>
                                                <div onClick={() => this.setState({ insertionIndex: index + 1, addElement: true })}>
                                                    <i className="material-icons">add</i>
                                                </div>
                                            </div>
                                        )
                                    } else if (substring.includes("<br />")) {
                                        return (
                                            <div>
                                                {index == 0 ?
                                                    <div>
                                                        <i className="material-icons">add</i>
                                                    </div>
                                                    : null
                                                }
                                                <div onClick={() => this.setState({ numElement: index, addElement: false, elementType: "line break" })}>
                                                    <span>LINE BREAK</span>
                                                </div>
                                                <div onClick={() => this.setState({ insertionIndex: index + 1, addElement: true })}>
                                                    <i className="material-icons">add</i>
                                                </div>
                                            </div>
                                        )
                                    } else if (!substring == " ") {
                                        return (
                                            <div>
                                                {index == 0 ?
                                                    <div onClick={() => this.setState({ insertionIndex: 0, addElement: true })}>
                                                        <i className="material-icons">add</i>
                                                    </div>
                                                    : null
                                                }
                                                <div onClick={() => this.setState({ numElement: index, addElement: false, elementType: "rtf" })}>
                                                    <span dangerouslySetInnerHTML={{ __html: substring }}></span>
                                                </div>
                                                <div onClick={() => this.setState({ insertionIndex: index + 1, addElement: true })}>
                                                    <i className="material-icons">add</i>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                }
                            </div>
                            <div className="editorBottom50">
                                <ElementEditor
                                    parsedInput={parsedInput}
                                    numElement={this.state.numElement}
                                    elementType={this.state.elementType}
                                    onExternalEditorChangeQuestion={this.props.onExternalEditorChangeQuestion}
                                    addElement={this.state.addElement}
                                    insertionIndex={this.state.insertionIndex}
                                    refreshEditor={this.refreshEditor}
                                    editableElement={this.props.editableElement}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

export default Editor
