import firebase from 'firebase/app';
import 'firebase/storage'
const auth= require("firebase/auth");
const firestore= require("firebase/firestore")

var config = {
    apiKey: "AIzaSyBAQ9c-fRKnG1-a3DFoVr6m3yeBcmqlNEM",
    authDomain: "idk-tuition.firebaseapp.com",
    databaseURL: "https://idk-tuition.firebaseio.com",
    projectId: "idk-tuition",
    storageBucket: "idk-tuition.appspot.com",
    messagingSenderId: "345340403765",
    appId: "1:345340403765:web:962880160c78b45808117c",
    measurementId: "G-6W5468E5FZ"
};

firebase.initializeApp(config);

const storage = firebase.storage()

export {storage, firebase as default};


