import React from 'react';
import {connect} from 'react-redux'
import { createScore } from '../../store/actions/quizscoreActions'
import { Link } from 'react-router-dom'
import './deleteWarning.scss'




class DeleteWarning extends React.Component {
    constructor(props) {
        super(props);
        
    }
    
    render() {
       const {toggleDeleteWarning, removalIndex}=this.props
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp">
                            <div className="btn-floating closePopup" onClick={toggleDeleteWarning}><i className="material-icons">close</i></div>
                            <h5 className="needHelp">Are you sure?</h5>
                                <div className="fancy-btn needHelp" onClick={(event) => this.props.removeQuestion(removalIndex, event)}>
                                    <i className="material-icons needHelp">delete</i>
                                    <p className="needHelp">Yes, delete</p>
                                </div>
                                <p className="needHelp">This cannot be reversed</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteWarning