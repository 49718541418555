import React from 'react';
import MobileNavBar from '../layout/mobileNavbar'
import './quizzes.css'
import Collapsible from 'react-collapsible';
import { Link } from 'react-router-dom'

import Theme from './theme'
// import Macro from '../Macro landing page/macro'

import { getQuizzesTrial } from '../../helpers/getData';
import firebase from 'firebase';

class QuizLandingTrial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quizzes: [],
            mode: "toggleSubject",
            studentArray: {}
        }
        this.getQuizzesTrialProps = this.getQuizzesTrialProps.bind(this);
    }

    componentDidMount () {
        //For manually updating resource links for SEO
        // firebase.firestore().collection("pastPapers")
        // .get()
        // .then(querySnapshot => {
        //     let resources = []
        //     let code= ""
        //     querySnapshot.docs.map(doc => {
        //         resources.push(doc.data())
        //     })
        //     resources.map(resource => {
        //         code = code + `<a href="resources/${resource.paper}">` + resource.paper + "</a>" +"ZZZ"
        //     })
        //     console.log(code)
        // })
    }

    getQuizzesTrialProps (school, subject) {
        getQuizzesTrial(school, subject)
            .then(( quizzes ) => {
                this.setState({
                    quizzes,
                });
            });
    }

    changeSubject = (subject) => {
        console.log(subject)

        let nonSpecificSubjectString = ""
        if (subject=== "Economics") {
            nonSpecificSubjectString="economics"
        } else if (subject=="gcseBiologySingleScienceAQA") {
            nonSpecificSubjectString = "biology"
        } else if (subject=="gcseChemistrySingleScienceAQA") {
            nonSpecificSubjectString = "chemistry"
        }  else if (subject=="Physics") {
            nonSpecificSubjectString = "Physics"
        }


        this.setState({
            mode: subject,
            nonSpecificSubjectString
        })
        
        if (subject!=="toggleSubject") {
            this.getQuizzesTrialProps("GradeIt", subject)
        }
    }


    render() {

        // if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />

        const { quizzes, mode, nonSpecificSubjectString } = this.state;
        const biology = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FBiology.png?alt=media&token=00c2cabd-e696-4f11-a448-07ef3de87887"
        const chemistry ="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FChemistry.png?alt=media&token=8244cf2c-80ea-48df-aa4b-c11f86bca601"
        const physics = "https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FPhysics.png?alt=media&token=7d891ed9-f5c0-433d-9d3b-379decdd2379"
        const economics ="https://firebasestorage.googleapis.com/v0/b/idk-tuition.appspot.com/o/images%2FWallet.png?alt=media&token=e937952c-e943-4b3a-864c-88fed657cd7c"

        return (
            <div>
                <MobileNavBar />
                <div className="trialHeaderContainer">
                    <div className="trialHeaderTextContainer">
                        <div className="trialHeaderTextContainerTop">
                            {mode == "gcseBiologySingleScienceAQA" || mode ==  "Physics" || mode == "gcseChemistrySingleScienceAQA" 
                            ?
                            <div className="pledge flexColumn">
                                <h4 className="trialHeader">Our pledge: GCSE Science will always be 100% free</h4>
                                <div className="pledge flexRow">
                                    <p className="trialHeader small">No usage limits  |  No hidden charges</p>
                                </div>
                            </div>
                            : <h4 className="trialHeader">Students and teachers can sign up for free!</h4>
                            }
                            <Link to='/signup' className="trialSignupButton" >Sign up</Link>
                        </div>
                    </div>
                </div>


                <div className="topThemeContainer chooseSubject">
                    {mode ==="toggleSubject"
                    ?
                    <div className="selectSubjectContainer">
                        <div className="chooseSubjectContainer">
                            <p className="chooseSubjectText">Choose subject</p>
                        </div>
                        <div className="chooseSubjectPairDivider">
                            <div className="chooseSubjectCard" onClick={()=> this.changeSubject("gcseChemistrySingleScienceAQA")}>
                                <div className="subjectTextContainer">
                                    <p className="chooseSubjectName">GCSE</p>
                                    <p className="chooseSubjectName">Chemistry</p>
                                </div>
                                <img className="chooseSubjectImage" src={chemistry}></img>
                            </div>
                            <div className="chooseSubjectCard" onClick={()=> this.changeSubject("gcseBiologySingleScienceAQA")}>
                                <div className="subjectTextContainer">
                                    <p className="chooseSubjectName">GCSE</p>
                                    <p className="chooseSubjectName">Biology</p>
                                </div>
                                <img className="chooseSubjectImage" src={biology}></img>
                            </div>
                        </div>
                        <div className="chooseSubjectPairDivider">
                            <div className="chooseSubjectCard" onClick={()=> this.changeSubject("Physics")}>
                                
                                <div className="subjectTextContainer">
                                    <p className="chooseSubjectName">GCSE</p>
                                    <p className="chooseSubjectName">Physics</p>
                                </div>
                                <img className="chooseSubjectImage" src={physics}></img>
                            </div>
                            <div className="chooseSubjectCard" onClick={()=> this.changeSubject("Economics")}>
                                <div className="subjectTextContainer">
                                    <p className="chooseSubjectName">A-LEVEL</p>
                                    <p className="chooseSubjectName">Economics</p>
                                </div>
                                <img className="chooseSubjectImage" src={economics}></img>
                            </div>
                        </div>
                    </div>
                    :
                    null
                    }

                    {mode !=="toggleSubject"
                    ? 
                    <div className="subjectToggle" onClick={()=> this.changeSubject("toggleSubject")}>
                        <i className="material-icons subjectToggle">list</i>
                        <p className="subjectToggle">Change subject</p>
                    </div>
                    : null
                    }
                    
                    {mode !=="toggleSubject"
                    &&
                    Object.entries(quizzes).map(([category, quizzesList], index) => (
                        <div className="quizLandingFormContainer" key={index}>
                            
                            <Collapsible trigger={
                                <div className="quizLandingThemeName">
                                        <p className="quizLandingThemeNameTextQuizDash">{category}</p>
                                        {/* <p className="quizLandingThemeInfo">0/{quizzes[category].length} attempted</p> */}
                                </div>
                            }>
                                <div className="categoryContainers">
                                    <Theme
                                        quizzes={quizzesList}
                                        nonSpecificSubjectString={nonSpecificSubjectString}

                                        // quizAttempts={this.state.quizAttempts}
                                        // highScores={highScores}
                                        />
                                </div>
                            </Collapsible>
                        </div>
                    ))}

                    {mode ==="Economics"
                    ?                                   
                    <div className="quizLandingCreateQuizContainer">
                        <Link to='/econmaths' className="quizLandingCreateQuizTextContainer">
                            <div className="flexCentre">
                                <i className='material-icons calculate'>calculate</i>
                            </div>
                            <p className="quizLandingCreateQuizDash">Economics for Mathematicians</p>
                        </Link>
                    </div>
                    : null}  
                </div>
            </div>
        );     
    }
};

export default (QuizLandingTrial);

