import React from 'react';
import { Droppable } from 'react-beautiful-dnd'
import { Draggable } from 'react-beautiful-dnd'

// {/*flashcardSelect={this.props.flashcardSelect} removeQuestion={this.props.removeQuestion}*/}

export default class ColumnAssign extends React.Component {
    render () {
        return (
            <Droppable droppableId="0">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {/* {this.props.cards.map((card, index) => <CardComponent key={`card${index}`} card={card} index={index}/>)} */}
                        {this.props.assignments.map((assignment, index) => 
                            <Draggable draggableId={assignment.randomString} index={index} key={assignment.randomString} >
                            {(provided) => (  
                            <div className="flashcardPreview" 
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                            >
                                <div className="dragItem">
                                    <i className="material-icons">drag_handle</i>
                                </div>
                                <p className="computerTaskNumber">Task {index+1}</p>
                                <a href={
                                    assignment.activity==='quiz' ?
                                        assignment.category==="My quizzes" ?
                                            `quizzesschool/${assignment.quiz}`
                                            :`quizzes/${assignment.quiz}`
                                        : `flashcardformbeta/${assignment.flashcard}`
                                }
                                 className="flashcardPreviewText columnAssign"
                                >
                                    {assignment.activity}- {assignment.quiz}
                                </a>
                                <div className="btn-floating flashcardFormRemoveQuestion" onClick={(event) => this.props.removeAssignment(index, event)}>
                                                <i className="material-icons flashcardFormRemoveQuestion">delete</i>
                                </div>
                                
                            </div>
                            ) }
                            </Draggable>
        )}

                        
                        {provided.placeholder}
                    </div>
                )}
                
            </Droppable> 
        )
    }
}