import React from 'react';
import MathJax from 'react-mathjax'

import './mathjaxEditor.scss'

class EditorPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    parseInput(input) {
        let secondParse = []

        if (input) {
            const firstParse = input.split(/</g)
        

            firstParse.map(substring => {

                //Deals with opening tags
                if(substring.includes(">") 
                    && !substring.includes("</") 
                    && !substring.includes("/>")
                    && !substring.includes("/")
                    ){
                    let combinedString = ""
                    let splitString= substring.split(/>/g)

                        // secondParse.push(splitString[0])
                        let tag = ""
                        splitString.map((miniString, index) => {

                            if(index==0) {
                                combinedString = combinedString.concat("<" + miniString + ">")
                                tag = miniString
                            } 
                            else {
                                combinedString = combinedString.concat(miniString + "</" + tag + ">")
                            }
                        })
              
                        secondParse.push(combinedString)


                }

                //Deals with closing tags e.g </strong>
                if(substring[0]==="/") {
                    if (substring.includes("/strong>")) {
                        let splitString= substring.split(/>/g)
                        secondParse.push(splitString[1])
                    }

                }


                //Deals with closing tags
                if(substring.includes("/>") && !substring.includes("inline formula")) {

                    if (substring.includes("br />")) {
                        let splitString= substring.split(/>/g)
                        // secondParse.push("<br />", splitString[1])
                        if (splitString[1] == " "){
                            secondParse.push("<br />")
                        } else {
                        secondParse.push("<br />", splitString[1])
                        }
                    } else {
                        let splitString= substring.split(/>/g)

                        // secondParse.push(splitString[0])
                        splitString.map((miniString, index) => {
                            if(index!=0) {
                                secondParse.push(miniString)
                            }
                            
                        })
                    }
                }

                //Deals with Mathjax
                if (substring.includes("inline formula")) {
                    let splitString = substring.split(" />")
                    
                    //Splits using ""
                    let inlineFormula = splitString[0].split("\"")

                    if ((splitString[1] == " ") || !splitString[1]){
                        secondParse.push(`MATHJAX${inlineFormula[1]}`)
                    } else {
                        secondParse.push(`MATHJAX${inlineFormula[1]}`, `${splitString[1]}`)
                    }

                } else if (substring.includes("MathJax.Node")) {
                    return
                } else if (!substring.includes(">")) {
                    secondParse.push(substring)
                }
            })
        }


        return secondParse;
    }

    render() {
        const cleanArray = this.parseInput(this.props.question2);
        const { textStyle, style, embedded } = this.props

        return (
            cleanArray
                ?

                    <p className={textStyle}>
                        <MathJax.Provider>
                            {cleanArray.map(substring => {
                                if (substring.includes("MATHJAX")) {
                                    let cleanString = substring.replace('MATHJAX', '')
                                    return (
                                        <MathJax.Node inline formula={cleanString} />
                                    )
                                } else {
                                    return (
                                        <span dangerouslySetInnerHTML={{__html: substring}}></span>
                                    )
                                }
                            })}
                            {!embedded 
                                ? 
                                <i className="material-icons" onClick={()=>this.props.toggleEditor(cleanArray, this.props.question2, this.props.editableElement)}>edit</i>
                                : 
                                null
                            }
                        </MathJax.Provider>
                    </p>

                :
                <div></div>
        )

    }
}

export default EditorPreview