import React from 'react';
import './manageClassesPopup.scss'

class SignUpPopup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
           
        };
        
    }

    copyToClipboard = () => {
        const {signUpKey} = this.props
        navigator.clipboard.writeText(`https://gradeit.io/signup/${signUpKey}`)
        this.setState({
            copied: true
        })
    }    
    

    
    render() {
        const {signUpKey, classID} = this.props
        const {copied} = this.state 
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popupManageClasses">
                        <div className="closePopupManageClasses">
                            <i className="material-icons" onClick={(()=>this.props.toggleSignUpPopup())}>close</i>
                        </div>

                            <div className="marginTenTop">
                                <p className="signUpTitle" htmlFor="School">Sign-up students to {classID}</p>
                            </div>
                            <div className="ninetyPadded">
                                <p className="classLinkText">https://gradeit.io/signup/{signUpKey}</p>
                                <div className="copyToClipboard" onClick={this.copyToClipboard}>
                                    <i className="material-icons copyToClipboard">content_copy</i>
                                    {copied 
                                        ? <p>Copied</p>
                                        : <p>Copy to clipboard</p>
                                    }
                                </div>
                            </div>
                            <div className="ninetyPadded">
                                <p className="signUpCopyInstruction">Copy and paste this link into an email to send to your students</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default SignUpPopup