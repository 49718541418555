import React from 'react';
import './manageClassesPopup.scss'

class WarningPopup extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
           
        };
        
    }

    

    
    handleChange = (e) => {
        
        let { id, name, value, type, checked } = e.target
        this.setState ( 
            {edited : true}
        )

    }


    removeHelper = () => {
        const { classSelector, removeClass, closeWarning } = this.props
        removeClass(classSelector)
        closeWarning()
    }
    
    
    render() {
        const { closeWarning } = this.props
        const { edited } = this.state

        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="col-md-8 col-md-offset-2">
                        <div className="popupManageClasses">

                            <div className="marginTenTop">
                                <p>Are you sure you want to remove this class?</p>
                            </div>

                            <div className="flexRowWarning">
                                <div className="removeButton" onClick={this.removeHelper}>
                                    <p className="removeButton">REMOVE</p>
                                </div>
                                <div className="cancelButton" onClick={closeWarning}>
                                    <p className="cancelButton">CANCEL</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default WarningPopup