import React from 'react'
import './progress.css';
import { Line } from 'rc-progress';
import NewProgressLine from './newProgressLine';
import { Link } from 'react-router-dom'

class NewProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render () {
        const {quizAttempts} = this.props

       
        return (
            <div className="dashboardContainerLeftElement">
                <div className="dashBoardContainerProgress">
                    <div className="flexRow">
                        <h1 className="dashboardElementTextProgress">🚀 Progress</h1>
                    </div>
                    
                    {
                        quizAttempts && Object.entries(quizAttempts).map(category => {
                            if (category[0]!== "My quizzes") {
                                const percent = Math.round(category[1].attempts/category[1].total*100)
                                    return (
                                        <div>
                                            <div className="dashboardElement">
                                                <Link to='/quizzes' className="dashboardElementTheme" >{category[0]}</Link>
                                            </div>
                    
                                            <NewProgressLine
                                                category={category}
                                                percent={percent}
                                                themeAttempts={category[1].attempts}
                                                themeTotal={category[1].total}
                                            />
                                        </div>
                                    )
                            }
                        })
                    }
                </div>
            </div>
            )
        }
}
        
    

export default NewProgress