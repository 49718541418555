import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Footer from '../layout/footer'
import firebase from '../auth/auth'



class PaperDump extends Component {

    constructor(props) {
        super(props);
        this.state= {
            loading: true
        }

    }


    render () {
        const { auth } = this.props;
        const { loading } = this.state


        return (
            <div>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 1 Question Paper Foundation tier">AQA GCSE Chemistry June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 1 Question Paper Foundation tier">AQA GCSE Physics June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics June 2019 Paper 1 Mark Scheme">Edexcel A-Level Economics June 2019 Paper 1 Mark Scheme</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 2 Mark Scheme Foundation tier">AQA GCSE Biology June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 1 Question Paper Higher tier">AQA GCSE Combined Chemistry June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Biology June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 2 Question Paper Higher tier">Edexcel GCSE Biology June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 1 Question Paper Higher tier">Edexcel GCSE Physics June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 2 Question Paper Higher tier">Edexcel GCSE Mathematics June 2017 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 1 Mark Scheme Higher tier">AQA GCSE Chemistry June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Physics June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2018 Paper 3 Question Paper">Edexcel A-Level Economics June 2018 Paper 3 Question Paper</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 3 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2017 Paper 3 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 2 Mark Scheme Higher tier">AQA GCSE Chemistry November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 1 Question Paper Higher tier">Edexcel GCSE Mathematics June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Chemistry November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Chemistry June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Physics June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel A-Level Economics November 2020 Paper 3 Question Paper">Edexcel A-Level Economics November 2020 Paper 3 Question Paper</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Chemistry June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Physics June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 2 Mark Scheme Foundation tier">AQA GCSE Physics November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Biology June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Biology June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel A-Level Economics November 2020 Paper 3 Mark Scheme">Edexcel A-Level Economics November 2020 Paper 3 Mark Scheme</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Biology June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 1 Mark Scheme Higher tier">AQA GCSE Physics June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 3 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2018 Paper 3 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2017 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 1 Question Paper Higher tier">AQA GCSE Combined Biology November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2018 Paper 1 Question Paper">Edexcel A-Level Economics June 2018 Paper 1 Question Paper</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 2 Question Paper Higher tier">Edexcel GCSE Biology June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Biology June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Physics June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Biology November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Physics November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Physics June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 2 Question Paper Foundation tier">AQA GCSE Chemistry June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 1 Question Paper Higher tier">Edexcel GCSE Mathematics November 2017 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 1 Mark Scheme Higher Tier">Edexcel GCSE Chemistry June 2018 Paper 1 Mark Scheme Higher Tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Chemistry June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Biology November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Biology November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Physics June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Physics November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 2 Mark Scheme Higher tier">AQA GCSE Chemistry June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 2 Question Paper Higher tier">AQA GCSE Combined Chemistry November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Physics June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 2 Question Paper Foundation tier">Edexcel GCSE Physics June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Biology November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Chemistry June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 2 Mark Scheme Higher tier">AQA GCSE Physics June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 1 Question Paper Higher tier">AQA GCSE Chemistry November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Chemistry November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 1 Question Paper Foundation tier">AQA GCSE Chemistry June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics November 2020 Paper 2 Mark Scheme">Edexcel A-Level Economics November 2020 Paper 2 Mark Scheme</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 3 Question Paper Higher tier">Edexcel GCSE Mathematics June 2019 Paper 3 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 2 Question Paper Higher tier">Edexcel GCSE Physics June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 2 Question Paper Higher tier">Edexcel GCSE Biology November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 3 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2017 Paper 3 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 2 Question Paper Higher tier">AQA GCSE Combined Chemistry June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 1 Question Paper Higher tier">Edexcel GCSE Mathematics November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 3 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2020 Paper 3 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Biology June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Biology November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Chemistry November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Biology June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 2 Question Paper Higher tier">AQA GCSE Chemistry June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 3 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2018 Paper 3 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 1 Mark Scheme Foundation tier">AQA GCSE Chemistry June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Biology June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Biology November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 2 Question Paper Higher tier">AQA GCSE Biology November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Chemistry November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 1 Question Paper Foundation tier">Edexcel GCSE Biology November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 2 Question Paper Foundation tier">Edexcel GCSE Chemistry November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 2 Question Paper Higher tier">AQA GCSE Combined Physics June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Physics June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 3 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2017 Paper 3 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Physics November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Physics November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 1 Question Paper Higher tier">AQA GCSE Physics June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Physics November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Chemistry June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 1 Question Paper Foundation tier">AQA GCSE Physics June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 2 Question Paper Higher tier">AQA GCSE Biology June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 2 Mark Scheme Foundation Tier">Edexcel GCSE Biology June 2018 Paper 2 Mark Scheme Foundation Tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 1 Question Paper Foundation tier">Edexcel GCSE Physics June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 1 Question Paper Higher tier">Edexcel GCSE Biology June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 3 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2020 Paper 3 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 2 Question Paper Foundation tier">Edexcel GCSE Chemistry June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Chemistry June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics June 2017 Paper 3 Mark Scheme">Edexcel A-Level Economics June 2017 Paper 3 Mark Scheme</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 2 Question Paper Foundation tier">Edexcel GCSE Biology November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 1 Mark Scheme Foundation tier">AQA GCSE Biology November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 2 Question Paper Foundation tier">Edexcel GCSE Biology June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 2 Question Paper Foundation tier">AQA GCSE Chemistry June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 2 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2017 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 1 Mark Scheme Foundation Tier">Edexcel GCSE Biology June 2018 Paper 1 Mark Scheme Foundation Tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 1 Question Paper Higher tier">AQA GCSE Combined Physics June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 2 Mark Scheme Higher tier">AQA GCSE Physics June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Physics November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Chemistry June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 1 Mark Scheme Foundation tier">AQA GCSE Biology June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 2 Question Paper Higher tier">Edexcel GCSE Physics June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Higher tier">Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Physics November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 2 Question Paper Higher tier">Edexcel GCSE Mathematics November 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 1 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 1 Mark Scheme Higher tier">AQA GCSE Biology June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 1 Mark Scheme Higher tier">AQA GCSE Physics June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 2 Mark Scheme Foundation tier">AQA GCSE Chemistry June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 2 Question Paper Higher tier">AQA GCSE Combined Biology June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 3 Question Paper Higher tier">Edexcel GCSE Mathematics November 2017 Paper 3 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 2 Mark Scheme Foundation tier">AQA GCSE Physics June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Chemistry June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 2 Question Paper Higher tier">Edexcel GCSE Physics November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Chemistry June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics June 2018 Paper 1 Mark Scheme">Edexcel A-Level Economics June 2018 Paper 1 Mark Scheme</a>
                <a href="resources/Edexcel A-Level Economics June 2017 Paper 1 Mark Scheme">Edexcel A-Level Economics June 2017 Paper 1 Mark Scheme</a>
                <a href="resources/Edexcel A-Level Economics June 2018 Paper 2 Mark Scheme">Edexcel A-Level Economics June 2018 Paper 2 Mark Scheme</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 1 Question Paper Higher tier">Edexcel GCSE Physics June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 2 Question Paper Foundation tier">AQA GCSE Biology June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2017 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 1 Mark Scheme Foundation tier">AQA GCSE Physics June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Biology June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Physics June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Physics June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 2 Question Paper Higher tier">AQA GCSE Chemistry June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 2 Mark Scheme Foundation tier">AQA GCSE Physics June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Chemistry November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 1 Question Paper Higher tier">AQA GCSE Combined Physics June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 1 Question Paper Higher tier">AQA GCSE Chemistry June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Biology June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Physics June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 1 Question Paper Higher Tier">Edexcel GCSE Chemistry June 2018 Paper 1 Question Paper Higher Tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 2 Question Paper Foundation tier">Edexcel GCSE Chemistry June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 2 Question Paper Higher tier">AQA GCSE Combined Biology November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 1 Question Paper Foundation tier">AQA GCSE Biology June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Chemistry November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 3 Question Paper Higher tier">Edexcel GCSE Mathematics June 2017 Paper 3 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 2 Mark Scheme Higher tier">AQA GCSE Biology June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Biology June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 1 Question Paper Higher tier">Edexcel GCSE Chemistry June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 1 Mark Scheme Foundation tier">AQA GCSE Physics November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Chemistry November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 2 Mark Scheme Higher tier">AQA GCSE Chemistry June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 1 Question Paper Higher tier">Edexcel GCSE Biology November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2017 Paper 3 Question Paper">Edexcel A-Level Economics June 2017 Paper 3 Question Paper</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 1 Question Paper Higher tier">AQA GCSE Combined Physics November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics June 2018 Paper 3 Mark Scheme">Edexcel A-Level Economics June 2018 Paper 3 Mark Scheme</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 2 Question Paper Foundation tier">AQA GCSE Biology June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 1 Question Paper Foundation tier">AQA GCSE Biology November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 1 Question Paper Foundation tier">Edexcel GCSE Physics June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 1 Mark Scheme Higher tier">AQA GCSE Chemistry June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 2 Mark Scheme Higher tier">AQA GCSE Physics November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Chemistry June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Chemistry June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Chemistry June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 2 Question Paper Foundation tier">AQA GCSE Physics November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 1 Question Paper Higher tier">AQA GCSE Biology June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 1 Question Paper Higher tier">AQA GCSE Physics June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 1 Question Paper Foundation tier">Edexcel GCSE Chemistry June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Physics June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 1 Question Paper Foundation tier">Edexcel GCSE Chemistry June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 2 Question Paper Higher tier">AQA GCSE Combined Physics November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 3 Question Paper Higher tier">Edexcel GCSE Mathematics November 2020 Paper 3 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Biology June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 2 Question Paper Higher tier">Edexcel GCSE Mathematics November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 3 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2020 Paper 3 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 2 Question Paper Higher tier">AQA GCSE Combined Chemistry June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Chemistry June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 1 Question Paper Higher tier">Edexcel GCSE Physics November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 1 Question Paper Higher tier">AQA GCSE Combined Biology June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 2 Question Paper Higher tier">Edexcel GCSE Mathematics November 2017 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 1 Mark Scheme Higher tier">AQA GCSE Biology June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2018 Paper 2 Question Paper">Edexcel A-Level Economics June 2018 Paper 2 Question Paper</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Chemistry November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 2 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 1 Question Paper Higher tier">AQA GCSE Biology November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 2 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics June 2019 Paper 3 Mark Scheme">Edexcel A-Level Economics June 2019 Paper 3 Mark Scheme</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Biology June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 2 Question Paper Higher tier">AQA GCSE Chemistry November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Biology June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 1 Question Paper Foundation tier">AQA GCSE Biology June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 1 Question Paper Higher tier">AQA GCSE Combined Chemistry June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2017 Paper 2 Question Paper">Edexcel A-Level Economics June 2017 Paper 2 Question Paper</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Biology November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Physics June 2019 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 2 Mark Scheme Higher tier">AQA GCSE Biology November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Physics June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Higher tier">Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Chemistry June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 1 Question Paper Higher tier">AQA GCSE Combined Chemistry November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Biology November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 1 Mark Scheme Foundation tier">AQA GCSE Biology June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 2 Question Paper Higher tier">Edexcel GCSE Chemistry June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Chemistry June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 2 Mark Scheme Foundation tier">AQA GCSE Chemistry June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 2 Question Paper Higher tier">AQA GCSE Physics June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry November 2020 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Chemistry November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Higher tier">Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2019 Paper 3 Question Paper">Edexcel A-Level Economics June 2019 Paper 3 Question Paper</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 1 Mark Scheme Higher tier">AQA GCSE Physics November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2019 Paper 2 Question Paper">Edexcel A-Level Economics June 2019 Paper 2 Question Paper</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Higher tier">Edexcel GCSE Mathematics June 2018 Paper 3 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 3 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2019 Paper 3 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2019 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Chemistry June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 3 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2017 Paper 3 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 2 Question Paper Higher tier">AQA GCSE Physics November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 1 Question Paper Higher tier">AQA GCSE Physics November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 2 Question Paper Foundation tier">AQA GCSE Chemistry November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 2 Question Paper Foundation tier">Edexcel GCSE Physics June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Physics June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 2 Mark Scheme Foundation tier">AQA GCSE Chemistry November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 1 Question Paper Higher tier">Edexcel GCSE Mathematics June 2017 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Biology November 2020 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Biology November 2020 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry June 2019 Paper 1 Question Paper Higher tier">AQA GCSE Chemistry June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 1 Mark Scheme Foundation tier">AQA GCSE Combined Biology June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 1 Question Paper Foundation Tier">Edexcel GCSE Biology June 2018 Paper 1 Question Paper Foundation Tier</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 2 Mark Scheme Foundation tier">AQA GCSE Biology June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 1 Question Paper Foundation tier">AQA GCSE Chemistry November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics June 2017 Paper 1 Question Paper">Edexcel A-Level Economics June 2017 Paper 1 Question Paper</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Biology June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics June 2017 Paper 2 Mark Scheme">Edexcel A-Level Economics June 2017 Paper 2 Mark Scheme</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 3 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2018 Paper 3 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 2 Question Paper Higher tier">AQA GCSE Combined Physics June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 2 Question Paper Higher tier">AQA GCSE Physics June 2018 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 1 Mark Scheme Higher tier">AQA GCSE Biology November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 2 Question Paper Foundation tier">AQA GCSE Physics June 2019 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Chemistry November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 3 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2017 Paper 3 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Physics June 2019 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Physics November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2020 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 1 Question Paper Higher tier">AQA GCSE Combined Biology June 2019 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Biology June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 1 Question Paper Higher tier">AQA GCSE Biology June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Physics November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Physics November 2020 Paper 1 Question Paper Foundation tier">AQA GCSE Physics November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 2 Question Paper Foundation tier">Edexcel GCSE Physics November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 1 Mark Scheme Higher tier">AQA GCSE Chemistry November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Biology June 2019 Paper 1 Question Paper Foundation tier">Edexcel GCSE Biology June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Higher tier">Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 3 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2019 Paper 3 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 1 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 2 Question Paper Higher tier">Edexcel GCSE Mathematics June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2019 Paper 1 Question Paper">Edexcel A-Level Economics June 2019 Paper 1 Question Paper</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 2 Question Paper Foundation tier">AQA GCSE Biology November 2020 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics November 2020 Paper 2 Mark Scheme Foundation tier">AQA GCSE Combined Physics November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2018 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Chemistry June 2018 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel A-Level Economics November 2020 Paper 1 Mark Scheme">Edexcel A-Level Economics November 2020 Paper 1 Mark Scheme</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2019 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Physics June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Biology June 2019 Paper 2 Question Paper Higher tier">AQA GCSE Biology June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 1 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2017 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2017 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel A-Level Economics November 2020 Paper 1 Question Paper">Edexcel A-Level Economics November 2020 Paper 1 Question Paper</a>
                <a href="resources/AQA GCSE Physics June 2019 Paper 1 Mark Scheme Foundation tier">AQA GCSE Physics June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Biology November 2020 Paper 2 Mark Scheme Foundation tier">AQA GCSE Biology November 2020 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 1 Question Paper Higher tier">Edexcel GCSE Chemistry November 2020 Paper 1 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Biology November 2020 Paper 1 Mark Scheme Higher tier">AQA GCSE Combined Biology November 2020 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 3 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2019 Paper 3 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 3 Question Paper Higher tier">Edexcel GCSE Mathematics November 2018 Paper 3 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Combined Chemistry June 2019 Paper 2 Mark Scheme Higher tier">AQA GCSE Combined Chemistry June 2019 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Chemistry November 2020 Paper 1 Mark Scheme Foundation tier">AQA GCSE Chemistry November 2020 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 2 Question Paper Foundation Tier">Edexcel GCSE Biology June 2018 Paper 2 Question Paper Foundation Tier</a>
                <a href="resources/Edexcel A-Level Economics November 2020 Paper 2 Question Paper">Edexcel A-Level Economics November 2020 Paper 2 Question Paper</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 3 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2017 Paper 3 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 1 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2017 Paper 1 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 2 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2017 Paper 2 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Mathematics November 2017 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Biology June 2018 Paper 2 Mark Scheme Higher tier">AQA GCSE Biology June 2018 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 3 Mark Scheme Foundation tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2018 Paper 1 Question Paper Foundation tier">AQA GCSE Combined Biology June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2019 Paper 1 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2019 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Higher tier">Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Physics November 2020 Paper 1 Question Paper Foundation tier">Edexcel GCSE Physics November 2020 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Foundation tier">Edexcel GCSE Mathematics June 2018 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 2 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/AQA GCSE Combined Physics June 2018 Paper 2 Question Paper Foundation tier">AQA GCSE Combined Physics June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 2 Question Paper Higher Tier">Edexcel GCSE Chemistry June 2018 Paper 2 Question Paper Higher Tier</a>
                <a href="resources/AQA GCSE Combined Biology June 2019 Paper 2 Question Paper Higher tier">AQA GCSE Combined Biology June 2019 Paper 2 Question Paper Higher tier</a>
                <a href="resources/AQA GCSE Chemistry June 2018 Paper 1 Mark Scheme Foundation tier">AQA GCSE Chemistry June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics June 2017 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Mathematics June 2017 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/Edexcel GCSE Physics June 2018 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Physics June 2018 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Mathematics May 2017 Paper 1 Question Paper Foundation tier">Edexcel GCSE Mathematics May 2017 Paper 1 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Biology June 2018 Paper 1 Question Paper Higher tier">Edexcel GCSE Biology June 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 2 Question Paper Foundation tier">Edexcel GCSE Mathematics November 2017 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Physics June 2019 Paper 1 Mark Scheme Foundation tier">Edexcel GCSE Physics June 2019 Paper 1 Mark Scheme Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 2 Question Paper Higher tier">Edexcel GCSE Chemistry November 2020 Paper 2 Question Paper Higher tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2017 Paper 2 Mark Scheme Higher tier">Edexcel GCSE Mathematics November 2017 Paper 2 Mark Scheme Higher tier</a>
                <a href="resources/AQA GCSE Physics June 2018 Paper 2 Question Paper Foundation tier">AQA GCSE Physics June 2018 Paper 2 Question Paper Foundation tier</a>
                <a href="resources/Edexcel GCSE Chemistry June 2018 Paper 2 Mark Scheme Higher Tier">Edexcel GCSE Chemistry June 2018 Paper 2 Mark Scheme Higher Tier</a>
                <a href="resources/Edexcel GCSE Mathematics November 2018 Paper 1 Question Paper Higher tier">Edexcel GCSE Mathematics November 2018 Paper 1 Question Paper Higher tier</a>
                <a href="resources/Edexcel A-Level Economics June 2019 Paper 2 Mark Scheme">Edexcel A-Level Economics June 2019 Paper 2 Mark Scheme</a>
                <a href="resources/Edexcel GCSE Chemistry November 2020 Paper 1 Question Paper Foundation tier">Edexcel GCSE Chemistry November 2020 Paper 1 Question Paper Foundation tier</a>

            
            </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


export default connect(mapStateToProps) (PaperDump)


