export const createScore = (score, total, quizName, school, firstName, 
    lastName, pathname, userId, eventId=0, classID="", subject="", assigned=true) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        //make sync call to database
        const firestore = getFirestore();
        // const profile = getState().firebase.profile;
        const userID= getState().firebase.auth.uid;
        firestore.collection('quizAttempts').add({
            score: score,
            total: total,
            percent: score/total,
            user: userID,
            quizName: quizName,
            createdAt: new Date(),
            school: school,
            firstName: firstName,
            lastName: lastName,
            userId: userId,
            eventId: eventId,
            classID: classID,
            subject: subject,
            assigned: assigned
        }).then(() => {
            
            firestore.collection(`studentProgress${subject==="Economics"?"":subject}`).doc(userID).get().then(doc => {
                if (!doc.exists) {
                    console.log("No such document")
                    var dynamicKey =`studentProgress.${quizName}`
                    var obj = {[dynamicKey] : Math.round(score/total*100)}
                    firestore.collection(`studentProgress${subject==="Economics"?"":subject}`).doc(userID).set(( 
                        {   createdAt: new Date(),
                            firstName: firstName,
                            lastName: lastName,
                            school: school,
                            [subject==="Economics"?"class":subject+"class"]: classID,
                            studentProgress: {[quizName]: Math.round(score/total*100)},
                            user: userID
                        }
                        ))

                } else {
                console.log(quizName)
                }
                
                const highestAttempt = doc.data()["studentProgress"][quizName] ? doc.data()["studentProgress"][quizName] : 0

                if (!highestAttempt || highestAttempt<Math.round(score/total*100)) {
                    var dynamicKey =`studentProgress.${quizName}`
                    var obj = {[dynamicKey] : Math.round(score/total*100)}
                    firestore.collection(`studentProgress${subject==="Economics"?"":subject}`).doc(userID).update(( obj ))
                }
            })

            firestore.collection('schools').where('school','==', school)
            .get()
            .then(querySnapshot => {
                const schoolId = querySnapshot.docs[0].id;

                firestore.runTransaction(t => {
                    return t.get(firestore.collection('schools').doc(schoolId))
                        .then(doc => {
                            const schoolFeedArray = doc.data().schoolFeedArray || []

                            if(schoolFeedArray.length >= 1000) {
                                schoolFeedArray.shift()
                                schoolFeedArray.push({
                                    type: "quiz",
                                    score: score,
                                    total: total,
                                    percent: Math.round(score/total*100),
                                    user: userID,
                                    quizName: quizName,
                                    createdAt: new Date(),
                                    school: school,
                                    firstName: firstName,
                                    lastName: lastName,
                                    pathname: pathname,
                                    userId: userId,
                                    classID: classID,
                                    subject: subject
                                })
                            } else {
                                schoolFeedArray.push({
                                    type: "quiz",
                                    score: score,
                                    total: total,
                                    percent: Math.round(score/total*100),
                                    user: userID,
                                    quizName: quizName,
                                    createdAt: new Date(),
                                    school: school,
                                    firstName: firstName,
                                    lastName: lastName,
                                    pathname: pathname,
                                    userId: userId,
                                    classID: classID,
                                    subject: subject
                                })
                            }
                            return t.update(firestore.collection('schools').doc(schoolId), {schoolFeedArray})

                        })
                }).then(result => {
                    console.log('Transaction success!' + result);
                  }).catch(err => {
                    console.log('Transaction failure:', err);
                  }) 

            })


            // firestore.collection('schools').where('school','==', school)
            // .get()
            // .then(querySnapshot => {
            //     const schoolID = querySnapshot.docs[0].id;
            //     const schoolFeedArray = querySnapshot.docs[0].data().schoolFeedArray || []
            //     console.log(schoolFeedArray)

            //     if(schoolFeedArray.length >= 200) {
            //         console.log(schoolFeedArray)
            //         schoolFeedArray.shift()
            //         schoolFeedArray.push({
            //             type: "quiz",
            //             score: score,
            //             total: total,
            //             percent: Math.round(score/total*100),
            //             user: userID,
            //             quizName: quizName,
            //             createdAt: new Date(),
            //             school: school,
            //             firstName: firstName,
            //             lastName: lastName,
            //             pathname: pathname,
            //             userId: userId

            //         })
            //     } else {
            //         console.log(schoolFeedArray)
            //         schoolFeedArray.push({
            //             type: "quiz",
            //             score: score,
            //             total: total,
            //             percent: Math.round(score/total*100),
            //             user: userID,
            //             quizName: quizName,
            //             createdAt: new Date(),
            //             school: school,
            //             firstName: firstName,
            //             lastName: lastName,
            //             pathname: pathname,
            //             userId: userId
            //         })
            //     }
            //     firestore.collection('schools')
            //     .doc(schoolID)
            //     .update({
            //         schoolFeedArray
            //     })
            // })
            
        })
        .then(() => {
            dispatch({type: 'CREATE_SCORE', score: score, total:total}); 
        }).catch((err) => {
            dispatch({type: 'CREATE_SCORE_ERROR', err})
        })
        
    }
}
