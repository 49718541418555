const initState = {
    progress: [
        {id: '1', completion: 60, accuracy: 72 }
    ]
}

const progressReducer = (state=initState, action) => {
    switch (action.type) {
        case 'CREATE_SCORE':
            console.log("created score", action.score)
            return state;
        case 'CREATE_SCORE_ERROR':
            console.log('create score error', action.err);
            return state;
        default:
            return state
    }
}

export default progressReducer