import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'



import './diagnostic.scss';


class RestrictionsWarning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }



    }

  


    render() {


        return(
            <div className="popup-container">
                <div className="container subjectMenu">
                    <div className="popupSubjectMenu">
                        <div className="subjectMenuCloseContainer">
                            <i className="material-icons closeCategoryMenu" onClick={this.props.restrictionsWarning}>close</i>
                        </div>
                        <div>
                            <div className="subjectMenuChangeSubjectTextContainer">
                                <div className="categoryMenuflexColumn">
                                    {this.props.totallyRestricted
                                    ?
                                    <div className="paddedRestrictionsWarning">
                                    <p className="categoryMenuCategoryName restrictionsWarning">The following topics have been completely restricted by your teacher. These may no longer be part of your exam:</p>
                                        {this.props.totallyRestrictedThemes.map(theme=> {
                                            return(
                                                <p className="categoryMenuCategoryName restrictionsWarning themes">•{theme}</p>
                                            )
                                        })}
                                        <div className="verifyButtonNo centre" onClick={() => this.props.startQuiz([])}>
                                            <i className="material-icons verifyTeacher">close</i>
                                            <p className="verifyText">Ignore restrictions</p>
                                        </div>
                                    </div>
                                    :
                                    <div className="paddedRestrictionsWarning">
                                        <p className="categoryMenuCategoryName restrictionsWarning">Some of the topics you have selected have been restricted by your teacher. 
                                    Certain topics may have been removed from your exam.
                                        </p>
                                        <p className="categoryMenuCategoryName restrictionsWarning">Do you want to apply the restrictions?
                                        </p>
                                    <div className="verifyButtonContainer">
                                            <div className="verifyButtonYes" onClick={this.props.triggerQuizWithRestrictions}>
                                                <i className="material-icons verifyTeacher">done</i>
                                                <p className="verifyText">Apply restrictions</p>
                                            </div>
                                            <div className="verifyButtonNo" onClick={() => this.props.startQuiz([])}>
                                                <i className="material-icons verifyTeacher">close</i>
                                                <p className="verifyText">Ignore restrictions</p>
                                            </div>
                                    </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionsWarning)