import React from 'react';
import firebase from '../auth/auth'
import { connect } from 'react-redux'
import Spinner from '../spinner/spinner'
import { Redirect, Link, Route } from 'react-router-dom'

import './customiseFeed.scss'

class CustomiseFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    handleChange = (e) => {
        this.props.toggleClass(e.target.value)
        this.props.getFeed(e.target.value)
    }

    
    render() {

        const {toggleCustomView, classData} = this.props
        
        const classes = Object.keys(classData) || null
        
        
        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupCustomiseFeed">
                            <div className="btn-floating closePopupCustomiseFeed" onClick={toggleCustomView}><i className="material-icons">close</i></div>
                            <div className='customiseFeedContainer'>
                                <label className={'select-label customView'} htmlFor="School">View by class</label>
                                <select className="schoolDropdown" onChange={this.handleChange} value={this.props.activeClass} name="school">
                                    <option value="">All students</option>
                                    {classes 
                                    ? classes.map(c => {return <option key={c}>{c}</option>})
                                    : null
                                    }
                                    
                                </select>

                                <div>
                                    <Link className='manageClassesButtonCustomiseFeed' to='/manageclasses'>
                                        Manage classes 
                                        <i className='material-icons settingsButtonCustomiseFeed'>settings</i>
                                    </ Link>
                                    <Route exact path={"/"}>
                                        <Link className='studentDataButtonCustomiseFeed' to='/teacherdata'>
                                            Student Data
                                            <i className='material-icons settingsButtonCustomiseFeed'>analytics</i>
                                        </ Link>
                                    </Route>     
                                    <Route exact path={"/mobilefeed"}>
                                        <Link className='studentDataButtonCustomiseFeed' to='/teacherdata'>
                                            Student Data
                                            <i className='material-icons settingsButtonCustomiseFeed'>analytics</i>
                                        </ Link>
                                    </Route>
                                </div>                                                         
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progress: state.progress.progress,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(CustomiseFeed);