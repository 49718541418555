import React from 'react';
import Answers from './mcqFormAnswer';
import Popup from './mcqFormPopup';
// import Footer from 'Footer';
import {connect} from 'react-redux'
import { Redirect } from 'react-router-dom'
import { createScore } from '../../store/actions/quizscoreActions'
import ReactPaginate from 'react-paginate';

import './mcqGraph.css';


class FatFinger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nr: 0,
            total: 0,
            showButton: false,
            questionAnswered: false,
            score: 0,
            displayPopup: 'none',
            answers2: [],
            question2: [],
            correct2: [],
            exposition: [],
            questionbank: [],
            numberClicked: 0,
            clearStyling: true,
            wrongRight: "INCORRECT"
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.selectQuestion = this.selectQuestion.bind(this);
        this.handleShowButton = this.handleShowButton.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.handleNumberClicked= this.handleNumberClicked.bind(this)
        this.handleStyling=this.handleStyling.bind(this);

        this.handleChange = this.handleChange.bind(this);

    }

    componentWillMount() {
        this.pushData(0);
    }

    pushData(nr) {
        let {data}= this.props
        this.setState({
                    data,
                    question2: data.questionText,
                    answers2: [
                        data.answer1, 
                        data.answer2,
                        data.answer3,
                        data.answer4],
                    correct2: data.correct,
                    graphQuestion: data.graphQuestion,
                    url: data.url,
                    exposition: [
                        data.exposition1,
                        data.exposition2,
                        data.exposition3,
                        data.exposition4],
                    exposition2: [
                        {__html: data.exposition1},
                        {__html: data.exposition2},
                        {__html: data.exposition3},
                        {__html: data.exposition4},
                    ],
                    correct: data.correct,
                    total: data.length,
                    nr: nr+1
        })

        if(!this.state.dataBank){
                const dataBank = {}
                for (let i=0; i<data.length; i++) {
                    dataBank[`studentAnswer${i+1}`]=null
                }
                for (let i=0; i<data.length; i++) {
                    dataBank[`questionAnswered${i+1}`]=false
                }
                this.setState({dataBank})
            }

            if (!this.state.classNames) {
            let { total } = this.state
            let classNames={}
            for (let i=0; i<total; i++) {
                classNames[i+1]=["","","",""]
            }
            this.setState({classNames})
            }
    }



    nextQuestion() {
        let { nr, total } = this.state;

        if(nr === total){
            this.setState({
                displayPopup: 'flex'
            });
            this.props.createScore(this.state.score, this.state.total, this.props.match.params.id, 
                this.props.profile.school, this.props.profile.firstName, this.props.profile.lastName)

        } else {
            this.pushData(nr);
            this.setState({
                showButton: false,
                questionAnswered: false,
                wrongRight: "INCORRECT"
            });
        }
        this.setState({clearStyling:true})
    }

    handleChange = (e) => {
        this.selectQuestion(e.target.value)
        this.setState({
            [e.target.id]: parseInt(e.target.value)
        })
        
    }

    selectQuestion (nr) {
        this.pushData(nr)
        this.setState({clearStyling:true})
    }

    handlePageClick = data => {
        let selected = data.selected;
        this.pushData(selected)   
    }

    handleShowButton() {
        this.setState({
            showButton: true,
            questionAnswered: true
        })
    }

    handleStartQuiz() {
        this.setState({
            displayPopup: 'none',
            nr: 1
        });
    }

    handleNumberClicked(answer) {
        let {nr, dataBank} = this.state
        dataBank[`studentAnswer${nr}`]= answer
        this.setState({
            numberClicked: answer-1,
            dataBank 
        })
    }

    handleStyling(){
        this.setState({wrongRight: "CORRECT"})
    }

    retrieveScore = (score) => {
        
        this.setState({
            score: score
        })
    }

    render() {
        let { nr, total, question2, answers2, correct2, showButton, questionAnswered, 
            displayPopup, score, graphQuestion, dataBank, classNames, mcqData, wrongRight, exposition2, numberClicked, url} = this.state;
        
        const { auth } = this.props;
        if (!auth.uid && auth.isLoaded) return <Redirect to='/signin' />
        return (
            <div className="container">

                <Popup style={{display: displayPopup}} score={score} total={total} startQuiz={this.handleStartQuiz} />

                <div className="row">
                    <div className="col-lg-10 col-lg-offset-1">
                        <div className="topContainerFatFinger">
                        
                        {graphQuestion==="yes" ?
                            <div id="image">
                                <img src={url} className="graph" alt="graph" />
                            </div> : null}
                            <div id="question">
                                <h4>Question {nr}/{total}</h4>
                                <p>{question2}</p>
                            </div>
                        </div>
                        <Answers 
                        answers={answers2} 
                        correct={correct2} 
                        showButton={this.handleShowButton} 
                        isAnswered={questionAnswered}
                        dataBank= {dataBank} 
                        numberClicked={this.handleNumberClicked}
                        nr={nr}
                        styling={this.handleStyling}
                        total={total}
                        classNames={classNames}
                        mcqData={mcqData}
                        retrieveScore={this.retrieveScore} 
                        />
                        {showButton ? 
                            <div className="exposition-container"> 
                                    {/* {showButton && wrongRight==="CORRECT" ? <p className="exposition"><span className="correct">{wrongRight}</span>: {exposition[numberClicked]}</p> : null}
                                    {showButton && wrongRight==="INCORRECT"? <p className="exposition"><span className="incorrect">{wrongRight}</span>: {exposition[numberClicked]}</p> : null} */}
                                    {showButton && wrongRight==="CORRECT" ? <div><p className="exposition"><span className="correct">{wrongRight}</span></p> <p className="exposition" dangerouslySetInnerHTML={exposition2[numberClicked]}/></div> : null}
                                    {showButton && wrongRight==="INCORRECT"? <div><p className="exposition"><span className="incorrect">{wrongRight}</span></p> <p className="exposition" dangerouslySetInnerHTML={exposition2[numberClicked]}/></div> : null}
                            </div> : null
                        }

                        <div id="submit">
                            {/* {showButton && nr!==total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Next question</button> : null} */}
                            {nr===total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Finish quiz</button> : null}
                            {/* <button className="fancy-btn" onClick={this.selectQuestion}  >Question 2</button> */}
                            <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={total}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            onPageChange={this.handlePageClick}
                        />
                            

                            {/* {showButton ? <button className="fancy-btn" onClick={this.nextQuestion}  >{nr===total ? 'Finish quiz' : 'Next question'}</button> : null} */}
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
};

const mapStateToProps= (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, school, firstName, lastName) => dispatch(createScore(score, total, quizName, school, firstName, lastName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FatFinger)
