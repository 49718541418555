import React from 'react';
import { connect } from 'react-redux'
import './assigned.scss'

class Assigned extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        
    }

    
    render() {

        

        return (
            <div className="popup-container">
                <div className="container">
                    <div className="CCC maxWidth">
                        <div className="popupNeedHelp dataLoggingCheck">
                            <p className="newTaskTitle">
                                    Help us improve the platform
                            </p>
                            <p className="noClassText">
                                    Did your teacher assign you this quiz?
                                </p>
                            <div className='twoButtonAssignContainer dataLoggingCheck'>
                                    <div className="assignButton dataLoggingCheck" onClick={()=>this.props.assigned(true)}>
                                        <p className='assignButtonText dataLoggingCheck'>Yes, my teacher assigned this to me</p>
                                    </div>
                                    <div className="assignButton dataLoggingCheck" onClick={()=>this.props.assigned(false)}>
                                        <p className='assignButtonText dataLoggingCheck'>No, I chose to do this quiz</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Assigned;