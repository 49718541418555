import React from 'react';
import Answers from './answer';
import Popup from './Popup';
import MobileNavBar from '../layout/mobileNavbar'
// import Footer from 'Footer';
import firebase from '../auth/auth'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import { createScore } from '../../store/actions/quizscoreActions'
import {Howl} from 'howler'
import McqTrialNeedHelp from './mcqTrialNeedHelp'
import CountUp from 'react-countup';
import fanfare from '../../resources/397353__plasterbrain__tada-fanfare-g.flac'

import './mcqGraph.css';


class McqGraphTrial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nr: 0,
            total: 0,
            showButton: false,
            questionAnswered: false,
            score: 0,
            displayPopup: 'none',
            answers2: [],
            question2: {__html: "Loading"},
            correct2: [],
            exposition: [],
            questionbank: [],
            numberClicked: 0,
            clearStyling: true,
            wrongRight: "INCORRECT",
            needHelp: "off",
            coins: 0,
            nextCoins: 0,
            streak: 0,
            losingStreak: 0,
        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.handleShowButton = this.handleShowButton.bind(this);
        this.handleStartQuiz = this.handleStartQuiz.bind(this);
        this.handleIncreaseScore = this.handleIncreaseScore.bind(this)
        this.handleNumberClicked= this.handleNumberClicked.bind(this)
        this.handleStyling=this.handleStyling.bind(this);
        this.resetStreak=this.resetStreak.bind(this);

        this.fanfare = new Howl (
            {
                src: fanfare,
                volume: 0.5,
            }
        )

    }


    pushData(nr) {

        let subjectString =""
        let subject=""
        if (this.props.match.path === "/economics/:id") {
            subjectString= ""
            this.setState({subject:'economics'})
        } else if (this.props.match.path === "/biology/:id") {
            subjectString = "gcseBiologySingleScienceAQA"
            this.setState({subject:'biology'})
        }  else if (this.props.match.path === "/Physics/:id") {
            subjectString = "Physics"
            this.setState({subject:'Physics'})
        }  else if (this.props.match.path === "/chemistry/:id") {
            subjectString = "gcseChemistrySingleScienceAQA"
            this.setState({subject:'chemistry'})
        }



        firebase.firestore().collection("mcqDatabase"+subjectString)
        .where('quizName', '==', this.props.match.params.id)
        .where('school', '==', "GradeIt")
        .orderBy('position')
        .get()
        .then(querySnapshot => {
            const mcqData = querySnapshot.docs.map(doc => doc.data());
            
            this.setState({
            question2: {__html: mcqData[nr].questionText},
            answers2: [
                {__html: mcqData[nr].answer1},
                {__html: mcqData[nr].answer2},
                {__html: mcqData[nr].answer3},
                {__html: mcqData[nr].answer4},
            ],
            correct2: mcqData[nr].correct,
            graphQuestion: mcqData[nr].graphQuestion,
            url: mcqData[nr].url,
            exposition: [
                mcqData[nr].exposition1,
                mcqData[nr].exposition2,
                mcqData[nr].exposition3,
                mcqData[nr].exposition4
            ],
            exposition2: [
                {__html: mcqData[nr].exposition1},
                {__html: mcqData[nr].exposition2},
                {__html: mcqData[nr].exposition3},
                {__html: mcqData[nr].exposition4},
            ],
                correct: mcqData[nr].correct,
                total: mcqData.length,
                nr: this.state.nr + 1
        })})
    }

    componentWillMount() {
        let { nr } = this.state;
        this.pushData(nr);
        this.retrieveFlashcard()
    }

    retrieveFlashcard () {
        let subjectString =""
        if (this.props.match.path === "/economics/:id") {
            subjectString= ""
        } else if (this.props.match.path === "/biology/:id") {
            subjectString = "gcseBiologySingleScienceAQA"
        } else if (this.props.match.path === "/Physics/:id") {
            subjectString = "Physics"
        } else if (this.props.match.path === "/chemistry/:id") {
            subjectString = "gcseChemistrySingleScienceAQA"
        }

        firebase.firestore().collection('quizzes' + subjectString)
        .where('quizName', '==', this.props.match.params.id)
        .where('school', '==', "GradeIt")
        .get()
        .then(querySnapshot => {
            const data = querySnapshot.docs.map(doc => doc.data());
            this.setState({flashcard: data[0]['flashcard']})
        })
    }

    nextQuestion() {
        let { nr, total } = this.state;

        if(nr === total){
            this.setState({
                displayPopup: 'flex'
            })
            this.fanfare.play();

        } else {
            this.pushData(nr);
            this.setState({
                showButton: false,
                questionAnswered: false,
                wrongRight: "INCORRECT"
            });
        }
        this.setState({clearStyling:true})
        this.clearAnswers()
    }

    clearAnswers() {
        const eventName = 'resetAnswers';
        const event = document.createEvent('HTMLEvents');
        event.initEvent(eventName, true, true);
        event.eventName = eventName;

        // Triggers reset answers event
        document.dispatchEvent(event);
    }

    handleShowButton() {
        this.setState({
            showButton: true,
            questionAnswered: true
        })
    }

    handleStartQuiz() {
        this.setState({
            displayPopup: 'none',
            nr: 1
        });
    }

    handleIncreaseScore() {
        const {nextCoins, streak}= this.state
        
        let multiplier
        if (streak===0) {
            multiplier=5
        } else if (streak===1) {
            multiplier=10
        } else if (streak===2) {
            multiplier=15
        } else if (streak>=3) {
            multiplier=20
        }
 
        this.setState({
            score: this.state.score + 1,
            nextCoins: nextCoins+multiplier,
            streak: streak+1,
            losingStreak: 0,
        });
        this.resetCoins()      
    }

    losingStreakIncrement = () => {
        const {losingStreak, needHelp} = this.state
        this.setState({losingStreak : losingStreak + 1})
        if (losingStreak===2 && needHelp==="off") {
            this.setState({needHelp:"on"})
        }
    }

    toggleNeedHelp = () => {
        this.setState ({needHelp: "acknowledged"})
    }

    handleNumberClicked(answer) {
        this.setState({
            numberClicked: answer-1
        })
    }

    handleStyling(){
        this.setState({wrongRight: "CORRECT"})
    }

    resetCoins() {
        this.setState ({coins: this.state.nextCoins})
    }

    resetStreak() {
        this.setState ({streak:0})
    }

    getCoins () {
        const {coins, nextCoins}= this.state

        return (
            <div className="coinLabel">
                <CountUp 
                    start={coins} 
                    end={nextCoins}
                    duration={0.5}
                    useEasing={true}
                /> 
                <p className="coin">XP</p>
            </div>
        )
    }

    getStreak () {
        const {streak}= this.state

        return (
            <div className="coinLabel">
                <p className="coin">{streak} in a row</p>
            </div>
        )
    }

    render() {
        let { nr, total, question2, answers2, correct2, showButton, questionAnswered, 
            displayPopup, score, exposition, numberClicked, 
            wrongRight, clearStyling, graphQuestion, exposition2,
            url, flashcard, subject, needHelp} = this.state;

        
        return (
            <div>
                <MobileNavBar />
                <div className="container">
                    

                    <Popup style={{display: displayPopup}} score={score} total={total} startQuiz={this.handleStartQuiz} quizName={this.props.match.params.id}/>
                    {needHelp==="on"
                    ? <McqTrialNeedHelp flashcard={`/${subject}flashcards/${flashcard}`} toggleNeedHelp={this.toggleNeedHelp}/>
                    : null
                    }

                    <div className="row">
                        <div className="col-lg-10 col-lg-offset-1">
                            <div className="topContainer">
                                {graphQuestion==="yes"?
                                <div className="fullWidth">
                                    <div className="flexRow">
                                        <div className="question">
                                            <div className="gamificationTopContainerTrial">
                                                <div className="flexMaxWidth">
                                                    <div className="gamificationContainer">
                                                        <button className="btn-floating coin pulse"><i className="material-icons coin">attach_money</i></button>
                                                        {this.getCoins()}
                                                    </div>
                                                    <div className="gamificationContainer">
                                                        <i className="material-icons fire">whatshot</i>
                                                        {this.getStreak()}
                                                    </div>
                                                    <div className="gamificationContainer white">
                                                        <Link to={`/${subject}flashcards/${flashcard}`} className="gamificationContainer">
                                                            <i className="material-icons">style</i>
                                                            <p className="coin">Flashcards</p>
                                                        </Link>
                                                    </div>
                                                </div>  
                                                <h4 className="gamification">Question {nr}/{total}</h4>
                                            </div>
                                            <div className="mcqQuestionContainer Trial">
                                                    <p className="mcqQuestion Trial" dangerouslySetInnerHTML={question2}></p>
                                            </div>
                                        </div>
                                        
                                            <div id="image">
                                                <img src={url} className="graph" alt="graph" />
                                            </div>
                                            <div className="mcqQuestionContainer Trial Mobile">
                                                    <p className="mcqQuestionMobile Trial Mobile" dangerouslySetInnerHTML={question2}></p>
                                            </div>
                                    </div>
                                    
                                </div>
                                :
                                <div className="fullWidth">
                                    <div className="flexRow">
                                        <div className="questionContainer">
                                            <div className="gamificationTopContainerTrial">
                                                <div className="flexMaxWidth">
                                                    <div className="gamificationContainer">
                                                        <button className="btn-floating coin pulse"><i className="material-icons coin">attach_money</i></button>
                                                        {this.getCoins()}
                                                    </div>
                                                    <div className="gamificationContainer">
                                                        <i className="material-icons fire">whatshot</i>
                                                        {this.getStreak()}
                                                    </div>
                                                    <div className="gamificationContainer white">
                                                        <Link to={`/${subject}flashcards/${flashcard}`} className="gamificationContainer">
                                                            <i className="material-icons">style</i>
                                                            <p className="coin">Flashcards</p>
                                                        </Link>
                                                    </div>
                                                </div>    
                                                <h4 className="gamification">Question {nr}/{total}</h4>
                                            </div>
                                            <div className="mcqQuestionContainerTrial">
                                                <p className="mcqQuestionTrial" dangerouslySetInnerHTML={question2}></p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                }
                            </div>

                            <Answers 
                            answers={answers2} 
                            correct={correct2} 
                            showButton={this.handleShowButton} 
                            isAnswered={questionAnswered} 
                            increaseScore={this.handleIncreaseScore} 
                            exposition={exposition}
                            losingStreakIncrement={this.losingStreakIncrement}
                            numberClicked={this.handleNumberClicked}
                            styling={this.handleStyling}
                            clearStyling={clearStyling}
                            resetStreak={this.resetStreak}
                            />
                            {showButton ? 
                                <div className="exposition-container"> 
                                        {/* {showButton && wrongRight==="CORRECT" ? <p className="exposition"><span className="correct">{wrongRight}</span>: {exposition[numberClicked]}</p> : null}
                                        {showButton && wrongRight==="INCORRECT"? <p className="exposition"><span className="incorrect">{wrongRight}</span>: {exposition[numberClicked]}</p> : null} */}
                                        {showButton && wrongRight==="CORRECT" ? <div><p className="exposition"><span className="correct">{wrongRight}</span></p> <p className="exposition" dangerouslySetInnerHTML={exposition2[numberClicked]}/></div> : null}
                                        {showButton && wrongRight==="INCORRECT"? <div><p className="exposition"><span className="incorrect">{wrongRight}</span></p> <p className="exposition" dangerouslySetInnerHTML={exposition2[numberClicked]}/></div> : null}
                                </div> : null
                            }

                            <div id="submit">
                                {showButton && nr!==total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Next question</button> : null}
                                {showButton && nr===total ? <button className="fancy-btn" onClick={this.nextQuestion}  >Finish quiz</button> : null}
                                {/* {showButton ? <button className="fancy-btn" onClick={this.nextQuestion}  >{nr===total ? 'Finish quiz' : 'Next question'}</button> : null} */}
                            </div>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        );
    }
};

const mapStateToProps= (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createScore: (score, total, quizName, school, firstName, lastName) => dispatch(createScore(score, total, quizName, school, firstName, lastName))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McqGraphTrial)
