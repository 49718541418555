import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Redirect } from 'react-router-dom'
import { submitQuestion } from '../../store/actions/mcqFormAction'
import firebase from '../auth/auth'


import { Redirect } from 'react-router-dom'
import {storage} from '../auth/auth';
import { ResourceDirectory } from './resourceDirectory'
import './resourceUpload.scss'

class ResourceUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            image: null,
            progress: 0,
            formComplete: "no",
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
    }



    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        }, () => this.checkSubmittable())
        
    }

    checkSubmittable = () => {
        const { level, subject, board, month, paper, url, tier, type, year} = this.state

        if ((level && subject && board && month && paper && url && type && year) && ((level=="GCSE" && tier) || level!=="GCSE")) 
            {
                this.setState ({formComplete: "yes"})
            } else {
                this.setState ({formComplete: "no"})
            }
    }


    handleImage = e => {
        if (e.target.files[0]) {
          const image = e.target.files[0];
          this.setState(() => ({image}));
        }
    }

    handleUpload = () => {
        const {image} = this.state;
        const uploadTask = storage.ref(`pastPapers/${image.name}`).put(image);
        uploadTask.on('state_changed', 
        (snapshot) => {
          // progrss function ....
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.setState({progress});
        }, 
        (error) => {
             // error function ....
          console.log(error);
        }, 
      () => {
          // complete function ....
          storage.ref('pastPapers').child(image.name).getDownloadURL().then(url => {

            const { level, subject, board, month, paper, tier, type, year } = this.state
            if ((level && subject && board && month && paper && type && year ) && ((level=="GCSE" && tier) || level!=="GCSE")) {
                this.setState({
                    url,
                    formComplete: "yes"
                });
            } else {
                this.setState({
                    url,
                });
            }
          })
      }
      );
    }

    handleSubmit = (e) => {
        e.preventDefault()
        console.log("Submitted")

        const { level, subject, board, month, paper, url, tier, type, year} = this.state

        firebase.firestore().collection("pastPapers").add({
            examBoard: board,
            level: level,
            tier: tier || "",
            paper: paper,
            year: parseInt(year),
            month: month,
            subject: subject,
            type: type,
            url: url,
            createdAt: new Date(),
            user: this.props.profile.initials,
            uid: (Math.random().toFixed(9)).substring(2,11)
        }).then(() => {
            console.log("resource added successfully")                        
        }).catch((err) => {
            console.log(err)
        })
    }


    render() {
        const { auth } = this.props;
        // const { authError } = this.props;
        const { graphQuestion, formComplete, preview } = this.state;
        
            return (
                <div className="flexRow resourceUpload">
                    <div className="container 50">
                        <form onSubmit={this.handleSubmit} className="white">
                            <h5 className="grey-text text-darken-3">Add resource</h5>
                            <div className="input-field">
                                <label className="graphQuestionLabel" htmlFor="graphQuestion"> Choose level: </label>
                                <br/>
                                <select className="graphQuestion" id="level" value={this.state.value} onChange={this.handleChange}>
                                    <option value="Default">Please choose the difficulty level</option>
                                    <option value="GCSE">GCSE</option>
                                    <option value="AS level">AS level</option>
                                    <option value="A-level">A-level</option>
                                </select>
                            </div>

                            {this.state.level=="GCSE" ?
                            <div className="input-field">
                                <label className="graphQuestionLabel" htmlFor="graphQuestion"> Choose tier: </label>
                                <br/>
                                <select className="graphQuestion" id="tier" value={this.state.value} onChange={this.handleChange}>
                                    <option value="Default">Please choose the tier</option>
                                    <option value="Higher">Higher</option>
                                    <option value="Foundation">Foundation</option>
                                </select>
                            </div>
                            : null
                            }
                            
                            <div className="input-field">
                                <label className="graphQuestionLabel" htmlFor="difficulty">Subject</label>
                                <br/>
                                <select className="graphQuestion" id="subject" value={this.state.value} onChange={this.handleChange}>
                                    <option value="Default">Please choose the subject</option>
                                    <option value="Mathematics">Mathematics</option>
                                    <option value="Economics">Economics</option>
                                    <option value="Biology">Biology</option>
                                    <option value="Chemistry">Chemistry</option>
                                    <option value="Physics">Physics</option>
                                    <option value="Combined Biology">Combined Biology</option>
                                    <option value="Combined Chemistry">Combined Chemistry</option>
                                    <option value="Combined Physics">Combined Physics</option>
                                </select>
                            </div>

                            <div className="input-field">
                                <label className="graphQuestionLabel" htmlFor="difficulty">Exam board</label>
                                <br/>
                                <select className="graphQuestion" id="board" value={this.state.value} onChange={this.handleChange}>
                                    <option value="Default">Please choose the exam board</option>
                                    <option value="Edexcel">Edexcel</option>
                                    <option value="AQA">AQA</option>
                                    <option value="OCR">OCR</option>
                                </select>
                            </div>

                            <div className="input-field">
                                <label className="graphQuestionLabel" htmlFor="difficulty">Year</label>
                                <br/>
                                <select className="graphQuestion" id="year" value={this.state.value} onChange={this.handleChange}>
                                    <option value="Default">Please choose the year</option>
                                    <option value={2021}>2021</option>
                                    <option value={2020}>2020</option>
                                    <option value={2019}>2019</option>
                                    <option value={2018}>2018</option>
                                    <option value={2017}>2017</option>
                                    <option value={2016}>2016</option>
                                    <option value={2015}>2015</option>
                                    <option value={2014}>2014</option>
                                    <option value={2013}>2013</option>
                                    <option value={2012}>2012</option>
                                    <option value={2011}>2011</option>
                                    <option value={2010}>2010</option>
                                    <option value={2009}>2009</option>
                                    <option value={2008}>2008</option>
                                </select>
                            </div>


                            <div className="input-field">
                                <label className="graphQuestionLabel" htmlFor="difficulty">Month</label>
                                <br/>
                                <select className="graphQuestion" id="month" value={this.state.value} onChange={this.handleChange}>
                                    <option value="Default">Please choose the month</option>
                                    <option value="May/June">May/June</option>
                                    <option value="November">November</option>
                                    <option value="January">January</option>
                                </select>
                            </div>

                            <div className="input-field">
                                <label className="graphQuestionLabel" htmlFor="difficulty">Type</label>
                                <br/>
                                <select className="graphQuestion" id="type" value={this.state.value} onChange={this.handleChange}>
                                    <option value="Default">Please choose the resource type</option>
                                    <option value="Question paper">Question paper</option>
                                    <option value="Mark scheme">Mark scheme</option>
                                    <option value="Insert">Insert</option>
                                </select>
                            </div>
                            
                            <div className="input-field">
                                <label htmlFor="questionText">Resource name</label>
                                <input type="text" id="paper" onChange={this.handleChange}/>
                            </div>

                            <div className="input-field">
                                <input className="chooseFile" type="file" onChange={this.handleImage} />
                                {this.state.image ? <p className="uploadButton" onClick={this.handleUpload}>Upload</p> : <p className="uploadButtonGreyed" onClick={this.handleUpload}>Upload</p> }
                                <br/>
                                <progress value={this.state.progress} max="100"/>
                                <br/>
                            </div>

                            <div className="submitDiv">
                                { formComplete==="yes" ? <button className="btn pink lighten-1 z-depth-0 waves-effect waves-light">Submit</button> : 
                                <p className="greyedOutButton">Submit </p>}
                            </div>
                            <div className="red-text">
                                { formComplete==="no" ? <p className="warning">You must fill out all fields before you submit. Upload an image for graph questions.</p> : null}
                            </div>

                        </form>
                    </div>
                    <div className="container 50">
                        <ResourceDirectory />
                    </div>
                </div>
            )
        
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        submitQuestion: (question) => dispatch(submitQuestion(question))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceUpload)
